import React from 'react';
import '../../../resources/main.scss';
import './EditGuest.scss';
import {Link} from 'react-router-dom';
import Menu from '../../../components/AdminMenu/AdminMenu';
import {guestsService} from '../../../services/AdminService';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { formService } from '../../../services/FormService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import {errorService} from '../../../services/ErrorService';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';

class EditGuest extends React.Component{
    constructor(props){
        super(props);

        this.state={
            guestInfo: {
                code: '',
                name: '',
                phoneNumber: '',
                company: '',
                companyNumber: '',
                menus: [],
                allergy: '',
                allergyMentions: '',
                otherRestrictions: '',
                housing: false,
                transport: false,
                other: '',
                phone: '',
                email: '',
                eventCode: '',
                clientSlug: ''
            },
            tabValue: 0
        }
        this.notificationSystem = React.createRef();
    }

    componentDidMount(){
        if (this.props.match.params.id) {
            guestsService.getGuest(this.props.match.params.id).then(response=>{
                let guestInfo = { ...this.state.guestInfo };
                guestInfo = {
                  ...response.data,
                  companyNumber: response.data.company_number,
                  allergyMentions: response.data.allergy_mentions,
                  otherRestrictions: response.data.other_restrictions
                };

                guestInfo.menus = [];

                response.data.menus.map(menu => {
                  guestInfo.menus.push({
                    id: menu.id,
                    menuType: menu.menu_type
                  });
                })

                this.setState({ guestInfo });
            })
            .catch(error=>{
              if (error.response.status === 401) {
                  errorService.handleUnauthorized();
                  this.props.history.push("/app/login");
                }
            })
          } else {
            this.props.history.push("/app/invitati");
          }
    }
    updateTabValue = (event, value) => {
        this.setState({ tabValue: value });
      }
    updateField = event => {
        const field = event.target.name;
        const guestInfo = { ...this.state.guestInfo };
        guestInfo[field] = event.target.value;

        this.setState({
            guestInfo
        }, () => {
          if (field === 'companyNumber') {
            this.generateMenus();
          }
        });
    };
    updateCompany = companyValue => {
        const guestInfo = { ...this.state.guestInfo };
        guestInfo.company = companyValue;

        guestInfo.companyNumber = 1;

        if(companyValue === 'single') {
          guestInfo.companyNumber = 1;
        }

        if(companyValue === 'couple') {
          guestInfo.companyNumber = 2;
        }

        this.setState({
            guestInfo
        }, () => this.generateMenus() );
    };
    generateMenus = () => {
        let guestInfo = this.state.guestInfo;

        guestInfo.menus = [];

        for(let i = 0; i < this.state.guestInfo.companyNumber; i++) {
          guestInfo.menus.push({
            menuType: 'normal'
          });
        }

        this.setState({ guestInfo }, () => {
          this.setState({ tabValue: 0 });
        });
      }
    updateAllergy = allergyValue => {
        const guestInfo = { ...this.state.guestInfo };
        guestInfo.allergy = allergyValue;
        this.setState({
            guestInfo
        });
    };
    updateMenu = (index, menuValue) => {
        const guestInfo = { ...this.state.guestInfo };
        guestInfo.menus[index].menuType = menuValue;

        this.setState({
            guestInfo
        });
    };
    updateHousing = () => {
        const guestInfo = { ...this.state.guestInfo };
        guestInfo.housing = !guestInfo.housing;

        this.setState({
            guestInfo
        });
    };
    updateTransport = () => {
        const guestInfo = { ...this.state.guestInfo };
        guestInfo.transport = !guestInfo.transport;
        this.setState({
            guestInfo
        });
    };
    handleSubmit=event=>{
        event.preventDefault();
        const notification = this.notificationSystem.current;
        formService.editForm(this.state.guestInfo).then(response=>{
            notification.addNotification({
                message:
                    'Raspunsul tau a fost trimis cu succes.',
                level: 'success',
                position: 'tc'
            });

        })
        .catch(err=>{
            notification.addNotification({
                message: err.response.data.error,
                level: 'error',
                position: 'tc'
            });

            return;
        })
    }
    goBack=()=>{
        this.props.history.goBack();
    }
    render(){
        return(
            <div className='standard guests edit'>
                <Menu/>
                <AdminMobileHeader/>
                <div className='guests__page-content guestss__page-content'>
                    <div className='guests__container'>
                        <h2 className='guests__title'>Editeaza invitat</h2>
                       <div className='guests__full-wrapper'>
                       <form className='form__form edit__form' onSubmit={this.handleSubmit}>
                            <h4 className='form__label-name'>Nume</h4>
                            <input type='text'
                                required
                                name='name'
                                className='form__field'
                                value={this.state.guestInfo.name}
                                onChange={this.updateField} />
                            <h4 className='form__label-name'>Număr de telefon:</h4>
                            <input type='number'
                                name='phone'
                                className='form__field'
                                value={this.state.guestInfo.phone}
                                onChange={this.updateField} />
                            <h4 className='form__label-name'>Email:</h4>
                            <input type='email'
                                name='email'
                                className='form__field'
                                value={this.state.guestInfo.email}
                                onChange={this.updateField} />
                            <h4 className='form__label-name'>Cu cine vei veni?</h4>
                            <label className='form__checkbox-container'>Singur
                                              <input type="radio" checked={this.state.guestInfo.company === 'single'} name="single" onChange={() => this.updateCompany('single')} />
                                <span className="form__checkmark"></span>
                            </label>
                            <label className='form__checkbox-container'>Cu partenerul/partenera
                                              <input type="radio" checked={this.state.guestInfo.company === 'couple'} name="couple" onChange={() => this.updateCompany('couple')} />
                                <span className="form__checkmark"></span>
                            </label>
                            <label className='form__checkbox-container'>Cu întreaga familie
                                              <input type="radio" checked={this.state.guestInfo.company === 'family'} name="family" onChange={() => this.updateCompany('family')} />
                                <span className="form__checkmark"></span>
                            </label>
                            {this.state.guestInfo.company === 'family' ?
                                <div>  <h4 className='form__label-name'>Dacă ai ales cu întreaga familie, spune-ne câte persoane veți fi:</h4>
                                    <input type='text'
                                        name='companyNumber'
                                        className='form__field'
                                        value={this.state.guestInfo.companyNumber}
                                        onChange={this.updateField} /></div> : ''}
                            <h3 className='form__subtitle'>Despre Meniu</h3>
                            <h4 className='form__label-name'>Meniurile tale</h4>
                            { this.state.guestInfo.companyNumber ? (
                              <div>
                                <Tabs
                                  value={this.state.tabValue}
                                  onChange={this.updateTabValue}
                                  variant="scrollable"
                                  scrollButtons="on"
                                  aria-label="scrollable auto tabs example"
                                >
                                  { this.state.guestInfo.menus.map((menu, index) => (
                                    <Tab
                                      key={index}
                                      label={`Meniu ${index + 1}`}
                                      id={`scrollable-force-tab-${index}`}
                                      aria-controls={`scrollable-force-tabpanel-${index}`}
                                      selected={true}
                                      />
                                  )) }
                                </Tabs>
                                { this.state.guestInfo.menus.map((menu, index) => (
                                  <div
                                    key={index}
                                    role="tabpanel"
                                    value={this.state.tabValue}
                                    index={index}
                                    hidden={this.state.tabValue !== index}
                                    id={`scrollable-force-tabpanel-${index}`}
                                    aria-labelledby={`scrollable-force-tab-${index}`}
                                    >
                                    { this.state.tabValue === index && (
                                      <div className='form__box'>
                                          <label className='form__checkbox-container'>Normal
                                              <input type="radio" checked={this.state.guestInfo.menus[index].menuType === 'normal'} name="normal" onChange={() => this.updateMenu(index, 'normal')} />
                                              <span className="form__checkmark"></span>
                                          </label>
                                          <label className='form__checkbox-container'>Vegetarian
                                              <input type="radio" checked={this.state.guestInfo.menus[index].menuType === 'vegetarian'} name="vegetarian" onChange={() => this.updateMenu(index, 'vegetarian')} />
                                              <span className="form__checkmark"></span>
                                          </label>
                                      </div>
                                    ) }
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h4 className='form__label-name'>Selectează numărul de persoane pentru generarea meniurilor.</h4>
                            ) }

                            <h4 className='form__label-name'>Ai vreo alergie la anumite alimente?</h4>
                            <div className='form__box'>
                                <label className='form__checkbox-container'>Nu
                                                <input type="radio" checked={this.state.guestInfo.allergy === 'no'} name="allergyNo" onChange={() => this.updateAllergy('no')} />
                                    <span className="form__checkmark"></span>
                                </label>
                                <label className='form__checkbox-container'>Da
                                                <input type="radio" checked={this.state.guestInfo.allergy === 'yes'} name="allergyYes" onChange={() => this.updateAllergy('yes')} />
                                    <span className="form__checkmark"></span>
                                </label>
                            </div>
                            {this.state.guestInfo.allergy === 'yes' ?
                                <div>  <h4 className='form__label-name'>Enumeră alergiile pe care le ai:</h4>
                                    <input type='text'

                                        name='allergyMentions'
                                        className='form__field'
                                        value={this.state.guestInfo.allergyMentions}
                                        onChange={this.updateField} /></div> : ''}
                            <h4 className='form__label-name'>Alte restricții pe care le ai:</h4>
                              <input type='text'
                                name='otherRestrictions'
                                className='form__field'
                                value={this.state.guestInfo.otherRestrictions}
                                onChange={this.updateField} />


                            <h4 className='form__label-name'>Doresc</h4>
                            <div className='form__box'>
                                <label className='form__checkbox-container'>
                                Cazare
                                                <input type="checkbox" checked={this.state.guestInfo.housing} name="housingYes" onChange={() => this.updateHousing()} />
                                    <span className="form__checkmark"></span>
                                </label>
                                <label className='form__checkbox-container'>Transport
                                                <input type="checkbox" checked={this.state.guestInfo.transport} name="transportYes" onChange={() => this.updateTransport()} />
                                    <span className="form__checkmark"></span>
                                </label>
                            </div>

                            <h4 className='form__label-name'>Alte mențiuni (optional)</h4>
                            <textarea type='text'
                                name='other'
                                rows='5'
                                cols='5'
                                className='form__field form__textarea'
                                value={this.state.guestInfo.other}
                                onChange={this.updateField} />

                            <div className='form__bottom'>

                                <button type='submit'>Editeaza</button>
                            </div>
                            <Link onClick={()=>this.goBack()} to={'/'} className='standard__link'> <img src='/images/back.png' alt='back' /></Link>
                        </form>
                        </div>
                    </div>
                    </div>
                    <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default EditGuest;
