import React from 'react';

export default function SecondArrow(props) {
    let className = props.type === "next" ? "SecondNextArrow" : "SecondPrevArrow";
    className += " arrow";
    const char = props.type === "next" ? <img className='slider-arrow' src='/images/back-black.png' alt="secondary arrow" /> : <img className='slider-arrow' src='/images/next-black.png' alt="secondary arrow"/>;
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
