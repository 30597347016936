import React from 'react';
import './AddEvent.scss';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import {eventService} from '../../services/EventService';
import {errorService} from '../../services/ErrorService';

class AddEvent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            eventInfo: {
                name: '',
                slug: '',
                date: '',
                type: '',
                location: '',
                code: ''
            }
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount(){
        if (this.props.match.params.id) {
            eventService.getEventById(this.props.match.params.id).then(response=>{
                let eventInfo = { ...this.state.eventInfo };
                eventInfo = response.data;
                this.setState({ eventInfo });
                console.log(response.data.events);
                console.log(this.state.eventInfo)
            })
            .catch(error=>{
              if (error.response.status === 401) {
                  errorService.handleUnauthorized();
                  this.props.history.push("/app/login");
                }
            })
          } else {

          }
    }
    updateField = event => {
        const field = event.target.name;
        const eventInfo = { ...this.state.eventInfo };
        eventInfo[field] = event.target.value;
        this.setState({
            eventInfo
        });
    };
    handleAddEvent=event=>{
        event.preventDefault();
        const notification = this.notificationSystem.current;
        eventService.createOrUpdateEvent(this.state.eventInfo).then(response=>{
            notification.addNotification({
                message:
                  `Felicitari! Evenimentul tău a fost ${this.state.eventInfo.id ? 'editat' : 'creat'}  cu succes.`,
                level: 'success',
                position: 'tc'
              });
              setTimeout(() => {
                this.props.history.push('/app/evenimente');
              }, 2000);
        })


        .catch(err=>{

            notification.addNotification({
                message: 'Ne pare rau, dar a aparut o problema. Va rog sa dati refresh sau sa contactati administratorul site-ului.',
                level: 'error',
                position: 'tc'
              });
        })
      }
    render() {
        return (
            <div className='standard event'>
                <div className='event__container'>
                    <h2 className='event__title'>{this.state.eventInfo.id? 'Editează evenimentul':'Adaugă un eveniment'}</h2>
                    <form className='form__form event__form' onSubmit={this.handleAddEvent}>
                        <h4 className='form__label-name'>Nume eveniment:</h4>
                        <input type='text'
                            required
                            name='name'
                            className='form__field'
                            value={this.state.eventInfo.name}
                            onChange={this.updateField} />
                        <h4 className='form__label-name'>Slug:</h4>
                        <input type='text'
                            required
                            name='slug'
                            className='form__field'
                            value={this.state.eventInfo.slug}
                            onChange={this.updateField} />
                        <h4 className='form__label-name'>Data:</h4>
                        <input type='date'
                            required
                            name='date'
                            className='form__field'
                            value={this.state.eventInfo.date}
                            onChange={this.updateField} />
                        <select
                            className="event__select"
                            title="tip"
                            value={this.state.eventInfo.type}
                            name="type"
                            onChange={this.updateField}
                            label='tip'
                        >
                            <option value="">Tip eveniment</option>
                            <option value="wedding">Nunta</option>
                            <option value="civilCeremony">Cununia civila</option>
                            <option value="both">Cununie civila si nunta</option>

                        </select>
                        <h4 className='form__label-name'>Locatia:</h4>
                        <input type='text'
                            required
                            name='location'
                            className='form__field'
                            value={this.state.eventInfo.location}
                            onChange={this.updateField} />
                        <h4 className='form__label-name'>Cod:</h4>
                        <input type='text'
                            required
                            name='code'
                            className='form__field'
                            value={this.state.eventInfo.code}
                            onChange={this.updateField} />
                        <button className='event__submit-button' type='submit'>{this.state.eventInfo.id? 'Editează':'Adaugă'}</button>
                    </form>
                </div>
                <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
            </div>
        )
    }
}

export default AddEvent;
