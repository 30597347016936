import axios from 'axios';
import { loginService } from './LoginService';

export const storyStepService = {
  removeDocument
}
function removeDocument(stepId) {

  let url = `${process.env.REACT_APP_API_URL}/api/story_steps/remove_document/${stepId}`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}
