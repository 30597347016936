import axios from 'axios';
import { loginService } from './LoginService';

export const userService={
    getClients,
    getProfile,
    getClientUsers,
    createOrUpdateUser,
    addProfilePicture,
    getBills
}

function getClients(){
    const requestOptions = {
        headers: {
          Accept: 'application/json'
        }

  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/clients`;
  return axios.get(url, requestOptions);
}

function getProfile(){
    const requestOptions = {
        headers: {
          Accept: 'application/json'
        }

  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/users/get_by_token`;
  return axios.get(url, requestOptions);
}

function getClientUsers(){
    const requestOptions = {
        headers: {
          Accept: 'application/json'
        }

  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/users/client_users`;
  return axios.get(url, requestOptions);
}

function getBills(){
    const requestOptions = {
        headers: {
          Accept: 'application/json'
        }

  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/users/get_bills`;
  return axios.get(url, requestOptions);
}

function createOrUpdateUser(userData){

    let url = `${process.env.REACT_APP_API_URL}/api/users${userData.id ? `/${userData.id}` : ''}`;
    const requestOptionsPost = {
        method: userData.id? 'PUT': 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data: {
          user: { ...userData }
        }
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
}
function addProfilePicture(profileData){
  let url = `${process.env.REACT_APP_API_URL}/api/invoice`;
  const requestOptionsPost = {
      method: 'PUT',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: buildRequestData(profileData)
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function buildRequestData(invoiceData) {
  let formData = new FormData();

  const document = invoiceData.invoiceDocument;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`invoice[invoice_document]`, document.url);
    } else {
      formData.append(`invoice[invoice_document]`, document, document.name);
    }
  }

  return formData;
}
