import React from 'react';
import {guestsService} from '../../services/AdminService';
import { VictoryPie, VictoryLabel, VictoryAnimation } from "victory";
import Divider from '@material-ui/core/Divider';

class ParticipantsDashboard extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          percentages: {
            isSingle: 0,
            isCouples: 0,
            isFamily: 0,
            hasTransport: 0,
            hasHousing: 0,
            civil_marriage_attend:0,
            dataSingle: this.getData(0),
            dataCouple: this.getData(0),
            dataFamily: this.getData(0),
            dataTransport: this.getData(0),
            dataHousing: this.getData(0),
            dataCivilMarriageAttend:this.getData(0)
          },
          extraInformations: {
            total_guests: 0,
            total_persons: 0,
            single_count: 0,
            couples_count: 0,
            family_count: 0,
            housing_count: 0,
            transport_count: 0,
            civil_marriage_attend_count:0
          }
      }
  }

  componentDidMount() {
    guestsService.guestsStatistics(this.props.currentEventId).then(response => {
        this.setData(response);
    })
    .catch(error => {
      if (error.response.status === 401) {
        this.props.history.push("/login");
      }
    })
  }

  getData(percent) {
    return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
  }

  setData(response) {
    this.setDataValues(response.data.single, 'isSingle', 'dataSingle');
    this.setDataValues(response.data.couples, 'isCouples', 'dataCouple');
    this.setDataValues(response.data.family, 'isFamily', 'dataFamily');
    this.setDataValues(response.data.transport, 'hasTransport', 'dataTransport');
    this.setDataValues(response.data.housing, 'hasHousing', 'dataHousing');
    this.setDataValues(response.data.civil_marriage_attend, 'civil_marriage_attend', 'dataCivilMarriageAttend');

    let extraInformations = { ...this.state.extraInformations };
    extraInformations = { ...response.data };

    this.setState({ extraInformations });
  }

  setDataValues(percent, key, dataKey) {
    if (!percent) {
      percent = 0
    }

    let percentages = { ...this.state.percentages };

    percentages[key] = percent;
    percentages[dataKey][0].y = percent;
    percentages[dataKey][1].y = 100 - percent;

    this.setState({ percentages });
  }

  render() {
    return (
      <div className="website-form">
        <h4 className='website-form__subtitle'>Număr participanți: { this.state.extraInformations.total_persons }</h4>
          <div className="dashboard__statistics-wrapper">
            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataSingle}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                          const color = 'lightgreen';
                          return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.single_count).toFixed(0)} - ${(newProps.percentages.isSingle).toFixed(2)}%`}
                          style={{ fontSize: 45 }}
                        />
                      );
                    }}
                  </VictoryAnimation>
                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Invitati single</h2>
              </div>
            </div>
            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataCouple}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                        const color = "lightgreen";
                        return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.couples_count).toFixed(0)} - ${Math.round(newProps.percentages.isCouples)}%`}
                          style={{ fontSize: 45 }}
                        />
                      );
                    }}
                  </VictoryAnimation>
                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Invitati cu partener</h2>
              </div>
            </div>
            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataFamily}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                        const color = "lightgreen";
                        return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.family_count).toFixed(0)} - ${(newProps.percentages.isFamily).toFixed(2)}%`}
                          style={{ fontSize: 45 }}
                        />
                      );
                    }}
                  </VictoryAnimation>

                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Invitati cu familia</h2>
              </div>
            </div>
          </div>
          <Divider />
          <div className="dashboard__statistics-wrapper">
            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataTransport}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                          const color = 'lightgreen';
                          return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.transport_count).toFixed(0)} - ${(newProps.percentages.hasTransport).toFixed(2)}%`}
                          style={{ fontSize: 45 }}
                        />
                      );
                    }}
                  </VictoryAnimation>
                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Invitati care au nevoie de transport</h2>
              </div>
            </div>
            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataHousing}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                          const color = 'lightgreen';
                          return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.housing_count).toFixed(0)} - ${(newProps.percentages.hasHousing).toFixed(2)}%`}
                          style={{ fontSize: 38 }}
                        />
                      );
                    }}
                  </VictoryAnimation>
                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Invitati care au nevoie de cazare</h2>
              </div>
            </div>


            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataCivilMarriageAttend}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                          const color = 'lightgreen';
                          return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.civil_marriage_attend_count).toFixed(0)} - ${(newProps.percentages.civil_marriage_attend).toFixed(2)}%`}
                          style={{ fontSize: 38 }}
                        />
                      );
                    }}
                  </VictoryAnimation>
                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Invitati care vin la cununie</h2>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default ParticipantsDashboard;
