import React from 'react';
import './Footer.scss';
import * as Scroll from 'react-scroll';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    scrollToBenefits = () => {
        const scroller = Scroll.scroller;

        scroller.scrollTo('benefits', {
            duration: 1000,
            smooth: true,
            spy: true
        })
    }
    render() {
        return (
            <div className='footer'>
                <div className='footer__container'>
                    <div className='footer__column'>
                        <img src='/images/logo.png' alt='logo' className='footer__logo' />
                        <h4>Planifică nunta pe care o vrei alături de noi!</h4>
                        <h4>0747774796</h4>
                        <h4>we.are.a.match@facemnunta.ro</h4>
                    </div>
                    <div className='footer__column'>
                        <h3>Navigare</h3>
                        <Link to='/' onClick={this.scrollToTop} className='standard__link'> <h4>Facem nuntă</h4></Link>
                        <Link to='/' className='standard__link' onClick={() => this.scrollToBenefits()}> <h4   >Beneficii</h4></Link>
                        <Link to='/app/creeaza-cont' onClick={this.scrollToTop} className='standard__link'> <h4>Inregistrare</h4></Link>

                    </div>
                    <div className='footer__column'>
                        <h3>Despre noi</h3>

                        <a
                            className='sp-wa-call standard__link'
                            href={`https://api.whatsapp.com/send?phone=+40747774796&text=Bună, %0aCu ce te putem ajuta?`} target="_blank"
                            rel="noopener noreferrer"> <h4>Contactează-ne</h4></a>
                                                                            <h4 onClick={() =>
                            window.open(
                                '/documents/politica_cookies.pdf',
                                '_blank'
                            )
                        } className='standard__link'>Termeni și condiții</h4>
                        <h4 onClick={() =>
                            window.open(
                                '/documents/termeni-si-conditii.pdf',
                                '_blank'
                            )
                        } className='standard__link'>Politică de confidențialitate</h4>
                                                <h4 onClick={() =>
                            window.open(
                                '/documents/politica_confidentialitate.pdf',
                                '_blank'
                            )
                        } className='standard__link'>Politică cookies</h4>
                        <h4 onClick={() =>
                            window.open(
                                '/documents/politica_anulare.pdf',
                                '_blank'
                            )
                        } className='standard__link'>Politică de anulare</h4>

                        <Link to='/' className='standard__link' onClick={() => this.scrollToBenefits()}> <h4  className='standard__link' >Beneficii</h4></Link>
                    </div>
                    <div className='footer__column'>
                        <h3>Rămâi conectat cu noi</h3>
                        <a className='standard__link'
                            href="https://www.facebook.com/facemnunta"
                            target="_blank"
                            rel="noopener noreferrer nofollow"> <div className='footer__social-box'>
                                <img src='/images/facebook.png' alt='facebook' />
                                <h4>Facebook</h4>
                            </div></a>
                        <a className='standard__link'
                            href="https://www.instagram.com/facemnunta"
                            target="_blank"
                            rel="noopener noreferrer nofollow "> <div className='footer__social-box'>
                                <img src='/images/instagram.png' alt='instagram' />
                                <h4>Instagram</h4>
                            </div></a>

                    </div>

                </div>
                <h5 className='footer__copyright'>©Copyright by Prometeus Tech 2023</h5>
                <h5 className='footer__copyright'>Prometeus Tech | CUI 38023044 | Nr. inreg J33/1364/25.07.2017 </h5>

                <a className='standard__link'
                    href="https://anpc.ro/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"> <h5 className='footer__copyright'>ANPC</h5></a>
            </div>
        )
    }
}

export default Footer;
