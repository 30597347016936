import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import './LoginOptionsModal.scss'

const customStyles = {
    overlay: {
        zIndex: 30,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        borderRadius: '32px'
    }
};

class LoginOptionsModal extends React.Component {

  loginRedirect = () => {
    window.fbq('track', 'StartTrial', {value: '0.00', currency: 'RON', predicted_ltv: '0.00'});
  }

  paymentRedirect = () => {
    window.fbq('track', 'Purchase', {value: 0.00, currency: 'RON'});
  }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}

                >
                    <div className='standard quote-modal qr contact-modal options'>
                        <img src='/images/close.png' className='quote-modal__close-icon contact-modal__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h3 className='contact-modal__title options__title'>Cum dorești să te intrii în contul tău?</h3>
                        <div className="options__box">
                            <button type='button' className='login__submit-button' onClick={this.loginRedirect}><Link to="/app/login"><h5 className='login__button-writing options__writing'>Încearcă gratuit pentru 7 zile</h5></Link></button>
                            <h4>sau</h4>
                            <button type='button' className='login__submit-button options__writing' onClick={this.paymentRedirect}><a href={this.props.paymentUrl} target="_blank"><h5 className='login__button-writing options__writing'>Plătește direct și bucură-te de contul tău</h5></a></button>
                        </div>

                    </div>

                </Modal>
            </div>
        )
    }
}

export default LoginOptionsModal;
