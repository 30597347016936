import React from 'react';
import './Guests.scss';
import '../../../resources/main.scss';
import { Link } from 'react-router-dom';
import { guestsService } from '../../../services/AdminService';
import Menu from '../../../components/AdminMenu/AdminMenu';
import { eventService } from '../../../services/EventService';
import ReactPaginate from 'react-paginate';
import GuestModal from '../../../components/GuestModal/GuestModal';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';

class Guests extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            guests: [],
            currentEvent: {},
            pagination: {},
            events: [],
            isGuestModalOpen:false,
            selectedGuest:{}
        }

    }
    openGuestModal=currentGuest=>{
        let selectedGuest={...this.state.selectedGuest};
        selectedGuest=currentGuest;
        let isGuestModalOpen=this.state.isGuestModalOpen;
        isGuestModalOpen=true;
        this.setState({selectedGuest})
        this.setState({isGuestModalOpen})
    }
    closeModal = () => {
        this.setState({ isGuestModalOpen: false });


      }
    componentDidMount() {
        eventService.getEvents().then(response => {
            let events = this.state.events;
            events = response.data.events;
            let currentEvent = { ...this.state.currentEvent };
            currentEvent = { ...events[0] };

            this.setState({ events });
            this.setState({ currentEvent }, () => {
                guestsService.getGuests(this.state.currentEvent.id).then(response => {
                    let guests = this.state.guests;
                    guests = response.data.guests;
                    let pagination = { ...this.state.pagination };
                    pagination = response.data.pagination;

                    this.setState({ pagination })
                    this.setState({ guests })
                })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.props.history.push("/app/login");
                        }
                    })
            })
        })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }
    handlePageClick = selectedPage => {
        guestsService
            .getGuests(this.state.currentEvent.id, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let guests = this.state.guests;
        guests = response.data.guests;

        this.setState({ guests });
    };
    updateEventId = event => {
        let currentEvent = { ...this.state.currentEvent };

        currentEvent = this.state.events.find(eventToFind => eventToFind.id === event.target.value);

        this.setState({ currentEvent }, () => {
            guestsService.getGuests(this.state.currentEvent.id).then(response => {
                let guests = this.state.guests;
                guests = response.data.guests;
                let pagination = { ...this.state.pagination };
                pagination = response.data.pagination;

                this.setState({ guests });
                this.setState({ pagination });
            })
                .catch(err => console.log(err))
        });
    }

    render() {
        return (
            <div className='standard guests'>
                <Menu />
                <AdminMobileHeader />
                <div className='guests__page-content guestss__page-content'>
                    <div className='guests__container'>
                        <div className='guests__top-search'>
                            <h2 className='guests__title'>Invitati care au spus da</h2>

                            <select
                                className="event__select"
                                title="tip"
                                value={this.state.eventId}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className='guests__full-wrapper'>
                            <div className='guests__wrapper'>
                                <div className='guests__heading'>
                                    <h4 className='guests__head-title '>Nume</h4>
                                    <h4 className='guests__head-title'>Nr. telefon</h4>
                                    <h4 className='guests__head-title'>Email</h4>
                                    <h4 className='guests__head-title'>Companie invitat</h4>
                                    <h4 className='guests__head-title'>Numar persoane</h4>
                                    <h4 className='guests__head-title'>Meniu</h4>
                                    <h4 className='guests__head-title'>Alergii</h4>
                                    <h4 className='guests__head-title'>Ce alergii</h4>
                                    <h4 className='guests__head-title'>Cazare</h4>
                                    <h4 className='guests__head-title'>Transport</h4>
                                    <h4 className='guests__head-title'>Vezi invitatul</h4>
                                    <h4 className='guests__head-title'>Editare</h4>
                                </div>
                                {this.state.guests.map(guests => (
                                    <div className='guests__row guests__row' key={guests.id}>
                                        <div className='guests__cell'>
                                            <h4>{guests.name}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.phone}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.email}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.company}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.company_number}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.menus.map(menu => (
                                                <div>
                                                    <h4>{menu.menu_type}</h4>
                                                </div>
                                            ))}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.allergy}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.allergy_mentions ? guests.allergy_mentions : '-'}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.housing ? 'Da' : 'Nu'}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{guests.transport ? 'Da' : 'Nu'}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <img src='/images/see-guest.png' alt='see' className='guests__see-icon' onClick={()=>this.openGuestModal(guests)}/>

                                        </div>
                                        <div className='guests__cell'>
                                        <Link className='standard__link' to={`editeaza-invitat/${guests.id}`}>  <img src='/images/edit.png' alt='edit' className='guests__see-icon'/></Link>

                                        </div>
                                    </div>
                                ))}
                                {this.state.pagination && this.state.pagination.last_page > 1 && (
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.pagination.last_page}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isGuestModalOpen && <GuestModal
                  modalIsOpen={this.state.isGuestModalOpen}
                  closeModal={this.closeModal}
                  guest={this.state.selectedGuest}
                />}
            </div>
        )
    }
}

export default Guests;
