import axios from 'axios';
import { loginService } from './LoginService';

export const registryService={
    createOrUpdateGift,
    getGifts,
    deleteGift,
    searchGifts,
    reserveGift
}

function createOrUpdateGift(giftData){

    let url = `${process.env.REACT_APP_API_URL}/api/presents${giftData.id ? `/${giftData.id}` : ''}`;
    const requestOptionsPost = {
        method: giftData.id? 'PUT': 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data: buildRequestData(giftData)
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
}
function buildRequestData(giftData) {
    let formData = new FormData();
  
    formData.append('present[name]', giftData.name);
    formData.append('present[description]', giftData.description);
    formData.append('present[url]', giftData.url);
    formData.append('present[similar]', giftData.similar);
    formData.append('present[name]', giftData.name);
    formData.append('present[event_id]', giftData.websiteEventId);
    const document = giftData.image;
  
    if (document && document.url !== null) {
      if (document.url) {
        formData.append(`present[image]`, document.url);
      } else {
        formData.append(`present[image]`, document, document.name);
      }
    }
  
    return formData;
  }
function getGifts(eventId, pageNumber){
    let url = `${process.env.REACT_APP_API_URL}/api/presents?page[number]=${pageNumber ? pageNumber : 1}&event_id=${eventId}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}



function deleteGift(giftId){
    const requestOptionsDelete = {
        headers: {
          Accept: 'application/json'
        }
      };

      if (loginService.loggedIn()) {
        requestOptionsDelete.headers['Authorization'] =loginService.getToken();
      }
      let url = `${process.env.REACT_APP_API_URL}/api/presents/${giftId}`;

      return axios.delete(url, requestOptionsDelete);
}
function searchGifts(eventId, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/presents/search?page[number]=${pageNumber ? pageNumber : 1
  }&event_id=${eventId}`;

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };
    return axios(requestOptions)
}
function reserveGift(giftData){

  let url = `${process.env.REACT_APP_API_URL}/api/presents/reserve_gift`;
  const requestOptionsPost = {
      method:'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        present: {
          ...giftData,
          event_id:giftData.eventId
        }
      }
    };

    return axios(requestOptionsPost);
}