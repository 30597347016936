import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import { userService } from '../../services/UserService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'

const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        borderRadius: "32px"
    }
};

class AddClient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                name: '',
                email: ''
            }
        };

        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        if (this.props.user) {
            let user = { ...this.state.user };
            user = {
                ...this.props.user
            };

            this.setState({ user });
        }
    }

    handleChange = event => {
        const field = event.target.name;
        const user = { ...this.state.user };
        user[field] = event.target.value;
        this.setState({ user });
    }

    handleAddUser = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        userService.createOrUpdateUser(this.state.user)
            .then(response => {
                console.log(response);
                notification.addNotification({
                    message:
                        `Utilizatorul a fost ${this.state.user.id ? 'editat' : 'adaugat'} cu succes.`,
                    level: 'success',
                    position: 'tc'
                });
                this.props.addUserToList(response.data);
                this.props.closeModal();
            })
            .catch(error => {
                if (error.response.status === 422) {
                  notification.addNotification({
                      message:
                          `Exista un utilizator cu acest email.`,
                      level: 'error',
                      position: 'tc'
                  });
                }
            })
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
                    <div className='standard add-modal'>
                        <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h2 className="add-modal__title">{this.state.user.id ? 'Editează userul' : 'Adaugă user'} </h2>
                        <form onSubmit={this.handleAddUser}>
                            <div>
                                <h4 className='add-modal__label'>Nume</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.user.name} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Email</h4>
                                <input
                                    className='add-modal__field'
                                    type="email"
                                    name="email"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.user.email} />
                            </div>


                            <div className="add-modal__buttons">
                                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.user.id ? 'Editează' : 'Adaugă'}</button>
                                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default AddClient;
