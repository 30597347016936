import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';
import ItemsTable from "./TableExport/ItemsTable";

// Create styles
const borderColor = '#e66300';
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    height: '60%',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logoWrapper: {
    display: 'flex',
    height: '55px'
  },
  logo: {
    width: '80px',
    height: '35px',
    margin: 10
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginRight: 10
  },
  tablesWrapper: {
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  tablesImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'fill',
    margin: 10
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '30px'
  },
  footerLink: {
    fontSize: '10px',
    margin: 5,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '40px 15px 0',
      border: '1px solid #e66300',
      borderRadius: '10px',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
      fontSize: '14px',
      padding: '10px',
  },
  headerDescription: {
      width: '45%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  headerQty: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  headerRate: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  headerAmount: {
      width: '15%',
  },
  row: {
      flexDirection: 'row',
      margin: '0 15px',
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
      fontSize: '12px',
      padding: '0 10px',
  },
  description: {
      width: '45%',
      textAlign: 'left',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
  },
  qty: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'right',
      paddingRight: 8,
  },
  rate: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'right',
      paddingRight: 8,
  },
  amount: {
      width: '15%',
      textAlign: 'right',
      paddingRight: 8,
  },
});

// Create Document Component
class GuestListPDFExport extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Document>
        <Page style={styles.page} wrap>
          <View style={styles.header} fixed>
            <View style={styles.logoWrapper}>
              <Image style={styles.logo} src='/images/logo.png' />
            </View>
            <Text style={styles.title}>{this.props.title}</Text>
          </View>
          <View style={styles.tableContainer}>
              <View style={styles.headerContainer} fixed>
                  <Text style={styles.headerDescription}>Nume Invitat</Text>
                  <Text style={styles.headerQty}>Numar Persoane</Text>
                  <Text style={styles.headerRate}>Masa</Text>
                  <Text style={styles.headerAmount}>Prezenta</Text>
              </View>
              <View>
                { this.props.guests.map( item =>
                    <View style={styles.row} key={item.id.toString() + Math.random()}>
                        <Text style={styles.description}>{item.name}</Text>
                        <Text style={styles.qty}>{item.company_number || '-'}</Text>
                        <Text style={styles.rate}>{item.seats.length ? (item.seats[0].table_id ? item.seats[0].table.number : 'Fara loc') : 'Fara loc' }</Text>
                        <Text style={styles.amount}></Text>
                    </View>
                ) }
              </View>
          </View>
          <View style={styles.footer}>
            <Link style={styles.footerLink} src="www.facemnunta.ro">www.facemnunta.ro</Link>
          </View>
        </Page>
      </Document>
    )
  }
};

export default GuestListPDFExport;
