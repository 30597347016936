import React, { Component } from 'react';
import './Blog.scss';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Slider from "react-slick";
import MetaTags from 'react-meta-tags';
import Footer from '../../components/Footer/Footer';
import { blogService } from '../../services/BlogService';
import Moment from 'react-moment';
import categoriesJson from './categories.json';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import DOMPurify from 'dompurify';


class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newsletterInfo: {
                name: '',
                email: ''
            },
            articles: [],
            categories: categoriesJson,
            newestArticle: {
                main_image: {}
            }
        }
        this.notificationSystem = React.createRef();
    }
    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    handleChange = event => {
        const field = event.target.name;
        const newsletterInfo = { ...this.state.newsletterInfo };
        newsletterInfo[field] = event.target.value;
        this.setState({
            newsletterInfo
        });
    };

    componentDidMount() {
        blogService.getArticles().then(response => {
            let articles = this.state.articles;
            let newestArticle = { ...this.state.newestArticle }
            articles = response.data.articles;
            newestArticle = articles[articles.length - 1];

            this.setState({ articles });
            this.setState({ newestArticle });
        })
            .catch(err => console.log(err))
    }
    handleSubmit=event=>{
        event.preventDefault();
        const notification = this.notificationSystem.current;

        blogService.sendContactMessage(this.state.newsletterInfo).then(response=>{
            notification.addNotification({
                message:
                  'Subscripția ta a fost înregistrată!',
                level: 'success',
                position: 'tc'
              });
        })

        .catch(err=>{
            notification.addNotification({
                message: 'Ne pare rău, a intervenit o eroare. Revino mai târziu sau, mai bine, contactează-ne!',
                level: 'error',
                position: 'tc'
              });
        })
    }


    render() {
        const blogSettings = {
            infinite: true,
            speed: 200,
            swipe: true,
            swipeToSlide: true,
            adaptiveHeight: true,
            focusOnSelect: true,
            centeredMode: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        };
        const settings = {
            infinite: true,
            speed: 200,
            swipe: true,
            swipeToSlide: true,
            adaptiveHeight: true,
            focusOnSelect: true,
            centeredMode: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            autoplay: false
        };
        return (
            <div className='standard blog'>
                <Header menuItem='blog'/>
                <MetaTags>
                    <title>Facem Nunta Blog</title>
                    <meta
                        name="description"
                        content="Facem Nunta este platforma online de organizare si planifiweddinge destinata mirilor si mireselor| Pentru o nunta asa cum iti doresti, fara stres sau compromisuri."
                    />
                    <meta
                        name="keywords"
                        content="facem nunta, nunta, organizare nunta, planifiweddinge nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta"
                    />
                    <meta
                        property="og:title"
                        content="Facem Nunta Blog"
                    />
                    <meta
                        property="og:url"
                        content="https://www.facemnunta.ro/app/blog"
                    />
                </MetaTags>
                <div className='blog__container'>
                    <div className="blog__top">
                      <div className='blog__image-div'>
                        {this.state.newestArticle.main_image && this.state.newestArticle.main_image.url ? (
                            <img src={process.env.REACT_APP_BLOG_API_URL + this.state.newestArticle.main_image.url} className='blog__main-img blog__featured-img' alt='wedding' />
                        ) : (
                                <img src="/images/wedding.jpg" className='blog__main-img blog__featured-img' alt='wedding' />
                            )}
                          <div className='blog__image-shadow' />
                      </div>
                      <div className=' blog__featured-section-info'>
                            <div className='blog__time-box'>
                                <h4>Organizare</h4>
                                <div className='blog__circle' />
                                <h5 className='blog__article-time'>
                                    <Moment format="DD/MM/YYYY">
                                        {this.state.newestArticle.updated_at}
                                    </Moment>
                                </h5>
                            </div>
                            <h3 className='blog__featured-section-title'>{this.state.newestArticle.title}</h3>
                            <h4 className='blog__featured-section-details' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.newestArticle.description) }}></h4>
                            <Link onClick={this.scrollToTop} to={`article/${this.state.newestArticle.slug}`} className="blog__read-link">
                              <button className='blog__read-featured'>Citeste Articol</button>
                            </Link>
                        </div>
                    </div>

                    <div className='blog__second-section'>
                        <div className='blog__article-wrapper'>
                            <h3 className='blog__subtitle'>Noutăți</h3>
                            <div className='blog__line' />
                            <div className='blog__blog-slider standard__only-mobile'>
                                <Slider  {...blogSettings} className=''>
                                    {this.state.articles.slice(0, 3).map(article =>
                                        <div key={article.id} className='blog__news-wrapper' >

                                            <Link onClick={this.scrollToTop} to={`article/${article.slug}`} className='standard__link' >
                                                <div className='blog__image-div'>
                                                    {article.main_image && article.main_image.url ? (
                                                        <img src={process.env.REACT_APP_BLOG_API_URL + article.main_image.url} className='blog__article-img' alt='wedding' />
                                                    ) : (
                                                            <img src="/images/wedding.jpg" className='main__article-main-img' alt='wedding' />
                                                        )}
                                                    <div className='blog__slider-shadow' />
                                                </div>

                                                <div className='blog__slider-info'>
                                                    <div className='blog__time-box'>
                                                        <h4>Organizare</h4>
                                                        <div className='blog__circle' />
                                                        <h5 className='blog__article-time'>
                                                            <Moment format="DD/MM/YYYY">
                                                                {article.updated_at}
                                                            </Moment>
                                                        </h5>
                                                    </div>
                                                    <div className='blog__blog-section-info'>
                                                        <h3 className='blog__article-title'>{article.title}</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </Slider>
                                </div>
                                <div className='blog__articles-desktop standard__only-desktop'>
                                    {this.state.articles.slice(0, 4).map(article =>
                                        <div key={article.id} className='' >

                                            <Link onClick={this.scrollToTop} to={`article/${article.slug}`} className='standard__link' >
                                                <div className='blog__image-div'>
                                                    {article.main_image && article.main_image.url ? (
                                                        <img src={process.env.REACT_APP_BLOG_API_URL + article.main_image.url} className='blog__article-img' alt='wedding' />
                                                    ) : (
                                                            <img src="/images/wedding.jpg" className='main__article-main-img' alt='wedding' />
                                                        )}
                                                    <div className='blog__slider-shadow' />
                                                </div>

                                                <div className='blog__slider-info'>
                                                    <div className='blog__time-box'>
                                                        <h4>Organizare</h4>
                                                        <div className='blog__circle' />
                                                        <h5 className='blog__article-time'>
                                                            <Moment format="DD/MM/YYYY">
                                                                {article.updated_at}
                                                            </Moment>
                                                        </h5>
                                                    </div>
                                                    <div className='blog__blog-section-info'>
                                                        <h3 className='blog__article-title'>{article.title}</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </div>

                            <img src='/images/blog-decoration.png' alt='decoration' className='blog__decoration' />
                        </div>

                        <div className='blog__categories standard__only-mobile'>
                            <h3 className='blog__subtitle'>Categorii</h3>
                            <div className='blog__line' />
                            <div className='blog__categories-slider'>
                                <Slider  {...settings} className=''>
                                    {this.state.categories.map(category =>
                                        <div key={category.id} className='blog__category-wrapper' >
                                            <h4>{category.name}</h4>
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </div>
                        <div className='blog__bottom-part'>
                        <div className='blog__all-articles'>
                            <h3 className='blog__subtitle'>Articole</h3>
                            <div className='blog__line' />
                            {this.state.articles.map(article =>
                                <div key={article.id} className='blog__article-container' >
                                    <div className=''>
                                        <Link to={`article/${article.slug}`} onClick={this.scrollToTop}className='standard__link blog__article-whole' >
                                            <div className='blog__image-div'>
                                                {article.main_image && article.main_image.url ? (
                                                    <img src={process.env.REACT_APP_BLOG_API_URL + article.main_image.url} className='blog__article-img' alt='wedding' />
                                                ) : (
                                                        <img src="/images/wedding.jpg" className='main__article-img' alt='wedding' />
                                                    )}
                                                <div className='blog__article-image-shadow' />
                                            </div>
                                            <div className='blog__article-only-info'>
                                                <div className='blog__time-box blog__article-time-box'>
                                                    <h4>Organizare</h4>
                                                    <div className='blog__circle' />
                                                    <h5 className='blog__article-time'>
                                                        <Moment format="DD/MM/YYYY">
                                                            {article.updated_at}
                                                        </Moment>
                                                    </h5>
                                                </div>
                                                <h3 className='blog__wrapper-article-title'>{article.title}</h3>
                                                <p className='blog__article-description' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.description) }}></p>

                                                <div className='blog__article-read-box'>
                                                    <h4>Citeste articolul</h4>
                                                    <img src='/images/right-orange.png' alt='see article' />
                                                </div>
                                                <button className='standard__only-desktop blog__read-more'>Citeste articol</button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='blog__bottom-right'>
                        <div className='blog__categories standard__only-desktop'>
                            <h3 className='blog__subtitle'>Categorii</h3>
                            <div className='blog__line' />
                            <div className='blog__categories-slider'>

                                    {this.state.categories.map(category =>
                                        <div key={category.id} className='blog__category-wrapper' >
                                            <h4>{category.name}</h4>
                                        </div>
                                    )}

                            </div>
                        </div>
                        <div className='blog__newsletter'>
                            <h2 className='blog__newsletter-title'>Newsletter</h2>
                            <h3 className='blog__newsletter-subtitle'>Cele mai bune sfaturi legate de nuntă, direct în inbox-ul tău!</h3>
                            <form className='blog__form' onSubmit={this.handleSubmit}>
                                <input
                                    className="blog__field"
                                    type="text"
                                    name="name"
                                    onChange={this.handleChange}
                                    value={this.state.newsletterInfo.name}
                                    placeholder='Nume si prenume'
                                />
                                <input
                                    className="blog__field"
                                    type="email"
                                    name="email"
                                    onChange={this.handleChange}
                                    value={this.state.newsletterInfo.email}
                                    placeholder='Email'
                                />
                                <button className="blog__read-featured">Aboneaza-te</button>
                            </form>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default Blog;
