import React from 'react';
import '../../resources/main.scss';
import './PreorderPage.scss';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { preorderService } from '../../services/PreorderService';
import MetaTags from 'react-meta-tags';
import SuccessModal from '../../components/SuccessModal/SuccessModal';
import { googleVerificationService } from '../../services/GoogleVerificationService';

class PreoderPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newAccount: {
                name: '',
                weddingDate: '',
                email: '',
                phone: ''
            },
            isSuccesModalOpen: false
        }
        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;

        window.grecaptcha.ready(function () {
            window.grecaptcha
                .execute('6Lc4VHUeAAAAAHFrbwy7JveIQMokNSP419so-hQS', {
                    action: 'ComunitateaTaHomepage'
                })
                .then(function (token) {
                    googleVerificationService.verifyToken(token).then(response => {
                        if (response.data.score < 0.2) {
                            this._notificationSystem.addNotification({
                                message: 'Google Recaptcha a detectat o activitate suspecta, veti fi redirectionat de pe website.',
                                level: 'error',
                                position: 'tc'
                            });

                            setTimeout(() => {
                                window.location.assign('https://www.google.com');
                            }, 4000);
                        }
                    });
                });
        });
    }
    setSuccessModalOpen = () => {
        this.setState({ isSuccesModalOpen: true })
    }
    closeModal = () => {
        this.setState({ isSuccesModalOpen: false });
        this.props.history.push('/');
    }

    handleChange = event => {
        const field = event.target.name;
        const newAccount = { ...this.state.newAccount };
        newAccount[field] = event.target.value;
        this.setState({ newAccount })
    }
    handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        preorderService.createAccount(this.state.newAccount)
            .then(response => {
                this.setSuccessModalOpen()
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'Ne pare rău, a intervenit o problemă. Dacă un refresh la pagină nu ajută, contactați-ne prin email sau telefon.',
                    level: 'error',
                    position: 'tc'
                });
            })
    }
    render() {
        return (
            <div className='standard login account preorder'>
                <Header menuItem='pre-inregistrare'/>
                <MetaTags>
                    <title>Facem Nunta</title>
                    <meta
                        name="description"
                        content="Creaza un cont până vom fi live si beneficiezi de un “mic cadou de nunta” o reducere de 15% la pachetele oferite de Facem Nunta. Incepe sa planifici nunta azi!"
                    />
                    <meta
                        name="keywords"
                        content="facem nunta, nunta, organizare nunta, planificare nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta"
                    />
                    <meta
                        property="og:title"
                        content="Facem Nunta"
                    />
                    <meta
                        property="og:url"
                        content="https://www.facemnunta.ro/app/pre-inregistrare"
                    />
                </MetaTags>
                <div className='login__wrapper'>
                    <img src='/images/lateral.png' alt='background' className='login__lateral preorder__lateral' />
                    <div className='login__container account__container'>
                        {/* <h1 className='preorder__title preorder__main-title'>În martie, aplicația ta pentru organizarea unei nunți de vis va fi online.</h1> */}
                        {/* <h3 className='preorder__title'>Dacă îți creezi cont <b> până când vom fi live, </b>beneficiezi de un ”mic cadou de nuntă” - o <b>reducere de 15%</b> la pachetele Facem Nuntă.</h3> */}
                        <h3 className='preorder__title'>O nuntă de vis este o nuntă planificată bine!</h3>
                        <form className='login__form' onSubmit={this.handleSubmit}>

                            <div>
                                <h4 className='login__label'>Nume*</h4>
                                <input
                                    className='login__input preorder__input'
                                    type="text"
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.newAccount.name} />
                            </div>
                            <div>
                                <h4 className='login__label'>Data nunții*</h4>
                                <input
                                    className='login__input  preorder__input'
                                    type="date"
                                    name="weddingDate"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.newAccount.weddingDate} />
                            </div>



                            <div>
                                <h4 className='login__label'>Telefon*</h4>
                                <input
                                    className='login__input  preorder__input'
                                    type="number"
                                    name="phone"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.newAccount.phone} />
                            </div>
                            <div>
                                <h4 className='login__label'>Email*</h4>
                                <input
                                    className='login__input  preorder__input'
                                    type="email"
                                    name="email"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.newAccount.email} />
                            </div>



                            <div className='login__account-box'>
                                <button type='submit' className='login__submit-button preorder__button'><h5 className='login__button-writing'>CREARE CONT</h5></button>

                            </div>
                        </form>

                    </div>

                </div>
                <Footer />
                {this.state.isSuccesModalOpen && <SuccessModal
                    modalIsOpen={this.state.isSuccesModalOpen}
                    closeModal={this.closeModal}
                />}
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default PreoderPage;
