import React from 'react';

class GuestTable extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="tables-configurator__table">
        <div className="handle">Test</div>
      </div>
    )
  }

}

export default GuestTable;
