import axios from 'axios';
import { loginService } from './LoginService';

export const notificationsService={
    getNotifications,
    getUnreadNotificationsCount,
    markAsRead
}

function getNotifications(eventId) {
  const requestOptions = {
      headers: {
        Accept: 'application/json'
      }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/notifications?event_id=${eventId}`;
  return axios.get(url, requestOptions);
}

function getUnreadNotificationsCount(eventId) {
  const requestOptions = {
      headers: {
        Accept: 'application/json'
      }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/notifications/unread_notification_count?event_id=${eventId}`;
  return axios.get(url, requestOptions);
}

function markAsRead(eventId, notificationId) {

  let url = `${process.env.REACT_APP_API_URL}/api/notifications/${notificationId}/mark_as_read?event_id=${eventId}`;

  const requestOptionsPost = {
    method:'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}
