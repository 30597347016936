import React from 'react';

export function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? <img className='slider-arrow' src='/images/expand-less.png'/> : <img className='slider-arrow' src='/images/expand-more.png'/>;
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
