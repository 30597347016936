import axios from 'axios';
import { loginService } from './LoginService';

export const tablesConfiguratorService = {
  getTablesConfigurator
}
function getTablesConfigurator(eventId){
     let url = `${process.env.REACT_APP_API_URL}/api/table_configurations/details?event_id=${eventId}`;
     const requestOptionsGet = {
         method: 'GET',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         }
       };

       if (loginService.loggedIn()) {
         requestOptionsGet.headers['Authorization'] = loginService.getToken();
       }

       return axios(requestOptionsGet);
 }
