import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

import { compose, withProps } from 'recompose';

const DaianRestaurantMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAaamqPezKtwlEXQMrZPAPZeTshPushkZA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="google-location" />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: 46.7908297662552, lng: 23.6102318851532 }}
  >
    <Marker position={{ lat: 46.7908297662552, lng: 23.6102318851532 }} />
  </GoogleMap>
));

export default DaianRestaurantMap;
