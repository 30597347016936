import React from 'react';

export default function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? <img className='slider-arrow' src='/images/back-black.png' alt="first arrow"/> : <img className='slider-arrow' src='/images/next-black.png' alt="first arrow"/>;
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
