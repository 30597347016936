import React from 'react';
import {guestsService} from '../../services/AdminService';
import { VictoryPie, VictoryLabel, VictoryAnimation } from "victory";
import Divider from '@material-ui/core/Divider';
import { invitationFormService } from '../../services/InvitationFormService';

class MenusDashboard extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          percentages: {
            hasAllergies: 0,
            hasOtherRestrictions: 0,
            dataAllergies: this.getData(0),
            dataOtherRestrictions: this.getData(0)
          },
          extraInformations: {
            allergies_count: 0,
            other_restrictions_count: 0,
            total_menus: 0
          },
          menuTypeConfigs: []
      }
  }

  componentDidMount() {
    invitationFormService.getDetails(this.props.eventId)
      .then(response => {

        guestsService.guestsMenusStatistics(this.props.currentEventId).then(response => {
            this.setData(response);
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.props.history.push("/login");
          }
        })
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/login");
        }
      })
  }

  getData(percent) {
    return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
  }

  setData(response) {
    this.setDataValues(response.data.allergies, 'hasAllergies', 'dataAllergies');
    this.setDataValues(response.data.other_restrictions, 'hasOtherRestrictions', 'dataOtherRestrictions');

    if (response.data.menu_types) {
      response.data.menu_types.map(menu_type => {
        this.setDataValues(response.data[menu_type], menu_type, `data${menu_type}`);
      });

      this.setState({ menu_types: response.data.menu_types });
    }

    let extraInformations = { ...this.state.extraInformations };
    extraInformations = { ...response.data };

    this.setState({ extraInformations });
  }

  setDataValues(percent, key, dataKey) {
    if (!percent) {
      percent = 0
    }

    let percentages = { ...this.state.percentages };

    if (!percentages[dataKey]) {
      percentages[dataKey] = this.getData(0);
    }

    percentages[key] = percent;
    percentages[dataKey][0].y = percent;
    percentages[dataKey][1].y = 100 - percent;

    this.setState({ percentages });
  }

  render() {
    return (
      <div  className="website-form">
         <h4 className='website-form__subtitle'>Număr meniuri: { this.state.extraInformations.total_menus }</h4>
          <div className="dashboard__statistics-wrapper">
          { this.state.menu_types && this.state.menu_types.length ? (
            this.state.menu_types.map(menuType => (
              this.state.percentages[menuType] ? (
                <div className="dashboard__statistics-chart" key={menuType}>
                  <div>
                    <svg viewBox="0 0 400 400" width="100%" height="100%">
                      <VictoryPie
                        standalone={false}
                        animate={{ duration: 2000 }}
                        width={400} height={400}
                        data={this.state.percentages[`data${menuType}`]}
                        cornerRadius={25}
                        innerRadius={120}
                        labels={() => null}
                        style={{
                          data: { fill: ({ datum }) => {
                              const color = 'lightgreen';
                              return datum.x === 1 ? color : 'lightgrey';
                            }
                          }
                        }}
                      />
                      <VictoryAnimation duration={2000} data={this.state}>
                        {(newProps) => {
                          return (
                            <VictoryLabel
                              textAnchor="middle" verticalAnchor="middle"
                              x={200} y={200}
                              text={`${(newProps.extraInformations[`${menuType}_count`] ? newProps.extraInformations[`${menuType}_count`] : 0).toFixed(0)} - ${(newProps.percentages[`${menuType}`] ? newProps.percentages[`${menuType}`] : 0).toFixed(2)}%`}
                              style={{ fontSize: 45 }}
                            />
                          );
                        }}
                      </VictoryAnimation>
                    </svg>
                  </div>
                  <div className="dashboard__chart-title">
                    <h2>Meniu {menuType}</h2>
                  </div>
                </div>
              ) : (null)
            ))
          ) : (null) }
          </div>
          <Divider />
          <div className="dashboard__statistics-wrapper">
            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataAllergies}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                          const color = 'lightgreen';
                          return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.allergies_count).toFixed(0)} - ${(newProps.percentages.hasAllergies).toFixed(2)}%`}
                          style={{ fontSize: 45 }}
                        />
                      );
                    }}
                  </VictoryAnimation>
                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Persoane cu alergii</h2>
              </div>
            </div>
            <div className="dashboard__statistics-chart">
              <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                  <VictoryPie
                    standalone={false}
                    animate={{ duration: 2000 }}
                    width={400} height={400}
                    data={this.state.percentages.dataOtherRestrictions}
                    cornerRadius={25}
                    innerRadius={120}
                    labels={() => null}
                    style={{
                      data: { fill: ({ datum }) => {
                          const color = 'lightgreen';
                          return datum.x === 1 ? color : 'lightgrey';
                        }
                      }
                    }}
                  />
                  <VictoryAnimation duration={2000} data={this.state}>
                    {(newProps) => {
                      return (
                        <VictoryLabel
                          textAnchor="middle" verticalAnchor="middle"
                          x={200} y={200}
                          text={`${(newProps.extraInformations.other_restrictions_count).toFixed(0)} - ${(newProps.percentages.hasOtherRestrictions).toFixed(2)}%`}
                          style={{ fontSize: 45 }}
                        />
                      );
                    }}
                  </VictoryAnimation>
                </svg>
              </div>
              <div className="dashboard__chart-title">
                <h2>Persoane cu alte restrictii</h2>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default MenusDashboard;
