import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import { QRCode } from 'react-qrcode-logo';


const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        borderRadius: "32px"
    }
};

class GenerateQR extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            websiteLink:this.props.websiteLink
        };

    }
     logoUrl='/images/logo.png'
    

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
                    <div className='standard add-modal'>
                        <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h2 className="add-modal__title">Codul QR al evenimentului tau: </h2>
                        <div className='standard__qr-box'>
                        <QRCode value={this.state.websiteLink}
                         logoImage={this.logoUrl} 
                         logoPadding={10}
                         logoPaddingStyle={'circle'}
                         size={250}
                         logoWidth={50}
                         logoHeight={25}
                         logoOpacity={1}
                         />
                        </div>
                    </div>
                </Modal>
            
            </div>
        )
    }
}

export default GenerateQR;
