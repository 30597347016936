import axios from 'axios';

export const blogService={
    getArticles,
    getArticleBySlug,
    searchArticles,
    sendContactMessage
}

function getArticles(){
    const requestOptionsPost = {
        headers: {
          Accept: 'application/json'
        }
      };

      let url = `${process.env.REACT_APP_BLOG_API_URL}/api/articles`;


     return axios.get(url, requestOptionsPost);
}
function searchArticles(searchName) {
  const requestOptionsPost = {
      headers: {
        Accept: 'application/json'
      }
    };

    let url = `${process.env.REACT_APP_BLOG_API_URL}/api/articles/search${searchName ? `?meta_tags=${searchName}`: ''}`;

   return axios.get(url, requestOptionsPost);
}
function getArticleBySlug(slug) {
    const requestOptionsPost = {
        headers: {
          Accept: 'application/json'
        }
      };

      let url = `${process.env.REACT_APP_BLOG_API_URL}/api/articles/get_by_slug/${slug}`;


     return axios.get(url, requestOptionsPost);
}
function sendContactMessage(contactData){

    let url = `${process.env.REACT_APP_BLOG_API_URL}/api/newsletters`;

    const requestOptionsPost = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        url,
        data: {
          newsletter: { ...contactData }
        }
      };


    return axios(requestOptionsPost);
}
