import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import GuestListPDFExport from './GuestListPDFExport';
import moment from "moment";

const generatePdfGuestList = async (guests, title) => {
        const blob = await pdf((
            <GuestListPDFExport
                guests={guests}
                title={title}
            />
        )).toBlob();

        const filename = 'Lista Invitati - ' + title + ' - '+ moment().format('DD-MM-YYYY');
        saveAs(blob, filename);
};

export default generatePdfGuestList;
