import React from 'react';
import './NegativeForm.scss';
import queryString from 'query-string';
import {
  Link
} from "react-router-dom";
import { formService } from '../../services/FormService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import couplesJson from '../Homepage/couples.json';
import { websiteDetailsService } from '../../services/WebsiteDetailsService';
import Moment from 'react-moment';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

class NegativeForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guestAnswer: {
        name: '',
        code: '',
        eventCode: '',
        reason: ''
      },
      couples: couplesJson,
      selectedCouple: [],
      invitationForm: {},
      coupleFromJson: false,
      websiteDetails: {},
      params: {}
    }
    this.notificationSystem = React.createRef();
  }
  componentDidMount() {
    this.setState({ submittedStarted: true });

    let parameters = this.props.location.pathname;
    let selectedCouple = { ...this.state.selectedCouple };
    const firstSegment = parameters.split("/")[1];

    let existingParams = { ...this.state.params };
    let params = queryString.parse(this.props.location.search);
    existingParams = params;
    this.setState({ params: existingParams });


    websiteDetailsService.getInvitationBySlug(firstSegment)
      .then(response => {
        let selectedCouple = { ...this.state.selectedCouple };

        selectedCouple = { ...response.data.event };

        if (response.data.event.invitation_forms.length) {
          let invitationForm = { ...this.state.invitationForm };

          invitationForm = { ...response.data.event.invitation_forms[0] };

          this.setState({ invitationForm });
        }

        let websiteDetails = { ...this.state.websiteDetails };
        websiteDetails = response.data.event.website_details[0];
        this.setState({ websiteDetails });

        this.setState({ selectedCouple });
        this.setState({ submittedStarted: false });

      })
      .catch(error => {
        this.setState({ submittedStarted: false });
      })

    let guestAnswer = { ...this.state.guestAnswer };
    guestAnswer.code = params.guest_code;
    guestAnswer.eventCode = params.event_code;
    guestAnswer.clientSlug = firstSegment;

    this.setState({ guestAnswer });
  }
  updateField = event => {
    const field = event.target.name;
    const guestAnswer = { ...this.state.guestAnswer };
    guestAnswer[field] = event.target.value;
    this.setState({
      guestAnswer
    });
  };
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  handleAnswer = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;
    formService.negativeForm(this.state.guestAnswer).then(response => {
      notification.addNotification({
        message:
        i18n.t('success'),
        level: 'success',
        position: 'tc'
      });
      let params = queryString.parse(this.props.location.search);
      if (params.guest_code) {
        setTimeout(() => {
          this.props.history.push('/app/raspunsuri-negative');
        }, 2000);
      }
    })

      .catch(err => {
        if (err.response.status === 403) {
          notification.addNotification({
            message:  i18n.t('notYet'),
            level: 'error',
            position: 'tc'
          });

          return;
        }
        notification.addNotification({
          message:  i18n.t('error'),
          level: 'error',
          position: 'tc'
        });
      })
  }
  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    let language = this.state.language;
    language = lng;
    this.setState({ language })
  }
  render() {
    return (
      <LoadingOverlay
        active={this.state.submittedStarted}
        spinner={<img src="/images/GifFacemNunta.gif" alt="loading gif" className="standard__loading-gif" />}
      >
        <div className='standard homepage form'
          style={{
            background: this.state.websiteDetails.background_image ? `url(${process.env.REACT_APP_API_URL + this.state.websiteDetails.background_image.url})` : 'transparent',
          }}
        >
          <div className='form__container'>
            <div className='homepage__header form__header'>
              <div>
                <Link to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug), search: queryString.stringify(this.state.params)}} className='standard__link'>
                  <h1 className='homepage__names'>{this.state.selectedCouple.name ? this.state.selectedCouple.name : 'Mire & Mireasa'}</h1>
                </Link>
                {this.state.coupleFromJson ? (
                  <div>
                    <h3 className='homepage__date'>{this.state.selectedCouple.date}</h3>
                  </div>
                ) : (
                  <h3 className='homepage__date'>
                    {this.state.selectedCouple.date ? <Moment locale="ro" format="dddd">{this.state.selectedCouple.date}</Moment> : 'Zi Eveniment'} | {this.state.selectedCouple.date ? <Moment locale="ro" format="DD MMMM YYYY">{this.state.selectedCouple.date}</Moment> : 'Data Eveniment'} | {this.state.selectedCouple.city || 'Oras eveniment'}
                  </h3>
                )}
              </div>
              <div className='homepage__header-info'>
                <img className='standard__only-mobile' src='/images/gallery.svg' alt='gallery' />
              </div>
              <div className="homepage__flags">
                    <img src='/images/ro-flag.png' alt='romanian'onClick={() => this.changeLanguage('ro')}/>
                    <img src='/images/uk-flag.png' alt='english' onClick={() => this.changeLanguage('en')}/>
                    </div>
            </div>

            <div className='form__banner'>
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) &&  <img src='/images/flower-1.png' alt='flower' className='standard__only-desktop form__banner-icon' /> }
              <img className='form__banner-img' src={(this.state.invitationForm.refused_image && this.state.invitationForm.refused_image.url ? `${process.env.REACT_APP_API_URL}${this.state.invitationForm.refused_image.url}` : '/images/nefinalizat.png')} alt='us' />
              <h3>{this.state.invitationForm.refused_text ? this.state.invitationForm.refused_text : 'Mesaj de intampinare'}</h3>
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) && <img src='/images/flower-3.png' alt='flower' className='standard__only-desktop form__banner-icon-2' /> }
            </div>


            <div className='form__wrapper'>
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) && <img src='/images/flower-2.png' alt='flower' className='standard__only-desktop form__form-icon' /> }
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) && <img src='/images/flower-1.png' alt='flower' className='standard__only-desktop form__form-icon-2' /> }
              <h4 className="form__label-name">{this.state.selectedCouple.invitationPageMessage}</h4>

              {this.state.invitationForm.message_refused ? (
                <h4 className='form__label-name form__general-message'>{ this.state.invitationForm.message_refused }</h4>
              ) : (null)}

              <form className='form__form' onSubmit={this.handleAnswer}>
                <h4 className='form__label-name'>{i18n.t('weddingCode')}</h4>
                <input type='text'
                  required
                  name='eventCode'
                  className='form__field'
                  value={this.state.guestAnswer.eventCode}
                  onChange={this.updateField} />
                <h4 className='form__label-name'>{i18n.t('guestCode')}:</h4>
                <input type='text'
                  required
                  name='code'
                  className='form__field'
                  value={this.state.guestAnswer.code}
                  onChange={this.updateField} />
                <h4 className='form__label-name'>{i18n.t('name')}</h4>
                <input type='text'
                  required
                  name='name'
                  className='form__field'
                  value={this.state.guestAnswer.name}
                  onChange={this.updateField} />

                <h4 className='form__label-name'>{i18n.t('message')}</h4>
                <textarea type='text'
                  name='reason'
                  rows='5'
                  cols='5'
                  className='form__field form__textarea'
                  value={this.state.guestAnswer.reason}
                  onChange={this.updateField} />

                <div className='form__bottom'>
                  <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug), search: queryString.stringify(this.state.params)}} className='standard__link'> <img src='/images/back.png' alt='back' /></Link>
                  <button type='submit'>{i18n.t('send')}</button>


                </div>
              </form>
            </div>
          </div>
          <div className='standard__footer'>
            <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie', search: queryString.stringify(this.state.params)}} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('positiveForm')}</h4></Link>
            <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ', search: queryString.stringify(this.state.params)}} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('negativeForm')}</h4></Link>
            <div className='standard__disclaimer'><h5>{this.state.websiteDetails.footer_disclaimer ? this.state.websiteDetails.footer_disclaimer : 'Aici va fi textul ales de voi'}</h5>
              <h5> {this.state.websiteDetails.footer_text ? this.state.websiteDetails.footer_text : i18n.t('chosenText')} </h5>
            </div>
            <div className='standard__footer-box'>
              <a className='standard__link'
                href="https://prometeus-tech.com/"
                target="_blank"
                rel="noopener noreferrer">
                <h5 className="footer__copyright">© Prometeus Tech 2024</h5></a>
              <a className='standard__link'
                href="https://www.facebook.com/CosminaHatmanu"
                target="_blank"
                rel="noopener noreferrer">
                <h5 className="footer__copyright">Designed by Cosmina Hatmanu</h5></a>
            </div>
          </div>
          {this.state.websiteDetails.registry_list_activation ?
            <div className='providers__contact-us homepage__gift-message'>
              <img src='/images/blue-gift.png' alt='gift' />
              <h5>{i18n.t('registryText')}</h5>
              <Link  onClick={this.scrollToTop}className='standard__link' to={{ pathname: `/${encodeURIComponent(this.state.selectedCouple.slug)}/registru-de-cadouri`, search: queryString.stringify(this.state.params)}}>  <button >{i18n.t('toRegistry')}</button></Link>
            </div> : '-'}

          <NotificationSystem
            ref={this.notificationSystem}
            style={NotificationSystemStyle}
          />
        </div>
      </LoadingOverlay>
    )
  }
}

export default NegativeForm;
