import React from 'react';
import '../../../resources/main.scss';
import Menu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField'
import { userService } from '../../../services/UserService';
import { passwordService } from '../../../services/PasswordService'
import './UserProfile.scss';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddUser from '../../../components/AddUser/AddUser'

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0,
            users: [],
            invoices: [],
            user: {},
            passwordData: {
                currentPassword: "",
                password: "",
                confirmPassword: ''
            },
            profile: {
                profilePicture:''
            },
            disabledDocument: false,
            seePassword: false,
            seeNewPassword: false,
            isAddUserModalOpen: false,
            selectedUser: {},
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
      userService.getProfile()
        .then(response => {
          let user = { ...this.state.user };

          user = response.data.user;

          this.setState({ user });
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.props.history.push("/app/login");
          }
        })

        userService.getClientUsers()
          .then(response => {
            let users = this.state.users.slice()

            users = response.data.users;

            this.setState({ users });
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.props.history.push("/app/login");
            }
          })

          userService.getBills()
            .then(response => {
              let invoices = this.state.invoices.slice()

              invoices = response.data.invoices;

              this.setState({ invoices });
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.props.history.push("/app/login");
              }
            })
    }
    updateTabValue = (event, value) => {
      this.setState({ tabValue: value });
    }
    handleUpdate = event => {
        const field = event.target.name;
        const passwordData = { ...this.state.passwordData };
        passwordData[field] = event.target.value;
        this.setState({
            passwordData
        });
    };
    handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        if (this.state.passwordData.password == this.state.passwordData.confirmPassword) {
            passwordService
                .changePassword(this.state.passwordData)
                .then(response => {

                    notification.addNotification({
                        message: 'Parola a fost schimbata cu succes.',
                        level: 'success',
                        position: 'tc'
                    });


                })
                .catch(error => {
                    notification.addNotification({
                        message: 'Parola anterioara este incorecta',
                        level: 'error',
                        position: 'tc'
                    });
                });
        }
        else {
            notification.addNotification({
                message: 'Parola nu corespunde cu parola confirmata',
                level: 'error',
                position: 'tc'
            });
        }

    }
    togglePassword = () => {
        let seePassword = this.state.seePassword;
        seePassword = !seePassword;
        this.setState({ seePassword })
    }
    toggleNewPassword = () => {
        let seeNewPassword = this.state.seeNewPassword;
        seeNewPassword = !seeNewPassword;
        this.setState({ seeNewPassword })
    }
    onDropDocument = documents => {
        let profile = { ...this.state.profile };
        let reader = new FileReader();
        let self = this;

        reader.onload = function (upload) {
            profile.profilePicture = documents[0];

            self.setState({ profile });
            self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
    };

    removeDocument = () => {
        let profile = { ...this.state.profile };
        profile.profilePicture = null;
        profile.profilePicture_filename = '';

        this.setState({ profile });
        this.setState({ disabledDocument: false });
    }
    handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        // supremeAdminService.addprofile(this.state.profile)
        //     .then(response => {
        //         console.log(response);
        //         notification.addNotification({
        //             message:
        //                 `Factura a fost adaugata cu succes cu succes.`,
        //             level: 'success',
        //             position: 'tc'
        //         });
        //         setTimeout(() => {
        //             window.location.reload(true);
        //         }, 2000);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
    }

    openAddUserModal = currentUser => {
        let selectedUser = { ...this.state.selectedUser };
        selectedUser = currentUser;
        let isAddUserModalOpen = this.state.isAddUserModalOpen;
        isAddUserModalOpen = true;
        this.setState({ selectedUser })
        this.setState({ isAddUserModalOpen })
    }
    closeModal = () => {
        this.setState({ isAddUserModalOpen: false });
    }

    addUserToList = user => {
      let users = this.state.users.slice();

      users.push(user);

      this.setState({ users });
    }

    render() {
        return (
            <div className='standard guests profile'>
                <Menu />
                <AdminMobileHeader />
                <div className='guests__page-content guestss__page-content'>
                    <div className='guests__container'>
                      <Tabs
                        value={this.state.tabValue}
                        onChange={this.updateTabValue}
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable auto tabs example"
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#e66300",
                            width:'50px',
                            'border-top-left-radius': '12px',
                            'border-top-right-radius': '12px',
                            marginLeft:'4%',
                            display:'flex',
                            justifyContent:"center",
                            alignItems:"center",
                            height:'8px'
                           }
                          }}

                      >
                        <Tab
                          key={1}
                          label="Profil"
                          id={`scrollable-force-tab-1`}
                          aria-controls={`scrollable-force-tabpanel-1`}
                          selected={true}

                        />
                        <Tab
                          key={2}
                          label="Utilizatori"
                          id={`scrollable-force-tab-2`}
                          aria-controls={`scrollable-force-tabpanel-2`}
                          selected={true}
                        />
                        <Tab
                          key={3}
                          label="Facturare"
                          id={`scrollable-force-tab-2`}
                          aria-controls={`scrollable-force-tabpanel-2`}
                          selected={true}
                        />
                      </Tabs>
                      <div
                        role="tabpanel"
                        value={this.state.tabValue}
                        hidden={this.state.tabValue !== 0}
                        id={`scrollable-force-tabpanel-${0}`}
                        aria-labelledby={`scrollable-force-tab-${0}`}
                        name="statistics">
                        {this.state.tabValue === 0 && (
                            <div>
                                <h3 className="profile__subtitle">Detalii profil</h3>
                                <div className='guests__wrapper profile__wrapper'>
                                    <div className='guests__heading'>
                                        <h4 className='guests__head-title '>Nume</h4>
                                        <h4 className='guests__head-title '>Email</h4>
                                    </div>

                                    <div className='guests__row guests__row' >
                                        <div className='guests__cell'>
                                            <h4>{this.state.user.name ? this.state.user.name : '-'}</h4>
                                        </div>
                                        <div className='guests__cell'>
                                            <h4>{this.state.user.email}</h4>
                                        </div>
                                    </div>

                                </div>
                                <div className="profile__change-password">
                                    <h3 className="profile__subtitle">Schimbă parola</h3>
                                    <form className='login__form profile__change-password-form' onSubmit={this.handleSubmit}>
                                        <h4 className='login__label'>Parola curentă*</h4>
                                        <div className="login__see-password">

                                            <input
                                                className='login__input'
                                                type={this.state.seePassword ? 'text' : 'password'}
                                                name="currentPassword"
                                                onChange={this.handleUpdate}
                                                required
                                                value={this.state.passwordData.currentPassword} />
                                            <img src="/images/eye.png" alt="see password" onClick={this.togglePassword} />
                                        </div>

                                        <h4 className='login__label'>Noua parolă*</h4>
                                        <div className="login__see-password">
                                            <input
                                                className='login__input'
                                                type={this.state.seeNewPassword ? 'text' : 'password'}
                                                name="password"
                                                onChange={this.handleUpdate}
                                                required
                                                value={this.state.passwordData.password} />
                                            <img src="/images/eye.png" alt="see password" onClick={this.toggleNewPassword} />
                                        </div>
                                        <h4 className='login__label'>Confirmă noua parolă:*</h4>
                                        <div className="login__see-password">

                                            <input
                                                className='login__input'
                                                type={this.state.seeNewPassword ? 'text' : 'password'}
                                                name="confirmPassword"
                                                onChange={this.handleUpdate}
                                                required
                                                value={this.state.passwordData.confirmPassword} />
                                            <img src="/images/eye.png" alt="see password" onClick={this.toggleNewPassword} />
                                        </div>
                                        <div className='login__account-box'>
                                        <button type="submit" className=" profile__submit-button supreme__add-client">Schimbă parola</button>

                                        </div>

                                    </form>

                                </div>
                                {/* <h3 className="profile__subtitle">Schimbă poza de profil:</h3>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="profile__profile-pic">
                                        <AttachementField
                                            onDrop={this.onDropDocument}
                                            removeDocument={this.removeDocument}
                                            disabledDocuments={this.state.disabledDocument}
                                            document={this.state.profile.profilePicture}
                                            documentFilename={
                                                this.state.profile.profilePicture_filename
                                            }
                                            text=""
                                            type="image/*"
                                            section="profile__attachement-field"
                                        />
                                        <button type="submit" className="supreme__add-client profile__button">Adauga poza de profil</button>
                                    </div>

                                </form> */}
                            </div>
                        )}
                      </div>

                      <div
                        role="tabpanel"
                        value={this.state.tabValue}
                        hidden={this.state.tabValue !== 1}
                        id={`scrollable-force-tabpanel-${1}`}
                        aria-labelledby={`scrollable-force-tab-${1}`}
                        className="test">
                        {this.state.tabValue === 1 && (
                          <div>
                            <h3 className="profile__subtitle">Utilizatori</h3>
                            <div className='guests__wrapper profile__wrapper'>
                                <div className='guests__heading'>
                                    <h4 className='guests__head-title '>Nume</h4>
                                    <h4 className='guests__head-title '>Email</h4>
                                </div>

                                { this.state.users.map(user => (
                                  <div className='guests__row guests__row' >
                                      <div className='guests__cell'>
                                          <h4>{user.name ? user.name : '-'}</h4>
                                      </div>
                                      <div className='guests__cell'>
                                          <h4>{user.email}</h4>
                                      </div>

                                  </div>
                                )) }
                              </div>
                              <button type="button" className="supreme__add-client" onClick={() => this.openAddUserModal()}>Adauga Utilizator</button>
                              {this.state.isAddUserModalOpen && <AddUser
                                  modalIsOpen={this.state.isAddUserModalOpen}
                                  closeModal={this.closeModal}
                                  user={this.state.selectedUser}
                                  addUserToList={this.addUserToList}
                              />}
                          </div>
                        )}
                      </div>


                      <div
                        role="tabpanel"
                        value={this.state.tabValue}
                        hidden={this.state.tabValue !== 2}
                        id={`scrollable-force-tabpanel-${2}`}
                        aria-labelledby={`scrollable-force-tab-${2}`}
                        className="test">
                        {this.state.tabValue === 2 && (
                          <div>
                            <h3 className="profile__subtitle">Facturi</h3>
                            <div className='guests__wrapper profile__wrapper'>
                                <div className='guests__heading'>
                                    <h4 className='guests__head-title '>ID comanda</h4>
                                    <h4 className='guests__head-title '>Descriere</h4>
                                    <h4 className='guests__head-title '>Suma (Euro)</h4>
                                    <h4 className='guests__head-title '>Status</h4>
                                    <h4 className='guests__head-title '>Link Plata</h4>
                                </div>

                                { this.state.invoices.map(invoice => (
                                  <div className='guests__row guests__row' key={invoice.id} >
                                      <div className='guests__cell'>
                                          <h4>{invoice.id}</h4>
                                      </div>
                                      <div className='guests__cell'>
                                          <h4>{invoice.description || '-'}</h4>
                                      </div>
                                      <div className='guests__cell'>
                                          <h4>{invoice.amount || '-'}</h4>
                                      </div>
                                      <div className='guests__cell'>
                                          <h4>{invoice.status || '-'}</h4>
                                      </div>
                                      <div className="guests__cell">
                                        <a href={invoice.payment_url} target="_blank"><button className="supreme__add-client">Link factura</button></a>
                                      </div>
                                  </div>
                                )) }
                              </div>
                          </div>
                        )}
                      </div>

                    </div>
                </div>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default UserProfile;
