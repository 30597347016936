import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = '#e66300'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        margin: '0 10px',
        borderBottomColor: '#e66300',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '45%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const TableRow = ({items}) => {
    const rows = items.map( item =>
        <View style={styles.row} key={item.id.toString() + Math.random()}>
            <Text style={styles.description}>{item.name}</Text>
            <Text style={styles.qty}>{item.company_number || '-'}</Text>
            <Text style={styles.rate}>{item.seats.length ? (item.seats[0].table_id ? item.seats[0].table.number : 'Fara loc') : 'Fara loc' }</Text>
            <Text style={styles.amount}></Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};

export default TableRow;
