import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import axios from 'axios';

// export const locationDetector = {
//   name: 'locationDetector',
//
//   lookup(options) {
//     console.log('asd');
//     // options -> are passed in options
//
//     const currentLang = localStorage.getItem('i18nextLng');
//
//     if (currentLang) {
//       return;
//     }
//
//     axios.get('https://ipapi.co/json/').then((response) => {
//         let data = response.data;
//
//         const lang = data.languages.split(',')[0];
//
//         if (lang) {
//           localStorage.setItem('i18nextLng', lang)
//         }
//
//     }).catch((error) => {
//         console.log(error);
//     });
//   },
//
//   cacheUserLanguage(lng, options) {
//     // options -> are passed in options
//     // lng -> current language, will be called after init and on changeLanguage
//     // store it
//   },
// };

// const options = {
//   order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'locationDetector', 'navigator', 'htmlTag', 'path', 'subdomain'],
//   lookupQuerystring: 'lng'
// }
//
// const languageDetector = new I18nextBrowserLanguageDetector();
// languageDetector.addDetector(locationDetector);


i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    //debug: true,
    fallbackLng: 'ro',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: { useSuspense: false },
    //detection: options
  });

  export default i18n;
