import React from 'react';
import './CivilCeremony.scss';
import MetaTags from 'react-meta-tags';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header'


class CivilCeremony extends React.Component {


    render() {

        return (
            <div className='standard landing civil'>
                <Header />
                <MetaTags>
                    <title>Facem Nunta</title>
                    <meta
                        name="description"
                        content="Facem Nunta este platforma online de organizare si planificare destinata mirilor si mireselor| Pentru o nunta asa cum iti doresti, fara stres sau compromisuri."
                    />
                    <meta
                        name="keywords"
                        content="facem nunta, nunta,cununie civila, legislatie, acte cununie civila,stare civila, organizare nunta, planificare nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta, casatorie, acte casatorie"
                    />
                    <meta
                        property="og:title"
                        content="Facem Nunta - Cununie Civila 101"
                    />
                    <meta
                        property="og:url"
                        content="https://www.facemnunta.ro/app/cununie-civila"
                    />
                </MetaTags>
                <div className="civil__whole-wrapper">
                <div className='civil__container'>
                    <h1 className='civil__title'>Facem cununie civilă. Dar cum?</h1>
                    <h2 className="civil__introduction">A sosit momentul în care deveniți soț și soție în fața legii (cununie civilă). Bine, de fapt sper că ați ajuns pe această pagină înainte de acel moment, mai exact cu cel puțin 15 zile înainte de data pe care v-ați propus-o.</h2>
                    <h2 className="civil__introduction">Motivul pentru care spun asta, plus toate lucrurile pe care trebuie să le știți, le găsiți pe această pagină.</h2>
                    <div className="civil__steps-container">
                    <div className="civil__step  civil__step-1">
                        <div className="civil__number"><h3>1</h3></div>
                        <div className="civil__info">
                            <h3 className="civil__step-title">Analizele medicale</h3>
                            <div className="civil__time-box">
                                <img src='/images/clock-orange.png' alt='time'/>
                                <h4>Analizele medicale cerute prin lege sunt <b>valabile 14 zile calendaristice</b>, iar căsătoria nu se poate programa la Oficiul Stării Civile în absența lor.</h4>
                            </div>
                            <h4 className="civil__info-text">Analizele medicale sunt VDRL/TPHA, anticorpi anti-HIV si radiografie pulmonara (dar nu trebuie să rețineți asta, doctorul de familie și laboratorul de analize vor știi exact ce vă doriți; au mai dat de viitori miri).</h4>
                            <h4 className="civil__info-text">în <b>24 de ore de la eliberarea certificatului prenupțial</b> (eliberat în urma analizelor) este obligatorie depunerea actelor de casatorie.</h4>
                        </div>
                    </div>

                    <div className="civil__step civil__step-2">
                        <div className="civil__number"><h3>2</h3></div>
                        <div className="civil__info">
                            <h3 className="civil__step-title">Depunerea actelor de căsătorie</h3>
                            <div className="civil__time-box">
                                <img src='/images/clock-orange.png' alt='time'/>
                                <h4>Depunerea actelor de căsătorie la sediul Serviciului de stare civilă se face cu 11 zile calendaristice înaintea evenimentului, termen în care se cuprind atât ziua când a fost făcută
declaraţia de căsătorie cât şi ziua în care se oficiază căsătoria</h4>
                            </div>
                            <h4 className="civil__info-text"><i>Exemplu: dacă doriţi să vă căsătoriţi SÂMBĂTĂ - 27 august 2022 trebuie să depuneţi dosarul MIERCURI - 17 august 2022</i></h4>
                            <h4 className="civil__list-title">Acte necesare:</h4>
                            <ul className="civil__list">
                                <div className="civil__list-box">
                                    <img src='/images/right-orange.png' alt="list item"/>
                                    <li>Actul de identitate al amândurora, in original si copie; </li>
                                </div>
                                <div className="civil__list-box">
                                    <img src='/images/right-orange.png' alt="list item"/>
                                    <li>Certificatul de nastere al amândurora, in original si copie; </li>
                                </div>
                                <div className="civil__list-box">
                                    <img src='/images/right-orange.png' alt="list item"/>
                                    <li>Certificatul prenupțial;  </li>
                                </div>
                                <div className="civil__list-box">
                                    <img src='/images/right-orange.png' alt="list item"/>
                                    <li>Declaratie din care sa rezulte ca cei doi soți nu cunosc niciun motiv de natura legala care sa impiedice incheierea casatoriei, in care  vor menționa numele de familie pe care îl vor purta în timpul căsătoriei, conform prevederilor art. 282 din Legea nr. 287/2009 privind Codul Civil, precum și regimul matrimonial ales (îl găsiți pe site-ul primăriei);  </li>
                                </div>
                                <div className="civil__list-box">
                                    <img src='/images/right-orange.png' alt="list item"/>
                                    <li>În unele cazuri, dacă vă doriți ca evenimentul să aibă loc altundeva decât la stare civilă, trebuie să aduceți o fotografie cu locul pe care vi-l doriți; cel mai bine verificați pe site-ul primăriei; </li>
                                </div>
                                <div className="civil__list-box">
                                    <img src='/images/right-orange.png' alt="list item"/>
                                    <li>Copie acte identitate valabile a două persoane, în calitate de martori (aici puteți pune nașii la treabă); </li>
                                </div>
                                <div className="civil__list-box">
                                    <img src='/images/right-orange.png' alt="list item"/>
                                    <li>Convenție matrimonială, autentificată la notariat în cazul, in care regimul matrimonial este altul decat cel al comunitatii legale. </li>
                                </div>

                            </ul>
                        </div>
                    </div>

                    <div className="civil__step civil__step-2 civil__step-3">
                        <div className="civil__number"><h3>3</h3></div>
                        <div className="civil__info">
                            <h3 className="civil__step-title">Așteptarea</h3>
                            <div className="civil__time-box">
                                <img src='/images/clock-orange.png' alt='time'/>
                                <h4>De la depunerea actelor până la eveniment în sine sunt 10 zile calendaristice de nerăbdare continuă.</h4>
                            </div>
                            <h4 className="civil__info-text">Pont: regretabil, nu avem.</h4>

                        </div>
                    </div>
                    <h4 className="civil__wish">Casă de piatră!</h4>
                </div>
                </div>
                <div className="civil__img-box">   <img src='/images/couple-cartoon.png' alt="couple" className="standard__only-desktop civil__main-icon"/></div>

                </div>

                <Footer />
            </div>
        )
    }
}

export default CivilCeremony;
