import axios from 'axios';
import { loginService } from './LoginService';

export const providerService={
    createOrUpdateProvider,
    getProviders,
    deleteProvider,
    searchProviders
}
function createOrUpdateProvider(providerData){

    let url = `${process.env.REACT_APP_API_URL}/api/providers${providerData.id ? `/${providerData.id}` : ''}`;
    const requestOptionsPost = {
        method: providerData.id? 'PUT': 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data: buildRequestData(providerData)
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
}
function getProviders(pageNumber){
    let url = `${process.env.REACT_APP_API_URL}/api/providers?page[number]=${pageNumber ? pageNumber : 1}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}

function searchProviders(providerType, pageNumber){
  let url = `${process.env.REACT_APP_API_URL}/api/providers/search?page[number]=${pageNumber ? pageNumber : 1}
    &${providerType ? `provider_type=${providerType}` : ''}`;

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
  }
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptions);
}

function deleteProvider(providerId){
    const requestOptionsDelete = {
        headers: {
          Accept: 'application/json'
        }
      };

      if (loginService.loggedIn()) {
        requestOptionsDelete.headers['Authorization'] =loginService.getToken();
      }
      let url = `${process.env.REACT_APP_API_URL}/api/providers/${providerId}`;

      return axios.delete(url, requestOptionsDelete);
}
function buildRequestData(providerData) {
  let formData = new FormData();

  formData.append('provider[name]', providerData.name);
  formData.append('provider[website_url]', providerData.website_url);
  formData.append('provider[discount_code]', providerData.discount_code);
  formData.append('provider[description]', providerData.description);
  formData.append('provider[value]', providerData.value);
  formData.append('provider[provider_type]', providerData.type);
  formData.append('provider[contact_person]', providerData.contactPerson);
  formData.append('provider[phone]', providerData.phone);
  formData.append('provider[email]', providerData.email);
  formData.append('provider[address]', providerData.address);

  const document = providerData.logo;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`provider[logo]`, document.url);
    } else {
      formData.append(`provider[logo]`, document, document.name);
    }
  }

  return formData;
}
