import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../../components/AdminMenu/AdminMenu';
import { invitationService } from '../../../services/InvitationService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { eventService } from '../../../services/EventService';
import { guestsService } from '../../../services/AdminService';
import ReactPaginate from 'react-paginate';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import generatePdfGuestList from '../../../components/TableConfiguratorPDFExport/GeneratePDFGuestList';
import { clientsService } from '../../../services/ClientsService';
import moment from "moment";
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { Divider } from "antd";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const { Panel } = Collapse;

class Answers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pagination: {},
            guests: [],
            currentEvent: {},
            events: [],
            searchFieldStatus: false,
            currentGuest: {},
            exportStarted: false,
            exportDisabled: true,
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false,
            idLocalStorage:'',
            searchObject: {
              search_name: '',
              company: '',
              housing: '',
              transport: '',
              civil_marriage_attend: '',
              other_restrictions: ''
            },
            exportDetails: {
              fileType: 'excel',
              includeFilters: true
            }
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
      const notification = this.notificationSystem.current;
      this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })
        window.scrollTo({ top: 0, behavior: 'smooth' });

        clientsService.checkClient()
          .then(response => {
              if (response.data.active_demo === false) {
                this.setState({ expiredAccount: true });

                notification.addNotification({
                    message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                    level: 'error',
                    position: 'tc'
                });

                setTimeout(() => {
                  this.props.history.push("/app/profil");
                }, 3000);

                return;
              }

              if (response.data.active_demo) {
                this.setState({ activeDemo: true });
                const freeDays = moment(response.data.free_until).diff(moment(), 'days');
                const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

                this.setState({ freeDays });

                if (freeHours > 0) {
                  this.setState({ freeHours });
                }
              }

              eventService.getEvents().then(response => {
                  let events = this.state.events;
                  events = response.data.events;
                  let currentEvent = { ...this.state.currentEvent };
                  let specificEvent= response.data.events.find(event => event.id === this.state.idLocalStorage);

                  currentEvent = specificEvent ? specificEvent : { ...events[0] };
                  this.setState({ events });
                  this.setState({ currentEvent }, () => {
                      guestsService.getGuests(this.state.currentEvent.id).then(response => {
                          let guests = this.state.guests;
                          guests = response.data.guests;
                          let pagination = this.state.pagination;
                          pagination = response.data.pagination;

                          this.setState({ pagination })
                          this.setState({ guests }, () => {
                            this.setState({ exportDisabled: false });
                          })
                      })
                          .catch(error => {
                              if (error.response.status === 401) {
                                  this.props.history.push("/app/login");
                              }
                          })
                  })
              })
                  .catch(error => {
                      if (error.response.status === 401) {
                          this.props.history.push("/app/login");
                      }
                  })

          })
          .catch(error => {
              if (error.response.status === 401) {
                  this.props.history.push("/app/login");
              }
          })
    }
    handleSearch = event => {
        event.preventDefault();
        guestsService.searchGuest(this.state.currentEvent.id, this.state.searchObject).then(response => {
            this.setPaginationState(response);
        })
            .catch(err => console.log(err))
    }
    handlePageClick = selectedPage => {
        guestsService.searchGuest(this.state.currentEvent.id, this.state.searchObject, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let guests = this.state.guests;
        guests = response.data.guests;

        this.setState({ guests });
    };
    updateSearchField = event => {
      let searchObject = { ...this.state.searchObject }
      searchObject[event.target.name] = event.target.value

        this.setState({ searchObject });
    }

    updateExportDetails = event => {
      let exportDetails = { ...this.state.exportDetails }
      exportDetails[event.target.name] = event.target.value

      this.setState({ exportDetails });
    }

    handleCheckboxChange = event => {
      const field = event.target.name;
      const exportDetails = { ...this.state.exportDetails };
      exportDetails[field] = event.target.checked;
      this.setState({ exportDetails });
    }

    updateEventId = event => {
        const field = event.target.name;
        let currentEvent = { ...this.state.currentEvent };

        currentEvent = this.state.events.find(eventToFind => eventToFind.id == event.target.value);

        console.log(currentEvent)
        this.setState({ currentEvent }, () => {
            guestsService.getGuests(this.state.currentEvent.id).then(response => {
                let guests = this.state.guests;
                guests = response.data.guests;
                this.setState({ guests })
            })
                .catch(err => console.log(err))
        });
    }

    openSearchField = () => {
        let searchFieldStatus = this.state.searchFieldStatus;
        searchFieldStatus = true;
        this.setState({ searchFieldStatus })
    }
    closeSearchField = () => {
        let searchFieldStatus = this.state.searchFieldStatus;
        searchFieldStatus = false;
        let searchObject = { ...this.state.searchObject }
        searchObject.search_name = ''
        this.setState({searchObject}, () => {
          guestsService.searchGuest(this.state.currentEvent.id, this.state.searchObject).then(response => {
              this.setPaginationState(response);
          })
              .catch(err => console.log(err))
        })
        this.setState({ searchFieldStatus })
    }



    toggleGuest = currentGuest => {
        console.log('djf')
        let guests = this.state.guests;
        const guestIndex = guests.findIndex(guest => guest.id === currentGuest.id);
        guests[guestIndex].openedBox = !guests[guestIndex].openedBox;
        this.setState({
            guests
        });
    }

    closeModal = () => {
        this.setState({ isAddGuestModalOpen: false });

    }
    switchCompany = companyName => {
      if (companyName === "single") {
          return "Singur(ă)"
      }
      if (companyName === "family") {
          return "Cu familia"
      }
      if (companyName === "couple") {
          return "Cuplu"
      }
    }

    generateGuestPDF = () => {
      this.setState({ exportStarted: true });

      guestsService.getAllGuests(this.state.currentEvent.id)
        .then(response => {
          let guestList = [];
          guestList.push(...response.data.guests);

          generatePdfGuestList(guestList, this.state.currentEvent.name);
        })
        .catch(error => {
            if (error.response.status === 401) {
                this.props.history.push("/app/login");
            }
        })

      // generatePdfGuestList(this.state, this.state.currentEvent.name);
      this.setState({ exportStarted: false });
    }

    exportInvitees = (event) => {
      event.preventDefault();

      guestsService.searchGuest(this.state.currentEvent.id, this.state.exportDetails.includeFilters ? this.state.searchObject : {}, 1, 1000).then(response => {
        if (this.state.exportDetails.fileType === 'csv') {
          this.generateCSVFile(response.data.guests);
        }

        if (this.state.exportDetails.fileType === 'excel') {
          this.generateExcelFile(response.data.guests);
        }
      })
      .catch(err => console.log(err))
    }

    generateCSVFile = (rows) => {
      // Headers for each column
      let headers = ['Id,Name,Email,Telefon,Nr. Persoane,Meniuri,Alergii, Mentiuni alergii,Alte Restrictii, Cazare, Transport, Participare Civila, Nume Masa, Numar Masa, Nr. Persoane']

      // Convert users data to a csv
      let usersCsv = rows.reduce((acc, guest) => {
        const { id, name, email, phone, company_number, allergy, allergy_mentions, housing, transport, civil_marriage_attend, other_restrictions} = guest;
        const allMenus = guest.menus.reduce((a, b) => `${a.menu_type || a};${b.menu_type}`, '').toString();
        const hasSeat = guest.has_seat;
        let tableNumber = '-';
        let tableName = '-';

        if (hasSeat && guest.seats.length) {
          tableNumber = guest.seats[0].table_id ? guest.seats[0].table.number : '-';
          tableName = guest.seats[0].table_id ? guest.seats[0].table.name : '-';
        }

        acc.push([id, name, email, phone, company_number, allMenus , allergy, allergy_mentions, other_restrictions, housing ? 'DA' : 'NU', transport ? 'DA' : 'NU', civil_marriage_attend ? 'DA' : 'NU', tableName, tableNumber, company_number].join(','))
        return acc
      }, [])

      this.downloadFile({
        data: [...headers, ...usersCsv].join('\n'),
        fileName: 'users.csv',
        fileType: 'text/csv',
      })
    }

    generateExcelFile = (rows) => {
      console.log('excel');

      let data = [];

      data = rows.map(row => {
        const allMenus = row.menus.reduce((a, b) => `${a.menu_type || a};${b.menu_type}`, '').toString();
        const hasSeat = row.has_seat;
        let tableNumber = '';
        let tableName = '';

        if (hasSeat && row.seats.length) {
          tableNumber = row.seats[0].table_id ? row.seats[0].table.number : '';
          tableName = row.seats[0].table_id ? row.seats[0].table.name : '';
        }

        return {
          "Nume": row.name,
          "Email": row.email,
          "Telefon": row.phone,
          "Numar Persoane": row.company_number,
          "Meniuri": allMenus,
          "Alergii Prezente": row.allergy,
          "Alergii": row.allergy_mentions,
          "Alte Restrictii": row.other_restrictions,
          "Cazare": row.housing ? 'DA' : 'NU',
          "Transport": row.transport ? 'DA' : 'NU',
          "Prezenta Cununie Civila": row.civil_marriage_attend ? 'DA' : 'NU',
          "Nume Masa": tableName,
          "Numar Masa": tableNumber
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Buffer to store the generated Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

      saveAs(blob, "data.xlsx");

    }

    // generateCSVExport = event => {
    //   event.preventDefault();
    //
    //   guestsService.searchGuest(this.state.currentEvent.id, this.state.searchObject, 1, 1000).then(response => {
    //     console.log(response.data.guests);
    //     // Headers for each column
    //     let headers = ['Id,Name,Email,Telefon,Nr. Persoane,Meniuri,Alergii, Mentiuni alergii,Alte Restrictii, Cazare, Transport, Participare Civila, Nume Masa, Numar Masa, Nr. Persoane']
    //
    //     // Convert users data to a csv
    //     let usersCsv = response.data.guests.reduce((acc, guest) => {
    //       const { id, name, email, phone, company_number, allergy, allergy_mentions, housing, transport, civil_marriage_attend, other_restrictions} = guest;
    //       const allMenus = guest.menus.reduce((a, b) => `${a.menu_type || a};${b.menu_type}`, '').toString();
    //       const hasSeat = guest.has_seat;
    //       let tableNumber = '-';
    //       let tableName = '-';
    //
    //       if (hasSeat && guest.seats.length) {
    //         tableNumber = guest.seats[0].table_id ? guest.seats[0].table.number : '-';
    //         tableName = guest.seats[0].table_id ? guest.seats[0].table.name : '-';
    //       }
    //
    //       acc.push([id, name, email, phone, company_number, allMenus , allergy, allergy_mentions, other_restrictions, housing ? 'DA' : 'NU', transport ? 'DA' : 'NU', civil_marriage_attend ? 'DA' : 'NU', tableName, tableNumber, company_number].join(','))
    //       return acc
    //     }, [])
    //
    //     this.downloadFile({
    //       data: [...headers, ...usersCsv].join('\n'),
    //       fileName: 'users.csv',
    //       fileType: 'text/csv',
    //     })
    //   })
    //       .catch(err => console.log(err))
    // }

    downloadFile = ({ data, fileName, fileType }) => {
      // Create a blob with the data we want to download as a file
      const blob = new Blob([data], { type: fileType })
      // Create an anchor element and dispatch a click event on it
      // to trigger a download
      const a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }

    updateEventId=event=>{
        const eventId = event.target.value

        localStorage.setItem('idLocalStorage', eventId)
        let idLocalStorage=this.state.idLocalStorage;
        idLocalStorage=eventId;
        this.setState({idLocalStorage}, () => window.location.reload())
    }

    render() {
        return (
            <div className={ this.state.expiredAccount ? 'standard__no-interaction guests admin invitation' : 'guests admin invitation'}>
                <Menu menuItem="answers" />
                <AdminMobileHeader page="Raspunsuri" />
                <div className='admin__container'>
                    <div className='admin__top'>
                        <div className='admin__select-box'>
                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.idLocalStorage}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    { this.state.activeDemo ? (
                      <div className="admin__no-something">
                          <img src="/images/info.png" alt="info" />
                          <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti { this.state.freeDays } zile si { this.state.freeHours } ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
                      </div>
                    ) : null }
                    <div className="admin__wrapper invitation__wrapper">
                        <div className=''>
                            <div className="invitation__top-desktop only-desktop">
                                <h2 className="admin__title">Răspunsuri</h2>
                                <div className="invitation__top-right">
                                    {this.state.searchFieldStatus ? '' : <div className="invitation__search-button" onClick={() => this.openSearchField()}>
                                        <img src="/images/search-black.png" alt="search" className="invitation__search-img" />
                                    </div>}
                                    {this.state.searchFieldStatus ?
                                        <form className='admin__form invitation__search-form' onSubmit={this.handleSearch}>
                                            <input type='text'
                                                name='search_name'
                                                placeholder="Cauta dupa nume..."
                                                className='invitation__search-field'
                                                value={this.state.searchObject.search_name}
                                                onChange={this.updateSearchField} />
                                            <img src='/images/search-black.png' alt='search' className="invitation__search-icon" />
                                            <img src="/images/close-black.png" onClick={() => this.closeSearchField()} alt="close" className="invitation__close-search" />
                                        </form> : ''}
                                </div>
                            </div>
                            <div className="only-mobile">
                                <form className='admin__form  invitation__search-form' onSubmit={this.handleSearch}>
                                    <input type='text'
                                        name='search_name'
                                        placeholder="Cauta dupa nume..."
                                        className='invitation__search-field'
                                        value={this.state.searchObject.search_name}
                                        onChange={this.updateSearchField} />
                                    <img src='/images/search-black.png' alt='search' className="invitation__search-icon" />
                                </form>
                            </div>

                            <Collapse>
                              <Panel header={
                                <h4>Exporta Invitati</h4>
                              } key="1">
                                <h2 className="admin__title">Exporta asezarea la mese</h2>
                                <h5>Atentie, asezarea la mese a invitatilor trebuie realizata din modului de aseazare la mese inainte de export!</h5>
                                <button disabled={this.state.exportDisabled || this.state.exportStarted} className="website-form__add" onClick={this.generateGuestPDF}>Genereaza PDF asezare mese</button>
                                <Divider />
                                <h2 className="admin__title">Exporta invitati</h2>

                                <div className="add-modal__row">
                                  <div className="add-modal__select">
                                    <h4 className='add-modal__label'>Tip Fisier</h4>
                                    <select
                                        className="add-modal__field"
                                        title="fileType"
                                        value={this.state.exportDetails.fileType}
                                        name="fileType"
                                        onChange={this.updateExportDetails}
                                    >
                                        <option value="xls">Excel</option>
                                        <option value="csv">CSV</option>
                                    </select>
                                  </div>
                                  <div className="add-modal__toggle-consent">
                                  <h4 className='add-modal__label'>Include Filtre</h4>
                                  <label class="switch">
                                    <input type="checkbox"
                                     name="includeFilters"
                                     onChange={this.handleCheckboxChange}

                                     checked={this.state.exportDetails.includeFilters} />
                                    <span class="slider round"></span>
                                  </label>
                                  </div>
                                  <button disabled={this.state.exportStarted} className="website-form__add" onClick={this.exportInvitees}>Exporta Invitati</button>
                                </div>
                              </Panel>
                            </Collapse>

                            <div className="admin__filter-form-wrapper">
                                <form className="admin__filter-form" onSubmit={this.handleSearch}>
                                    <div>
                                        <h4 className='add-modal__label'>Status Invitat</h4>
                                        <select
                                            className="add-modal__field"
                                            title="company"
                                            value={this.state.searchObject.company}
                                            name="company"
                                            onChange={this.updateSearchField}
                                        >
                                            <option value="">Oricare</option>
                                            <option value="single">Singur</option>
                                            <option value="couple">Cu Partenerul/Partenera</option>
                                            <option value="family">Cu Intreaga Familie</option>
                                        </select>
                                    </div>
                                    <div>
                                        <h4 className='add-modal__label'>Alergii Prezente</h4>
                                        <select
                                            className="add-modal__field"
                                            title="allergy"
                                            value={this.state.searchObject.allergy}
                                            name="allergy"
                                            onChange={this.updateSearchField}
                                        >
                                            <option value="">Oricare</option>
                                            <option value="yes">Da</option>
                                            <option value="no">Nu</option>
                                        </select>
                                    </div>
                                    <div>
                                        <h4 className='add-modal__label'>Alte Restrictii</h4>
                                        <select
                                            className="add-modal__field"
                                            title="other_restrictions"
                                            value={this.state.searchObject.other_restrictions}
                                            name="other_restrictions"
                                            onChange={this.updateSearchField}
                                        >
                                            <option value="">Oricare</option>
                                            <option value="true">Da</option>
                                            <option value="false">Nu</option>
                                        </select>
                                    </div>
                                    <div>
                                        <h4 className='add-modal__label'>Necesita Transport</h4>
                                        <select
                                            className="add-modal__field"
                                            title="transport"
                                            value={this.state.searchObject.transport}
                                            name="transport"
                                            onChange={this.updateSearchField}
                                        >
                                            <option value="">Oricare</option>
                                            <option value="true">Da</option>
                                            <option value="false">Nu</option>
                                        </select>
                                    </div>
                                    <div>
                                        <h4 className='add-modal__label'>Necesita Cazare</h4>
                                        <select
                                            className="add-modal__field"
                                            title="housing"
                                            value={this.state.searchObject.housing}
                                            name="housing"
                                            onChange={this.updateSearchField}
                                        >
                                            <option value="">Oricare</option>
                                            <option value="true">Da</option>
                                            <option value="false">Nu</option>
                                        </select>
                                    </div>
                                    <div>
                                        <h4 className='add-modal__label'>Prezenta Cununie</h4>
                                        <select
                                            className="add-modal__field"
                                            title="civil_marriage_attend"
                                            value={this.state.searchObject.civil_marriage_attend}
                                            name="civil_marriage_attend"
                                            onChange={this.updateSearchField}
                                        >
                                            <option value="">Oricare</option>
                                            <option value="true">Da</option>
                                            <option value="false">Nu</option>
                                        </select>
                                    </div>
                                    <button type='submit' className="admin__add-budget">Filtreaza Raspunsuri</button>
                                </form>
                            </div>

                            <div className="invitation__content-wrapper">
                                {this.state.guests.length ? this.state.guests.map(guest => (
                                    <div key={guest.id} className="invitation__guest">

                                        <div className="invitation__guest-top" onClick={() => this.toggleGuest(guest)}>
                                            <div className="invitation__guest-status">
                                                <h4>{guest.name}</h4>
                                                <h4>{guest.participant ? 'Participant' : 'Invitat'}</h4>
                                            </div>
                                            <div className="invitation__guest-confirmation">
                                                <h4>{guest.status}</h4>
                                                <img src={guest.openedBox ? "/images/less.png" : "/images/more.png"} alt="more" />
                                            </div>
                                        </div>
                                        {guest.openedBox ? <div className="invitation__toggled-info">
                                            <div className='events__info-box'>
                                                <h4 className="events__left">Cod</h4>
                                                <h4 className="events__right">{guest.code}</h4>
                                            </div>
                                            <div className='events__info-box'>
                                                <h4 className="events__left">Numar telefon</h4>
                                                <h4 className="events__right">{guest.phone}</h4>
                                            </div>
                                            <div className='events__info-box'>
                                                <h4 className="events__left">Cu cine va veni</h4>
                                                <h4 className="events__right">{this.switchCompany(guest.company)}</h4>
                                            </div>
                                            <div className='events__info-box'>
                                                <h4 className="events__left">Numar persoane</h4>
                                                <h4 className="events__right">{guest.company_number}</h4>
                                            </div>
                                            <div className='events__info-box'>
                                                <h4 className="events__left">Acțiuni</h4>
                                                <Link className="standard__link events__edit" to={`/app/invitat/${guest.id}`}> <h4>Vezi invitatul</h4></Link>

                                            </div>
                                        </div> : ''}

                                    </div>
                                )) :<h4 className='standard__no-items'>Încă nu ai invitați confirmați.</h4>}
                            </div>
                            <div className="website-form__wedding-contacts only-desktop invitation__guests-table">
                                <div className="website-form__contact-head">
                                    <h4>Nume invitat</h4>
                                    <h4>Numar telefon</h4>
                                    <h4>Cu cine va veni</h4>
                                    <h4>Numar persoane</h4>
                                    <h4>Acțiuni</h4>
                                </div>
                                {this.state.guests.length? this.state.guests.map(guest => (
                                    <div className="website-form__contact-row">
                                        <h4 className='website-form__cell'>{guest.name}</h4>
                                        <h4 className='website-form__cell'>{guest.phone}</h4>
                                        <h4  className='website-form__cell'>{this.switchCompany(guest.company)}</h4>
                                        <h4 className='website-form__cell'>{guest.company_number}</h4>

                                        <div className="website-form__actions website-form__cell">
                                          <Link className="invitation__link standard__link" to={`/app/invitat/${guest.id}`}> <h4>Vezi invitatul</h4></Link>
                                        </div>

                                    </div>
                                )): <h4 className='standard__no-items'>Încă nu ai invitați confirmați sau cautarile nu au rezultate.</h4>}
                            </div>
                            {this.state.pagination && this.state.pagination.last_page > 1 && (
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pagination.last_page}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            )}
                        </div>

                    </div>
                    <NotificationSystem
                        ref={this.notificationSystem}
                        style={NotificationSystemStyle}
                    />



                </div>
            </div>
        )
    }
}

export default Answers;
