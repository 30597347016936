import React from 'react';
import '../../resources/main.scss';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import {passwordService} from '../../services/ResetPasswordService';

class SendEmail extends React.Component{
    constructor(props){
        super(props);

        this.state={
            email:""
        }
        this.notificationSystem = React.createRef();
    }

      handleChange=event=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }
    handleSubmit=event=>{
        event.preventDefault();
        const notification = this.notificationSystem.current;
    
        passwordService
          .sendEmail(this.state)
          .then(response => {
            notification.addNotification({
                message: 'Link-ul de resetare a parolei a fost trimis pe email-ul tau.',
                level: 'success',
                position: 'tc'
            });
    
    
          })
          .catch(error => {
            if (error.response.data.error[0] === 'Email address not found. Please check and try again.') {
              notification.addNotification({
                message: 'Email-ul tau nu a fost gasit in baza noastra de date. Te rugam sa iti faci cont pe platforma noastra.',
                level: 'error',
                position: 'tc'
              });
    
              return;
            }
            notification.addNotification({
                message: 'Ne pare rau, a apărut o problema. Va rugăm sa dați un refresh sau să ne contactați daca problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
    }
   
    render(){
        return(
            <div className='standard login'>
                <Header/>
                    <div className='login__wrapper'>
                    <img src='/images/lateral.png' alt='background' className='login__lateral'/>
                    <div className='login__container'>
                        <h3 className='login__title'>Introdu email-ul pentru a-ți trimite un link pentru resetarea parolei.</h3>
                        <h4 className='login__subtitle'>Nu ai un cont? <Link className="standard__link" to="/app/creeaza-cont"><span> Înregistrează-te aici</span></Link></h4>
                        <form className='login__form'onSubmit={this.handleSubmit} >
                            <h4 className='login__label'>Email*</h4>
                              <input
                                className='login__input'
                                type="email"
                                name="email"
                                onChange={this.handleChange}
                                required
                                value={this.state.email}/>
                            <div className='login__account-box'>
                                <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>Trimite</h5></button>
                                {/* <div className='login__option-box'>
                                    <div className='login__line'/>
                                    <h4 className='login__option-writing'>Sau intră în contul tău cu</h4>
                                    <div className='login__line'/>
                                </div>
                                <button className='login__option-login'>
                                    <img src='/images/google.png' alt='google'/>
                                    <h5>Google</h5>
                                </button>
                                <button className='login__option-login'>
                                    <img src='/images/facebook.png' alt='facebook'/>
                                    <h5>Facebook</h5>
                                </button> */}

                            </div>

                        </form>

                    </div>
                    </div>
                    <Footer/>
                    <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
            </div>
        )
    }
}

export default SendEmail;
