import React from 'react';
import { Link } from 'react-router-dom';
import SupremeMenu from "../../../components/SupremeMenu/SupremeMenu";
import { supremeAdminService } from '../../../services/SupremeAdminService';
import { clientsService } from '../../../services/ClientsService';
import Moment from 'react-moment';
import AttachementField from '../../../components/AttachementField/AttachementField'
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import moment from "moment";

class ClientPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            client: {},
            currentEvent: {},
            invoice: {
                invoiceDocument: '',
            },
            disabledDocument: false,
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        if (this.props.match.params.id) {
            supremeAdminService.getClient(this.props.match.params.id).then(response => {
                let client = { ...this.state.client };
                client = response.data;
                client.freeDays = moment(response.data.free_until).diff(moment(), 'days');
                const freeHours = moment(response.data.free_until).diff(moment(), 'hours');
                client.freeHours = freeHours - (parseInt(freeHours / 24) * 24);
                this.setState({ client })
            })
        }
    }
    onDropDocument = documents => {
        let invoice = { ...this.state.invoice };
        let reader = new FileReader();
        let self = this;

        reader.onload = function (upload) {
            invoice.invoiceDocument = documents[0];

            self.setState({ invoice });
            self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
    };

    removeDocument = () => {
        let invoice = { ...this.state.invoice };
        invoice.invoiceDocument = null;
        invoice.invoiceDocument_filename = '';

        this.setState({ invoice });
        this.setState({ disabledDocument: false });
    }
    handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        supremeAdminService.addInvoice(this.state.invoice)
            .then(response => {
                console.log(response);
                notification.addNotification({
                    message:
                        `Factura a fost adaugata cu succes cu succes.`,
                    level: 'success',
                    position: 'tc'
                });
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            })
            .catch(error => {
                console.log(error);
            })
    }

    regeneratePaymentLink = (event, invoiceId) => {
      event.preventDefault();
      const notification = this.notificationSystem.current;

      clientsService.regeneratePaymentLink(invoiceId)
          .then(response => {
              console.log(response);
              notification.addNotification({
                  message:
                      `Link-ul a fost regenerat cu succes.`,
                  level: 'success',
                  position: 'tc'
              });
          })
          .catch(error => {
              console.log(error);
          })
    }

    handleChange = event => {
        const field = event.target.name;
        const client = { ...this.state.client };
        client[field] = event.target.value;
        this.setState({ client });
    }

    applyDiscountCode = (event) => {
      event.preventDefault();
      const notification = this.notificationSystem.current;

      clientsService.applyDiscountCode(this.state.client)
          .then(response => {
              notification.addNotification({
                  message:
                      `Discount-ul a fost aplicat cu succes.`,
                  level: 'success',
                  position: 'tc'
              });
          })
          .catch(error => {
              console.log(error);
          })
    }

    setAccountType = (event) => {
      event.preventDefault();
      const notification = this.notificationSystem.current;

      clientsService.setAccountType(this.state.client)
          .then(response => {
              notification.addNotification({
                  message:
                      `Cont-ul a fost schimbat cu succes.`,
                  level: 'success',
                  position: 'tc'
              });
          })
          .catch(error => {
              console.log(error);
          })
    }

    setFreeDays = (event, freeDays) => {
      event.preventDefault();

      const notification = this.notificationSystem.current;

      clientsService.setFreeDays(this.state.client.id, freeDays)
          .then(response => {
              notification.addNotification({
                  message:
                      `Zilele au fost aplicate cu success.`,
                  level: 'success',
                  position: 'tc'
              });
          })
          .catch(error => {
              console.log(error);
          })
    }

    render() {
        return (
            <div className=' guests admin invitation supreme'>
                <SupremeMenu menuItem="" />
                <div className='admin__container'>
                    <div className="admin__wrapper invitation__wrapper">
                        <div className=''>
                            <div className="admin__guest-top">
                                <Link to="/app/clienti" className="standard__link"><img src='/images/go-back.png' alt='back' className="admin__back-icon" /></Link>
                                <h2 className="admin__title">Vizualizare client :{this.state.client.name}</h2>
                            </div>
                            <div className="admin__guest-container">

                                <div className="admin__guest-info supreme__client-wrapper">
                                    <h3 className="admin__subtitle">Detalii</h3>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Nume:</h4>
                                        <h4 className="admin__info-specific">{this.state.client.name}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Email:</h4>
                                        <h4 className="admin__info-specific">{this.state.client.email}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Telefon:</h4>
                                        <h4 className="admin__info-specific">{this.state.client.phone}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Cod Discount:</h4>
                                        <h4 className="admin__info-specific">{this.state.client.discount_code}</h4>
                                    </div>
                                    <h3 className="admin__subtitle">Eveniment</h3>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Data:</h4>
                                        <h4 className="admin__info-specific">{this.state.client.events && this.state.client.events.length ? this.state.client.events[0].date : '-'}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Website Slug:</h4>
                                        <h4 className="admin__info-specific">{this.state.client.events && this.state.client.events.length ? this.state.client.events[0].slug : '-'}</h4>
                                    </div>

                                    {this.state.client.users && this.state.client.users.length ? <div>
                                        <h3 className="admin__subtitle supreme__subtitle">Utilizatori</h3>
                                        {this.state.client.users.map(user => (
                                            <div className="supreme__user">
                                                <div className="admin__guest-info-box">
                                                    <h4 className="admin__info-generic">Email:</h4>
                                                    <h4 className="admin__info-specific">{user.email}</h4>
                                                </div>
                                                <div className="admin__guest-info-box">
                                                    <h4 className="admin__info-generic">Acces de logare:</h4>
                                                    <h4 className="admin__info-specific">{user.allowed_to_log_in ? 'Da' : 'Nu'}</h4>
                                                </div>
                                            </div>

                                        ))}        </div>
                                        : ''}

                                    {this.state.client.invoices && this.state.client.invoices.length ? <div>
                                        <h3 className="admin__subtitle supreme__subtitle">Facturi</h3>
                                        {this.state.client.invoices.map(invoice => (
                                            <div className="supreme__bills">
                                                <div className="admin__guest-info-box">
                                                    <h4 className="admin__info-generic">Data factura:</h4>
                                                    <h4 className="admin__info-specific"><Moment format="DD/MM/YYYY">{invoice.created_at}</Moment></h4>
                                                </div>
                                                <div className="admin__guest-info-box">
                                                    <h4 className="admin__info-generic">Valoare factura:</h4>
                                                    <h4 className="admin__info-specific">{invoice.amount}</h4>
                                                </div>
                                                <div className="admin__guest-info-box">
                                                    <h4 className="admin__info-generic">Descriere:</h4>
                                                    <h4 className="admin__info-specific">{invoice.description}</h4>
                                                </div>
                                                <div className="admin__guest-info-box">
                                                    <a href={invoice.payment_url} target="_blank"><button className="supreme__add-client">Link factura</button></a>
                                                    <button className="supreme__add-client" onClick={(event) => this.regeneratePaymentLink(event, invoice.id)}>Regenerare Link factura</button>
                                                </div>
                                                {/* <div className="admin__guest-info-box">
                                                    <h4 className="admin__info-generic">Vezi factura:</h4>
                                                    <h4
                                                    className="admin__info-specific"
                                                    onClick={() =>
                                                        window.open(
                                                            `${process.env.REACT_APP_API_URL}${invoice.document_task_book.url
                                                            }`
                                                        )
                                                    }
                                                >
                                                   Factura
                                                </h4>
                                                </div> */}

                                            </div>
                                        ))}
                                    </div> : ''}
                                      <div className="supreme__client-actions">
                                      <h3 className="admin__subtitle supreme__subtitle">Actiuni Client</h3>
                                      <div className="admin__guest-info-box admin__guest-info-box--small">
                                          <input
                                              className='add-modal__field'
                                              type="text"
                                              name="discount_code"
                                              onChange={this.handleChange}
                                              required
                                              value={this.state.client.discount_code} />
                                          <button className="supreme__add-client" onClick={this.applyDiscountCode}>Aplica Cod Discount</button>
                                      </div>
                                      <div className="admin__guest-info-box admin__guest-info-box--small">
                                      <div>
                                          <select
                                            className="add-modal__field"
                                            title="tag"
                                            value={this.state.client.account_type}
                                            name="account_type"
                                            onChange={this.handleChange}
                                          >
                                            <option value=''>Selectează Tip Cont</option>
                                            <option value="free">Free</option>
                                            <option value="paid">Platit</option>
                                          </select>
                                        </div>
                                          <button className="supreme__add-client" onClick={this.setAccountType}>Seteaza Tip Cont</button>
                                      </div>
                                    </div>
                                    <div className="supreme__client-actions">
                                    <h3 className="admin__subtitle supreme__subtitle">Cont Demo</h3>
                                    <h4>Numar zile cont demo ramase: { this.state.client.freeDays } zile si { this.state.client.freeHours } ore</h4>
                                    <div className="admin__guest-info-box admin__guest-info-box--small">
                                        <button className="supreme__add-client" onClick={(event) => this.setFreeDays(event, 7)}>Seteaza 7 zile gratis</button>
                                    </div>
                                    <div className="admin__guest-info-box admin__guest-info-box--small">
                                        <button className="supreme__add-client" onClick={(event) => this.setFreeDays(event, 0)}>Seteaza 0 zile gratis</button>
                                    </div>
                                  </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <h3 className="admin__subtitle supreme__subtitle">Adauga factura</h3>
                                        <div className="supreme__invoice">

                                            <AttachementField
                                                onDrop={this.onDropDocument}
                                                removeDocument={this.removeDocument}
                                                disabledDocuments={this.state.disabledDocument}
                                                document={this.state.invoice.invoiceDocument}
                                                documentFilename={
                                                    this.state.invoice.invoiceDocument_filename
                                                }
                                                text=""
                                                type="application/pdf"
                                                section="profile__attachement-field"
                                            />
                                            <button type="submit" className="supreme__add-client">Adauga factura</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }

}

export default ClientPage;
