import React from 'react';
import './WebsiteForm.scss'
import { websiteDetailsService } from '../../services/WebsiteDetailsService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import AddContactModal from '../AddContactModal/AddContactModal';
import AddEventPartModal from '../AddEventPartModal/AddEventPartModal';
import { Link } from 'react-router-dom';
import { eventService } from '../../services/EventService';
import AttachementField from '../AttachementField/AttachementField';
import DeletePUGCertificates from '../../components/DeleteDialog/DeleteDialog';
import { websiteContactsService } from '../../services/WebsiteContactsService';
import { coupleStoryService } from '../../services/CoupleStoryService';
import { websiteEventPartsService } from '../../services/WebsiteEventPartsService';
import AddEventStoryModal from '../AddEventStoryModal/AddEventStoryModal';
import { Editor } from '@tinymce/tinymce-react';

class WebsiteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      websiteDetails: {
        invitationImage: '',
        videoInvitation: false,
        invitationVideo: '',
        invitationText: '',
        footerDisclaimer: '',
        footerText: '',
        generalWebsiteVideo: '',
        activeWebsiteVideo: false,
        longInvitationText: '',
        simpleStory: false,
        simpleStoryImage: '',
        backgroundImage: '',
      },
      weddingContacts: [],
      eventParts: [],
      coupleStories: [],
      contactModalOpened: false,
      eventPartModalOpened: false,
      storyModalOpened: false,
      disabledInvitationImage: false,
      disabledSimpleStoryImage: false,
      disabledBackgroundImage: false,
      disabledInvitationVideo: false,
      disabledGeneralWebsiteVideo: false,
      deleteWebsiteContact: {
        openedDialog: false,
        deleteId: 0
      },
      deleteStory: {
        openedDialog: false,
        deleteId: 0
      },
      deleteEventPart: {
        openedDialog: false,
        deleteId: 0
      },
      contactToEdit: {},
      storyToEdit: {},
      eventPartToEdit: {},
      currentEvent: '',
      events: [],
    };

    this.notificationSystem = React.createRef();
  }

  componentDidMount() {
    websiteDetailsService.getDetails(this.props.eventId)
      .then(response => {
        let websiteDetails = { ...this.state.websiteDetails };
        let weddingContacts = this.state.weddingContacts.slice();
        let eventParts = this.state.eventParts.slice();
        let coupleStories = this.state.coupleStories.slice();

        websiteDetails = {
          ...response.data.website_detail,
          invitationText: response.data.website_detail.invitation_text || '',
          invitationVideo: response.data.website_detail.invitation_video || '',
          videoInvitation: response.data.website_detail.video_invitation,
          footerText: response.data.website_detail.footer_text || '',
          footerDisclaimer: response.data.website_detail.footer_disclaimer || '',
          invitationImage: response.data.website_detail.invitation_image,
          isRegistryActive: response.data.website_detail.registry_list_activation,
          generalWebsiteVideo: response.data.website_detail.general_website_video,
          activeWebsiteVideo: response.data.website_detail.active_website_video,
          longInvitationText:response.data.website_detail.long_invitation_text || '',
          simpleStory: response.data.website_detail.simple_story,
          simpleStoryImage: response.data.website_detail.simple_story_image,
          backgroundImage: response.data.website_detail.background_image
        };
        weddingContacts = response.data.website_detail.wedding_contacts.slice();
        eventParts = response.data.website_detail.event_parts.slice();
        coupleStories = response.data.website_detail.couple_stories.slice();

        this.setState({ websiteDetails });
        this.setState({ weddingContacts });
        this.setState({ eventParts });
        this.setState({ coupleStories });
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/app/login");
        }
      })
    eventService.getEvents().then(response => {
      let events = this.state.events;
      events = response.data.events;
      let currentEvent = { ...this.state.currentEvent };
      currentEvent = { ...events[0] };

      this.setState({ events });
      this.setState({ currentEvent });
    })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/app/login");
        }
      })
  }

  handleChange = event => {
    const field = event.target.name;
    const websiteDetails = { ...this.state.websiteDetails };
    websiteDetails[field] = event.target.value;
    this.setState({ websiteDetails });
  }

  handleCheckboxChange = event => {
    const field = event.target.name;
    const websiteDetails = { ...this.state.websiteDetails };
    websiteDetails[field] = event.target.checked;
    this.setState({ websiteDetails });

  }


  openAddContactModal = (key, contact) => {
    this.setState({ [key]: true });
    let contactToEdit = { ...this.state.contactToEdit };
    contactToEdit = contact;

    this.setState({ contactToEdit });
  }

  openAddStoryModal = (story) => {
    this.setState({ storyModalOpened: true });

    let storyToEdit = { ...this.state.storyToEdit };
    storyToEdit = story;

    this.setState({ storyToEdit });
  }
  openAddEventPartModal = eventPart => {
    this.setState({ eventPartModalOpened: true });
    let eventPartToEdit = { ...this.state.eventPartToEdit };
    eventPartToEdit = eventPart;
    this.setState({ eventPartToEdit });
  }

  closeModal = () => {
    this.setState({ contactModalOpened: false });
    this.setState({ eventPartModalOpened: false });
    this.setState({ storyModalOpened: false });
  }

  onDropDocument = documents => {
    let websiteDetails = { ...this.state.websiteDetails };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      websiteDetails.invitationImage = documents[0];

      self.setState({ websiteDetails });
      self.setState({ disabledInvitationImage: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let websiteDetails = { ...this.state.websiteDetails };
    websiteDetails.invitationImage = null;
    websiteDetails.invitation_image_filename = '';

    this.setState({ websiteDetails });
    this.setState({ disabledInvitationImage: false });
  }

  onDropDocumentSimpleStory = documents => {
    let websiteDetails = { ...this.state.websiteDetails };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      websiteDetails.simpleStoryImage = documents[0];

      self.setState({ websiteDetails });
      self.setState({ disabledSimpleStoryImage: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentSimpleStory = () => {
    let websiteDetails = { ...this.state.websiteDetails };
    websiteDetails.simpleStoryImage = null;
    websiteDetails.simple_story_image_filename = '';

    this.setState({ websiteDetails });
    this.setState({ disabledSimpleStoryImage: false });
  }

  onDropDocumentBackgroundImage = documents => {
    let websiteDetails = { ...this.state.websiteDetails };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      websiteDetails.backgroundImage = documents[0];

      self.setState({ websiteDetails });
      self.setState({ disabledBackgroundImage: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentBackgroundImage = () => {
    let websiteDetails = { ...this.state.websiteDetails };
    websiteDetails.backgroundImage = null;
    websiteDetails.background_image_filename = '';

    this.setState({ websiteDetails });
    this.setState({ disabledBackgroundImage: false });
  }

  onDropDocumentVideo = documents => {
    let websiteDetails = { ...this.state.websiteDetails };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      websiteDetails.invitationVideo = documents[0];

      self.setState({ websiteDetails });
      self.setState({ disabledInvitationVideo: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentVideo = () => {
    let websiteDetails = { ...this.state.websiteDetails };
    websiteDetails.invitationVideo = null;
    websiteDetails.invitation_video_filename = '';

    this.setState({ websiteDetails });
    this.setState({ disabledInvitationVideo: false });
  }

  onDropDocumentGeneralVideo = documents => {
    let websiteDetails = { ...this.state.websiteDetails };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      websiteDetails.generalWebsiteVideo = documents[0];

      self.setState({ websiteDetails });
      self.setState({ disabledGeneralWebsiteVideo: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentGeneralVideo = () => {
    let websiteDetails = { ...this.state.websiteDetails };
    websiteDetails.generalWebsiteVideo = null;
    websiteDetails.general_website_video_filename = '';

    this.setState({ websiteDetails });
    this.setState({ disabledGeneralWebsiteVideo: false });
  }

  updateEditorSectionField = (value) => {
    let websiteDetails = { ...this.state.websiteDetails };
    websiteDetails.longInvitationText = value;

    this.setState({ websiteDetails });
  }

  handleSubmit = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    websiteDetailsService.updateWebsiteDetails(this.state.websiteDetails)
      .then(response => {
        notification.addNotification({
          message:
            `Builder-ul a fost editat cu succes.`,
          level: 'success',
          position: 'tc'
        });

        let websiteDetails = { ...this.state.websiteDetails };

        console.log('asd');
        console.log(response.data);

        websiteDetails = {
          ...websiteDetails,
          ...response.data,
          footerText: response.data.footer_text,
          invitationText: response.data.invitation_text || '',
          footerDisclaimer: response.data.footer_disclaimer || '',
          isRegistryActive: response.data.registry_list_activation,
          activeWebsiteVideo: response.data.active_website_video,
          longInvitationText: response.data.long_invitation_text
        };

        this.setState({ websiteDetails });
      })
      .catch(error => {
        notification.addNotification({
          message:
            'Ne pare rău, a intervenit o problemă. Dacă un refresh la pagină nu ajută, contactați-ne prin email sau telefon.',
          level: 'error',
          position: 'tc'
        });
      })
  }
  openDeleteStoryDialog = id => {
    let deleteStory = { ...this.state.deleteStory };
    deleteStory = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteStory });
  };

  openDeleteWebsiteContactDialog = id => {
    let deleteWebsiteContact = { ...this.state.deleteWebsiteContact };
    deleteWebsiteContact = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteWebsiteContact });
  };
  openDeleteEventPartDialog = id => {
    let deleteEventPart = { ...this.state.deleteEventPart };
    deleteEventPart = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteEventPart });
  };

  closeDeleteWebsiteContactDialog = () => {
    let deleteWebsiteContact = { ...this.state.deleteWebsiteContact };
    deleteWebsiteContact = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteWebsiteContact });
  };
  closeDeleteStory = () => {
    let deleteStory = { ...this.state.deleteStory };
    deleteStory = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteStory });
  };
  closeDeleteEventPartDialog = () => {
    let deleteEventPart = { ...this.state.deleteEventPart };
    deleteEventPart = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteEventPart });
  };


  handleDelete = () => {
    const notification = this.notificationSystem.current;
    websiteContactsService
      .deleteContact(this.state.deleteWebsiteContact.deleteId)
      .then(() => {
        let weddingContacts = this.state.weddingContacts.slice();
        weddingContacts = weddingContacts.filter(
          weddingContact => weddingContact.id !== this.state.deleteWebsiteContact.deleteId
        );
        this.setState({ weddingContacts });

        this.closeDeleteWebsiteContactDialog();

        notification.addNotification({
          message: 'Contactul a fost șters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        notification.addNotification({
          message:
            'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleDeleteStory = () => {
    const notification = this.notificationSystem.current;
    coupleStoryService
      .deleteStory(this.state.deleteStory.deleteId)
      .then(() => {
        let coupleStories = this.state.coupleStories.slice();
        coupleStories = coupleStories.filter(
          coupleStorie => coupleStorie.id !== this.state.deleteStory.deleteId
        );
        this.setState({ coupleStories });

        this.closeDeleteStory();

        notification.addNotification({
          message: 'Povestea a fost ștearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        notification.addNotification({
          message:
            'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
          level: 'error',
          position: 'tc'
        });
      });
  }
  handleEventPartDelete = () => {
    const notification = this.notificationSystem.current;
    websiteEventPartsService
      .deleteEventPart(this.state.deleteEventPart.deleteId)
      .then(() => {
        let eventParts = this.state.eventParts.slice();
        eventParts = eventParts.filter(
          eventPart => eventPart.id !== this.state.deleteEventPart.deleteId
        );
        this.setState({ eventParts });

        this.closeDeleteEventPartDialog();

        notification.addNotification({
          message: 'Secțiunea a fost ștearsă cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        notification.addNotification({
          message:
            'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
          level: 'error',
          position: 'tc'
        });
      });
  }
  updateEventPartList = (newEventPart) => {
    const notification = this.notificationSystem.current;
    let eventParts = this.state.eventParts.slice();

    const eventPartIndex = eventParts.findIndex(eventPart => eventPart.id === newEventPart.id);

    if (eventPartIndex !== -1) {
      eventParts[eventPartIndex] = { ...newEventPart };
    } else {
      eventParts.unshift(newEventPart)
    }

    this.setState({ eventParts }, () => {
      this.closeModal();
      notification.addNotification({
        message:
          `Secțiunea a fost ${eventPartIndex !== -1 ? 'editată' : 'adaugă'} cu succes.`,
        level: 'success',
        position: 'tc'
      });
    });
  }
  updateContactList = (newContact) => {
    const notification = this.notificationSystem.current;
    let weddingContacts = this.state.weddingContacts.slice();

    const contactIndex = weddingContacts.findIndex(weddingContact => weddingContact.id === newContact.id);

    if (contactIndex !== -1) {
      weddingContacts[contactIndex] = { ...newContact };
    } else {
      weddingContacts.unshift(newContact)
    }

    this.setState({ weddingContacts }, () => {
      this.closeModal();
      notification.addNotification({
        message:
          `Contactul a fost ${contactIndex !== -1 ? 'editat' : 'adaugat'} cu succes.`,
        level: 'success',
        position: 'tc'
      });
    });
  }
  updateEventStoryList = (newStory) => {
    const notification = this.notificationSystem.current;
    let coupleStories = this.state.coupleStories.slice();

    const storyIndex = coupleStories.findIndex(coupleStory => coupleStory.id === newStory.id);

    if (storyIndex !== -1) {
      coupleStories[storyIndex] = { ...newStory };
    } else {
      coupleStories.unshift(newStory)
    }

    this.setState({ coupleStories }, () => {
      this.closeModal();
      notification.addNotification({
        message:
          `Povestea a fost ${storyIndex !== -1 ? 'editată' : 'adaugată'} cu succes.`,
        level: 'success',
        position: 'tc'
      });
    });
  }

  handleRegistryActivation = event => {
    const notification = this.notificationSystem.current;

    let websiteDetails = { ...this.state.websiteDetails };

    websiteDetails.isRegistryActive = !websiteDetails.isRegistryActive;

    this.setState({ websiteDetails }, () => {
      websiteDetailsService.updateRegistryStatus(this.state.websiteDetails.isRegistryActive, this.props.eventId)
      .then(response=>{
        notification.addNotification({
          message:
            `Registrul a fost modificat cu succes.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(err=>{
        notification.addNotification({
          message:
            `A aparut o eroare`,
          level: 'error',
          position: 'tc'
        });

      })
    });
  }

  render() {
    return (
      <div className="website-form">
        <form onSubmit={this.handleSubmit}>
          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Povestea voastră</h4>
            <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Poveste simpla (Aveti optiunea de a alege intre o singura poza reprezentativa si o poveste din 7 pasi)</h4>
              <label class="switch">
                <input type="checkbox"
                  name="simpleStory"
                  onChange={this.handleCheckboxChange}
                  checked={this.state.websiteDetails.simpleStory} />
                <span class="slider round"></span>
              </label>
            </div>
            { this.state.websiteDetails.simpleStory ? (
              <div className="website-form__text-and-pic">
                <div className="website-form__attachement">
                  <h4 className='website-form__label'>Imagine reprezentativa cuplu</h4>
                  <AttachementField
                    onDrop={this.onDropDocumentSimpleStory}
                    removeDocument={this.removeDocumentSimpleStory}
                    disabledDocuments={this.state.disabledSimpleStoryImage}
                    document={this.state.websiteDetails.simpleStoryImage}
                    section="invitation-img-attachement"
                    documentFilename={
                      this.state.websiteDetails.simple_story_image_filename
                    }
                    type="image/*"
                  />
                </div>
              </div>
            ) : (
              <div>
              <button type='button' className="website-form__add " onClick={() => this.openAddStoryModal()} disabled={this.state.coupleStories.length >= 1}>Adaugă Poveste</button>
              <div>
                <h4 className='website-form__label'>Povești existente (doar cea activă va fi afișată pe website)</h4>
                <div className='website-form__table-wrapper'>
                <div className="website-form__wedding-contacts">
                  <div className="website-form__contact-head">
                    <h4>Titlu</h4>
                    <h4>Poveste activă</h4>
                    <h4>Acțiuni</h4>
                  </div>
                  {this.state.coupleStories.map(coupleStory => (
                    <div className="website-form__contact-row" key={coupleStory.id}>
                      <h4 className='website-form__cell'>{coupleStory.title}</h4>
                      <h4 className='website-form__cell'><img src={coupleStory.active ? '/images/yes.png' : '/images/no.png'} className="website-form__sent-invitation-icon" /></h4>
                      <div className="website-form__actions website-form__cell">
                        <img className="action-icon" src="/images/edit-black.png" onClick={() => this.openAddStoryModal(coupleStory)} />
                        <img className="action-icon" src="/images/delete-black.png" onClick={() => this.openDeleteStoryDialog(coupleStory.id)} />
                      </div>
                    </div>
                  ))}
                </div>
                </div>
              </div>
              </div>
            ) }
          </div>
          <div className='website-form__divider' />
          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Dorești să adaugi nu video general in website? Acesta va aparea sub povestea mirilor. ( Optional )</h4>
            <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Video general activ</h4>
              <label class="switch">
                <input type="checkbox"
                 name="activeWebsiteVideo"
                 onChange={this.handleCheckboxChange}
                 checked={this.state.websiteDetails.activeWebsiteVideo} />
                <span class="slider round"></span>
              </label>
            </div>
            <div className="website-form__attachement">
              <h4 className='website-form__label'>Video General (format mp4)</h4>
              <AttachementField
                onDrop={this.onDropDocumentGeneralVideo}
                removeDocument={this.removeDocumentGeneralVideo}
                disabledDocuments={this.state.disabledGeneralWebsiteVideo}
                document={this.state.websiteDetails.generalWebsiteVideo}
                section="invitation-img-attachement"
                documentFilename={
                  this.state.websiteDetails.general_website_video_filename
                }
                type="video/mp4"
              />
            </div>
          </div>
          <div className='website-form__divider' />
          <h4 className='website-form__subtitle'>Dorești să adaugi o listă de cadouri din care invitații pot alege?</h4>
          <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Registru de cadouri activ</h4>
              <label class="switch">
                <input type="checkbox"
                 name="isRegistryActive"
                 onChange={this.handleRegistryActivation}
                 checked={this.state.websiteDetails.isRegistryActive} />
                <span class="slider round"></span>
              </label>
            </div>
          <div className='website-form__divider' />
          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Invitație Eveniment</h4>
            <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Invitatie Video</h4>
              <label class="switch">
                <input type="checkbox"
                  name="videoInvitation"
                  onChange={this.handleCheckboxChange}
                  checked={this.state.websiteDetails.videoInvitation} />
                <span class="slider round"></span>
              </label>
            </div>
            {this.state.websiteDetails.videoInvitation ? (
              <div>
                <div className="website-form__attachement">
                  <h4 className='website-form__label'>Invitație video (format mp4)</h4>
                  <AttachementField
                    onDrop={this.onDropDocumentVideo}
                    removeDocument={this.removeDocumentVideo}
                    disabledDocuments={this.state.disabledInvitationVideo}
                    document={this.state.websiteDetails.invitationVideo}
                    section="invitation-img-attachement"
                    documentFilename={
                      this.state.websiteDetails.invitation_video_filename
                    }
                    type="video/mp4"
                  />
                </div>
              </div>
            ) : (
              <div>
              <div className="website-form__text-and-pic">
                <div className="website-form__attachement">
                  <h4 className='website-form__label'>Imagine invitație</h4>
                  <AttachementField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledInvitationImage}
                    document={this.state.websiteDetails.invitationImage}
                    section="invitation-img-attachement"
                    documentFilename={
                      this.state.websiteDetails.invitation_image_filename
                    }
                    type="image/*"
                  />
                </div>
                <div className="website-form__attachement-text">
                  <h4 className='website-form__label'>Text Invitație (Ex: Vă invităm la nuntă)</h4>
                  <textarea
                    className='website-form__textarea website-form__attachement-textarea'
                    type="text"
                    name="invitationText"
                    onChange={this.handleChange}
                    value={this.state.websiteDetails.invitationText} />
                </div>
              </div>
              <div>
                <h4 className='website-form__label'>Text invitatie extinsa: acesta va aparea intr-un popup si poate fi stilizat</h4>
                <Editor
                  className="website-form__textarea"
                  apiKey="ls15a02h3hmn3erw7e3mar50pv1w85ivm85bho7dv6oah4zn"
                  value={this.state.websiteDetails.longInvitationText}
                  onEditorChange={this.updateEditorSectionField}
                  init={{
                   height: 300,
                   width: '100%',
                   menubar: 'edit insert format',
                   plugins: [
                     'advlist a11ychecker autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                   ],
                   toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat ',
                   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
              </div>
              </div>
            )}
          </div>
          <div className='website-form__divider' />

          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Etapele evenimentului (cununia civilă, cununia religioasă, petrecerea)</h4>
            <button type='button'className="website-form__add" onClick={() => this.openAddContactModal('eventPartModalOpened')}>Adaugă Etapă</button>
            <div>
              <h4 className='website-form__label'>Etape existente</h4>
              <div className='website-form__table-wrapper'>
                <div className="website-form__wedding-contacts website-form__steps-wrapper">
                  <div className="website-form__contact-head">

                    <h4>Tag</h4>
                    <h4>Denumire locație</h4>
                    <h4>Adresă locație</h4>
                    <h4>Data si ora</h4>
                    <h4>Acțiuni</h4>

                  </div>
                  {this.state.eventParts.map(eventPart => (
                    <div className="website-form__contact-row">
                      <div className='website-form__cell'><div className='website-form__tag'><h4>{eventPart.tag}</h4></div></div>
                      <h4 className='website-form__cell'>{eventPart.location}</h4>
                      <h4 className='website-form__cell website-form__address'>{eventPart.address}</h4>
                      <div className='website-form__cell website-form__date-box'><h4>{eventPart.date}</h4><h4>{eventPart.hour}</h4></div>

                      <div className="website-form__actions website-form__cell">
                        <img src='/images/edit-black.png' alt='edit' onClick={() => this.openAddEventPartModal(eventPart)} />
                        <img src='/images/delete-black.png' alt='delete' onClick={() => this.openDeleteEventPartDialog(eventPart.id)} />

                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
          <div className='website-form__divider' />

          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Persoane pe care invitații le pot contacta în ziua nunții:</h4>
            <button type='button' className='website-form__add' onClick={() => this.openAddContactModal('contactModalOpened')}>Adaugă contact</button>
            <div>
              <h4 className='website-form__label'>Contactele voastre</h4>
              <div className='website-form__table-wrapper'>
                <div className="website-form__wedding-contacts">
                  <div className="website-form__contact-head">
                    <h4>Nume</h4>
                    <h4>Telefon</h4>
                    <h4>Acord apariție site</h4>
                    <h4>Acțiuni</h4>
                  </div>
                  {this.state.weddingContacts.map(weddingContact => (
                    <div className="website-form__contact-row">
                      <h4 className='website-form__cell'>{weddingContact.name}</h4>
                      <h4 className='website-form__cell'>{weddingContact.phone}</h4>
                      <h4 className='website-form__cell'>{weddingContact.consent ? 'Acord acordat' : 'Fără consimțământ'}</h4>
                      <div className="website-form__actions website-form__cell">
                        <img src='/images/edit-black.png' alt='edit' onClick={() => this.openAddContactModal('contactModalOpened', weddingContact)} />
                        <img src='/images/delete-black.png' alt='delete' onClick={() => this.openDeleteWebsiteContactDialog(weddingContact.id)} />

                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='website-form__divider' />

          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Informații Generale Website</h4>
            <div className="website-form__text-and-pic">
              <div className="website-form__attachement">
                <h4 className='website-form__label'>Imagine background (a se lasa gol, daca se doreste background alb)</h4>
                <AttachementField
                  onDrop={this.onDropDocumentBackgroundImage}
                  removeDocument={this.removeDocumentBackgroundImage}
                  disabledDocuments={this.state.disabledBackgroundImage}
                  document={this.state.websiteDetails.backgroundImage}
                  section="invitation-img-attachement"
                  documentFilename={
                    this.state.websiteDetails.background_image_filename
                  }
                  type="image/*"
                />
              </div>
            </div>
          </div>

          <div className='website-form__divider' />

          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Informații adiționale</h4>
            <div>
              <h4 className='website-form__label'>Paragraf în subsolul paginii:</h4>
              <textarea
                className='website-form__textarea'
                type="text"
                name="footerDisclaimer"
                rows='5'
                onChange={this.handleChange}
                value={this.state.websiteDetails.footerDisclaimer} />
            </div>
            <div>
              <h4 className='website-form__label'>Paragraf în subsolul paginii:</h4>
              <textarea
                className='website-form__textarea'
                type="text"
                name="footerText"
                onChange={this.handleChange}
                rows='5'
                value={this.state.websiteDetails.footerText} />
            </div>
            <div className='website-form__buttons'>
              <Link className='standard__link' to={`/app/preview/${this.state.currentEvent.slug}`} target="_blank"><button type="button" className='admin__preview'>Previzualizează website</button></Link>

              <button type='submit' className='website-form__save' >Salvează</button>
            </div>
          </div>

        </form>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
        {this.state.contactModalOpened && <AddContactModal
          modalIsOpen={this.state.contactModalOpened}
          closeModal={this.closeModal}
          websiteDetailsId={this.state.websiteDetails.id}
          updateContactList={this.updateContactList}
          contact={this.state.contactToEdit}
        />}
        {this.state.eventPartModalOpened && <AddEventPartModal
          modalIsOpen={this.state.eventPartModalOpened}
          closeModal={this.closeModal}
          eventPart={this.state.eventPartToEdit}
          updateEventPartList={this.updateEventPartList}
          websiteDetailsId={this.state.websiteDetails.id}
        />}

        {this.state.storyModalOpened && <AddEventStoryModal
          modalIsOpen={this.state.storyModalOpened}
          closeModal={this.closeModal}
          websiteDetailsId={this.state.websiteDetails.id}
          updateEventStoryList={this.updateEventStoryList}
          eventStory={this.state.storyToEdit}
        />}
        <DeletePUGCertificates
          opened={this.state.deleteWebsiteContact.openedDialog}
          handleClose={this.closeDeleteWebsiteContactDialog}
          handleDelete={this.handleDelete}
          headline="Sunteți sigur ca doriti sa ștergeți acest contact?"
          aditionalText="După ștergere, contact nu va mai putea fi recuperat."
        />
        <DeletePUGCertificates
          opened={this.state.deleteStory.openedDialog}
          handleClose={this.closeDeleteStory}
          handleDelete={this.handleDeleteStory}
          headline="Sunteți sigur ca doriti sa ștergeți aceasta poveste?"
          aditionalText="După ștergere, povestea nu va mai putea fi recuperată."
        />
        <DeletePUGCertificates
          opened={this.state.deleteEventPart.openedDialog}
          handleClose={this.closeDeleteEventPartDialog}
          handleDelete={this.handleEventPartDelete}
          headline="Sunteți sigur ca doriti sa ștergeți aceasta secțiune?"
          aditionalText="După ștergere, secțiunea nu va mai putea fi recuperata."
        />
      </div>
    )
  }
}

export default WebsiteForm;
