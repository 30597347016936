import React from 'react';
import './ProvidersPage.scss';
import MetaTags from 'react-meta-tags';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header'
import Slider from "react-slick";
import { providerService } from '../../services/ProviderService'
import categoriesJson from './categories.json'
import ContactUs from "../../components/ContactUs/ContactUs";
class ProvidersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: categoriesJson,
            providers: [],
            selectedCategory: '',
            selectedProvider: {},
            isContactModalOpen:false
        }

    }
    componentDidMount() { 
        this.selectCategory('Restaurant');
    }
    selectCategory = categoryName => {
        providerService.searchProviders(categoryName).then(response => {
            let providers = this.state.providers;
            providers = response.data.providers;
            this.setState({ providers }, () => {
                this.setState({ selectedCategory: categoryName })
            });
            
            let pagination = this.state.pagination;
            pagination = response.data.pagination;

            this.setState({ pagination })

        })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }
    selectProvider = providerIdValue => {
        let selectedProvider = { ...this.state.selectedProvider };
        let providers = this.state.providers;
        selectedProvider = providers.find(provider => provider.id === providerIdValue)
        console.log('rg')
        console.log(selectedProvider)
        this.setState({ selectedProvider })

        console.log(this.state.selectedProvider)
    }
    openContactModal=()=>{
        this.setState({isContactModalOpen:true})
    }
    closeModal=()=>{
        this.setState({isContactModalOpen:false})
    }
    render() {
        const settings = {
            infinite: true,
            speed: 200,
            swipe: true,
            swipeToSlide: true,
            adaptiveHeight: true,
            focusOnSelect: true,
            centeredMode: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            autoplay: false
        };
        return (
            <div className='standard landing providers'>
                <Header menuItem='parteneri' />
                <MetaTags>
                    <title>Facem Nunta</title>
                    <meta
                        name="description"
                        content="Facem Nunta este platforma online de organizare si planificare destinata mirilor si mireselor| Pentru o nunta asa cum iti doresti, fara stres sau compromisuri."
                    />
                    <meta
                        name="keywords"
                        content="facem nunta,parteneri, furnizori, fotograf, salon, muzica, dj, formatie, restaurant, nunta,cununie civila, organizare nunta, planificare nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta, casatorie, acte casatorie"
                    />
                    <meta
                        property="og:title"
                        content="Facem Nunta - Parteneri"
                    />
                    <meta
                        property="og:url"
                        content="https://www.facemnunta.ro/app/parteneri"
                    />
                </MetaTags>
                <div className="providers__wrapper">
                    <h2 className="providers__title">Parteneri</h2>
                    <div className="providers__line" />
                    <div className="providers__top">
                        <img src="/images/partners.png" alt="partners" className="providers__partners-icon" />
                        <h4>În acesta pagina poți gasi o lista variata de parteneri ce te vor ajuta sa-ti planifici nunta de vis. Tot ce ai nevoie, la un click distanta!
                        </h4>
                    </div>
                    <div className="standard__only-mobile">
                        <div className='providers__categories-slider'>
                            <Slider  {...settings}>
                                {this.state.categories.map(category =>
                                    <div key={category.id} className='providers__category-wrapper' onClick={() => this.selectCategory(category.nameValue)} >
                                        <img src={category.imgUrl} alt="category" />
                                        <h5>{category.name}</h5>
                                        {this.state.selectedCategory === category.nameValue ? <div className="providers__line"/> :''}
                                    </div>
                                )}
                            </Slider>
                        </div>
                        <div className="providers__container">
                            {this.state.providers && this.state.providers.length ?
                                <div>
                                    {this.state.providers.map(provider => (
                                        <div className="providers__mobile-provider">
                                            <div>
                                                <div className="providers__top-provider">
                                                    <img src={provider.logo.url? `${process.env.REACT_APP_API_URL}${provider.logo.url}`:'/images/Partner.png'}  alt="provider" className="providers__main-img" />
                                                    <img src='/images/white-shadow.png' alt='shadow' className="providers__shadow" />
                                                </div>
                                                <h4 className="providers__provider-name">{provider.name}</h4>

                                            </div>
                                            <div className="providers__more-info">
                                                {provider.contact_person?  <div className="providers__details">
                                                    <h5 className="providers__name-info"><b>Nume contact:</b></h5>
                                                    <h5>{provider.contact_person}</h5>
                                                </div> :''}
                                               
                                                <div className="providers__details">
                                                    <h5 className="providers__name-info"><b>Număr telefon:</b></h5>
                                                    <a className="standard__link"href={`tel:${provider.phone}`}><h5>{provider.phone}</h5></a>  
                                                </div>
                                                <div className="providers__details">
                                                    <h5 className="providers__name-info"><b>Email:</b></h5>
                                                    <a className="standard__link" href = {`mailto: ${provider.email}`}><h5>{provider.email}</h5></a> 
                                                </div>
                                                {provider.address?  <div className="providers__details">
                                                    <h5 className="providers__name-info"><b>Adresă:</b></h5>
                                                    <h5>{provider.address}</h5>
                                                </div> :''}
                                                {provider.website_url?  <div className="providers__details">
                                                    <h5 className="providers__name-info"><b>Website:</b></h5>
                                                    <h5>{provider.website_url}</h5>
                                                </div> :''}
                                                <div className="providers__details">
                                                    <h5 className="providers__name-info"><b>Descriere:</b></h5>
                                                    <h5 className="providers__blue">{provider.description}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div> : <div><h3 className="providers__no-providers">Ne pare rău, dar nu avem furnizori pentru această categorie.</h3> <h3 className="providers__no-providers">Dar lucrăm la acest lucru, așa că cine știe, peste un refresh sau două s-ar putea să fie categoria plină!</h3></div>}

                        </div>
                    </div>

                    <div className="standard__only-desktop">
                        <div className="providers__container">
                            <div className="providers__menu-wrapper">
                                <h4>Categorii</h4>
                                <div className="providers__menu">
                                    {this.state.categories.map(category => (
                                        <div className={this.state.selectedCategory === category.nameValue ? 'providers__category providers__category-selected' : 'providers__category'} onClick={() => this.selectCategory(category.nameValue)} >
                                            <img src={category.imgUrl} alt="category" />
                                            <h5>{category.name}</h5>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="providers__providers-type">
                                {this.state.providers && this.state.providers.length ?
                                    <div>
                                        {this.state.providers.map(provider => (
                                            <div onClick={() => this.selectProvider(provider.id)} className="providers__full">
                                                <div className="providers__top-provider">
                                                    <img src={provider.logo.url? `${process.env.REACT_APP_API_URL}${provider.logo.url}`:'/images/Partner.png'} alt="provider" className="providers__main-img" />
                                                    <h4 className="providers__provider-name">{provider.name}</h4>
                                                    <img src='/images/white-shadow.png' alt='shadow' className="providers__shadow" />
                                                </div>
                                                <div className="providers__container-more-info">
                                                    {this.state.selectedProvider.id === provider.id ? <div className="providers__more-info">
                                                        <h2>{provider.name}</h2>
                                                        {provider.contact_person? <div className="providers__details">
                                                            <h5><b>Nume contact : </b></h5>
                                                            <h5 className="providers__specific-detail">{provider.contact_person}</h5>
                                                        </div>:''}
                                                        
                                                        <div className="providers__details">
                                                            <h5><b>Număr telefon:</b></h5>
                                                            <a className="standard__link"href={`tel:${provider.phone}`}><h5 className="providers__specific-detail">{provider.phone}</h5></a>  
                                                        </div>
                                                        <div className="providers__details">
                                                            <h5><b>Email:</b></h5>
                                                            <a className="standard__link" href = {`mailto: ${provider.email}`}><h5 className="providers__specific-detail">{provider.email}</h5></a> 
                                                        </div>
                                                        {provider.address? <div className="providers__details">
                                                            <h5><b>Adresa: </b></h5>
                                                            <h5 className="providers__specific-detail">{provider.address}</h5>
                                                        </div>:''}
                                                        {provider.website_url? <div className="providers__details">
                                                            <h5><b>Website: </b></h5>
                                                            <h5 className="providers__specific-detail">{provider.website_url}</h5>
                                                        </div>:''}
                                                        <div className="providers__details">
                                                            <h5><b>Descriere:</b></h5>
                                                            <h5 className="providers__specific-detail  providers__description">{this.state.selectedProvider.description}</h5>
                                                        </div>
                                                    </div> : ''}

                                                </div>
                                            </div>
                                        ))}

                                    </div> :<div><h3 className="providers__no-providers">Ne pare rău, dar nu avem furnizori pentru această categorie.</h3> <h3 className="providers__no-providers">Dar lucrăm la acest lucru, așa că cine știe, peste un refresh sau două s-ar putea să fie categoria plină!</h3></div>}
                            </div>
                           

                        </div>
                    </div>
                </div>
                <div className='providers__contact-us'>
                        <img src='/images/headset.png' alt='headset' />
                        <h5>Ești un furnizor și vrei să devii partener Facem Nuntă? Ești în locul potrivit.</h5>
                        <button onClick={() => this.openContactModal()}>Contactează-ne!</button>
                    </div>
                <Footer />
                {this.state.isContactModalOpen && <ContactUs
                        modalIsOpen={this.state.isContactModalOpen}
                        closeModal={this.closeModal}
                    />}
            </div>
        )
    }
}

export default ProvidersPage;
