import React from 'react';
import './Homepage.scss';
import { Link } from 'react-router-dom';
import '../../resources/main.scss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Countdown from 'react-countdown';
import SwipeableViews from 'react-swipeable-views';
import LazyLoad from 'react-lazy-load';
import OptimizedImg from '../../components/OptimizedImg/OptimizedImg';
import couplesJson from './couples.json';
import { websiteDetailsService } from '../../services/WebsiteDetailsService';
import Moment from 'react-moment';
import GoogleMaps from '../../components/LocationsMap/GoogleMap';
import tagIcons from '../../containers/Admin/PreviewWebsite/tagIcons.json';
import ReactPlayer from 'react-player/lazy';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import { registryService } from "../../services/RegistryService";
import { withTranslation } from 'react-i18next';
import LongInvitationModal from '../../components/LongInvitationModal/LongInvitationModal';
import queryString from 'query-string';

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      answer: {
        answerGiven: '',
        rotate: false,
        toggle: false,
        showWriting: true,
      },
      selectedCouple: {},
      websiteDetails: {},
      weddingContacts: [],
      eventParts: [],
      coupleStory: {},
      brideStep: {},
      groomStep: {},
      meetStep: {},
      otherSteps: [],
      currentPathName: '',
      coupleFromJson: false,
      openedVideo: false,
      isRegistryActive: false,
      longTextModalOpened: false,
      params: {}
    }
    this.rotatingDone = this.rotatingDone.bind(this);
  }

  componentDidMount() {
    this.setState({ submittedStarted: true });

    let existingParams = { ...this.state.params };
    let params = queryString.parse(this.props.location.search);
    existingParams = params;
    this.setState({ params: existingParams });

    const elm = this.image;
    elm.addEventListener("animationend", this.rotatingDone);

    let parameters = this.props.location.pathname;

    let selectedCouple = { ...this.state.selectedCouple };

    const firstSegment = parameters.split("/")[1];

    selectedCouple = couplesJson.find(couple => couple.slug === firstSegment);

    if (selectedCouple) {
      this.setState({ selectedCouple });
      this.setState({ coupleFromJson: true });

      this.setState({ submittedStarted: false });

    } else {
      websiteDetailsService.getWebsiteBySlug(firstSegment)
        .then(response => {
          let selectedCouple = { ...this.state.selectedCouple };

          selectedCouple = response.data.event;

          let websiteDetails = { ...this.state.websiteDetails };
          websiteDetails = response.data.event.website_details[0];

          let weddingContacts = this.state.weddingContacts.slice();
          weddingContacts = response.data.event.website_details[0].wedding_contacts;


          let eventParts = this.state.eventParts.slice();
          eventParts = response.data.event.website_details[0].event_parts;

          let coupleStory = { ...this.state.coupleStory };
          coupleStory = response.data.event.website_details[0].couple_stories[0];

          this.setState({ selectedCouple });
          this.setState({ websiteDetails });


          if (weddingContacts) {
            this.setState({ weddingContacts });
          }

          if (eventParts) {
            this.setState({ eventParts });
          }

          if (coupleStory) {
            this.setState({ coupleStory });
          }

          if (coupleStory && coupleStory.story_steps && coupleStory.story_steps.length) {
            let steps = coupleStory.story_steps.slice();
            let brideStep = { ...this.state.brideStep };
            brideStep = steps.find(step => step.tip === 'mireasa');

            let groomStep = { ...this.state.groomStep };
            groomStep = steps.find(step => step.tip === 'mire');

            let meetStep = { ...this.state.meetStep };
            meetStep = steps.find(step => step.tip === 'intalnire');

            let otherSteps = this.state.otherSteps.slice();
            otherSteps = steps.slice(3);

            this.setState({ brideStep });
            this.setState({ groomStep });
            this.setState({ meetStep });
            this.setState({ otherSteps });
          }

          this.setState({ submittedStarted: false });
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.props.history.push("/");
          }
          this.setState({ submittedStarted: false });
        })
    }
  }

  handleChange = (event, tabValue) => {
    this.setState({
      tabValue
    });
  };
  handleChangeIndex = index => {
    this.setState({
      tabValue: index
    });
  };
  updateAnswer = answerValue => {
    const answer = { ...this.state.answer };
    answer.answerGiven = answerValue;
    this.setState({
      answer
    });
  }
  rotatingDone = () => {
    let rotate = this.state.rotate;
    rotate = false;
    let toggle = this.state.toggle;
    toggle = !toggle;
    let showWriting = this.state.showWriting;
    showWriting = false;
    this.setState({ rotate })
    this.setState({ toggle })
    this.setState({ showWriting })

    setTimeout(() => {
      this.setState({ openedVideo: false });
    }, 1000);

    if (this.state.websiteDetails.video_invitation) {
      const elm = this.image;
      elm.removeEventListener("animationend", this.rotatingDone);
    }
  }

  componentWillUnmount() {
    const elm = this.image;
    elm.removeEventListener("animationend", this.rotatingDone);
  }
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <p>Success</p>;
    } else {
      return <span style={{ display: 'flex' }}>{days} <span style={{ fontSize: '14px', paddingRight: '10px', color: '#1992D4' }}>D</span>:{hours}<span style={{ color: '#1992D4', fontSize: '14px', paddingRight: '10px' }}>H</span>:{minutes}<span style={{ color: '#1992D4', fontSize: '14px', paddingRight: '10px' }}>M</span>:{seconds}<span style={{ fontSize: '14px', paddingRight: '10px', color: '#1992D4' }}>S</span></span>;
    }
  }
  rotateMe = event => {
    let rotateFactor = 0;
    rotateFactor += 1;
    let rotateAngle = (180 * rotateFactor) % 360;
  }
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  stopVideo = () => {
    //this.rotatingDone();
  }

  rotateImage = () => {
    this.setState({ rotate: true })
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    let language = this.state.language;
    language = lng;
    this.setState({ language })
  }

  openLongInvitationTextModal = () => {
    this.setState({ longTextModalOpened: true });
  }

  closeModal = () => {
    this.setState({ longTextModalOpened: false });
  }

  render() {
    const videoRef = React.createRef()
    return (
      <LoadingOverlay
        active={this.state.submittedStarted}
        spinner={<img src="/images/GifFacemNunta.gif" alt="loading gif" className="standard__loading-gif" />}
      >
        <div className='standard homepage'
          style={{
            background: this.state.websiteDetails.background_image ? `url(${process.env.REACT_APP_API_URL + this.state.websiteDetails.background_image.url})` : 'transparent',
          }}
        >
          <div className="homepage__container">
            <div className='homepage__header'>
              <div>
                <h1 className='homepage__names'>{this.state.selectedCouple.name ? this.state.selectedCouple.name : i18n.t('brideAndGroom')}</h1>
                {/* <h3 className='homepage__date standard__only-desktop'>Abia așteptăm să petrecem această zi specială alături de voi!</h3>
                      <h3 className='homepage__date standard__only-mobile'>25 septembrie 2021</h3> */}
              </div>
              <div className='homepage__header-info'>
                <h3 className='homepage__date homepage__date-first standard__only-desktop'>
                  <Moment locale="ro" format="DD MMMM YYYY">{this.state.coupleFromJson ? this.state.selectedCouple.eventDate : this.state.selectedCouple.date}</Moment>
                </h3>
              </div>
              <div className="homepage__flags">
                <img src='/images/ro-flag.png' alt='romanian' onClick={() => this.changeLanguage('ro')} />
                <img src='/images/uk-flag.png' alt='english' onClick={() => this.changeLanguage('en')} />
              </div>
            </div>

            { this.state.websiteDetails.simple_story ? (null) : (
              <div className='homepage__story-title-box'>
                <h2> {this.state.coupleStory.title}</h2>
                <h3>{this.state.coupleStory.subtitle}</h3>
              </div>
            ) }
            <div className='homepage__first-section'>
            <div className='homepage__story'>
              { this.state.websiteDetails.simple_story ? (
                <div>
                { this.state.websiteDetails.simple_story_image && this.state.websiteDetails.simple_story_image.url ? (
                  <img src={`${process.env.REACT_APP_API_URL}${this.state.websiteDetails.simple_story_image.url}`} className="homepage__simple-story-img" alt='story' />
                ) : (
                  <img src='/images/step.png' className='homepage__story-img' alt='story' />
                ) }
                </div>
              ) : (
                <div>
                <div className='homepage__story-1'>
                  <div className='homepage__story-photos'>
                    <div className='homepage__story-box'>
                      <LazyLoad
                        debounce={false}
                        offsetVertical={500}
                      >
                        {this.state.brideStep && this.state.brideStep.image && this.state.brideStep.image.url ? (
                          <img src={`${process.env.REACT_APP_API_URL}${this.state.brideStep.image.url}`} className='homepage__story-img' alt='story' />
                        ) : (
                          <img src='/images/step.png' className='homepage__story-img' alt='story' />
                        )}
                      </LazyLoad>
                      <h4 className='homepage__writing'>{this.state.brideStep.description}</h4>
                    </div>
                    <div className='homepage__story-box'>
                      <LazyLoad
                        debounce={false}
                        offsetVertical={500}
                      >
                        {this.state.groomStep && this.state.groomStep.image && this.state.groomStep.image.url ? (
                          <img src={`${process.env.REACT_APP_API_URL}${this.state.groomStep.image.url}`} className='homepage__story-img' alt='story' />
                        ) : (
                          <img src='/images/step.png' className='homepage__story-img' alt='story' />
                        )}
                      </LazyLoad>
                      <h4 className='homepage__writing'>{this.state.groomStep.description}</h4>
                    </div>

                  </div>
                  <div className='homepage__story-arrows'>
                    <img src='/images/sageata-1.svg' style={{ fill: 'red', stroke: 'red' }} alt='arrow' className='homepage__arrow' />
                    <img src='/images/sageata-2.svg' alt='arrow' className='homepage__arrow' />
                  </div>
                </div>
                <div className='homepage__story-2 homepage__daian-story-2'>
                  <h4 className='homepage__writing homepage__daian-writing'>{this.state.selectedCouple.additionalLine}</h4>
                  <div className='homepage__story-box'>
                    <LazyLoad
                      debounce={false}
                      offsetVertical={500}
                    >
                      {this.state.meetStep && this.state.meetStep.image && this.state.meetStep.image.url ? (
                        <img src={`${process.env.REACT_APP_API_URL}${this.state.meetStep.image.url}`} className='homepage__story-img' alt='story' />
                      ) : (
                        <img src='/images/step.png' className='homepage__story-img' alt='story' />
                      )}
                    </LazyLoad>
                    <h4>{this.state.meetStep.description}</h4>
                  </div>
                </div>
                <img src='/images/sageata-3.svg' alt='arrow' className='homepage__arrow homepage__arrow-3' />
                <div className='homepage__story-3'>
                  <div className='homepage__story-box homepage__story-box-4'>
                    <LazyLoad
                      debounce={false}
                      offsetVertical={500}
                    >
                      {this.state.otherSteps.length && this.state.otherSteps[0].image && this.state.otherSteps[0].image.url ? (
                        <img src={`${process.env.REACT_APP_API_URL}${this.state.otherSteps[0].image.url}`} className='homepage__story-img' alt='story' />
                      ) : (
                        <img src='/images/step.png' className='homepage__story-img' alt='story' />
                      )}
                    </LazyLoad>
                    <h4>{this.state.otherSteps.length && this.state.otherSteps[0] ? this.state.otherSteps[0].description : ''}</h4>
                  </div>
                  <img src='/images/sageata-4.svg' alt='arrow' className='homepage__arrow homepage__arrow-4' />
                  <div className='homepage__story-box  homepage__story-box-5'>
                    <LazyLoad
                      debounce={false}
                      offsetVertical={500}
                    >
                      {this.state.otherSteps.length > 1 && this.state.otherSteps[1].image && this.state.otherSteps[1].image.url ? (
                        <img src={`${process.env.REACT_APP_API_URL}${this.state.otherSteps[1].image.url}`} className='homepage__story-img' alt='story' />
                      ) : (
                        <img src='/images/step.png' className='homepage__story-img' alt='story' />
                      )}
                    </LazyLoad>
                    <h4>{this.state.otherSteps.length && this.state.otherSteps[1] ? this.state.otherSteps[1].description : ''}</h4>
                  </div>
                  <img src='/images/sageata-4.svg' alt='arrow' className='homepage__arrow homepage__arrow-4 standard__only-desktop' />
                  <div className='homepage__story-box standard__only-desktop'>
                    <LazyLoad
                      debounce={false}
                      offsetVertical={500}
                    >
                      {this.state.otherSteps.length > 2 && this.state.otherSteps[2].image && this.state.otherSteps[2].image.url ? (
                        <img src={`${process.env.REACT_APP_API_URL}${this.state.otherSteps[2].image.url}`} className='homepage__story-img' alt='story' />
                      ) : (
                        <img src='/images/step.png' className='homepage__story-img' alt='story' />
                      )}
                    </LazyLoad>
                    <h4>{this.state.otherSteps.length && this.state.otherSteps[2] ? this.state.otherSteps[2].description : ''}</h4>
                  </div>
                </div>
                < div className='homepage__story-box homepage__spot-story standard__only-mobile'>
                  <LazyLoad
                    debounce={false}
                    offsetVertical={500}
                  >
                    {this.state.otherSteps.length > 2 && this.state.otherSteps[2].image && this.state.otherSteps[2].image.url ? (
                      <img src={`${process.env.REACT_APP_API_URL}${this.state.otherSteps[2].image.url}`} className='homepage__story-img' alt='story' />
                    ) : (
                      <img src='/images/step.png' className='homepage__story-img' alt='story' />
                    )}
                  </LazyLoad>
                  <h4>{this.state.otherSteps.length && this.state.otherSteps[2] ? this.state.otherSteps[2].description : ''}</h4>

                </div>
                <img src='/images/sageata-2.svg' alt='arrow' className='homepage__arrow homepage__arrow-6' />
                </div>
              ) }
            </div>

              <div className='standard__only-desktop homepage__time-details'>

                {this.state.coupleFromJson ? (
                  <h4 className='homepage__time-info'>
                    {this.state.selectedCouple.date}
                  </h4>
                ) : (
                  <h4 className='homepage__time-info'>
                    {this.state.selectedCouple.date ? <Moment locale="ro" format="dddd">{this.state.selectedCouple.date}</Moment> : 'Zi Eveniment'} | {this.state.selectedCouple.date ? <Moment locale="ro" format="DD MMMM YYYY">{this.state.selectedCouple.date}</Moment> : 'Data Eveniment'} | {this.state.selectedCouple.city || 'Oras eveniment'}
                  </h4>
                )}

                <div className="homepage__countdown-box">
                  {this.state.selectedCouple.date ?
                    <Countdown date={new Date(this.state.selectedCouple.date)} renderer={this.renderCountdown}></Countdown>
                    :
                    <h2 className='homepage__title'>{i18n.t('congratulations')}</h2>
                  }
                </div>
                <div className='homepage__ask standard__only-desktop'>
                  <div className='homepage__title-box'>
                    <h2 className='homepage__title'>{i18n.t('weddingPresence')}</h2>
                    <div className='homepage__smudge' />
                    <div className='standard__only-desktop' >
                      <div className='homepage__answer'>
                        <Link to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie', search: queryString.stringify(this.state.params) }} className='standard__link' onClick={() => this.scrollToTop()}><h3>{i18n.t('yes')}</h3></Link>
                        <Link to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ', search: queryString.stringify(this.state.params)}} className='standard__link' onClick={() => this.scrollToTop()}> <h3>{i18n.t('no')}</h3></Link>
                      </div>
                    </div>
                  </div>
                  { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) &&
                  <LazyLoad
                    debounce={false}
                    offsetVertical={500}
                  >
                    <OptimizedImg src='/images/flower-3.png' imageUrlWebp='/images/flower-3.webp' className='standard__only-desktop homepage__flower' alt='flower' />
                  </LazyLoad> }
                </div>
              </div>
            </div>

            { this.state.websiteDetails.active_website_video && this.state.websiteDetails.general_website_video && this.state.websiteDetails.general_website_video.url ? (
              <div className="homepage__general-video">
              <ReactPlayer
                url={`${process.env.REACT_APP_API_URL}${this.state.websiteDetails.general_website_video.url}`}
                className='header__video'
                width="100%"
                height="100%"
                playing={false}
                loop={true}
                volume={1}
                controls={true}
              />
              </div>
            ) : (null) }

            <div className='homepage__invitation'>
              <h4 className='homepage__invitation-title'>{this.state.coupleStory.outcome}</h4>
              <span>
                <img
                  src={this.state.toggle ? (this.state.websiteDetails.invitation_image && this.state.websiteDetails.invitation_image.url ? `${process.env.REACT_APP_API_URL}${this.state.websiteDetails.invitation_image.url}` : '/images/invitation-placeholder.png') : '/images/invitation.jpg'}
                  alt='invitation'
                  className={this.state.toggle && this.state.websiteDetails.video_invitation ? 'homepage__hidden' : (this.state.rotate ? ' homepage__invitation-img rotate' : 'homepage__invitation-img')}
                  ref={elm => { this.image = elm; }}
                  onClick={this.rotateImage}
                />
              </span>
              {!this.state.toggle ? (
                null
              ) : (
                this.state.websiteDetails.video_invitation ? (
                  this.state.websiteDetails.invitation_video && this.state.websiteDetails.invitation_video.url ? (
                    <div className="homepage__video-wrapper">
                      <ReactPlayer
                        ref={videoRef}
                        url={`${process.env.REACT_APP_API_URL}${this.state.websiteDetails.invitation_video.url}`}
                        className='header__video'
                        width="100%"
                        height="100%"
                        playing={!this.state.openedVideo}
                        volume={1}
                        playsinline={true}
                        controls={true}
                      />
                    </div>
                  ) : (
                    <img src='/images/step.png' className='homepage__story-img' alt='story' />
                  )
                ) : (
                  null
                )
              )}
              {this.state.toggle ? (
                this.state.websiteDetails.video_invitation ? ('') : (
                  <div className="homepage__invitation-text">
                    <h4>{this.state.websiteDetails.invitation_text ? this.state.websiteDetails.invitation_text : i18n.t('chosenText')}</h4>
                    { this.state.websiteDetails.long_invitation_text ? (
                      <div className="form__bottom">
                        <button type="button" onClick={this.openLongInvitationTextModal}>Invitatie</button>
                      </div>
                    ) : ('') }
                  </div>
                )
              )
                : (
                  <div className="homepage__invitation-text" onClick={this.rotateImage}>
                    <h4>{i18n.t('open')}</h4>
                    <h4>{i18n.t('invite')}</h4>
                  </div>
                )}
            </div>

            <div className='homepage__ask standard__only-mobile'>
              <div className='homepage__title-box'>
                <h4 className='homepage__small-detail'>{i18n.t('so')}</h4>
                <h2 className='homepage__title'>{i18n.t('weddingPresence')}</h2>
                <div className='homepage__smudge' />
                <div className='homepage__answer'>
                  <Link to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie', search: queryString.stringify(this.state.params) }} className='standard__link' onClick={() => this.scrollToTop()}>  <h3>{i18n.t('yes')}</h3></Link>
                  <Link to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ',  search: queryString.stringify(this.state.params) }} className='standard__link' onClick={() => this.scrollToTop()}><h3>{i18n.t('no')}</h3></Link>
                </div>
              </div>
            </div>

            {this.state.eventParts.length ? (
              <div className='homepage__location-info'>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleChange}
                  name="tabValue"
                  indicatorColor="none"
                  centered
                >
                  {this.state.eventParts.map(eventPart => (
                    <Tab label={eventPart.tag} icon={<img src={eventPart.tag_icon ? tagIcons[eventPart.tag_icon] : tagIcons.cununie} alt='wedding' className='homepage__tab-icon' />} />
                  ))}
                </Tabs>
                <SwipeableViews
                  axis="x"
                  index={this.state.tabValue}
                  onChangeIndex={this.handleChangeIndex}

                >

                  {this.state.eventParts.map(eventPart => (
                    <div className='homepage__location-wrapper'>
                      <div className='standard__only-desktop homepage__location-info-desktop'>
                        <h4 className='homepage__location-title'>{eventPart.location || '-'}</h4>
                        <div className='location-smaller-wrapper  homepage__church-wrapper'>
                          <div className='homepage__place'>
                            <div className='homepage__location-box'>
                              <img src='/images/location.png' alt='location' />
                              <h5>{eventPart.address || '-'}</h5>
                            </div>
                            <div className='homepage__location-box'>
                              <img src='/images/date.png' alt='date' />
                              <h5>{eventPart.date || '-'}</h5>
                            </div>
                            <div className='homepage__location-box'>
                              <img src='/images/clock.png' alt='clock' />
                              <h5>{eventPart.hour || '-'} </h5>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className='homepage__location standard__only-mobile'>
                        <h4 className='homepage__location-title'>{eventPart.location || '-'}</h4>
                        <div className='homepage__location-wrapper'>
                          <div>
                            <div className='homepage__location-box'>
                              <img src='/images/location.png' alt='location' />
                              <h5>{eventPart.address || '-'}</h5>
                            </div>
                            <div className='homepage__location-box'>
                              <img src='/images/date.png' alt='date' />
                              <h5>{eventPart.date || '-'}</h5>
                            </div>
                            <div className='homepage__location-box'>
                              <img src='/images/clock.png' alt='clock' />
                              <h5>{eventPart.hour || '-'} </h5>
                            </div>
                          </div>

                          <div className='standard__only-mobile homepage__google-mobile-location'>
                            {eventPart.google_lat && eventPart.google_long ?
                              <GoogleMaps lat={eventPart.google_lat} long={eventPart.google_long} /> :
                              <img src='/images/incomplete.png' className="incomplete-mobile" />
                            }
                          </div>
                        </div>
                      </div>
                      <div className='standard__only-desktop homepage__location-desktop-img'>
                        <LazyLoad
                          debounce={false}
                          offsetVertical={500}
                        >
                          {eventPart.image && eventPart.image.url ? (
                            <img src={`${process.env.REACT_APP_API_URL}${eventPart.image.url}`} alt='location' className='homepage__location-img' />
                          ) : (
                            <img src='/images/incomplete.png' alt='location' className='incomplete-mobile homepage__location-img' />
                          )}
                        </LazyLoad>
                      </div>
                      <div className='standard__only-desktop homepage__google-location'>
                        {eventPart.google_lat && eventPart.google_long ?
                          <GoogleMaps lat={eventPart.google_lat} long={eventPart.google_long} /> :
                          <img src='/images/incomplete.png' className='incomplete-mobile homepage__location-img' />
                        }
                      </div>
                    </div>
                  ))}
                </SwipeableViews>
              </div>
            ) : ''}

            <div className="homepage__countdown-box standard__only-mobile">
              <Countdown date={new Date(this.state.selectedCouple.date)} renderer={this.renderCountdown}></Countdown>
            </div>
            <div className='homepage__contact'>
              <div className='homepage__title-box'>
                <h2 className='homepage__title'>{i18n.t('dayOf')}</h2>
                <div className='homepage__smudge homepage__smudge-2' />
              </div>
              <h4 className='homepage__info'>{i18n.t('contact')}</h4>
              {this.state.weddingContacts && this.state.weddingContacts.length ?
                <div className='homepage__contact-wrapper'>
                  {this.state.weddingContacts.map(weddingContact => (
                    <div className='homepage__contact-box'>
                      <div>
                        <img src='/images/person.png' alt='person' />
                        <h4>{weddingContact.name ? weddingContact.name : i18n.t('toCompleteName')}</h4>
                      </div>
                      <div>
                        <img src='/images/phone.png' alt='phone' />
                        <a href={`tel:${weddingContact.phone}`} className='standard__link'> <h4>{weddingContact.phone ? weddingContact.phone : i18n.t('toCompletePhone')}</h4></a>
                      </div>
                    </div>
                  ))}
                </div> : ''}

            </div>
          </div>
          {this.state.longTextModalOpened && <LongInvitationModal
              modalIsOpen={this.state.longTextModalOpened}
              closeModal={this.closeModal}
              selectedCouple={this.state.selectedCouple}
              websiteDetails={this.state.websiteDetails}
              params={this.state.params}
          />}
          <div className='standard__footer'>
            <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie', search: queryString.stringify(this.state.params)}} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('positiveForm')}</h4></Link>
            <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ', search: queryString.stringify(this.state.params)}} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('negativeForm')}</h4></Link>
            <div className='standard__disclaimer'><h5>{this.state.websiteDetails.footer_disclaimer ? this.state.websiteDetails.footer_disclaimer : 'Aici va fi textul ales de voi'}</h5>
              <h5> {this.state.websiteDetails.footer_text ? this.state.websiteDetails.footer_text : i18n.t('chosenText')} </h5>
            </div>
            <div className='standard__footer-box'>
              <a className='standard__link'
                href="https://prometeus-tech.com/"
                target="_blank"
                rel="noopener noreferrer">
                <h5 className="footer__copyright">© Prometeus Tech 2024</h5></a>
              <a className='standard__link'
                href="https://www.facebook.com/CosminaHatmanu"
                target="_blank"
                rel="noopener noreferrer">
                <h5 className="footer__copyright">Designed by Cosmina Hatmanu</h5></a>
            </div>
          </div>
          {this.state.websiteDetails.registry_list_activation ?
            <div className='providers__contact-us homepage__gift-message'>
              <img src='/images/blue-gift.png' alt='gift' />
              <h5>{i18n.t('registryText')}</h5>
              <Link  onClick={this.scrollToTop}className='standard__link' to={{ pathname: `/${encodeURIComponent(this.state.selectedCouple.slug)}/registru-de-cadouri`, search: queryString.stringify(this.state.params)}}>  <button >{i18n.t('toRegistry')}</button></Link>
            </div> : '-'}

        </div>
      </LoadingOverlay>
    )
  }
}

export default withTranslation()(Homepage);
