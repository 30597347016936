import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import { invitationService } from '../../services/InvitationService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import { Tag } from 'antd'


const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        borderRadius: "32px"
    }
};

class AddGuestCategory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            category: {
                name: ''
            },
            categories:[],
            event_id:this.props.eventId,
            updatedCategories:[]
        };

        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        if (this.props.category) {
            let category = { ...this.state.category };
            category = {
                ...this.props.category
            };

            this.setState({ category });
        }
        invitationService.getCategories(this.state.event_id).then(response=>{
            let categories = this.state.categories;
            categories = response.data.categories;
            this.setState({ categories })
        })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
        
    }

    handleChange = event => {
        console.log(event.target.checked);
        const field = event.target.name;
        const category = { ...this.state.category };
        category[field] = event.target.value;
        this.setState({ category });
    }

 

    handleAddcategory = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        invitationService.createOrUpdateCategory(this.state.category, this.state.event_id)
            .then(response => {
                console.log(response);
                notification.addNotification({
                    message:
                        `Categoria a fost ${this.state.category.id ? 'editata' : 'adaugata'} cu succes.`,
                    level: 'success',
                    position: 'tc'
                });
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            })
            .catch(error => {
                console.log(error);
            })
    }
    handleDeleteCategory=(deletedCategory)=>{
        const notification = this.notificationSystem.current;

        invitationService.deleteCategory(deletedCategory.id).then(reponse=>{
            const updatedCategories = this.state.categories.filter(category => category.id !== deletedCategory.id);
            this.setState({ categories: updatedCategories });
            notification.addNotification({
                message:
                    `Categoria a fost stearsa cu succes.`,
                level: 'success',
                position: 'tc'
            });        })
        .catch(err=>{
            notification.addNotification({
                message:
                    `Ne pare rau, dar a aparut o problema. Va rog sa dati refresh sau sa contactati administratorul site-ului.`,
                level: 'error',
                position: 'tc'
            });

        })
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
                    <div className='standard add-modal'>
                        <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h2 className="add-modal__title">{this.state.category.id ? 'Editează categorie' : 'Adaugă categorie'} </h2>
                        <form onSubmit={this.handleAddcategory}>
                            <div>
                                <h4 className='add-modal__label'>Titlu categorie</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.category.name} />
                            </div>
                           
                            <div className="add-modal__buttons">
                                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.category.id ? 'Editează' : 'Adaugă'}</button>
                                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
                            </div>
                            {this.state.categories.length? 
                             <div className='standard__categories'>

                             <h4>Categorii existente:</h4>
                            {this.state.categories.map(category => (
                                    <Tag key={category.id} closable onClose={() => this.handleDeleteCategory(category)}>{category.name}</Tag>
                                ))}
                             </div> :''}
                           
                        </form>
                    </div>
                </Modal>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default AddGuestCategory;
