import React from 'react';
import './AdminMobileHeader.scss';
import * as Scroll from 'react-scroll';
import AdminHamburgerMenu from './AdminHamburgerMenu';

class AdminMobileHeader extends React.Component{
    scrollToBenefits = () => {
        const scroller = Scroll.scroller;

        scroller.scrollTo('benefits', {
          duration: 1000,
          smooth: true,
          spy: true
        })
      }
    render(){
        return(
            <div className='admin-header'>
                <div className='admin-header__container'>
                <h2 className="admin-header__title">{this.props.page}</h2>
                <AdminHamburgerMenu/>
            
                </div>
                <img src="/images/header-mobile.png"alt="gradient" className="admin-header__gradient"/>
            </div>
        )
    }
}
export default AdminMobileHeader;
