import axios from 'axios';
import { loginService } from './LoginService';

export const menuTypeConfigService ={
    createOrUpdateMenu,
    deleteMenu,
    getMenus
}

function getMenus(eventId){
    let url = `${process.env.REACT_APP_API_URL}/api/menu_type_configs`;
    const requestOptionsPost = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data:{
          event_id: eventId,
        }
      };

      return axios(requestOptionsPost);
}

 function createOrUpdateMenu(menuData){
     let url = `${process.env.REACT_APP_API_URL}/api/menu_type_configs${menuData.id ? `/${menuData.id}` : ''}`;
     const requestOptionsPost = {
         method: menuData.id? 'PUT': 'POST',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         },
         data:{
           menu_type_config: {
             ...menuData
           },
         }
       };
       if (loginService.loggedIn()) {
         requestOptionsPost.headers['Authorization'] = loginService.getToken();
       }

       return axios(requestOptionsPost);
 }

 function deleteMenu(menuId){
     const requestOptionsDelete = {
         headers: {
           Accept: 'application/json'
         }
       };

       if (loginService.loggedIn()) {
         requestOptionsDelete.headers['Authorization'] =loginService.getToken();
       }
       let url = `${process.env.REACT_APP_API_URL}/api/menu_type_configs/${menuId}`;

       return axios.delete(url, requestOptionsDelete);
 }
