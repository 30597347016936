import axios from 'axios';
import { loginService } from './LoginService';

export const websiteEventPartsService={
    addEventPart,
    createOrUpdatePart,
    deleteEventPart
}

function addEventPart(eventPartData){

     let url = `${process.env.REACT_APP_API_URL}/api/event_parts`;

     const requestOptionsGet = {
         method: 'POST',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         },
         data: buildRequestData(eventPartData)
       };

       if (loginService.loggedIn()) {
         requestOptionsGet.headers['Authorization'] = loginService.getToken();
       }

       return axios(requestOptionsGet);
 }
 function createOrUpdatePart(partData){
  let url = `${process.env.REACT_APP_API_URL}/api/event_parts${partData.id ? `/${partData.id}` : ''}`;
  const requestOptionsPost = {
      method: partData.id? 'PUT': 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: buildRequestData(partData)
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function deleteEventPart(eventPartId){
  const requestOptionsDelete = {
      headers: {
        Accept: 'application/json'
      }
    };

    if (loginService.loggedIn()) {
      requestOptionsDelete.headers['Authorization'] =loginService.getToken();
    }
    let url = `${process.env.REACT_APP_API_URL}/api/event_parts/${eventPartId}`;

    return axios.delete(url, requestOptionsDelete);
}

 function buildRequestData(eventPart) {
   console.log(eventPart);
   let formData = new FormData();

   formData.append('event_part[tag]', eventPart.tag);
   formData.append('event_part[tag_icon]', eventPart.tagIcon);
   formData.append('event_part[location]', eventPart.location);
   formData.append('event_part[address]', eventPart.address);
   formData.append('event_part[date]', eventPart.date);
   formData.append('event_part[hour]', eventPart.hour);
   formData.append('event_part[website_detail_id]', eventPart.website_detail_id);

   const document = eventPart.image;

   if (document && document.url !== null) {
     if (document.url) {
       formData.append(`event_part[image]`, document.url);
     } else {
       formData.append(`event_part[image]`, document, document.name);
     }
   }

   return formData;
 }
