import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import { registryService } from "../../services/RegistryService";
import AttachementField from '../AttachementField/AttachementField';


const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxHeight: '80%',
        borderRadius: "32px"
    }
};

class RegistryModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gift: {
                name: '',
                description: '',
                url: '',
                similar: false,
                image: '',
                websiteEventId: this.props.eventId
            },
            currentGift: this.props.gift
        };

        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        if (this.props.gift) {
            let gift = { ...this.state.gift };
            gift = {
                ...this.props.gift,
                websiteEventId: this.props.eventId
            };

            this.setState({ gift });
        }
    }

    handleChange = event => {
        const field = event.target.name;
        const gift = { ...this.state.gift };
        gift[field] = event.target.value;
        this.setState({ gift });
    }

    handleCheckboxChange = event => {
        const field = event.target.name;
        const gift = { ...this.state.gift };
        gift[field] = event.target.checked;
        this.setState({ gift });
    }

    handleAddGift = event => {
        event.preventDefault();
        console.log(this.state.gift)
        const notification = this.notificationSystem.current;

        registryService.createOrUpdateGift(this.state.gift)
            .then(response => {
                this.props.updateGiftList(response.data);
            })

            .catch(error => {
                notification.addNotification({
                    message:
                        `Ne pare rau, a aparut o eroare.`,
                    level: 'error',
                    position: 'tc'
                });
            })
    }
    onDropDocument = documents => {
        let gift = { ...this.state.gift };
        let reader = new FileReader();
        let self = this;

        reader.onload = function (upload) {
            gift.image = documents[0];

            self.setState({ gift });
            self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
    };

    removeDocument = () => {
        let gift = { ...this.state.gift };
        gift.image = null;
        gift.image_filename = '';

        this.setState({ gift });
        this.setState({ disabledDocument: false });
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
                    <div className='standard add-modal add-guest'>
                        <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h2 className="add-modal__title">{this.state.currentGift ? 'Editează' : 'Adaugă'} cadou</h2>
                        <form onSubmit={this.handleAddGift}>
                            <div>
                                <h4 className='add-modal__label'>Nume cadou</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.gift.name} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Descriere</h4>
                                <textarea
                                    rows='5'
                                    cols='5'
                                    className='add-modal__field add-modal__textarea'
                                    type="text"
                                    name="description"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.gift.description} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Link (magazine de unde se poate achiziționa cadoul) (URL-ul trebuie sa inceapa cu http/https: https://)</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="url"
                                    onChange={this.handleChange}
                                    value={this.state.gift.url} />
                            </div>

                            <div className="add-modal__toggle-consent">
                                <h4 className='add-modal__label'>E ok dacă invitatul ia un cadou similar?</h4>
                                <label className="switch">
                                    <input type="checkbox"
                                        name="similar"
                                        onChange={this.handleCheckboxChange}
                                        checked={this.state.gift.similar} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Imagine (opțional)</h4>
                                <AttachementField
                                    onDrop={this.onDropDocument}
                                    removeDocument={this.removeDocument}
                                    disabledDocuments={this.state.disabledDocument}
                                    document={this.state.gift.image}
                                    documentFilename={
                                        this.state.gift.image_filename
                                    }
                                    text="Adaugă imagine cadou"
                                    type="image/*"
                                /></div>


                            <div className="add-modal__buttons">
                                <button type='submit' className='add-modal__button add-modal__submit'>{this.props.gift ? 'Editează' : 'Adaugă'}</button>
                                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default RegistryModal;
