import React from 'react';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';

class PaymentRejected extends React.Component {
    constructor(props) {
        super(props);


    }


    render() {
        return (
            <div className='standard login contact-modal'>
                <Header />
                <div className='login__wrapper'>

                    <img src='/images/lateral.png' alt='background' className='login__lateral' />
                    <div className='login__container payment__container'>
                        <h3 className='login__title '>Ne pare rău, dar plata nu a putut fi efectuată.</h3>
                        <h4 className='login__subtitle'>Vă rugăm să ne contactați pentru activarea contului prin:</h4>
                        <div className=' contact-modal__container payment__contact-box'>

                            <a href={`tel:+40747774796`} className='standard__link contact-modal__contact-box payment__contact-div'>
                                <img src='/images/phone-icon.png' alt='suna' />
                                <h5>Telefon direct</h5>
                            </a>
                            <a
                                className='standard__link contact-modal__contact-box payment__contact-div'
                                href={`https://api.whatsapp.com/send?phone=+40747774796&text=Buna, %0aCu ce te putem ajuta?`} target="_blank"
                                rel="noopener noreferrer">
                                <img src='/images/whatsapp-icon.png' alt='whatsapp' />
                                <h5>Whatsapp</h5>
                            </a>
                            <a
                                className='standard__link contact-modal__contact-box payment__contact-div'
                                href={`https://www.facebook.com/facemnunta`} target="_blank"
                                rel="noopener noreferrer">
                                <img src='/images/messenger-icon.png' alt='messenger' />
                                <h5>Messenger</h5>
                            </a>
                        </div>
                    </div>
                </div>
                <Footer />


            </div>
        )
    }
}

export default PaymentRejected;
