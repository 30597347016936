import React from 'react';
import Menu from '../../../components/AdminMenu/AdminMenu';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import AddTask from "../../../components/AddTask/AddTask";
import { eventService } from '../../../services/EventService';
import ReactTooltip from 'react-tooltip';
import { todoService } from '../../../services/TodoService.jsx'
import './TodoList.scss';
import { Link } from 'react-router-dom';
import moment from "moment";
import { clientsService } from "../../../services/ClientsService.jsx";
import { Progress, Table, Tag, Space } from 'antd';
import TaskDrawer from "../../../components/TaskDrawer/TaskDrawer.jsx";


class TodoList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tasks: [],
            isAddTaskOpen: false,
            pagination: {},
            events: [],
            currentEvent: {},
            currentTask: {},
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false,
            deleteTask: {
                openedDialog: false,
                deleteId: 0
            },
            idLocalStorage: '',
            overallProgress: '',
            openDrawer: false
        }
        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })

        const notification = this.notificationSystem.current;
        clientsService.checkClient()
            .then(response => {
                if (response.data.active_demo === false) {
                    this.setState({ expiredAccount: true });

                    notification.addNotification({
                        message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                        level: 'error',
                        position: 'tc'
                    });

                    setTimeout(() => {
                        this.props.history.push("/app/profil");
                    }, 3000);

                    return;
                }

                if (response.data.active_demo) {
                    this.setState({ activeDemo: true });
                    const freeDays = moment(response.data.free_until).diff(moment(), 'days');
                    const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

                    this.setState({ freeDays });

                    if (freeHours > 0) {
                        this.setState({ freeHours });
                    }
                }
                this.columns = [
                    {
                        title: 'Titlu',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Descriere',
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        title: 'Importanță',
                        dataIndex: 'importance',
                        key: 'importance',
                        render: (text, record) => {
                            let fontWeight;

                            switch (record.importance) {
                                case 'Joasa':
                                    fontWeight = 'lighter';
                                    break;
                                case 'Medie':
                                    fontWeight = 'normal';
                                    break;

                                case 'Foarte importanta':
                                    fontWeight = 'bolder';
                                    break;
                                default:
                                    fontWeight = 'normal';
                            }
                            const style = { fontWeight };

                            return (
                                <span style={style}>
                                    {record.importance}
                                </span>
                            );
                        },

                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status) => {
                            const color = status === 'Done' ? 'green' : status === 'In progres' ? 'yellow' : 'red';
                            return <Tag color={color}>{status}</Tag>;
                        },
                    },
                    {
                        title: 'Deadline',
                        dataIndex: 'deadline',
                        key: 'deadline',
                        sorter: (a, b) => moment(a.deadline) - moment(b.deadline),
                        render: (deadline) => moment(deadline).format('DD/MM/YYYY'),
                    },
                    {
                        title: 'Acțiuni', dataIndex: 'actions', key: 'actions',
                        render: (_, task) => (
                            <Space size="middle">
                                <a onClick={() => this.openAddTask(task)}>Editează</a>
                                <a onClick={() => this.openDeleteDialog(task.id)}>Șterge</a>
                                <a onClick={() => this.openDrawer(task)}>Comentarii</a>

                            </Space>
                        )
                    }
                ];
                eventService.getEvents().then(response => {
                    let events = this.state.events;
                    events = response.data.events;
                    let currentEvent = { ...this.state.currentEvent };

                    let specificEvent = response.data.events.find(event => event.id === this.state.idLocalStorage);

                    currentEvent = specificEvent ? specificEvent : { ...events[0] };

                    this.setState({ events });
                    this.setState({ currentEvent }, () => {
                        todoService.getTasks(this.state.currentEvent.id).then(response => {
                            this.setPaginationState(response);
                            const totalTasks = response.data.tasks.length;
                            const doneTasks = response.data.tasks.filter(task => task.status === 'Done').length;
                            const newTasks = response.data.tasks.filter(task => task.status === 'Nou').length;
                            const progressTasks = response.data.tasks.filter(task => task.status === 'In progres').length / 2;
                            const progressedTasks = doneTasks + progressTasks;
                            const overallProgress = Math.round((progressedTasks / totalTasks) * 100);

                            this.setState({ overallProgress });
                        })
                            .catch(error => {
                                if (error.response.status === 401) {
                                    this.props.history.push("/app/login");
                                }
                            })
                    })
                })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.props.history.push("/app/login");
                        }
                    });

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }

    handlePageClick = selectedPage => {
        todoService
            .getTasks(this.state.currentEvent.id, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);

            })
            .catch(error => {
                console.log(error);
            });
    };
    openDrawer = (selectedTask) => {
        let currentTask = { ...this.state.currentTask };
        currentTask = selectedTask;
        this.setState({ currentTask })
        console.log('aiciii')
        this.setState({ openDrawer: true });
        console.log(this.state.openDrawer)
    };

    closeDrawer = () => {
        this.setState({ openDrawer: false });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let tasks = this.state.tasks;
        tasks = response.data.tasks;
        this.setState({ tasks });
    };
    updateField = event => {
        console.log('dfed')
        const field = event.target.name;
        const tasks = { ...this.state.tasks };
        tasks[field] = event.target.value;
        this.setState({
            tasks
        });
    };



    openDeleteDialog = id => {
        console.log('abd')
        let deleteTask = { ...this.state.deleteTask };
        deleteTask = {
            openedDialog: true,
            deleteId: id
        };

        this.setState({ deleteTask });
    };

    closeDeleteDialog = () => {
        let deleteTask = { ...this.state.deleteTask };
        deleteTask = {
            openedDialog: false,
            deleteId: 0
        };

        this.setState({ deleteTask });
    };


    openAddTask = selectedTask => {
        this.setState({ isAddTaskOpen: true })
        let currentTask = { ...this.state.currentTask };
        currentTask = selectedTask;
        this.setState({ currentTask })
    }
    closeModal = () => {
        this.setState({ isAddTaskOpen: false })
    }
    updateTaskList = newTask => {
        const notification = this.notificationSystem.current;
        let tasks = this.state.tasks.slice();

        const taskIndex = tasks.findIndex(task => task.id === newTask.id);

        if (taskIndex !== -1) {
            tasks[taskIndex] = { ...newTask };
        } else {
            tasks.unshift(newTask)
        }

        this.setState({ tasks }, () => {
            this.closeModal();
            notification.addNotification({
                message:
                    `Task-ul a fost ${taskIndex !== -1 ? 'editat' : 'adaugat'} cu succes.`,
                level: 'success',
                position: 'tc'
            });

        });

    }
    handleChange = event => {
        const field = event.target.name;
        const tasks = { ...this.state.tasks };
        tasks[field] = event.target.value;
        this.setState({ tasks });
    }

    handleDelete = () => {
        const notification = this.notificationSystem.current;
        todoService
            .deleteTask(this.state.deleteTask.deleteId)
            .then(() => {
                let tasks = this.state.tasks.slice();
                tasks = tasks.filter(
                    task => task.id !== this.state.deleteTask.deleteId
                );
                this.setState({ tasks });

                this.closeDeleteDialog();

                notification.addNotification({
                    message: 'Task-ul a fost șters cu success.',
                    level: 'success',
                    position: 'tc'
                });
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    };
    updateEventId = event => {
        const eventId = event.target.value
        localStorage.setItem('idLocalStorage', eventId)
        let idLocalStorage = this.state.idLocalStorage;
        idLocalStorage = eventId;
        this.setState({ idLocalStorage }, () => window.location.reload())
    }

    render() {
        const { tasks, overallProgress } = this.state;

        return (
            <div className=' guests admin invitation registry'>
                <Menu menuItem="to-do-list" />
                <AdminMobileHeader page="Lista task-uri" />
                <div className='admin__container registry__container'>
                    <div className='admin__top'>
                        <div className='admin__select-box'>
                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.idLocalStorage}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    {this.state.activeDemo ? (
                        <div className="admin__no-something">
                            <img src="/images/info.png" alt="info" />
                            <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti {this.state.freeDays} zile si {this.state.freeHours} ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
                        </div>
                    ) : null}
                    <div className="admin__wrapper invitation__wrapper ">
                        <div className=''>
                            <button className="only-mobile invitation__add-guest" onClick={() => this.openAddTask()}>Adaugă task</button>
                            <div className="invitation__top-desktop only-desktop">
                                <h2 className="admin__title">To do list</h2>
                                <div className="invitation__top-right">
                                    <button className=" invitation__add-guest" onClick={() => this.openAddTask()}>Adaugă task</button>
                                </div>
                            </div>
                        </div>
                        <div className="overall-progress">
                            <h4>Progresul tău</h4>
                            <Progress percent={overallProgress} status="active" />
                        </div>
                        <div className="registry__table-container">
                            <div className='guests__wrapper registry__wrapper'>
                                <Table
                                    columns={this.columns}
                                    dataSource={tasks}
                                    rowKey="id"
                                    pagination={{ pageSize: 10 }}
                                // ... (other table props as needed)
                                />
                                {this.state.pagination && this.state.pagination.last_page > 1 && (
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.pagination.last_page}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {this.state.openDrawer && <TaskDrawer
                        visible={this.state.openDrawer}
                        onClose={this.closeDrawer}
                        currentTask={this.state.currentTask}
                    />}
                    {this.state.isAddTaskOpen && <AddTask
                        modalIsOpen={this.state.isAddTaskOpen}
                        closeModal={this.closeModal}
                        updateTaskList={this.updateTaskList}
                        eventId={this.state.currentEvent.id}
                        registryId={this.state.tasks.id}
                        task={this.state.currentTask}
                    />}
                </div>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
                <DeletePUGCertificates
                    opened={this.state.deleteTask.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur ca doriti sa ștergeți acest task?"
                    aditionalText="După ștergere, task-ul nu va mai putea fi recuperat."
                />
            </div>
        );
    }
}

export default TodoList;