import React, { Component } from 'react';
import { notificationsService } from "../../../services/NotificationsService.jsx";
import { withRouter, Link } from 'react-router-dom'
import Menu from '../../../components/AdminMenu/AdminMenu'; import { Table, Space, Select } from 'antd'
import { eventService } from "../../../services/EventService.jsx";
import { BellTwoTone } from '@ant-design/icons';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import { clientsService } from "../../../services/ClientsService.jsx";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';


class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false,
            notifications: [],
            events: [],
            currentEvent: {},
            pagination:{}
        };
        this.notificationSystem = React.createRef();
    }
     


    
    componentDidMount() {
        this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })

        const notification = this.notificationSystem.current;
        clientsService.checkClient()
            .then(response => {
                if (response.data.active_demo === false) {
                    this.setState({ expiredAccount: true });

                    notification.addNotification({
                        message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                        level: 'error',
                        position: 'tc'
                    });

                    setTimeout(() => {
                        this.props.history.push("/app/profil");
                    }, 3000);

                    return;
                }

                if (response.data.active_demo) {
                    this.setState({ activeDemo: true });
                    const freeDays = moment(response.data.free_until).diff(moment(), 'days');
                    const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

                    this.setState({ freeDays });

                    if (freeHours > 0) {
                        this.setState({ freeHours });
                    }
                }
           
                eventService.getEvents().then(response => {
                    let events = this.state.events;
                    events = response.data.events;
                    let currentEvent = { ...this.state.currentEvent };

                    let specificEvent = response.data.events.find(event => event.id === this.state.idLocalStorage);

                    currentEvent = specificEvent ? specificEvent : { ...events[0] };

                    this.setState({ events });
                    this.setState({ currentEvent }, () => {
                        notificationsService.getNotifications(this.state.currentEvent.id)
                            .then(response => {
                                this.setState({ notifications: response.data });
                            })
                            .catch(error => {
                                const { response } = error;
                                if (response && response.status === 401) {
                                    this.props.history.push("/login");
                                }
                            });
                    })
                })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.props.history.push("/app/login");
                        }
                    });

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
            notificationsService.getNotifications(this.state.currentEvent.id)
            .then(response => {
                this.setState({ notifications: response.data });
            })
            .catch(error => {
                const { response } = error;
                if (response && response.status === 401) {
                    this.props.history.push("/login");
                }
            });
    }



    handleMarkAsRead = (notificationId) => {
        notificationsService.markAsRead(this.state.currentEvent.id, notificationId)
            .then(response => {
                const { notifications } = this.state;
                const updatedNotifications = notifications.map(notification => {
                    if (notification.id === notificationId) {
                        return { ...notification, read: true };
                    }
                    return notification;
                });
                this.setState({ notifications: updatedNotifications });
            })
            .catch(error => {
                const { response } = error;
                if (response && response.status === 401) {
                    this.props.history.push("/login");
                }
            });
    }

    render() {
        const { events, currentEvent, notifications } = this.state;

        const dataSource = notifications.map(notification => ({ ...notification }));
        const columns = [
            {
                title: 'Tip',
                dataIndex: 'type',
                key: 'type',
                render: (_, notification) => (
                    <Space size='middle'>
                        <img src='/images/notification.png' alt='notification' className='standard__notification-icon' />
                    </Space>
                )
            },
            { title: 'Titlu', dataIndex: 'title', key: 'title' },
            { title: 'Mesaj', dataIndex: 'message', key: 'message' },
            {
                title: 'Actiuni',
                dataIndex: 'actions',
                key: 'actions',
                render: (_, notification) => (
                    <Space size="middle">
                        {notification.read ?
                            <img src='/images/menu/checked-orange.png' className='standard__checked-icon' />
                            :
                            <div className='standard__checked' onClick={() => this.handleMarkAsRead(notification.id)}>
                                <h5>Marcheaza ca citit</h5>
                                <img src='/images/menu/checked.png' className='standard__checked-icon' />
                            </div>
                        }
                    </Space>
                )
            }
        ];


        return (
            <div className='standard'>
                <div className=' guests admin invitation registry'>
                    <Menu menuItem="Notificari" />
                    <AdminMobileHeader page="Notificari" />
                    <div className='admin__container registry__container'>
                        <div className='admin__top'>

                            <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                        </div>
                        {this.state.activeDemo ? (
                            <div className="admin__no-something">
                                <img src="/images/info.png" alt="info" />
                                <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti {this.state.freeDays} zile si {this.state.freeHours} ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
                            </div>
                        ) : null}
                        <div className="admin__wrapper invitation__wrapper ">
                        <h2 className="admin__title">Notificări</h2>

                            <div className="registry__table-container">
                                <div className='guests__wrapper registry__wrapper'>
                                    <Table
                                        columns={columns}
                                        dataSource={this.state.notifications}
                                        rowKey="id"
                                        rowClassName={(record) => (record.read ? '' : 'standard__unread')}
                                        pagination={{ pageSize: 10 }}
                                    />
                                    {this.state.pagination && this.state.pagination.last_page > 1 && (
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pagination.last_page}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {this.state.pagination && this.state.pagination.last_page > 1 && (
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pagination.last_page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                )}
                   <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        );
    }
}

export default withRouter(Notifications);