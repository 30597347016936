import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logoWrapper: {
    display: 'flex',
    height: '55px'
  },
  logo: {
    width: '80px',
    height: '35px',
    margin: 10
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginRight: 10
  },
  tablesWrapper: {
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tablesImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'fill',
    margin: 10
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '30px'
  },
  footerLink: {
    fontSize: '10px',
    margin: 5
  }
});

// Create Document Component
class TableConfiguratorDocument extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={styles.header} fixed={true}>
            <View style={styles.logoWrapper}>
              <Image style={styles.logo} src='/images/logo.png' />
            </View>
            <Text style={styles.title}>{this.props.title}</Text>
          </View>
          <View style={styles.tablesWrapper}>
            <Image style={styles.tablesImage} src={this.props.image.src}/>
          </View>
          <View style={styles.footer} fixed={true}>
            <Link style={styles.footerLink} src="www.facemnunta.ro">www.facemnunta.ro</Link>
          </View>
        </Page>
      </Document>
    )
  }
};

export default TableConfiguratorDocument;
