import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../AdminMenu/AdminMenu';
import '../../resources/main.scss';

class SupremeMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
      
    }
  
    render() {
        return (
            <div className='standard menu'>
                <Link to='/' className='standard__link'><img src='/images/logo.png' alt='logo' className='menu__logo' /></Link>
                <img src='/images/menu-gradient.png' alt='gradient' className='menu__gradient' />
                <ul className='menu__list'>
                    <Link className='standard__link' to='/app/clienti'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-1.png' className='menu__img' alt='menu' />

                            <li className={this.props.menuItem === "clienti" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Clienti</li>

                        </div>
                    </Link>
                    <Link className='standard__link' to='/app/furnizori'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-1.png' className='menu__img' alt='menu' />

                            <li className={this.props.menuItem === "furnizori" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Furnizori</li>

                        </div>
                    </Link>
                </ul>
            </div >
        )
    }
}

export default SupremeMenu;
