import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import { budgetService } from "../../services/BudgetService";
import AttachementField from '../AttachementField/AttachementField';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: '80%',
    borderRadius: "32px"
  }
};

class BudgetModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expense: {
        name: '',
        total_price: '',
        advance_payment: '',
        cost_per_guest: false,
        paid: '',
        comment: '',
        document: '',
        budgetId:this.props.budgetId,
        website_detail_id: this.props.eventId,
        currency:'RON'
      },
      disabledDocument: false
    };

    this.notificationSystem = React.createRef();
  }

  componentDidMount() {
    if (this.props.expense && Object.keys(this.props.expense).length) {
      let expense = { ...this.state.expense };
      expense = {
        ...expense,
        ...this.props.expense,
        paid: this.props.expense.paid || false,
        cost_per_guest:this.props.expense.cost_per_guest || false
      };

      this.setState({ expense });
    }

  }


  handleChange = event => {
    const field = event.target.name;
    const expense = { ...this.state.expense };
    expense[field] = event.target.value;
    this.setState({ expense });
  }


  handleCheckboxChange = event => {
    const field = event.target.name;
    const expense = { ...this.state.expense };
    expense[field] = event.target.checked;
    this.setState({ expense });
  }

  handleAddExpense = event => {
    event.preventDefault();

    budgetService.createOrUpdateExpense(this.state.expense, this.props.budgetId)
      .then(response => {
        console.log(response);

        this.props.updateExpenseList(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }
  onDropDocument = documents => {
    let expense = { ...this.state.expense };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      expense.document = documents[0];

      self.setState({ expense });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let expense = { ...this.state.expense };
    expense.document = null;
    expense.document_filename = '';

    this.setState({ expense });
    this.setState({ disabledDocument: false });
  }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="modal Data"
          ariaHideApp={false}
        >
          <div className='standard add-modal'>
            <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
            <h2 className="add-modal__title">{this.state.expense.id ? 'Editează cheltuială' : 'Adaugă cheltuială nouă pentru ziua nuntii'} </h2>
            <form onSubmit={this.handleAddExpense}>
              <div>
                <h4 className='add-modal__label'>Nume</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  required
                  value={this.state.expense.name} />
              </div>
              <div className="add-modal__two-inputs">
              <div>
                <h4 className='add-modal__label'>Preț total</h4>
                <input
                  className='add-modal__field'
                  type="number"
                  name="total_price"
                  onChange={this.handleChange}
                  required
                  value={this.state.expense.total_price} />
              </div>
              <div>
                <h4 className='add-modal__label'>Selecteaza Moneda </h4>
                <select
                  className="add-modal__field"
                  title="currency"
                  value={this.state.expense.currency}
                  name="currency"
                  onChange={this.handleChange}
                >
                  <option value="RON">Lei</option>
                  <option value="EUR">Euro</option>
                  <option value="USD">Dolar</option>
                  <option value="GBP">Lira</option>
                </select>
              </div>
              </div>

              <div>
                <h4 className='add-modal__label'>Avans</h4>
                <input
                  className='add-modal__field'
                  type="number"
                  name="advance_payment"
                  onChange={this.handleChange}
                  value={this.state.expense.advance_payment} />
              </div>
              <div className="add-modal__toggle-consent">
                <h4 className='add-modal__label'>Cost per invitat</h4>
                <label class="switch">
                  <input type="checkbox"
                    name="cost_per_guest"
                    onChange={this.handleCheckboxChange}

                    checked={this.state.expense.cost_per_guest} />
                  <span class="slider round"></span>
                </label>
              </div>

              <div>
                <h4 className='add-modal__label'>Comentariu</h4>
                <textarea
                  cols="3"
                  rows="5"
                  className='add-modal__field add-modal__textarea'
                  type="text"
                  name="comment"
                  onChange={this.handleChange}
                  value={this.state.expense.comment} />
              </div>
              <div className="add-modal__toggle-consent">
                <h4 className='add-modal__label'>Platit</h4>
                <label class="switch">
                  <input type="checkbox"
                    name="paid"
                    onChange={this.handleCheckboxChange}

                    checked={this.state.expense.paid} />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <h4 className='add-modal__label'>Contract:</h4>
                <AttachementField
                  onDrop={this.onDropDocument}
                  removeDocument={this.removeDocument}
                  disabledDocuments={this.state.disabledDocument}
                  document={this.state.expense.document}
                  documentFilename={
                    this.state.expense.document_filename
                  }
                  text="Adauga contract"
                  type="application/pdf,image/*"
                /></div>

              <div className="add-modal__buttons">
                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.expense.id ? 'Editează' : 'Adaugă'}</button>
                <button className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
              </div>
            </form>
          </div>
        </Modal>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default BudgetModal;
