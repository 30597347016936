import React from 'react';
import './Login.scss';
import '../../resources/main.scss';
import { loginService } from '../../services/LoginService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    }
    this.notificationSystem = React.createRef();
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleLogin = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    loginService
      .login(this.state)
      .then(response => {
        loginService.setToken(response.headers.authorization);
        localStorage.setItem('userProfile', JSON.stringify(response.data));

        if (response.data.role === 'admin') {
          this.props.history.push('/app/clienti');
        } else {
          this.props.history.push('/app/evenimente');
        }


      })
      .catch(error => {
        if (error.response.data.error === 'Your account is not activated yet.') {
          notification.addNotification({
            message: 'Trebuie sa realizati plata pentru a activa contul si a folosi platforma.',
            level: 'error',
            position: 'tc'
          });

          return;
        }
        notification.addNotification({
          message: 'Email sau parola incorecte.',
          level: 'error',
          position: 'tc'
        });
      });
  }
  render() {
    return (
      <div className='standard login'>
        <Header />
        <div className='login__wrapper'>
          <img src='/images/lateral.png' alt='background' className='login__lateral' />
          <div className='login__container'>
            <h3 className='login__title'>Conectează-te la contul tău</h3>
            <h4 className='login__subtitle'>Nu ai un cont? <Link className="standard__link" to="/app/creeaza-cont"><span> Înregistrează-te aici</span></Link></h4>
            <form className='login__form' onSubmit={this.handleLogin}>
              <h4 className='login__label'>Email*</h4>
              <input
                className='login__input'
                type="email"
                name="email"
                onChange={this.handleChange}
                required
                value={this.state.email} />
              <h4 className='login__label'>Parola*</h4>
              <input
                className='login__input'
                type="password"
                name="password"
                onChange={this.handleChange}
                required
                value={this.state.password} />
              <h4 className='login__forgot-password'>Ți-ai uitat parola? Nicio problemă! <Link className="standard__link" to="/app/trimite-email"><span> Resetează parola aici.</span></Link></h4>
              <div className='login__account-box'>
                <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>Conectare</h5></button>
                {/* <div className='login__option-box'>
                                    <div className='login__line'/>
                                    <h4 className='login__option-writing'>Sau intră în contul tău cu</h4>
                                    <div className='login__line'/>
                                </div>
                                <button className='login__option-login'>
                                    <img src='/images/google.png' alt='google'/>
                                    <h5>Google</h5>
                                </button>
                                <button className='login__option-login'>
                                    <img src='/images/facebook.png' alt='facebook'/>
                                    <h5>Facebook</h5>
                                </button> */}

              </div>

            </form>

          </div>
        </div>
        <Footer />
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default Login;
