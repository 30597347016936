import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import '../../resources/main.scss';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import { menuTypeConfigService } from '../../services/MenuTypeConfigService';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    borderRadius: "32px"
  }
};

class AddMenuModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        menu_type: '',
        invitation_form_id: this.props.invitationFormId
      }
    };

    this.notificationSystem = React.createRef();
  }

    componentDidMount() {
      if (this.props.menu && Object.keys(this.props.menu).length) {
        let menu = { ...this.state.menu };
        menu = {
          ...this.props.menu,
        };

        this.setState({ menu });
      }
    }

    handleChange = event => {
        const field = event.target.name;
        const menu = { ...this.state.menu };
        menu[field] = event.target.value;
        this.setState({ menu });
    }

  handleAddMenu = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    console.log(this.state.menu);

    menuTypeConfigService.createOrUpdateMenu(this.state.menu)
      .then(response => {
        console.log(response);
        notification.addNotification({
            message:
                `Meniul a fost ${this.state.menu.id ? 'editat' : 'adaugat'} cu succes.`,
            level: 'success',
            position: 'tc'
        });
        this.props.updateMenuList(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="modal Data"
          ariaHideApp={false}
        >
          <div className='standard add-modal'>
            <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
            <h2 className="add-modal__title">{this.state.menu.id ? 'Editează meniul' : 'Adaugă meniu nou pentru ziua nuntii'} </h2>
            <form onSubmit={this.handleAddMenu}>
              <div>
                <h4 className='add-modal__label'>Tipurile de meniu pe care le vei servi la nuntă (ex: normal,vegetarian, vegan)</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="menu_type"
                  onChange={this.handleChange}
                  required
                  value={this.state.menu.menu_type} />
              </div>

              <div className="add-modal__buttons">
                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.menu.id ? 'Editează' : 'Adaugă'}</button>
                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
              </div>
            </form>
          </div>
        </Modal>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default AddMenuModal;
