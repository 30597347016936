import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import '../AddEventPartModal/Add.scss'
import DOMPurify from 'dompurify';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './LongInvitationModal.scss';
import queryString from 'query-string';

const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: '70%',
        maxHeight: '90%',
        height: 'auto',
        borderRadius: "32px"
    }
};

class LongInvitationModal extends React.Component {
  constructor(props) {
      super(props);

      console.log(this.props);
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

    render() {
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                onRequestClose={this.props.closeModal}
                style={customStyles}
                contentLabel="modal Data"
                ariaHideApp={false}
            >
                <div className='add-modal' long-invitation-modal>
                    <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.websiteDetails.long_invitation_text) }}></div>

                    <div className='homepage__title-box long-invitation-modal'>
                      <h2 className='homepage__title'>{i18n.t('weddingPresence')}</h2>
                      <div className='homepage__smudge' />
                        <div className='homepage__answer'>
                          <Link to={{ pathname: encodeURIComponent(this.props.selectedCouple.slug) + '/formular-invitatie', search: queryString.stringify(this.props.params) }} className='standard__link' onClick={() => this.scrollToTop()}><h3>{i18n.t('yes')}</h3></Link>
                          <Link to={{ pathname: encodeURIComponent(this.props.selectedCouple.slug) + '/formular-raspuns-negativ', search: queryString.stringify(this.props.params)}} className='standard__link' onClick={() => this.scrollToTop()}> <h3>{i18n.t('no')}</h3></Link>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default LongInvitationModal;
