import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import { coupleStoryService } from '../../services/CoupleStoryService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import AttachementField from '../AttachementField/AttachementField';
import '../AddEventPartModal/Add.scss'
import { storyStepService } from '../../services/StoryStepService';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: '80%',
    borderRadius: "32px"
  }
};

class AddEventStoryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventStory: {
        title: '',
        subtitle: '',
        outcome: '',
        active: true,
        website_detail_id: this.props.websiteDetailsId,
        storySteps: [
          {
            tip: 'mireasa',
            description: ''
          },
          {
            tip: 'mire',
            description: ''
          },
          {
            tip: 'intalnire',
            description: ''
          },
          {
            tip: 'povestire',
            description: ''
          },
          {
            tip: 'povestire',
            description: ''
          },
          {
            tip: 'povestire',
            description: ''
          }
        ]
      },
      disabledDocument: false
    };

    this.notificationSystem = React.createRef();
  }

  componentDidMount() {
    if (this.props.eventStory && Object.keys(this.props.eventStory).length) {
      let eventStory = { ...this.state.eventStory };
      eventStory = {
        ...eventStory,
        ...this.props.eventStory,
        active: this.props.eventStory.active || undefined
      };

      if (this.props.eventStory.story_steps && this.props.eventStory.story_steps.length) {
        eventStory.storySteps = this.props.eventStory.story_steps.slice();
      }

      this.setState({ eventStory });
    }
  }

  handleChange = event => {
    const field = event.target.name;
    const eventStory = { ...this.state.eventStory };
    eventStory[field] = event.target.value;
    this.setState({ eventStory });
  }

  handleCheckboxChange = event => {
    const field = event.target.name;
    const eventStory = { ...this.state.eventStory };
    eventStory[field] = event.target.checked;
    this.setState({ eventStory });
  }

  updateSectionField = (event, index) => {
    let eventStory = this.state.eventStory;

    let currentSection = eventStory.storySteps[index];
    currentSection[event.target.name] = event.target.value;

    this.setState({ eventStory })
  }

  onDropImageDocument = (documents, rejected, index) => {
    let eventStory = { ...this.state.eventStory };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      let currentSection = eventStory.storySteps[index];

      currentSection.image = documents[0];

      self.setState({ eventStory });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeImageDocument = index => {
    let eventStory = { ...this.state.eventStory };
    let currentSection = eventStory.storySteps[index];
    currentSection.image = null;
    currentSection.image_filename = '';

    const notification = this.notificationSystem.current;

    storyStepService.removeDocument(currentSection.id)
      .then(response => {
        this.setState({ eventStory });

        notification.addNotification({
          message: `Documentul a fost sters cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => console.log(error));

  };

  handleAddContact = event => {
    console.log("asdasdasdadadasdasdasd")
    event.preventDefault();
    const notification = this.notificationSystem.current;

    coupleStoryService.createOrUpdateStory(this.state.eventStory)
      .then(response => {
        notification.addNotification({
          message:
            `Povestea voastra a fost ${this.state.eventStory.id ? 'editata' : 'adaugata'} cu succes.`,
          level: 'success',
          position: 'tc'
        });
        this.props.updateEventStoryList(response.data);
      })
      .catch(error => {
        console.log(error);
      })
      console.log('edit triggered')
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="modal Data"
          ariaHideApp={false}
        >
          <div className='standard add-modal'>
            <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
            <h2 className="add-modal__title">{this.state.eventStory.id? "Editează":"Adaugă"} povestea voastră</h2>
            <form onSubmit={this.handleAddContact}>
              <div>
                <h4 className='add-modal__label'>Titlu poveste*</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="title"
                  onChange={this.handleChange}
                  required
                  value={this.state.eventStory.title} />
              </div>
              <div>
                <h4 className='add-modal__label'>Subtitlu poveste</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="subtitle"
                  onChange={this.handleChange}
                  value={this.state.eventStory.subtitle} />
              </div>

              {this.state.eventStory.storySteps.map((storyStep, index) => (
                <div key={index} className="add-modal__text-and-pic">
                  <div className="add-modal__half-section">
                    <h4 className='add-modal__label'>{`Poza ${storyStep.tip}`}</h4>
                    <AttachementField
                      onDrop={this.onDropImageDocument}
                      removeDocument={this.removeImageDocument}
                      disabledDocuments={storyStep.disabledDocument}
                      currentArticleSectionIndex={index}
                      document={storyStep.image}
                      section="story-attachement"
                      documentFilename={
                        storyStep.image_filename
                      }
                      type="image/*"
                    />
                  </div>
                  <div className="add-modal__half-section">

                    <h4 className='add-modal__label'>Scurtă descriere</h4>
                    <input
                      className='add-modal__field add-modal__description-field'
                      type="text"
                      name="description"
                      onChange={event => this.updateSectionField(event, index)}
                      value={storyStep.description} />
                  </div>
                </div>
              ))}

              <div>
                <h4 className='add-modal__label'>Text legătură între poveste și invitație:</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="outcome"
                  onChange={this.handleChange}
                  value={this.state.eventStory.outcome} />
              </div>

              {/* <div className="add-modal__toggle-consent">
                <h4 className='add-modal__label'>Poveste activă:</h4>
                <label class="switch">
                  <input type="checkbox"
                    name="active"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.eventStory.active} />
                  <span class="slider round"></span>
                </label>
              </div> */}
              <div className="add-modal__buttons">
                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.eventStory.id? "Editează":"Adaugă"}</button>
                <button type='button' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
              </div>
            </form>
          </div>
        </Modal>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default AddEventStoryModal;
