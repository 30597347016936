import axios from 'axios';
import { loginService } from './LoginService';

export const coupleStoryService={
    createOrUpdateStory,
    getCoupleStories,
    deleteStory
}

function createOrUpdateStory(coupleStoryData) {
  let url = `${process.env.REACT_APP_API_URL}/api/couple_stories/${coupleStoryData.id ? `${coupleStoryData.id}` : ''}`;
  const requestOptionsPost = {
      method: coupleStoryData.id? 'PUT': 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: createBodyRequest(coupleStoryData)
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}

function createBodyRequest(coupleStoryData) {
  let formData = new FormData();

  if (coupleStoryData.id) {
    formData.append('couple_story[id]', coupleStoryData.id);
  }

  formData.append('couple_story[title]', coupleStoryData.title);
  formData.append('couple_story[subtitle]', coupleStoryData.subtitle);
  formData.append('couple_story[outcome]', coupleStoryData.outcome);
  formData.append('couple_story[website_detail_id]', coupleStoryData.website_detail_id);
  formData.append('couple_story[active]', coupleStoryData.active);

  coupleStoryData.storySteps.map(storyStep => {
    if (storyStep.id) {
      formData.append('couple_story[story_steps_attributes][][id]', storyStep.id);
    }

    if (storyStep.couple_story_id) {
      formData.append('couple_story[story_steps_attributes][][couple_story_id]', storyStep.couple_story_id);
    }

    formData.append('couple_story[story_steps_attributes][][tip]', storyStep.tip);
    formData.append('couple_story[story_steps_attributes][][description]', storyStep.description);

    if (storyStep.image && storyStep.image.url !== null) {
      const image = storyStep.image;

      if (image) {
        if (image.url) {
          formData.append(`couple_story[story_steps_attributes][][image]`, image.url);
        } else {
          formData.append(`couple_story[story_steps_attributes][][image]`, image, image.name);
        }
      }

    }
  });

  return formData;
}

function getCoupleStories() {
  let url = `${process.env.REACT_APP_API_URL}/api/couple_stories`;

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
  }
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptions);
}
function deleteStory(storyId){
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/couple_stories/${storyId}`;

  return axios.delete(url, requestOptionsDelete);
}
