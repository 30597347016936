import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#e66300';

const styles = StyleSheet.create({
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '40px 10px 0',
        border: '1px solid #e66300',
        borderRadius: '20px',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1
    },
    headerDescription: {
        width: '45%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    headerQty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    headerRate: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    headerAmount: {
        width: '15%',
    },
  });

  const TableHeader = () => (
    <View style={styles.headerContainer}>
        <Text style={styles.headerDescription}>Nume Invitat</Text>
        <Text style={styles.headerQty}>Numar Persoane</Text>
        <Text style={styles.headerRate}>Masa</Text>
        <Text style={styles.headerAmount}>Prezenta</Text>
    </View>
  );

  export default TableHeader;
