import React, { useState } from 'react';
import questions from './questions.json';
import Menu from '../../../components/AdminMenu/AdminMenu';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import './FrequentQuestions.scss'
const FrequentQuestions = () => {
    const [expandedQuestions, setExpandedQuestions] = useState([]);

    const toggleQuestion = (questionId) => {
        setExpandedQuestions((prevExpanded) => {
            if (prevExpanded.includes(questionId)) {
                return prevExpanded.filter((id) => id !== questionId);
            } else {
                return [...prevExpanded, questionId];
            }
        });
    };

    return (
        <div className='admin website-form events questions'>
            <Menu menuItem="intrebari" />
            <AdminMobileHeader page="Intrebari frecvente" />
            <div className='admin__container' >

                <div className='admin__wrapper'>
                    <h2 className='events__title'>Intrebari frecvente</h2>
                    <div className="questions__wrapper">
                        
                        {questions.map((q) => (
                            <div key={q.id}>
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => toggleQuestion(q.id)}
                                >
                                    {q.question}
                                </div>
                                {expandedQuestions.includes(q.id) && (
                                    <div>
                                        <p>{q.answer}</p>
                                        <img src={q.imageUrl} alt={q.question} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>






                </div>
            </div>

        </div>

    );
};

export default FrequentQuestions;