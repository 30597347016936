import React from 'react';
import '../../../resources/main.scss';
import Menu from '../../../components/AdminMenu/AdminMenu';
import {guestsService} from '../../../services/AdminService';
import { eventService } from '../../../services/EventService';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';

class NonGuests extends React.Component{
    constructor(props){
        super(props);

        this.state={
          guests:[],
          events:[],
          currentEvent:{}
        }

    }
    componentDidMount(){
        eventService.getEvents().then(response => {
            let events = this.state.events;
            events = response.data.events;
            let currentEvent = { ...this.state.currentEvent };
            currentEvent = { ...events[0] };

            this.setState({ events });
            this.setState({ currentEvent }, () => {
              guestsService.getNonGuests(this.state.currentEvent.id).then(response=>{
                let guests = this.state.guests;
                guests = response.data.refuzed_invitations;
                let pagination=this.state.pagination;
                pagination=response.data.pagination;

                this.setState({pagination})
                this.setState({ guests })
              })
              .catch(error => {
                if (error.response.status === 401) {
                  this.props.history.push("/app/login");
                }
              })
            })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.props.history.push("/app/login");
          }
        })
      }

      updateEventId = event => {
          let currentEvent = { ...this.state.currentEvent };

          currentEvent = this.state.events.find(eventToFind => eventToFind.id === event.target.value);

          console.log(currentEvent)
          this.setState({ currentEvent }, () => {
              guestsService.getNonGuests(this.state.currentEvent.id).then(response => {
                let guests = this.state.guests;
                guests = response.data.refuzed_invitations;
                let pagination={ ...this.state.pagination };
                pagination=response.data.pagination;

                this.setState({ guests });
                this.setState({ pagination })
              })
              .catch(err => console.log(err))
          });
      }
      handlePageClick = selectedPage => {
        guestsService
            .getNonGuests(this.state.currentEvent.id, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let guests = this.state.guests;
        guests = response.data.guests;

        this.setState({ guests });
    };
    render(){
        return(
            <div className='standard guests'>
                <Menu/>
                <AdminMobileHeader/>
                <div className='guests__page-content guestss__page-content'>
                    <div className='guests__container'>
                    <div className='guests__top-search'>
                        <h2 className='guests__title'>Invitați care au spus nu</h2>

                            <select
                                className="event__select"
                                title="tip"
                                value={this.state.eventId}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                              {this.state.events.map(event => (
                                  <option value={event.id}>{event.name}</option>
                              ))}
                            </select>
                            </div>
                       <div className='guests__full-wrapper'>
                        <div className='guests__wrapper guests__non-guests-wrapper'>
                            <div className='guests__heading'>
                                <h4 className='guests__head-title '>Nume</h4>
                                <h4 className='guests__head-title'>Motiv</h4>
                            </div>
                            {this.state.guests.length ? this.state.guests.map(guests=>(
                                <div className='guests__row guests__row' key={guests.id}>
                                    <div className='guests__cell'>
                                        <h4>{guests.name}</h4>
                                    </div>
                                    <div className='guests__cell'>
                                        <h4>{guests.reason? guests.reason :'-'}</h4>
                                    </div>
                                </div>
                            )): <h4 className='standard__no-items'>Încă nu ai răspunsuri negative.</h4>}

                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}

export default NonGuests;
