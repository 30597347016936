import axios from 'axios';
import { loginService } from './LoginService';

export const guestsService={
    getGuests,
    getNonGuests,
    guestsStatistics,
    guestsMenusStatistics,
    getGuest,
    searchGuest,
    searchNonGuest,
    deleteGuest,
    searchNoSeatInvites,
    editHallCapacity,
    getHallCapacity,
    getAllGuests
}
function getGuest(id, eventId){
  let url = `${process.env.REACT_APP_API_URL}/api/guests/${id}${eventId ? `?event_id=${eventId}` : ''}`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }
  return axios(requestOptions);
}

function getGuests(eventId, pageNumber){
    const requestOptions = {
        headers: {
          Accept: 'application/json'
        }

  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/guests?page[number]=${pageNumber ? pageNumber : 1}&event_id=${eventId}`;
  return axios.get(url, requestOptions);
}

function getAllGuests(eventId){
    const requestOptions = {
        headers: {
          Accept: 'application/json'
        }

  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/guests?event_id=${eventId}`;
  return axios.get(url, requestOptions);
}

function getNonGuests(eventId, pageNumber){
    const requestOptions = {
        headers: {
          Accept: 'application/json'
        }

  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/refuzed_invitations?page[number]=${pageNumber ? pageNumber : 1}&event_id=${eventId}`;
  return axios.get(url, requestOptions);
}

function guestsStatistics(eventId){
    let url = `${process.env.REACT_APP_API_URL}/api/guests/guests_statistics?event_id=${eventId}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}

function guestsMenusStatistics(eventId){
    let url = `${process.env.REACT_APP_API_URL}/api/guests/menus_statistics?event_id=${eventId}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}
function searchGuest(eventId, searchObject, pageNumber, perPage) {
  let url = `${process.env.REACT_APP_API_URL}/api/guests/search?page[number]=${
    pageNumber ? pageNumber : 1
  }${ perPage ? `&page[per_page]=${perPage}` : '&page[per_page]=10' }&event_id=${eventId}`;

  Object.keys(searchObject).map(searchKey => {
    if (searchObject[searchKey] !== '') {
      url += `&${searchKey}=${searchObject[searchKey]}`;
    }
  })

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] =loginService.getToken();
    }
    return axios(requestOptions)
}
function searchNonGuest(eventId, searchName, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/refuzed_invitations/search?page[number]=${
    pageNumber ? pageNumber : 1
  }${searchName ? `&search_name=${searchName}` : '' }&event_id=${eventId}`;

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] =loginService.getToken();
    }
    return axios(requestOptions)
}

function searchNoSeatInvites(eventId, searchName, searchCategory) {
  let url = `${process.env.REACT_APP_API_URL}/api/guests/no_seat_invites?event_id=${eventId}${searchName ? `&search_name=${searchName}` : '' }${searchCategory ? `&search_category=${searchCategory}` : '' }`;

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] =loginService.getToken();
    }
    return axios(requestOptions)
}

function deleteGuest(guestId) {
  const requestOptionsDelete = {
      headers: {
        Accept: 'application/json'
      }
    };

    if (loginService.loggedIn()) {
      requestOptionsDelete.headers['Authorization'] =loginService.getToken();
    }
    let url = `${process.env.REACT_APP_API_URL}/api/guests/${guestId}`;

    return axios.delete(url, requestOptionsDelete);
}
function editHallCapacity(eventId, capacityData){
  let url = `${process.env.REACT_APP_API_URL}/api/table_configurations/${capacityData.id}`;
  const requestOptionsPost = {
      method:'PUT',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data:{
        total_capacity:capacityData.totalCapacity,
        max_tables:capacityData.maxTables,
        event_id:eventId
      }
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function getHallCapacity(eventId){
  let url = `${process.env.REACT_APP_API_URL}/api/table_configurations/details?event_id=${eventId}`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }
  return axios(requestOptions);
}
