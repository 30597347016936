import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import TableConfiguratorDocument from './TableConfiguratorPDFExport';
import moment from "moment";

const generatePdfDocument = async (image, title) => {
        const blob = await pdf((
            <TableConfiguratorDocument
                image={image}
                title={title}
            />
        )).toBlob();

        const filename = title + ' - '+ moment().format('DD-MM-YYYY');
        saveAs(blob, filename);
};

export default generatePdfDocument;
