import React from 'react';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import Confetti from "react-confetti";
import './PaymentSuccess.scss'
import qs from 'query-string';
import { clientsService } from '../../services/ClientsService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props);

        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
      const notification = this.notificationSystem.current;

      window.gtag('event', 'conversion', {
        'send_to': 'AW-11003465916/RrYbCMDGn-UDELyh7v4o',
        'transaction_id': ''
      });

      if (qs.parse(window.location.search).action == 0) {
        clientsService.processPayment(qs.parse(window.location.search).invoice_id)
          .then(response => {
            notification.addNotification({
                message: 'Plata a fost realizata cu success si contul a fost activat.',
                level: 'success',
                position: 'tc'
            });

          })
          .catch(error => {
            console.log(error)
            notification.addNotification({
                message: 'Plata a fost realizata cu success, dar a aparut o problema la activarea contului. Va rugam sa ne contactati direct.',
                level: 'error',
                position: 'tc'
            });
          })
      }
    }


    render() {
        return (
            <div className='standard login'>
                <Header />
                <div className='login__wrapper'>
                    <div className="standard__only-mobile">
                    <Confetti width="400" height="500" tweenDuration="500"/>
                    </div>
                    <div className="standard__only-desktop">
                    <Confetti width="1700" height="1000" />
                    </div>

                    <img src='/images/lateral.png' alt='background' className='login__lateral' />
                    <div className='login__container payment__container'>
                        <h3 className='login__title payment__title'>Felicitări!</h3>  <h3 className='login__title payment__title'> Plata a fost efectuată cu succes și primul pas pentru organizarea evenimentului tău a fost făcut.</h3>
                        <h4 className='login__subtitle payment__subtitle'> <Link className="standard__link" to="/app/login">Acum intră <span>în contul tău </span> și organizează nunta pe care ți-o dorești! </Link></h4>

                    </div>
                </div>
                <Footer />
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default PaymentSuccess;
