import React, { Component } from 'react';
import { Drawer, Input, Button, Modal } from 'antd';
import { commentService } from "../../services/CommentService";
import './TaskDrawer.scss';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class TaskDrawer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTaskComments: [],
            body: '',
            selectedComment: { id: null, body: '' },
            visibleDeleteComm: false,
            commIdDelete: '',
            userId: ''
        };
        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        console.log('we are here');
        console.log(this.props.currentTask);
        this.fetchCommentsForTask(this.props.currentTask);
        const userProfileJSON = localStorage.getItem('userProfile');
        const userProfile = JSON.parse(userProfileJSON);
        const userIdValue = userProfile.id;
        this.setState({ userId: userIdValue });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentTask !== this.props.currentTask) {
            this.fetchCommentsForTask(this.props.currentTask);
        }
    }

    showDeleteModal = commId => {
        this.setState({ visibleDeleteComm: true, commIdDelete: commId });
    };

    handleOk = () => {
        this.setState({ visibleDeleteComm: false });
    };

    handleCancel = () => {
        this.setState({ visibleDeleteComm: false });
    };

    fetchCommentsForTask = (task) => {
        let threadId = task.commontator_thread.id;
        commentService.getThread(threadId).then(response => {
            this.setState({ currentTaskComments: response.data.comments });
        }).catch(err => console.log(err));
    };

    postComment = () => {
        const notification = this.notificationSystem.current;

        let threadId = this.props.currentTask.commontator_thread.id;
        commentService.postComment(this.state.selectedComment.body, threadId)
            .then(response => {
                notification.addNotification({
                    message: 'Comentariul a fost adaugat.',
                    level: 'success',
                    position: 'tc'
                });                let updatedComments = [...this.state.currentTaskComments]
                updatedComments.push(response.data);
                this.setState({ currentTaskComments: updatedComments, selectedComment: { id: null, body: '' } });
            })
            .catch(error => {
                notification.addNotification({
                    message: 'A aparut o problema. Mai incercati odata.',
                    level: 'error',
                    position: 'tc'
                });            });
        this.fetchCommentsForTask(this.props.currentTask);
        this.setState({ body: '' });
    };

    handleEditComment = () => {
        this.editComment(this.state.selectedComment.body, this.state.selectedComment.id);
    };

    selectCommentForEdit = (commentId, commentBody) => {
        this.setState({ selectedComment: { id: commentId, body: commentBody } });
    };

    handleCommentInputChange = (e) => {
        this.setState({ selectedComment: { ...this.state.selectedComment, body: e.target.value } });
    };

    editComment = (body, commentId) => {
        const notification = this.notificationSystem.current;

        commentService.editComment(body, commentId)
            .then(response => {
                notification.addNotification({
                    message: 'Comentariul a fost editat cu succes.',
                    level: 'success',
                    position: 'tc'
                });                const updatedComments = [...this.state.currentTaskComments];
                const commentIndex = updatedComments.findIndex(comment => comment.id === this.state.selectedComment.id);
                if (commentIndex !== -1) {
                    updatedComments[commentIndex].body = this.state.selectedComment.body;
                    this.setState({ currentTaskComments: updatedComments, selectedComment: { id: null, body: '' } });
                } else {
                    console.error("Selected comment not found in currentTaskComments.");
                }
            })
            .catch(error => {
                notification.addNotification({
                    message: 'A aparut o eroare. Va rugam sa mai incercati o data',
                    level: 'error',
                    position: 'tc'
                });            });
    };

    handleDeleteComment = (commentId) => {
        const notification = this.notificationSystem.current;

        commentService.deleteComment(commentId).then(response => {
            notification.addNotification({
                message: 'Comentariul a fost șters cu succes!',
                level: 'success',
                position: 'tc'
            });
            this.setState(prevState => ({
                visibleDeleteComm: false,
                commIdDelete: null,
                currentTaskComments: prevState.currentTaskComments.filter(comment => comment.id !== commentId)
            }));
        }).catch(err => {
            notification.addNotification({
                message: 'Ne pare rau, dar a aparut o problemă. Va rog sa dati refresh sau sa contactati administratorul site-ului.',
                level: 'error',
                position: 'tc'
            });
            this.setState({ visibleDeleteComm: false });
        });
    };

    formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC'
        };
        return date.toLocaleDateString('en-US', options);
    }

    render() {
        const { visible, onClose, currentTask } = this.props;
        const { currentTaskComments, selectedComment, visibleDeleteComm } = this.state;

        return (
            <div className='standard drawer'>
                <Drawer
                    title="Task Comments"
                    placement="right"
                    closable={true}
                    onClose={onClose}
                    open={visible}
                    width={400}
                    footer={(
                        <div style={{ textAlign: 'center' }}>
                            <Input.TextArea
                                placeholder={selectedComment.id ? "Editează comentariul" : "Adaugă un comentariu nou"}
                                value={selectedComment.body}
                                onChange={this.handleCommentInputChange}
                                rows={4}
                            />
                            <Button type="primary" onClick={selectedComment.id ? this.handleEditComment : this.postComment}>
                                {selectedComment.id ? "Salvează modificările" : "Adaugă comentariu"}
                            </Button>
                        </div>
                    )}
                >
                    {currentTaskComments.length > 0 ? (
                        currentTaskComments.map(item => (
                            <div className='drawer__list-item' key={item.key}>
                                <h5 className='drawer__name'>{item.creator.name ? item.creator.name : 'Utilizator'}</h5>
                                <h4 className='drawer__comment'>{item.body}</h4>
                                <h5 className='drawer__date'>{this.formatDate(item.created_at)}</h5>
                                <div className='drawer__actions-box'>
                                        <div className='drawer__actions'>
                                            <img src="/images/edit-black.png" alt='edit' onClick={() => this.selectCommentForEdit(item.id, item.body)} />
                                            <img src="/images/delete-orange.png" alt='delete' onClick={() => this.showDeleteModal(item.id)} />
                                            <Modal
                                                title="Ștergere comentariu"
                                                open={visibleDeleteComm}
                                                onOk={() => this.handleDeleteComment(item.id)}
                                                onCancel={this.handleCancel}
                                            >
                                                <p>Sunteți siguri că vreți să ștergeți acest comentariu?</p>
                                            </Modal>
                                        </div>
                       
                                </div>
                                <div className='drawer__line' />
                            </div>
                        ))
                    ) : (
                        <p>Nu s-au postat comentarii pentru acest task.</p>
                    )}

                </Drawer>
                <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
            </div>
        );
    }
}

export default TaskDrawer;