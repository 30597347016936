import axios from 'axios';
import { loginService } from './LoginService';

export const clientsService = {
  createAccount,
  processPayment,
  regeneratePaymentLink,
  applyDiscountCode,
  setAccountType,
  checkClient,
  setFreeDays,
  contactFoodWaste
}

function createAccount(userData) {
  let url = `${process.env.REACT_APP_API_URL}/api/clients`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      client: {
        ...userData,
        event_date: userData.weddingDate,
        password_confirmation: userData.confirmPassword,
        discount_code: userData.discountCode
      }
    }
  };

  return axios(requestOptionsPost);
}
function contactFoodWaste(userData) {
  let url = `${process.env.REACT_APP_API_URL}/api/food_contacts`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      food_contact: userData
    }
  };

  return axios(requestOptionsPost);
}

function processPayment(invoiceId) {
  let url = `${process.env.REACT_APP_API_URL}/api/clients/process_payment`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      client: {
        invoice_id: invoiceId
      }
    }
  };

  return axios(requestOptionsPost);
}

function regeneratePaymentLink(invoiceId) {
  let url = `${process.env.REACT_APP_API_URL}/api/clients/regenerate_payment_link`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      client: {
        invoice_id: invoiceId
      }
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function applyDiscountCode(client) {
  let url = `${process.env.REACT_APP_API_URL}/api/clients/apply_discount_code`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      client: {
        id: client.id,
        discount_code: client.discount_code
      }
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function checkClient() {
  let url = `${process.env.REACT_APP_API_URL}/api/clients/check_client`;
  const requestOptionsPost = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function setAccountType(client) {
  let url = `${process.env.REACT_APP_API_URL}/api/clients/set_account_type`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      client: {
        id: client.id,
        account_type: client.account_type
      }
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function setFreeDays(clientId, freeDays) {
  let url = `${process.env.REACT_APP_API_URL}/api/clients/set_free_days`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      client: {
        id: clientId,
        free_days: freeDays
      }
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function buildRequestData(acountData) {
  let formData = new FormData();

  formData.append('new_acount[invitation_text]', acountData.invitationText);
  formData.append('new_acount[footer_text]', acountData.footerText);
  formData.append('new_acount[footer_disclaimer]', acountData.footerDisclaimer);
  const document = acountData.coupleImage;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`new_acount[couple_image]`, document.url);
    } else {
      formData.append(`new_acount[invitation_couple_image]`, document, document.name);
    }
  }

  return formData;
}
