import React from 'react';
import './InvitationForm.scss';
import queryString from 'query-string';
import {
  Link
} from "react-router-dom";
import { formService } from '../../services/FormService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import couplesJson from '../Homepage/couples.json';
import { websiteDetailsService } from '../../services/WebsiteDetailsService';
import Moment from 'react-moment';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';

class InvitationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guestInfo: {
        code: '',
        name: '',
        phoneNumber: '',
        company: '',
        companyNumber: '',
        menus: [],
        allergy: '',
        allergyMentions: '',
        otherRestrictions: '',
        housing: false,
        transport: false,
        other: '',
        phone: '',
        email: '',
        eventCode: '',
        clientSlug: '',
        civil_marriage_attend: false
      },
      tabValue: 0,
      couples: couplesJson,
      selectedCouple: {},
      invitationForm: {},
      websiteDetails: {},
      menusConfig: [],
      coupleFromJson: false,
      params: {},
    }
    this.notificationSystem = React.createRef();
  }
  componentDidMount() {
    this.setState({ submittedStarted: true });

    let parameters = this.props.location.pathname;
    let selectedCouple = { ...this.state.selectedCouple };
    const firstSegment = parameters.split("/")[1];

    let existingParams = { ...this.state.params };
    let params = queryString.parse(this.props.location.search);
    existingParams = params;
    this.setState({ params: existingParams });

    websiteDetailsService.getInvitationBySlug(firstSegment)
      .then(response => {
        let selectedCouple = { ...this.state.selectedCouple };

        selectedCouple = { ...response.data.event };


        if (response.data.event.invitation_forms.length) {
          let invitationForm = { ...this.state.invitationForm };

          invitationForm = { ...response.data.event.invitation_forms[0] };

          this.setState({ invitationForm });


          let menusConfig = this.state.menusConfig.slice();

          if (invitationForm.menu_type_configs && invitationForm.menu_type_configs.length) {
            menusConfig = invitationForm.menu_type_configs.slice();

            this.setState({ menusConfig });
          }
        }

        let websiteDetails = { ...this.state.websiteDetails };
        websiteDetails = response.data.event.website_details[0];
        this.setState({ websiteDetails });


        this.setState({ selectedCouple });
        this.setState({ submittedStarted: false });

      })
      .catch(error => {
        this.setState({ submittedStarted: false });
      })


    let guestInfo = { ...this.state.guestInfo };
    guestInfo.code = params.guest_code;
    guestInfo.eventCode = params.event_code;
    guestInfo.clientSlug = firstSegment;

    this.setState({ guestInfo });
  }
  updateField = event => {
    const field = event.target.name;
    const guestInfo = { ...this.state.guestInfo };
    guestInfo[field] = event.target.value;

    this.setState({
      guestInfo
    }, () => {
      if (field === 'companyNumber') {
        this.generateMenus();
      }
    });
  };

  updateTabValue = (event, value) => {
    this.setState({ tabValue: value });
  }

  updateCompany = companyValue => {
    const guestInfo = { ...this.state.guestInfo };
    guestInfo.company = companyValue;

    guestInfo.companyNumber = 1;

    if (companyValue === 'single') {
      guestInfo.companyNumber = 1;
    }

    if (companyValue === 'couple') {
      guestInfo.companyNumber = 2;
    }

    this.setState({
      guestInfo
    }, () => this.generateMenus());
  };

  generateMenus = () => {
    let guestInfo = this.state.guestInfo;

    guestInfo.menus = [];

    if (this.state.menusConfig && this.state.menusConfig.length) {
      for (let i = 0; i < this.state.guestInfo.companyNumber; i++) {
        guestInfo.menus.push(this.state.menusConfig[0]);
      }
    }

    this.setState({ guestInfo }, () => {
      this.setState({ tabValue: 0 });
    });
  }

  updateAllergy = allergyValue => {
    const guestInfo = { ...this.state.guestInfo };
    guestInfo.allergy = allergyValue;
    this.setState({
      guestInfo
    });
  };
  updateUmanistAttendance = () => {
    console.log('ads')
    const guestInfo = { ...this.state.guestInfo };
    guestInfo.civil_marriage_attend = !guestInfo.civil_marriage_attend;

    console.log(guestInfo)
    this.setState({
      guestInfo
    });
  }
  updateMenu = (index, menu) => {
    const guestInfo = { ...this.state.guestInfo };
    guestInfo.menus[index] = { ...menu };

    this.setState({
      guestInfo
    });
  };
  updateHousing = () => {
    const guestInfo = { ...this.state.guestInfo };
    guestInfo.housing = !guestInfo.housing;

    this.setState({
      guestInfo
    });
  };
  updateTransport = () => {
    const guestInfo = { ...this.state.guestInfo };
    guestInfo.transport = !guestInfo.transport;
    this.setState({
      guestInfo
    });
  };
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  handleInviteForm = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;
    formService.positiveForm(this.state.guestInfo).then(response => {
      notification.addNotification({
        message:
          i18n.t('success'),
        level: 'success',
        position: 'tc'
      });
      let params = queryString.parse(this.props.location.search);
    })
      .catch(err => {
        if (err.response.status === 403) {
          notification.addNotification({
            message: i18n.t('notYet'),
            level: 'error',
            position: 'tc'
          });

          return;
        }

        if (err.response.status === 400) {
          notification.addNotification({
            message: err.response.data.error,
            level: 'error',
            position: 'tc'
          });

          return;
        }

        notification.addNotification({
          message: i18n.t('error'),
          level: 'error',
          position: 'tc'
        });
      })
  }

  checkExistingResponse = () => {
    if (this.state.guestInfo.eventCode && this.state.guestInfo.code) {
      const notification = this.notificationSystem.current;

      formService.guestDetails(this.state.guestInfo.eventCode, this.state.guestInfo.code)
        .then(response => {
          let guestInfo = { ...this.state.guestInfo };

          guestInfo = {
            ...response.data.guest,
            allergyMentions: response.data.guest.allergy_mentions,
            companyNumber: response.data.guest.company_number,
            otherRestrictions: response.data.guest.other_restrictions,
          };

          this.setState({ guestInfo }, () => {
            notification.addNotification({
              message: 'Am gasit o invitatie completata deja cu acest cod. Vom incarca datele completate deja.',
              level: 'success',
              position: 'tc'
            });
          });
        })
        .catch(error => {
          console.log(error);
        })
    }
  }
  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    let language = this.state.language;
    language = lng;
    this.setState({ language })
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.submittedStarted}
        spinner={<img src="/images/GifFacemNunta.gif" alt="loading gif" className="standard__loading-gif" />}
      >
        <div className='standard homepage form'
          style={{
            background: this.state.websiteDetails.background_image ? `url(${process.env.REACT_APP_API_URL + this.state.websiteDetails.background_image.url})` : 'transparent',
          }}
        >
          <div className='form__container'>
            <div className='homepage__header form__header'>
              <div>
                <Link to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug), search: queryString.stringify(this.state.params) }} className='standard__link'>
                  <h1 className='homepage__names'>{this.state.selectedCouple.name ? this.state.selectedCouple.name : i18n.t('brideAndGroom')}</h1>
                </Link>
                {this.state.coupleFromJson ? (
                  <div>
                    <h3 className='homepage__date'>{this.state.selectedCouple.date}</h3>
                  </div>
                ) : (
                  <h3 className='homepage__date'>
                    {this.state.selectedCouple.date ? <Moment locale="ro" format="dddd">{this.state.selectedCouple.date}</Moment> : 'Zi Eveniment'} | {this.state.selectedCouple.date ? <Moment locale="ro" format="DD MMMM YYYY">{this.state.selectedCouple.date}</Moment> : 'Data Eveniment'} | {this.state.selectedCouple.city || 'Oras eveniment'}
                  </h3>
                )}
              </div>
              <div className='homepage__header-info'>
                <img className='standard__only-mobile' src='/images/gallery.svg' alt='gallery' />
              </div>
              <div className="homepage__flags">
                <img src='/images/ro-flag.png' alt='romanian' onClick={() => this.changeLanguage('ro')} />
                <img src='/images/uk-flag.png' alt='english' onClick={() => this.changeLanguage('en')} />
              </div>
            </div>

            <div className='form__banner'>
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) && <img src='/images/flower-1.png' alt='flower' className='standard__only-desktop form__banner-icon' /> }
              <img className='form__banner-img' src={(this.state.invitationForm.welcome_image && this.state.invitationForm.welcome_image.url ? `${process.env.REACT_APP_API_URL}${this.state.invitationForm.welcome_image.url}` : '/images/nefinalizat.png')} alt='us' />
              <h3>{this.state.invitationForm.welcome_text ? this.state.invitationForm.welcome_text : 'Mesaj de intampinare'}</h3>
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) && <img src='/images/flower-3.png' alt='flower' className='standard__only-desktop form__banner-icon-2' /> }
            </div>

            <div className='form__wrapper'>
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) && <img src='/images/flower-2.png' alt='flower' className='standard__only-desktop form__form-icon' /> }
              { !(this.state.websiteDetails.background_image && this.state.websiteDetails.background_image.url) && <img src='/images/flower-1.png' alt='flower' className='standard__only-desktop form__form-icon-2' /> }
              <h4 className="form__label-name">{this.state.selectedCouple.invitationPageMessage}</h4>

              {this.state.invitationForm.message ? (
                <h4 className='form__label-name form__general-message'>{this.state.invitationForm.message==='null'? '':this.state.invitationForm.message}</h4>
              ) : (null)}

              <form className='form__form' onSubmit={this.handleInviteForm}>
                <h4 className='form__label-name'>{i18n.t('weddingCode')}</h4>
                <input type='text'
                  required
                  name='eventCode'
                  className='form__field'
                  value={this.state.guestInfo.eventCode}
                  onChange={this.updateField}
                  onBlur={this.checkExistingResponse}
                />
                <h4 className='form__label-name'>{i18n.t('guestCode')}</h4>
                <input type='text'
                  required
                  name='code'
                  className='form__field'
                  value={this.state.guestInfo.code}
                  onChange={this.updateField}
                  onBlur={this.checkExistingResponse}
                />
                <h4 className='form__label-name'>{i18n.t('name')}</h4>
                <input type='text'
                  required
                  name='name'
                  className='form__field'
                  value={this.state.guestInfo.name}
                  onChange={this.updateField} />
                <h4 className='form__label-name'>{i18n.t('phone')}</h4>
                <input type='number'
                  name='phone'
                  className='form__field'
                  value={this.state.guestInfo.phone}
                  onChange={this.updateField} />
                <h4 className='form__label-name'>{i18n.t('email')}</h4>
                <input type='email'
                  name='email'
                  className='form__field'
                  value={this.state.guestInfo.email}
                  onChange={this.updateField} />
                <h4 className='form__label-name'>{i18n.t('company')}</h4>
                <label className='form__checkbox-container'>{i18n.t('single')}
                  <input type="radio" checked={this.state.guestInfo.company === 'single'} name="single" onChange={() => this.updateCompany('single')} />
                  <span className="form__checkmark"></span>
                </label>
                <label className='form__checkbox-container'>{i18n.t('couple')}
                  <input type="radio" checked={this.state.guestInfo.company === 'couple'} name="couple" onChange={() => this.updateCompany('couple')} />
                  <span className="form__checkmark"></span>
                </label>
                <label className='form__checkbox-container'>{i18n.t('family')}
                  <input type="radio" checked={this.state.guestInfo.company === 'family'} name="family" onChange={() => this.updateCompany('family')} />
                  <span className="form__checkmark"></span>
                </label>
                {this.state.guestInfo.company === 'family' ?
                  <div>  <h4 className='form__label-name'>{i18n.t('familyQuestion')}</h4>
                    <input type='number'
                      name='companyNumber'
                      className='form__field'
                      value={this.state.guestInfo.companyNumber}
                      onChange={this.updateField} /></div> : ''}
                <h3 className='form__subtitle'>{i18n.t('menu')}</h3>
                <h4 className='form__label-name'>{i18n.t('yourMenus')}</h4>
                {this.state.guestInfo.companyNumber ? (
                  <div>
                    <Tabs
                      value={this.state.tabValue}
                      onChange={this.updateTabValue}
                      variant="scrollable"
                      scrollButtons="on"
                      aria-label="scrollable auto tabs example"
                    >
                      {this.state.guestInfo.menus.map((menu, index) => (
                        <Tab
                          key={index}
                          label={`Meniu ${index + 1}`}
                          id={`scrollable-force-tab-${index}`}
                          aria-controls={`scrollable-force-tabpanel-${index}`}
                          selected={true}
                        />
                      ))}
                    </Tabs>
                    {this.state.guestInfo.menus.map((menu, index) => (
                      <div
                        key={index}
                        role="tabpanel"
                        value={this.state.tabValue}
                        index={index}
                        hidden={this.state.tabValue !== index}
                        id={`scrollable-force-tabpanel-${index}`}
                        aria-labelledby={`scrollable-force-tab-${index}`}
                      >
                        {this.state.tabValue === index && (
                          <div className='form__box'>
                            {this.state.menusConfig && this.state.menusConfig.length ? (
                              this.state.menusConfig.map(menu => (
                                <label className='form__checkbox-container'>{menu.menu_type}
                                  <input type="radio" checked={this.state.guestInfo.menus[index].menu_type === menu.menu_type} name={menu.menu_type} onChange={() => this.updateMenu(index, menu)} />
                                  <span className="form__checkmark"></span>
                                </label>
                              ))
                            ) : (
                              <div>{i18n.t('menusPlace')}</div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4 className='form__label-name'>{i18n.t('selectPersons')}</h4>
                )}

                <h4 className='form__label-name'>{i18n.t('allergyQuestion')}</h4>
                <div className='form__box'>
                  <label className='form__checkbox-container'>{i18n.t('noSmallLetter')}
                    <input type="radio" checked={this.state.guestInfo.allergy === 'no'} name="allergyNo" onChange={() => this.updateAllergy('no')} />
                    <span className="form__checkmark"></span>
                  </label>
                  <label className='form__checkbox-container'>{i18n.t('yesSmallLetter')}
                    <input type="radio" checked={this.state.guestInfo.allergy === 'yes'} name="allergyYes" onChange={() => this.updateAllergy('yes')} />
                    <span className="form__checkmark"></span>
                  </label>
                </div>
                {this.state.guestInfo.allergy === 'yes' ?
                  <div>
                    <h4 className='form__label-name'>{i18n.t('allergySpecification')}</h4>
                    <input type='text'
                      name='allergyMentions'
                      className='form__field'
                      value={this.state.guestInfo.allergyMentions}
                      onChange={this.updateField} /></div> : ''}

                <h4 className='form__label-name'>{i18n.t('restriction')}</h4>
                <input type='text'
                  name='otherRestrictions'
                  className='form__field'
                  value={this.state.guestInfo.otherRestrictions}
                  onChange={this.updateField} />

                {this.state.coupleFromJson ? (
                  encodeURIComponent(this.state.selectedCouple.slug) === 'cristina-vali' ? (
                    <div>
                      <h3 className='form__subtitle'>Noi oferim transport si cazare pentru cei de acasă, din Suceava.</h3>
                      <h4 className='form__label-name'>{i18n.t('want')}</h4>
                      <div className='form__box'>
                        <label className='form__checkbox-container'>
                          {encodeURIComponent(this.state.selectedCouple.slug) === 'cristina-vali' ? 'Cazare' : i18n.t('accomodation')}
                          <input type="checkbox" checked={this.state.guestInfo.housing} name="housingYes" onChange={() => this.updateHousing()} />
                          <span className="form__checkmark"></span>
                        </label>

                        <label className='form__checkbox-container'>{i18n.t('transport')}
                          <input type="checkbox" checked={this.state.guestInfo.transport} name="transportYes" onChange={() => this.updateTransport()} />
                          <span className="form__checkmark"></span>
                        </label>
                      </div>
                    </div>) : '')
                  : (
                    <div>
                      <h3 className='form__subtitle'>{this.state.invitationForm.extra_services || ''}</h3>
                      {this.state.invitationForm.accommodation || this.state.invitationForm.transport ? (<h4 className='form__label-name'>{i18n.t('want')}</h4>) : ('')}
                      <div className='form__box'>
                        {this.state.invitationForm.accommodation ? (
                          <label className='form__checkbox-container'> {i18n.t('accomodation')}
                            <input type="checkbox" checked={this.state.guestInfo.housing} name="housingYes" onChange={() => this.updateHousing()} />
                            <span className="form__checkmark"></span>
                          </label>
                        ) : (<div></div>)}

                        {this.state.invitationForm.transport ? (
                          <label className='form__checkbox-container'>{i18n.t('transport')}
                            <input type="checkbox" checked={this.state.guestInfo.transport} name="transportYes" onChange={() => this.updateTransport()} />
                            <span className="form__checkmark"></span>
                          </label>
                        ) : (<div></div>)}
                      </div>
                    </div>
                  )}

                <h4 className='form__label-name'>{i18n.t('other')}</h4>
                <textarea type='text'
                  name='other'
                  rows='5'
                  cols='5'
                  className='form__field form__textarea'
                  value={this.state.guestInfo.other}
                  onChange={this.updateField} />
                {this.state.invitationForm.civil_marriage_attend? <div>  <h4 className='form__label-name'>{this.state.invitationForm.civil_marriage_message}</h4>
                <label className='form__checkbox-container'>Da
                            <input type="checkbox" checked={this.state.guestInfo.civil_marriage_attend} name="civil_marriage_attend" onChange={() => this.updateUmanistAttendance()} />
                            <span className="form__checkmark"></span>
                            </label></div>:''}



                <div className='form__bottom'>
                  <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug), search: queryString.stringify(this.state.params) }} className='standard__link'> <img src='/images/back.png' alt='back' /></Link>
                  <button type='submit'>{i18n.t('send')}</button>

                  <add-to-calendar-button
                    name={`Wedding ${this.state.selectedCouple.name}`}
                    description={ `Ne bucuram sa fiti alaturi de noi!\n\n Mai multe informatii despre eveniment puteti gasi pe www.facemnunta.ro/${this.state.selectedCouple.slug}` }
                    startDate={this.state.selectedCouple.date}
                    timeZone="Europe/Bucharest"
                    location={`${this.state.selectedCouple.location}, ${this.state.selectedCouple.city}`}
                    options="'Apple','Google','Outlook.com','Yahoo'"
                    hideBackground
                    size="3"
                    label="Adauga in Calendar"
                    hideBranding="true"
                  ></add-to-calendar-button>
                </div>
              </form>
            </div>
          </div>
          <div className='standard__footer'>
            <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie', search: queryString.stringify(this.state.params) }} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('positiveForm')}</h4></Link>
            <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ', search: queryString.stringify(this.state.params) }} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('negativeForm')}</h4></Link>
            <div className='standard__disclaimer'><h5>{this.state.websiteDetails.footer_disclaimer ? this.state.websiteDetails.footer_disclaimer : ''}</h5>
              <h5> {this.state.websiteDetails.footer_text ? this.state.websiteDetails.footer_text : i18n.t('chosenText')} </h5>
            </div>
            <div className='standard__footer-box'>
              <a className='standard__link'
                href="https://prometeus-tech.com/"
                target="_blank"
                rel="noopener noreferrer">
                <h5 className="footer__copyright">© Prometeus Tech 2024</h5></a>
              <a className='standard__link'
                href="https://www.facebook.com/CosminaHatmanu"
                target="_blank"
                rel="noopener noreferrer">
                <h5 className="footer__copyright">Designed by Cosmina Hatmanu</h5></a>
            </div>
          </div>
          {this.state.websiteDetails.registry_list_activation ?
            <div className='providers__contact-us homepage__gift-message'>
              <img src='/images/blue-gift.png' alt='gift' />
              <h5>{i18n.t('registryText')}</h5>
              <Link onClick={this.scrollToTop} className='standard__link' to={{ pathname: `/${encodeURIComponent(this.state.selectedCouple.slug)}/registru-de-cadouri`, search: queryString.stringify(this.state.params) }}>  <button >{i18n.t('toRegistry')}</button></Link>
            </div> : '-'}

          <NotificationSystem
            ref={this.notificationSystem}
            style={NotificationSystemStyle}
          />
        </div>
      </LoadingOverlay>
    )
  }
}

export default withTranslation()(InvitationForm);;
