import React from 'react';
import './GiftRegistry.scss';
import MetaTags from 'react-meta-tags';
import { registryService } from '../../services/RegistryService'
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { eventService } from '../../services/EventService';
import { websiteDetailsService } from '../../services/WebsiteDetailsService';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import ReserveGift from "../../components/ReserveGift/ReserveGift";
import queryString from 'query-string';

class GiftRegistry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gifts: [],
            selectedGifts: {},
            isGiftModalOpen: false,
            events: [],
            currentEvent: {},
            websiteDetails: {},
            selectedCouple: {},
            pagination:{},
            invitationForm: {},
            params: {},
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        let parameters = this.props.location.pathname;
        let selectedCouple = { ...this.state.selectedCouple };
        const firstSegment = parameters.split("/")[1];

        let existingParams = { ...this.state.params };
        let params = queryString.parse(this.props.location.search);
        existingParams = params;
        this.setState({ params: existingParams });

        websiteDetailsService.getInvitationBySlug(firstSegment)
        .then(response => {
          let selectedCouple = { ...this.state.selectedCouple };

          selectedCouple = { ...response.data.event };

          if (response.data.event.invitation_forms.length) {
            let invitationForm = { ...this.state.invitationForm };

            invitationForm = { ...response.data.event.invitation_forms[0] };

            this.setState({ invitationForm });
          }

          let websiteDetails = { ...this.state.websiteDetails };
          websiteDetails = response.data.event.website_details[0];
          this.setState({ websiteDetails });


          this.setState({ selectedCouple });
          this.setState({ submittedStarted: false });

        })
        .catch(error => {
          this.setState({ submittedStarted: false });
        })

        eventService.getEvents().then(response => {
            let events = this.state.events;
            events = response.data.events;
            let currentEvent = { ...this.state.currentEvent };
            currentEvent = { ...events[0] };

            this.setState({ events });
            this.setState({ currentEvent }, () => {
                registryService.searchGifts(this.state.currentEvent.id).then(response => {
                    this.setPaginationState(response)

                })
                    .catch(error => {
                        console.log(error)
                    })
            })
        })
            .catch(error => {
                console.log(error)
            });
    }

    selectGift = (giftIdValue, openModal) => {
        let selectedGifts = { ...this.state.selectedGifts };
        let gifts = this.state.gifts;
        selectedGifts = gifts.find(gift => gift.id === giftIdValue)
        this.setState({ selectedGifts }, () => {
          if (openModal) {
            this.setState({ isGiftModalOpen: true })
          }
        })
    }
    openGiftModal = (gift) => {
      if (gift) {
        this.selectGift(gift.id, true);
      } else {
        this.setState({ isGiftModalOpen: true })
      }
    }
    closeModal = () => {
        this.setState({ isGiftModalOpen: false })
    }
    changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        let language = this.state.language;
        language = lng;
        this.setState({ language })
    }


    handlePageClick = selectedPage => {
        registryService
            .searchGifts(this.state.currentEvent.id, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let gifts = this.state.gifts;
        gifts = response.data.presents;
        this.setState({ gifts });
    };

    render() {
        const settings = {
            infinite: true,
            speed: 200,
            swipe: true,
            swipeToSlide: true,
            adaptiveHeight: true,
            focusOnSelect: true,
            centeredMode: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            autoplay: false
        };
        return (
            <div className='standard landing providers gifts'>

                <MetaTags>
                    <title>Facem Nunta</title>
                    <meta
                        name="description"
                        content="Facem Nunta este platforma online de organizare si planificare destinata mirilor si mireselor| Pentru o nunta asa cum iti doresti, fara stres sau compromisuri."
                    />
                    <meta
                        name="keywords"
                        content="facem nunta,parteneri, furnizori, fotograf, salon, muzica, dj, formatie, restaurant, nunta,cununie civila, organizare nunta, planificare nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta, casatorie, acte casatorie"
                    />
                    <meta
                        property="og:title"
                        content="Facem Nunta - Parteneri"
                    />
                    <meta
                        property="og:url"
                        content="https://www.facemnunta.ro/app/parteneri"
                    />
                </MetaTags>
                <div className='homepage__header form__header'>
                    <div>
                        <Link to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug), search: queryString.stringify(this.state.params)}} className='standard__link'>
                        <h1 className='homepage__names'>{this.state.selectedCouple.name ? this.state.selectedCouple.name : i18n.t('brideAndGroom')}</h1>
                        </Link>
                        <h3 className='homepage__date'>
                            {this.state.selectedCouple.date ? <Moment locale="ro" format="dddd">{this.state.selectedCouple.date}</Moment> : 'Zi Eveniment'} | {this.state.selectedCouple.date ? <Moment locale="ro" format="DD MMMM YYYY">{this.state.selectedCouple.date}</Moment> : 'Data Eveniment'} | {this.state.selectedCouple.city || 'Oras eveniment'}
                        </h3>

                    </div>
                    <div className='homepage__header-info'>
                        <img className='standard__only-mobile' src='/images/gallery.svg' alt='gallery' />
                    </div>
                    <div className="homepage__flags">
                        <img src='/images/ro-flag.png' alt='romanian' onClick={() => this.changeLanguage('ro')} />
                        <img src='/images/uk-flag.png' alt='english' onClick={() => this.changeLanguage('en')} />
                    </div>
                </div>
                <div className='form__banner'>
                    <img src='/images/flower-1.png' alt='flower' className='standard__only-desktop form__banner-icon' />
                    <img className='form__banner-img' src={(this.state.invitationForm.welcome_image && this.state.invitationForm.welcome_image.url ? `${process.env.REACT_APP_API_URL}${this.state.invitationForm.welcome_image.url}` : '/images/nefinalizat.png')} alt='us' />
                    <h3>{this.state.invitationForm.welcome_text ? this.state.invitationForm.welcome_text : 'Mesaj de intampinare'}</h3>
                    <img src='/images/flower-3.png' alt='flower' className='standard__only-desktop form__banner-icon-2' />
                </div>
                <div className="providers__wrapper">
                    <h2 className="providers__title">{i18n.t('registryTitle')}</h2>
                    <div className="providers__line" />
                    <div className="providers__top">
                        <img src="/images/gift-pic.jpg" alt="gifts" className="providers__partners-icon" />
                        <h4>{i18n.t('registryText')}
                        </h4>
                    </div>
                    <div className="standard__only-mobile">
                        <div className="providers__container">

                            {this.state.gifts.length && this.state.gifts.map(gift => (
                                <div className={gift.reserved? "providers__mobile-provider gifts__reserved":"providers__mobile-provider"}>
                                    <div>
                                        <div className="providers__top-provider">
                                            <img src={gift.image.url ? `${process.env.REACT_APP_API_URL}${gift.image.url}` : '/images/gift-pic.jpg'} alt="provider" className="providers__main-img" />
                                            <img src='/images/white-shadow.png' alt='shadow' className="providers__shadow" />
                                           {gift.reserved? <div className='gifts__reserved-banner'>{i18n.t('reservation')}</div>:''}
                                        </div>
                                        <h4 className="providers__provider-name">{gift.name}</h4>

                                    </div>
                                    <div className="providers__more-info">
                                        <div className="providers__details">
                                            <h5 className="providers__name-info"><b>{gift.description}</b></h5>

                                        </div>
                                        {gift.url ? <div className="providers__details">
                                            <h5 className="providers__name-info"><b>{i18n.t('giftUrl')}</b></h5>
                                            <a rel="noopener noreferrer nofollow" className="standard__link" href={gift.url} target="_blank"> <h5 className="providers__specific-detail providers__blue">{i18n.t('linkCadou')}</h5></a>

                                        </div> : ''}
                                        {gift.similar ? <div className="providers__details">
                                            <h5 className="providers__name-info"><b>{i18n.t('similar')}</b></h5>
                                        </div> : ''}
                                    </div>
                                    <button disabled={gift.reserved} onClick={()=>this.openGiftModal(gift)} className='gifts__reserve'>{i18n.t('reserve')}</button>
                                </div>
                            ))}

                        </div>
                    </div>

                    <div className="standard__only-desktop">
                        <div className="providers__container">
                            <div className="providers__providers-type gifts__desktop-container">
                                {this.state.gifts.length ? this.state.gifts.map(gift => (
                                    <div onClick={() => this.selectGift(gift.id)} className={this.state.selectedGifts.id === gift.id ? "providers__full gifts__gift-box gifts__more-room" : "providers__full gifts__gift-box" } >
                                        <div className="providers__top-provider">
                                            <img src={gift.image.url ? `${process.env.REACT_APP_API_URL}${gift.image.url}` : '/images/gift-pic.jpg'} alt="gift" className="providers__main-img" />
                                            <h4 className="providers__provider-name">{gift.name}</h4>
                                            <img src='/images/white-shadow.png' alt='shadow' className="providers__shadow" />
                                            {gift.reserved? <div className='gifts__reserved-banner'>{i18n.t('reservation')}</div>:''}
                                            <button disabled={gift.reserved} className='gifts__reserve' onClick={()=>this.openGiftModal()}>{i18n.t('reserve')}</button>
                                        </div>
                                        <div className="providers__container-more-info">
                                            {this.state.selectedGifts.id === gift.id ? <div className="providers__more-info gifts__more-info">
                                                <h2>{gift.name}</h2>
                                                <div className="providers__details">
                                                    <h5 className="providers__specific-detail  providers__description">{this.state.selectedGifts.description}</h5>
                                                </div>
                                                {gift.url ? <div className="providers__details">
                                                    <h5><b>{i18n.t('giftUrl')} </b></h5>
                                                    <a rel="noopener noreferrer nofollow" className="standard__link" href={gift.url} target="_blank"> <h5 className="providers__specific-detail providers__blue">{i18n.t('linkCadou')}</h5></a>
                                                </div> : ''}
                                                {gift.similar ? <div className="providers__details">
                                                    <h5 className="providers__specific-detail providers__blue">{i18n.t('similar')} </h5>
                                                </div> : ''}
                                            </div> : ''}

                                        </div>
                                     {gift.reserved?  <img src='/images/white-shadow.png' alt='shadow' className="gifts__shadow" />:''}
                                    </div>
                                )): (
                                  <div className="providers__top">
                                    <h4>{i18n.t('noPresentsText')}</h4>
                                  </div>
                                )}


                            </div>


                        </div>
                    </div>
                </div>
                {this.state.pagination && this.state.pagination.last_page > 1 && (
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pagination.last_page}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        )}
                <div className='standard__footer'>
                    <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie', search: queryString.stringify(this.state.params)}} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('positiveForm')}</h4></Link>
                    <Link onClick={this.scrollToTop} to={{ pathname: '/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ', search: queryString.stringify(this.state.params)}} className='standard__link'><h4 className='standard__menu-item'>{i18n.t('negativeForm')}</h4></Link>
                    <div className='standard__disclaimer'><h5>{this.state.websiteDetails.footer_disclaimer ? this.state.websiteDetails.footer_disclaimer : i18n.t('chosenText')}</h5>
                        <h5> {this.state.websiteDetails.footer_text ? this.state.websiteDetails.footer_text : i18n.t('chosenText')} </h5>
                    </div>
                    <div className='standard__footer-box'>
                        <a className='standard__link'
                            href="https://prometeus-tech.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <h5 className="footer__copyright">© Prometeus Tech 2023</h5></a>
                        <a className='standard__link'
                            href="https://www.facebook.com/CosminaHatmanu"
                            target="_blank"
                            rel="noopener noreferrer">
                            <h5 className="footer__copyright">Designed by Cosmina Hatmanu</h5></a>
                    </div>
                </div>


                {this.state.isGiftModalOpen && <ReserveGift
                        modalIsOpen={this.state.isGiftModalOpen}
                        closeModal={this.closeModal}
                        gift={this.state.selectedGifts}
                        eventId={this.state.currentEvent.id}
                    />}
                     <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}
export default withTranslation()(GiftRegistry);
