import React from 'react';
import '../../resources/main.scss';
import './SuccessModal.scss';
import Modal from 'react-modal';


const customStyles = {
    overlay: {
      zIndex: 12,
      backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      height: 'auto',
    }
  };

class GuestModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            guestInfo:this.props.guest
        }
    }

    render(){
        return(
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
            <div className='standard success'>
                <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal}/>
                <div className='success__container'>
                    <img src='/images/logo.png' alt='logo' className='success__logo'/>
                    <h3 className='success__title'>Mulțumim pentru precomandă!</h3>
                    <h4 className='success__text'>Echipa noastră te va contacta în cel mai scurt timp.</h4>
                </div>
            </div>
      </Modal>
            </div>
        )
    }
}

export default GuestModal;
