import axios from 'axios';
import { loginService } from './LoginService';

export const seatsService = {
  addGuestToSeat,
  removeGuestFromSeat
}


  function addGuestToSeat(seatId, guestId, tableId, guestCompanyNumber) {
    let url = `${process.env.REACT_APP_API_URL}/api/seats/add_guest/${seatId}`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data:{
          seat: {
            guest_id: guestId,
            company_number: guestCompanyNumber,
            table_id: tableId
          },
        }
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
  }

  function removeGuestFromSeat(seatId) {
    let url = `${process.env.REACT_APP_API_URL}/api/seats/remove_guest/${seatId}`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
  }
