import React from 'react';

import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import { providerService } from '../../../services/ProviderService'
import AddProvider from '../../../components/AddProvider/AddProvider'
import SupremeMenu from "../../../components/SupremeMenu/SupremeMenu";
import './Providers.scss';

class Providers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            providerInfo: {

            },
            providers: [],
            pagination: {},
            isAddProviderModalOpen: false,
            selectedProvider: {},
            deleteProvider: {
                openedDialog: false,
                deleteId: 0
            },

        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        providerService.getProviders().then(response => {
            let providers = this.state.providers;
            providers = response.data.providers;
            this.setState({ providers });
            let pagination = this.state.pagination;
            pagination = response.data.pagination;

            this.setState({ pagination })
            console.log(providers)
        })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }
    openAddProviderModal = currentProvider => {
        let selectedProvider = { ...this.state.selectedProvider };
        selectedProvider = currentProvider;
        let isAddProviderModalOpen = this.state.isAddProviderModalOpen;
        isAddProviderModalOpen = true;
        console.log('cluent')
        this.setState({ selectedProvider })
        this.setState({ isAddProviderModalOpen })
    }
    closeModal = () => {
        this.setState({ isAddProviderModalOpen: false });


    }
    handlePageClick = selectedPage => {
        providerService
            .getProviders(selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let providers = this.state.providers;
        providers = response.data.providers;

        this.setState({ providers });
    };
    updateField = event => {
        // console.log('dfed')
        // const field = event.target.name;
        // const providerInfo = { ...this.state.providerInfo };
        // providerInfo[field] = event.target.value;
        // this.setState({
        //     providerInfo
        // });
    };


    openDeleteDialog = id => {
        let deleteProvider = { ...this.state.deleteProvider };
        deleteProvider = {
            openedDialog: true,
            deleteId: id
        };

        this.setState({ deleteProvider });
    };

    closeDeleteDialog = () => {
        let deleteProvider = { ...this.state.deleteProvider };
        deleteProvider = {
            openedDialog: false,
            deleteId: 0
        };

        this.setState({ deleteProvider });
    };

    handleDelete = () => {
        const notification = this.notificationSystem.current;
        providerService
            .deleteProvider(this.state.deleteProvider.deleteId)
            .then(() => {
                let providers = this.state.providers.slice();
                providers = providers.filter(
                    provider => provider.id !== this.state.deleteProvider.deleteId
                );
                this.setState({ providers });

                this.closeDeleteDialog();

                notification.addNotification({
                    message: 'Furnizorul a fost șters cu success.',
                    level: 'success',
                    position: 'tc'
                });
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    };

    render() {
        return (
            <div className='standard supreme  website-form guests provider'>
                <SupremeMenu menuItem="furnizori" />
                <div className="supreme__container">
                    <div className="invitation__top-desktop">
                        <h2 className="supreme__title">Furnizori</h2>
                        <div className="invitation__top-right">
                            <button className="supreme__add-client" onClick={() => this.openAddProviderModal()}>Adaugă furnizor</button>
                        </div>
                    </div>
                    <div className="provider__full-wrapper">
                    <div className="website-form__wedding-contacts provider__providers-wrapper">
                        <div className="website-form__contact-head">
                            <h4>Logo</h4>
                            <h4>Tip</h4>
                            <h4>Nume</h4>
                            <h4>Persoana de contact</h4>
                            <h4>Telefon</h4>
                            <h4>Email</h4>
                            <h4>Adresa</h4>
                            <h4 >Website</h4>
                            <h4 >Descriere</h4>
                            <h4 >Cod discount</h4>
                            <h4>Acțiuni</h4>
                        </div>
                        {this.state.providers.map(provider => (
                            <div className="website-form__contact-row">
                                <div className="website-form__cell"> {provider.logo.url? <img src={`${process.env.REACT_APP_API_URL}${provider.logo.url}`} className="website-form__cell supreme__logo-icon" alt="logo"/> : 
                                <img src="/images/logo.png"className="website-form__cell supreme__logo-icon" alt="logo"/>}
                                </div>
                                <h4 className='website-form__cell'>{provider.provider_type}</h4>
                                <h4 className='website-form__cell'>{provider.name}</h4>
                                <h4 className='website-form__cell'>{provider.contact_person?provider.contact_person:'-' }</h4>
                                <h4 className='website-form__cell'>{provider.phone}</h4>
                                <h4 className='website-form__cell'>{provider.email}</h4>
                                <h4 className='website-form__cell'>{provider.address? provider.address :'-'}</h4>
                                <h4 className='website-form__cell'>{provider.website_url}</h4>
                                <div className='website-form__cell provider__description-box'>
                                    <h4 className="provider__description" >{provider.description}</h4></div>
                                <h4 className='website-form__cell '>{provider.discount_code}</h4>
                                <div className="website-form__actions website-form__cell">
                                    <img alt="edit" onClick={() => this.openAddProviderModal(provider)} src='/images/edit-black.png' />
                                    <img alt="delete" src="/images/delete-orange.png" onClick={() => this.openDeleteDialog(provider.id)} />
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
                    
                    {this.state.pagination && this.state.pagination.last_page > 1 && (
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pagination.last_page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                )} </div>

                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
                {this.state.isAddProviderModalOpen && <AddProvider
                    modalIsOpen={this.state.isAddProviderModalOpen}
                    closeModal={this.closeModal}
                    provider={this.state.selectedProvider}
                />}

                <DeletePUGCertificates
                    opened={this.state.deleteProvider.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur ca doriti sa ștergeți acest furnizor?"
                    aditionalText="După ștergere, furnizorul nu va mai putea fi recuperat."
                />

            </div>
        )
    }
}

export default Providers;
