import axios from 'axios';
import { loginService } from './LoginService';

export const commentService = {
    postComment,
    editComment,
    deleteComment,
    getThread,
    upvoteComment,
    downvoteComment,
    unvoteComment
}
function getThread(threadId){
    let url = `${process.env.REACT_APP_API_URL}/api/commontator/threads/${threadId}`;

    const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] = loginService.getToken();
    }
  
    return axios(requestOptions);
}

function postComment(body, threadId) {
    let url = `${process.env.REACT_APP_API_URL}/api/commontator/threads/${threadId}/comments`;
    const requestOptionsPost = {
        method: 'POST',
        url,
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        data: {
            body: body
        }
    };
    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function editComment(body, commentId) {
    let url = `${process.env.REACT_APP_API_URL}/api/commontator/comments/${commentId}`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        data: {
            body: body
        }
    };
    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function deleteComment(commentId) {
    let url = `${process.env.REACT_APP_API_URL}/api/commontator/comments/${commentId}/delete`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        }
    };

    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function upvoteComment(commentId) {

    let url = `${process.env.REACT_APP_API_URL}/api/commontator/comments/${commentId}/upvote`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        }
    };
    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function downvoteComment(commentId) {
    let url = `${process.env.REACT_APP_API_URL}/api/commontator/comments/${commentId}/downvote`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        }
    };
    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function unvoteComment(commentId) {
    let url = `${process.env.REACT_APP_API_URL}/api/commontator/comments/${commentId}/unvote`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        }
    };
    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}