import axios from 'axios';
import { loginService } from './LoginService';

export const websiteContactsService={
    createOrUpdateContact,
    deleteContact
}

 function createOrUpdateContact(contactData){
     let url = `${process.env.REACT_APP_API_URL}/api/wedding_contacts${contactData.id ? `/${contactData.id}` : ''}`;
     const requestOptionsPost = {
         method: contactData.id? 'PUT': 'POST',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         },
         data:buildRequestData(contactData)
       };
       if (loginService.loggedIn()) {
         requestOptionsPost.headers['Authorization'] = loginService.getToken();
       }

       return axios(requestOptionsPost);
 }

 function deleteContact(contactId){
     const requestOptionsDelete = {
         headers: {
           Accept: 'application/json'
         }
       };

       if (loginService.loggedIn()) {
         requestOptionsDelete.headers['Authorization'] =loginService.getToken();
       }
       let url = `${process.env.REACT_APP_API_URL}/api/wedding_contacts/${contactId}`;

       return axios.delete(url, requestOptionsDelete);
 }
 function buildRequestData(contactData) {
  let formData = new FormData();

  if (contactData.id) {
    formData.append('wedding_contact[id]', contactData.id);
  }
  formData.append('wedding_contact[name]', contactData.name);
  formData.append('wedding_contact[phone]', contactData.phone);
  formData.append('wedding_contact[consent]', contactData.consent);
  formData.append('wedding_contact[website_detail_id]', contactData.website_detail_id);

  return formData;
}
