import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        height: '50%',
    }
};

class ViewTableModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            table: this.props.table
        }
    }
    componentDidMount() {
        console.log(this.state.table)
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
                    <div className='standard guest-modal'>
                        <img src='/images/close.png' className='guest-modal__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h4 className='guest-modal__title'>Masa nr. {this.state.table.number}</h4>
                        <h4 className='guest-modal__title'>{this.state.table.name}</h4>
                        <div className='guest-modal__info-container guest-modal__table-container'>
                          { this.state.table.seats.length && this.state.table.seats.map(seat => (
                            seat.guest_id ? (
                              <div className="guest-modal__guest-card">
                                  <img src="/images/drag.png" alt="guest" />
                                  <h4 >{seat.guest.name}</h4>
                              </div>
                            ) : (null)
                          )) }
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

export default ViewTableModal;
