import axios from 'axios';

export const preorderService={
    createAccount
}
function createAccount(userData){

     let url = `${process.env.REACT_APP_API_URL}/api/preregister_emails`;
     const requestOptionsPost = {
         method: 'POST',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         },
         data: {
           preregister: {
             ...userData,
             event_date: userData.weddingDate
           }
         }
       };

       return axios(requestOptionsPost);
 }
