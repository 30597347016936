import React from 'react';
import './SupremeAdminPage.scss';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import { supremeAdminService } from '../../../services/SupremeAdminService'
import AddClient from '../../../components/AddClient/AddClient'
import SupremeMenu from "../../../components/SupremeMenu/SupremeMenu";
import { Link } from 'react-router-dom'

class SupremeAdminPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clientInfo: {

            },
            clients: [],
            pagination: {},
            isAddClientModalOpen: false,
            selectedClient: {},
            deleteClient: {
                openedDialog: false,
                deleteId: 0
            },

        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        supremeAdminService.getClients().then(response => {
            let clients = this.state.clients;
            clients = response.data.clients;
            this.setState({ clients });
            let pagination = this.state.pagination;
            pagination = response.data.pagination;

            this.setState({ pagination })
            console.log(clients)
        })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }
    openAddClientModal = currentClient => {
        let selectedClient = { ...this.state.selectedClient };
        selectedClient = currentClient;
        let isAddClientModalOpen = this.state.isAddClientModalOpen;
        isAddClientModalOpen = true;
        this.setState({ selectedClient })
        this.setState({ isAddClientModalOpen })
    }
    closeModal = () => {
        this.setState({ isAddClientModalOpen: false });


    }
    handlePageClick = selectedPage => {
        supremeAdminService
            .getClients(selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let clients = this.state.clients;
        clients = response.data.clients;

        this.setState({ clients });
    };
    updateField = event => {
        // console.log('dfed')
        // const field = event.target.name;
        // const clientInfo = { ...this.state.clientInfo };
        // clientInfo[field] = event.target.value;
        // this.setState({
        //     clientInfo
        // });
    };


    openDeleteDialog = id => {
        let deleteClient = { ...this.state.deleteClient };
        deleteClient = {
            openedDialog: true,
            deleteId: id
        };

        this.setState({ deleteClient });
    };

    closeDeleteDialog = () => {
        let deleteClient = { ...this.state.deleteClient };
        deleteClient = {
            openedDialog: false,
            deleteId: 0
        };

        this.setState({ deleteClient });
    };
    checkStatus = value => {
        if (value === "new") {
            return 'supreme__circle yellow'
        }
        if (value === "paid") {
            return 'supreme__circle green'
        }
        else {
            return 'supreme__circle red'
        }
    }
    checkStatusName = value => {
        if (value === "new") {
            return 'Nou'
        }
        if (value === "paid") {
            return 'Platit'
        }
        else {
            return 'Neplatit'
        }
    }

    handleDelete = () => {
        const notification = this.notificationSystem.current;
        supremeAdminService
            .deleteClient(this.state.deleteClient.deleteId)
            .then(() => {
                let clients = this.state.clients.slice();
                clients = clients.filter(
                    client => client.id !== this.state.deleteClient.deleteId
                );
                this.setState({ clients });

                this.closeDeleteDialog();

                notification.addNotification({
                    message: 'Clientul a fost șters cu success.',
                    level: 'success',
                    position: 'tc'
                });
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    };

    render() {
        return (
            <div className='standard supreme  website-form  guests'>
                <SupremeMenu menuItem="clienti" />
                <div className="supreme__container">
                    <div className="invitation__top-desktop">
                        <h2 className="supreme__title">Clienți</h2>
                        <div className="invitation__top-right">
                            <button className="supreme__add-client" onClick={() => this.openAddClientModal()}>Adaugă client</button>
                        </div>
                    </div>
                    <div className="website-form__wedding-contacts">
                        <div className="website-form__contact-head">
                            <h4>Nume</h4>
                            <h4 >Email</h4>
                            <h4>Status plata</h4>
                            <h4>Acțiuni</h4>

                        </div>
                        {this.state.clients.map(client => (
                            <div className="website-form__contact-row">
                                <h4 className='website-form__cell'>{client.name}</h4>
                                <h4 className='website-form__cell events__event-name'>{client.email}</h4>
                                <div className='website-form__cell supreme__color-box'>
                                    <div className={this.checkStatus(client.status)} />
                                    <h4>{this.checkStatusName(client.status)}</h4>
                                </div>
                                <div className="website-form__actions website-form__cell">
                                    <img alt="edit" onClick={() => this.openAddClientModal(client)} src='/images/edit-black.png' />
                                    <img alt="delete" src="/images/delete-orange.png" onClick={() => this.openDeleteDialog(client.id)} />
                                    <Link className="standard__link events__edit" to={`/app/client/${client.id}`}> <img alt="see" src="/images/eye.png" /></Link>
                                </div>
                            </div>
                        ))}
                    </div>  {this.state.pagination && this.state.pagination.last_page > 1 && (
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pagination.last_page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                )}</div>

                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
                {this.state.isAddClientModalOpen && <AddClient
                    modalIsOpen={this.state.isAddClientModalOpen}
                    closeModal={this.closeModal}
                    client={this.state.selectedClient}
                />}

                <DeletePUGCertificates
                    opened={this.state.deleteClient.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur ca doriti sa ștergeți acest client?"
                    aditionalText="După ștergere, clientul nu va mai putea fi recuperat."
                />

            </div>
        )
    }
}

export default SupremeAdminPage;
