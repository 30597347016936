import React from 'react';
import { eventService } from '../../../services/EventService';
import './Dashboard.scss';
import Menu from '../../../components/AdminMenu/AdminMenu';
import '../Admin.scss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ParticipantsDashboard from '../../../components/ParticipantsDashboard/ParticipantsDashboard';
import MenusDashboard from '../../../components/MenusDashboard/MenusDashboard';
import InvitationsDashboard from '../../../components/InvitationsDashboard/InvitationsDashboard';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import { clientsService } from '../../../services/ClientsService';
import moment from "moment";
import { Link } from 'react-router-dom';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      currentEvent: {},
      tabValue: 0,
      activeDemo: false,
      freeDays: 0,
      freeHours: 0,
      expiredAccount: false,
      idLocalStorage:''
    }
    this.notificationSystem = React.createRef();

  }

  componentDidMount() {
    const notification = this.notificationSystem.current;
    this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })
    clientsService.checkClient()
      .then(response => {
          if (response.data.active_demo === false) {
            this.setState({ expiredAccount: true });

            notification.addNotification({
                message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                level: 'error',
                position: 'tc'
            });

            setTimeout(() => {
              this.props.history.push("/app/profil");
            }, 3000);

            return;
          }

          if (response.data.active_demo) {
            this.setState({ activeDemo: true });
            const freeDays = moment(response.data.free_until).diff(moment(), 'days');
            const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

            this.setState({ freeDays });

            if (freeHours > 0) {
              this.setState({ freeHours });
            }
          }

          eventService.getEvents().then(response => {
            let events = this.state.events;
            events = response.data.events;
            let currentEvent = { ...this.state.currentEvent };
            let specificEvent= response.data.events.find(event => event.id === this.state.idLocalStorage);

            currentEvent = specificEvent ? specificEvent : { ...events[0] };


            this.setState({ events });
            this.setState({ currentEvent });
          })
            .catch(error => {
              if (error.response.status === 401) {
                this.props.history.push("/app/login");
              }
            })

      })
      .catch(error => {
          if (error.response.status === 401) {
              this.props.history.push("/app/login");
          }
      })
  }

  updateTabValue = (event, value) => {
    this.setState({ tabValue: value });
  }
  updateEventId=event=>{
    const eventId = event.target.value
    localStorage.setItem('idLocalStorage', eventId)
    let idLocalStorage=this.state.idLocalStorage;
    idLocalStorage=eventId;
    this.setState({idLocalStorage}, () => window.location.reload())
}
  render() {
    return (
      <div className={ this.state.expiredAccount ? 'standard__no-interaction standard guests dashboard admin' : 'standard guests dashboard admin'}>
        <Menu menuItem="dashboard"/>
        <AdminMobileHeader/>
        <div className='admin__container'>
          <div className='admin__top'>
            <div className='admin__select-box'>
              <select
                className="admin__select"
                title="tip"
                value={this.state.idLocalStorage}
                name="type"
                onChange={this.updateEventId}
                label='tip'
              >
                {this.state.events.map(event => (
                  <option key={event.id} value={event.id}>{event.name}</option>
                ))}
              </select>
            </div>
            <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
          </div>
          { this.state.activeDemo ? (
            <div className="admin__no-something">
                <img src="/images/info.png" alt="info" />
                <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti { this.state.freeDays } zile si { this.state.freeHours } ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
            </div>
          ) : null }
          <div className='admin__wrapper'>
            <div className='guests__top-search'>
              <h2 className='admin__title'>Dashboard</h2>
            </div>
            <div className="guests__full-wrapper">
              <Tabs
                value={this.state.tabValue}
                onChange={this.updateTabValue}
                variant="scrollable"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "rgba(230, 99, 0, 0.4)",
                    width: '50px',
                    'border-top-left-radius': '12px',
                    'border-top-right-radius': '12px',
                    marginLeft: '4%',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    height: '8px'
                  }
                }}
                scrollButtons="on"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  key={1}
                  label="Invitatii Eveniment"
                  id={`scrollable-force-tab-1`}
                  aria-controls={`scrollable-force-tabpanel-1`}
                  selected={true}
                />
                <Tab
                  key={2}
                  label="Participanti Eveniment"
                  id={`scrollable-force-tab-2`}
                  aria-controls={`scrollable-force-tabpanel-2`}
                  selected={true}
                />
                <Tab
                  key={3}
                  label="Meniuri Eveniment"
                  id={`scrollable-force-tab-3`}
                  aria-controls={`scrollable-force-tabpanel-3`}
                  selected={true}
                />
              </Tabs>
              <div
                role="tabpanel"
                value={this.state.tabValue}
                hidden={this.state.tabValue !== 0}
                id={`scrollable-force-tabpanel-${0}`}
                aria-labelledby={`scrollable-force-tab-${0}`}
                name="statistics">
                {this.state.tabValue === 0 && (
                  this.state.currentEvent.id ? (
                    <InvitationsDashboard currentEventId={this.state.currentEvent.id} />
                  ) : ''
                )}
              </div>

              <div
                role="tabpanel"
                value={this.state.tabValue}
                hidden={this.state.tabValue !== 1}
                id={`scrollable-force-tabpanel-${1}`}
                aria-labelledby={`scrollable-force-tab-${1}`}
                className="test">
                {this.state.tabValue === 1 && (
                  <ParticipantsDashboard currentEventId={this.state.currentEvent.id} />
                )}
              </div>
              <div
                role="tabpanel"
                value={this.state.tabValue}
                hidden={this.state.tabValue !== 2}
                id={`scrollable-force-tabpanel-${2}`}
                aria-labelledby={`scrollable-force-tab-${2}`}
                className="test">
                {this.state.tabValue === 2 && (
                  <MenusDashboard currentEventId={this.state.currentEvent.id} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
