import React from 'react';
import './Gallery.scss';
import {Link} from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import couplesJson from '../Homepage/couples.json';

class Gallery extends React.Component{
    constructor(props){
        super(props)
        this.state={
            password:'',
            tabValue:0,
            couples:couplesJson,
            selectedCouple:{}
        }
    }
    componentDidMount() {
        let selectedCouple={...this.state.selectedCouple};
        let parameters = this.props.location.pathname;
        const firstSegment=parameters.split("/")[1];

        selectedCouple=this.state.couples.find(couple=>couple.slug===firstSegment);
        this.setState({selectedCouple});
    }
    handleChange = (event, tabValue) => {
        this.setState({
          tabValue
        });
      };
    handleChangeIndex = index => {
      this.setState({
        tabValue: index
      });
    };
    render(){
        return(
            <div className='standard form gallery'>
                <div className='form__container'>
                <div className='homepage__header form__header'>
                        <div>
                           <Link to={'/' + encodeURIComponent(this.state.selectedCouple.slug) } className='standard__link'> <h1 className='homepage__names'>{this.state.selectedCouple.couplesName} </h1></Link>
                            <h3 className='homepage__date standard__only-desktop'>{this.state.selectedCouple.officialDate}</h3>
                            <h3 className='homepage__date standard__only-mobile'>{this.state.selectedCouple.officialDate}</h3>
                        </div>
                            <div className='homepage__header-info'>
                                <h3 className='homepage__date homepage__date-first standard__only-desktop'>25 septembrie 2021</h3>
                            </div>
                    </div>
                    <div className='gallery__tabs'>
                            <Tabs
                                value={this.state.tabValue}
                                onChange={this.handleChange}
                                name="tabValue"
                                indicatorColor="none"
                                textColor="#3A3740"
                                centered
                    >

                        <Tab label="Cununia civilă"></Tab>
                        <Tab label="Cununia religioasă și petrecere"></Tab>
                    </Tabs>
                    <SwipeableViews
                    axis="x"
                    index={this.state.tabValue}
                    onChangeIndex={this.handleChangeIndex}

                    >
                     <div className='gallery__wrapper' style={{backgroundImage: this.state.selectedCouple.galleryPhoto}}>
                                <h2 className='gallery__title'>FOTOGRAFII CUNUNIE CIVILĂ</h2>

                                <h4 className='gallery__label'>Pentru a vedea fotografiile, te rugăm să introduci parola.</h4>
                                <form className='gallery__form'>
                                    <input type='password'
                                    className='gallery__input'
                                    name='password'
                                    value={this.state.password}
                                    onChange={this.updateField}/>
                                     <button type='submit'>VIZUALIZEAZĂ</button>
                                </form>
                            </div>
                            <div className='gallery__wrapper' style={{backgroundImage: this.state.selectedCouple.galleryPhoto}}>
                                <h2 className='gallery__title'>FOTOGRAFII NUNTĂ</h2>

                                <h4 className='gallery__label'>Pentru a vedea fotografiile, te rugăm să introduci parola.</h4>
                                <form className='gallery__form'>
                                    <input type='password'
                                    className='gallery__input'
                                    name='password'
                                    value={this.state.password}
                                    onChange={this.updateField}/>
                                     <button type='submit'>VIZUALIZEAZĂ</button>
                                </form>
                            </div>
                    </SwipeableViews>
                            </div>


                </div>
                <div className='standard__footer'>
                    <Link onClick={this.scrollToTop} to={'/' + encodeURIComponent(this.state.selectedCouple.slug) }  className='standard__link'><h4 className='standard__menu-item'>Pagina cu informații</h4></Link>
                    <Link onClick={this.scrollToTop} to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie'} className='standard__link'><h4 className='standard__menu-item'>Formular pentru răspuns pozitiv</h4></Link>
                    <Link onClick={this.scrollToTop} to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ'} className='standard__link'><h4 className='standard__menu-item'>Formular pentru răspuns negativ</h4></Link>
                    <div className='standard__disclaimer'><h5>{this.state.selectedCouple.footerText1}</h5>.
                    <h5> {this.state.selectedCouple.footerText2}</h5>
                    </div>
                    <div className='standard__footer-box'>
                        <a className='standard__link'
                        href="https://prometeus-tech.com/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <h5 className="footer__copyright">© Prometeus Tech 2021</h5></a>
                        <a className='standard__link'
                        href="https://www.facebook.com/CosminaHatmanu"
                        target="_blank"
                        rel="noopener noreferrer">
                        <h5 className="footer__copyright">Designed by Cosmina Hatmanu</h5></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gallery;
