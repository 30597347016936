import React from 'react';
import Menu from '../../../components/AdminMenu/AdminMenu';
import { invitationService } from '../../../services/InvitationService';
import { eventService } from '../../../services/EventService';
import { guestsService } from '../../../services/AdminService';
import ReactPaginate from 'react-paginate';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import { clientsService } from '../../../services/ClientsService';
import moment from "moment";
import { Link } from 'react-router-dom';

class NegativeAnswers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pagination: {},
            guests: [],
            currentEvent: {},
            events: [],
            searchName: '',
            searchFieldStatus: false,
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false,
            idLocalStorage: ''
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        const notification = this.notificationSystem.current;
        this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })

        window.scrollTo({ top: 0, behavior: 'smooth' });

        clientsService.checkClient()
            .then(response => {
                if (response.data.active_demo === false) {
                    this.setState({ expiredAccount: true });

                    notification.addNotification({
                        message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                        level: 'error',
                        position: 'tc'
                    });

                    setTimeout(() => {
                        this.props.history.push("/app/profil");
                    }, 3000);

                    return;
                }

                if (response.data.active_demo) {
                    this.setState({ activeDemo: true });
                    const freeDays = moment(response.data.free_until).diff(moment(), 'days');
                    const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

                    this.setState({ freeDays });

                    if (freeHours > 0) {
                        this.setState({ freeHours });
                    }
                }

                eventService.getEvents().then(response => {
                    let events = this.state.events;
                    events = response.data.events;
                    let currentEvent = { ...this.state.currentEvent };
                    let specificEvent = response.data.events.find(event => event.id === this.state.idLocalStorage);

                    currentEvent = specificEvent ? specificEvent : { ...events[0] };
                    this.setState({ events });
                    this.setState({ currentEvent }, () => {
                        guestsService.getNonGuests(this.state.currentEvent.id).then(response => {
                            let guests = this.state.guests;
                            guests = response.data.refuzed_invitations;
                            let pagination = this.state.pagination;
                            pagination = response.data.pagination;

                            this.setState({ pagination })
                            this.setState({ guests })
                        })
                            .catch(error => {
                                if (error.response.status === 401) {
                                    this.props.history.push("/app/login");
                                }
                            })
                    })
                })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.props.history.push("/app/login");
                        }
                    })

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }
    handlePageClick = selectedPage => {
        guestsService.getNonGuests(this.state.currentEvent.id, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let guests = this.state.guests;
        guests = response.data.refuzed_invitations;

        this.setState({ guests });
    };
    updateSearchField = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    updateEventId = event => {
        const field = event.target.name;
        let currentEvent = { ...this.state.currentEvent };

        currentEvent = this.state.events.find(eventToFind => eventToFind.id == event.target.value);

        this.setState({ currentEvent }, () => {
            guestsService.getNonGuests(this.state.currentEvent.id).then(response => {
                let guests = this.state.guests;
                guests = response.data.refuzed_invitations;
                this.setState({ guests })
            })
                .catch(err => console.log(err))
        });
    }

    openSearchField = () => {
        let searchFieldStatus = this.state.searchFieldStatus;
        searchFieldStatus = true;
        this.setState({ searchFieldStatus })
    }
    closeSearchField = () => {
        let searchFieldStatus = this.state.searchFieldStatus;
        searchFieldStatus = false;
        let searchName = this.state.searchName;
        searchName = "";
        this.setState({ searchName }, () => {
            guestsService.searchNonGuest(this.state.currentEvent.id, this.state.searchName).then(response => {
                this.setPaginationState(response);
            })
                .catch(err => console.log(err))
        })

        this.setState({ searchFieldStatus })
    }

    handleSearch = event => {
        event.preventDefault()
        guestsService.searchNonGuest(this.state.currentEvent.id, this.state.searchName).then(response => {
            this.setPaginationState(response);
        })
            .catch(err => console.log(err))
    }
    updateEventId = event => {
        const eventId = event.target.value

        localStorage.setItem('idLocalStorage', eventId)
        let idLocalStorage = this.state.idLocalStorage;
        idLocalStorage = eventId;
        this.setState({ idLocalStorage }, () => window.location.reload())
    }

    render() {
        return (
            <div className={this.state.expiredAccount ? 'standard__no-interaction guests admin invitation' : 'guests admin invitation'}>
                <Menu menuItem="answers-no" />
                <AdminMobileHeader page="Raspunsuri negative" />
                <div className='admin__container'>
                    <div className='admin__top'>
                        <div className='admin__select-box'>
                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.idLocalStorage}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    {this.state.activeDemo ? (
                        <div className="admin__no-something">
                            <img src="/images/info.png" alt="info" />
                            <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti {this.state.freeDays} zile si {this.state.freeHours} ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
                        </div>
                    ) : null}
                    <div className="admin__wrapper invitation__wrapper">
                        <div className=''>
                            <div className="invitation__top-desktop only-desktop">
                                <h2 className="admin__title">Răspunsuri negative</h2>
                                <div className="invitation__top-right">
                                    {this.state.searchFieldStatus ? '' : <div className="invitation__search-button" onClick={() => this.openSearchField()}>
                                        <img src="/images/search-black.png" alt="search" className="invitation__search-img" />
                                    </div>}
                                    {this.state.searchFieldStatus ?
                                        <form className='admin__form invitation__search-form' onSubmit={this.handleSearch}>
                                            <input type='text'
                                                required
                                                name='searchName'
                                                placeholder="Cauta dupa nume..."
                                                className='invitation__search-field'
                                                value={this.state.searchName}
                                                onChange={this.updateSearchField} />
                                            <img src='/images/search-black.png' alt='search' className="invitation__search-icon" />
                                            <img src="/images/close-black.png" onClick={() => this.closeSearchField()} alt="close" className="invitation__close-search" />
                                        </form> : ''}
                                </div>
                            </div>
                            <div className="only-mobile">
                                <form className='admin__form  invitation__search-form' onSubmit={this.handleSearch}>
                                    <input type='text'
                                        required
                                        name='searchName'
                                        placeholder="Cauta dupa nume..."
                                        className='invitation__search-field'
                                        value={this.state.searchName}
                                        onChange={this.updateSearchField} />
                                    <img src='/images/search-black.png' alt='search' className="invitation__search-icon" />
                                </form>

                            </div>


                            <div className="budget__table-container">
                                <div className='guests__wrapper budget__wrapper guests__non-guests-wrapper'>
                                    <div className="website-form__contact-head">
                                        <h4>Nume invitat</h4>
                                        <h4>Cod</h4>
                                        <h4>Motiv</h4>
                                    </div>
                                    {this.state.guests.map(guest => (
                                        <div className="website-form__contact-row">
                                            <h4 className='website-form__cell'>{guest.name}</h4>
                                            <h4 className='website-form__cell'>{guest.code}</h4>
                                            <h4 className='website-form__cell'>{guest.reason ? guest.reason : '-'}</h4>

                                        </div>
                                    ))}
                                </div>

                            </div>
                            {this.state.pagination && this.state.pagination.last_page > 1 && (
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pagination.last_page}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            )}
                        </div>

                    </div>



                </div>
            </div>
        )
    }
}

export default NegativeAnswers;
