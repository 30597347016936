import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import { registryService } from "../../services/RegistryService";
import AttachementField from '../AttachementField/AttachementField';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        borderRadius: "32px"
    }
};

class ReserveGift extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gift: {
                id:this.props.gift.id,
                code:'',
                email:'',
                eventId:this.props.eventId
            }
        };

        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        console.log(this.props.gift);
        if (this.props.gift) {
            let gift = { ...this.state.gift };
            gift = {
                ...this.props.gift,
                eventId:this.props.eventId
            };

            this.setState({ gift });
        }
    }

    handleChange = event => {
        const field = event.target.name;
        const gift = { ...this.state.gift };
        gift[field] = event.target.value;
        this.setState({ gift });
    }

    handleAddGift = event => {
        event.preventDefault();
        console.log(this.state.gift)
        const notification = this.notificationSystem.current;

        registryService.reserveGift(this.state.gift)
            .then(response => {
                    notification.addNotification({
                      message:
                      i18n.t('reserved'),
                      level: 'success',
                      position: 'tc'
                    });
            })

            .catch(error => {
                notification.addNotification({
                    message:
                    i18n.t('error'),
                    level: 'error',
                    position: 'tc'
                });
            })
    }
  
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
                    <div className='standard add-modal add-guest'>
                        <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h2 className="add-modal__title">{`${i18n.t('reserveText')} ${this.props.gift.name}`}</h2>
                        <form onSubmit={this.handleAddGift}>
                            <div>
                                <h4 className='add-modal__label'>{i18n.t('guestCode')}</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="code"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.gift.code} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>{i18n.t('email')}</h4>
                                <input
                                    className='add-modal__field'
                                    type="email"
                                    name="email"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.gift.email} />
                            </div>
                            


                            <div className="add-modal__buttons">
                                <button type='submit' className='add-modal__button add-modal__submit'>{i18n.t('reserve')}</button>
                                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>{i18n.t('close')}</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default withTranslation()(ReserveGift);
