import axios from 'axios';
import { loginService } from './LoginService';

export const invitationFormService={
    getDetails,
    updateInvitationForm
}

function getDetails(eventId){
     let url = `${process.env.REACT_APP_API_URL}/api/invitation_forms/details?event_id=${eventId}`;
     const requestOptionsGet = {
         method: 'GET',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         }
       };

       if (loginService.loggedIn()) {
         requestOptionsGet.headers['Authorization'] = loginService.getToken();
       }

       return axios(requestOptionsGet);
 }

 function updateInvitationForm(invitationFormData) {
   let url = `${process.env.REACT_APP_API_URL}/api/invitation_forms/${invitationFormData.id}`;

   const requestOptionsPost = {
       method: 'PUT',
       url,
       headers: {
         Accept: 'application/json',
         'Content-type': 'application/json'
       },
       data: buildRequestData(invitationFormData)
     };

     if (loginService.loggedIn()) {
       requestOptionsPost.headers['Authorization'] = loginService.getToken();
     }

     return axios(requestOptionsPost);
 }

 function buildRequestData(invitationFormData) {
   let formData = new FormData();

   formData.append('invitation_form[welcome_text]', invitationFormData.welcomeText|| '');
   formData.append('invitation_form[refused_text]', invitationFormData.refusedText|| '');
   formData.append('invitation_form[accommodation]', invitationFormData.accommodation);
   formData.append('invitation_form[extra_services]', invitationFormData.extraServices|| '');
   formData.append('invitation_form[transport]', invitationFormData.transport);
   formData.append('invitation_form[message]', invitationFormData.message);
   formData.append('invitation_form[message_refused]', invitationFormData.messageRefused);
   formData.append('invitation_form[civil_marriage_attend]', invitationFormData.civil_marriage_attend);

   formData.append('invitation_form[civil_marriage_message]', invitationFormData.civil_marriage_message);



   const documentWelcomeImage = invitationFormData.welcomeImage;

   if (documentWelcomeImage && documentWelcomeImage.url !== null) {
     if (documentWelcomeImage.url) {
       formData.append(`invitation_form[welcome_image]`, documentWelcomeImage.url);
     } else {
       formData.append(`invitation_form[welcome_image]`, documentWelcomeImage, documentWelcomeImage.name);
     }
   }

   const documentRefusedImage = invitationFormData.refusedImage;

   if (documentRefusedImage && documentRefusedImage.url !== null) {
     if (documentRefusedImage.url) {
       formData.append(`invitation_form[refused_image]`, documentRefusedImage.url);
     } else {
       formData.append(`invitation_form[refused_image]`, documentRefusedImage, documentRefusedImage.name);
     }
   }

   return formData;
 }
