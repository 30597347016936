import React from 'react';
import './CharityPage.scss';
import MetaTags from 'react-meta-tags';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header'
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import { clientsService } from '../../services/ClientsService';


class CharityPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            videoPlaying: true,
            contactInfo: {
                name: '',
                phone:'',
                date: '',
                city:'',
                location: ''            },
        }
        this.notificationSystem = React.createRef();
    }
    updateField = event => {
        event.preventDefault()
        const field = event.target.name;
        let contactInfo = { ...this.state.contactInfo };
        contactInfo[field] = event.target.value;
        this.setState({ contactInfo })
    }
    handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        clientsService.contactFoodWaste(this.state.contactInfo).then(response => {
            notification.addNotification({
                message: 'Mesajul a fost trimis cu succes.',
                level: 'success',
                position: 'tc'
            });
        })
            .catch(err => {
                notification.addNotification({
                    message:
                        'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
                    level: 'error',
                    position: 'tc'
                });
            })
    }
    render() {

        return (
            <div className='standard landing civil charity'>
                <Header />
                <MetaTags>
                    <title>Facem Nunta</title>
                    <meta
                        name="description"
                        content="Facem Nunta este platforma online de organizare si planificare destinata mirilor si mireselor| Pentru o nunta asa cum iti doresti, fara stres sau compromisuri."
                    />
                    <meta
                        name="keywords"
                        content="facem nunta, nunta,cununie civila, legislatie, acte cununie civila,stare civila, organizare nunta, planificare nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta, casatorie, acte casatorie"
                    />
                    <meta
                        property="og:title"
                        content="Facem Nunta - Evita risipa alimentara"
                    />
                    <meta
                        property="og:url"
                        content="https://www.facemnunta.ro/app/cununie-civila"
                    />
                </MetaTags>
                <div className="civil__whole-wrapper">
                <div className='civil__container'>
                    <h1 className='civil__title'>Facem nuntă, nu risipă!</h1>
                    <h2 className="civil__introduction">Niciun cuplu nu vrea ca invitații să plece flămânzi de la nunta lor; invitații ar trebui să plece acasă, obosiți de prea mult dans, și nu la KFC sau McDonalds.</h2>
                    <h2 className="civil__introduction">Din teama asta însă, nunțile sunt printre evenimentele la care se risipesc cele mai multe cantități de mâncare din diverse motive, de la invitați care promit că vin dar nu mai ajung, de la multiplele feluri de mancare care ajung să fie neatinse.</h2>
                    <h2 className="civil__introduction">Ne dorim să abordăm această problemă și să încercăm să reducem risipa alimentară și în același timp să facem un bine celor mai puțin norocoși. Momentan operăm în zona Moldovei, însă dacă ne cereți ajutorul, vom încerca să implementăm acest sistem și în zona voastră.</h2>
                    <h2 className="civil__introduction">Cum facem asta? Ne-am gândit la câțiva pași simpli:</h2>
                    <div className="civil__steps-container">
                    <div className="civil__step  civil__step-1">
                        <div className="civil__number"><h3>1</h3></div>
                        <div className="civil__info">
                            <h3 className="civil__step-title">Ce fac mirii?</h3>
                            <div className="civil__time-box">
                                <h4>Mirii trebuie doar să își dea acordul - și să informeze restaurantul în acest sens - ca meniurile invitaților neprezenți să fie donate.</h4>
                            </div>
                         
                        </div>
                    </div>
                    <div className="civil__step civil__step-2 ">
                        <div className="civil__number"><h3>2</h3></div>
                        <div className="civil__info">
                            <h3 className="civil__step-title">FacemNuntă</h3>
                            <div className="civil__time-box">
                                <h4>Noi vă vom pune în contact cu o asociație caritabilă care are nevoie de ajutor și vom stabili cu aceasta transportul alimentelor după eveniment.</h4>
                            </div>

                        </div>
                    </div>
                    <div className="civil__step civil__step-2 civil__step-3">
                        <div className="civil__number"><h3>3</h3></div>
                        <div className="civil__info">
                            <h3 className="civil__step-title">Restaurantul</h3>
                            <div className="civil__time-box">
                                <h4>Restaurantul trebuie să împacheteze și să pună deoparte meniurile invitaților neprezenți.</h4>
                            </div>
                           
                        </div>
                    </div>

                    <div className="civil__step civil__step-2 civil__step-4">
                        <div className="civil__number"><h3>4</h3></div>
                        <div className="civil__info">
                            <h3 className="civil__step-title">Gata!</h3>
                            <div className="civil__time-box">
                                <h4>Cu foarte puțin efort din partea tuturor părților implicate am prevenit risipa alimentară și am făcut un bine în cea mai importantă zi din viața voastră.</h4>
                            </div>
                           
                        </div>
                    </div>


                    <h4 className="civil__wish">Ați făcut nuntă și ați făcut bine!</h4>
                    <div className='charity__form'>
                        <h3 className='charity__form-title'>Ești vreo parte din sistemul de mai sus și vrei să știi mai multe? Contactează-ne !</h3>
                        <div class="landing__line landing__main-line charity__line"></div>
                    <form className='charity__form' onSubmit={this.handleSubmit}>
                            <div className='charity__field-box'>
                                <h3 className='charity__label'>Nume:</h3>
                                <input
                                    className="charity__field"
                                    type="text"
                                    name="name"
                                    required
                                    onChange={this.updateField}
                                    value={this.state.contactInfo.name}
                                    placeholder="Nume si prenume*"
                                />
                            </div>
                            <div className='charity__field-box'>
                                <h3 className='charity__label'>Număr de telefon:</h3>
                                <input
                                    className="charity__field"
                                    type="number"
                                    name="phone"
                                    required
                                    onChange={this.updateField}
                                    value={this.state.contactInfo.phone}
                                    placeholder="Telefon*"
                                />
                            </div>
                            <div className='charity__field-box'>
                                <h3 className='charity__label'>Data nunții:</h3>
                                <input
                                    className="charity__field"
                                    type="date"
                                    name="date"
                                    required
                                    onChange={this.updateField}
                                    value={this.state.contactInfo.date}
                                    placeholder="Data nunții*"
                                />
                            </div>
                            <div className='charity__field-box'>
                                <h3 className='charity__label'>Oraș:</h3>
                                <input
                                    className="charity__field"
                                    type="text"
                                    name="city"
                                    required
                                    onChange={this.updateField}
                                    value={this.state.contactInfo.city}
                                    placeholder="Oraș*"
                                />
                            </div>
                            <div className='charity__field-box'>
                                <h3 className='charity__label'>Locație eveniment:</h3>
                                <input
                                    className="charity__field"
                                    type="text"
                                    name="location"
                                    required
                                    onChange={this.updateField}
                                    value={this.state.contactInfo.location}
                                    placeholder="Locație*"
                                />
                            </div>
        
                            <button className='charity__submit' type='submit'>Trimite mesajul</button>
                        </form>
                    </div>
                </div>
                </div>
                <div className="civil__img-box">   <img src='/images/couple-cartoon.png' alt="couple" className="standard__only-desktop civil__main-icon"/></div>

                </div>

                <Footer />
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default CharityPage;
