import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import { supremeAdminService } from '../../services/SupremeAdminService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'

const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        borderRadius: "32px"
    }
};

class AddTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            table: {
                name: '',
                guestNumber: '',
                number: '',
                geo_type: 'round',
            }
        };

        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        if (this.props.isEditTableMode && this.props.table) {
            let table = { ...this.state.table };
            table = {
                ...this.props.table
            };

            this.setState({ table });
        }
    }

    handleChange = event => {
        const field = event.target.name;
        const table = { ...this.state.table };
        table[field] = event.target.value;
        this.setState({ table });
    }

    handleAddTable = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        this.props.addTable(this.state.table);
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
                    <div className='standard add-modal'>
                        <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h2 className="add-modal__title">{this.state.table.id ? 'Editează masa' : 'Adaugă masa'} </h2>
                        <form onSubmit={this.handleAddTable}>
                            <div>
                                <h4 className='add-modal__label'>Nume</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.table.name} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Numar Masa</h4>
                                <input
                                    className='add-modal__field'
                                    type="number"
                                    name="number"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.table.number} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Numar Invitati</h4>
                                <input
                                    className='add-modal__field'
                                    type="number"
                                    name="guestNumber"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.table.guestNumber} />
                            </div>

                            <div>
                                <h4 className='add-modal__label'>Tip Masa</h4>
                                <select
                                    className="add-modal__field"
                                    title="geo_type"
                                    value={this.state.table.geo_type}
                                    name="geo_type"
                                    onChange={this.handleChange}
                                >
                                    <option value="round">Cerc</option>
                                    <option value="square">Patrat</option>
                                </select>
                            </div>

                            <div className="add-modal__buttons">
                                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.table.id ? 'Editează' : 'Adaugă'}</button>
                                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default AddTable;
