import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import { eventService } from '../../services/EventService';

const customStyles = {
    overlay: {
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        borderRadius: "32px",
    }
};

class EditEventModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            eventInfo:{
                name: '',
                slug: '',
                date: '',
                location: '',
                code: '',
                city: ''
            }
        };

        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        if (this.props.event) {
          let eventInfo = { ...this.state.eventInfo };
          eventInfo = {
            ...this.props.event,
          };

          this.setState({ eventInfo });
          console.log(this.props.event)
        }
      }
    handleChange = event => {
        const field = event.target.name;
        const eventInfo = { ...this.state.eventInfo };
        eventInfo[field] = event.target.value;
        this.setState({
            eventInfo
        });
    };
    handleEditEvent = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        eventService.createOrUpdateEvent(this.state.eventInfo).then(response => {
            notification.addNotification({
                message:
                    `Felicitari! Evenimentul tău a fost editat  cu succes.`,
                level: 'success',
                position: 'tc'
            });

            setTimeout(() => {
                window.location.reload(true);
            }, 2000);
        })


            .catch(err => {

              if (err.response.data.slug_exists) {
                notification.addNotification({
                    message: 'Acest slug a fost folosit deja de catre alt cuplu, va rugam sa cautati alt slug.',
                    level: 'error',
                    position: 'tc'
                });

                return;
              }

                notification.addNotification({
                    message: 'Ne pare rau, dar a aparut o problema. Va rog sa dati refresh sau sa contactati administratorul site-ului.',
                    level: 'error',
                    position: 'tc'
                });
            })
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={!this.props.stayOpenModal}
                >
                    <div className='standard add-modal'>
                        { this.props.stayOpenModal ? ('') : (
                          <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
                        ) }
                        <h2 className="add-modal__title">Completează evenimentul</h2>
                        <form onSubmit={this.handleEditEvent}>
                            <div>
                                <h4 className='add-modal__label'>Nume (Va aparea ca și titlu al website-ului/eveniment in calendar; Ex: Vali & Cristina)</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.eventInfo.name} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Slug (partea unică din url-ul website-ului tau, de ex: alina-andrei)</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="slug"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.eventInfo.slug} />
                                <h5>Caractere nepermise: ; / ? : @ & = + $ #. Caractere vor fi salvate, dar in url vor fi modificate.</h5>
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Data nunții</h4>
                                <input
                                    className='add-modal__field'
                                    type="date"
                                    name="date"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.eventInfo.date} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Oraș (Va fi folosita si la adaugarea evenimentului in calendar)</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="city"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.eventInfo.city} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Locație (Va fi folosita si la adaugarea evenimentului in calendar)</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="location"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.eventInfo.location} />
                            </div>
                            <div>
                                <h4 className='add-modal__label'>Cod eveniment (codul evenimentului care va fi primit de catre toți invitații)</h4>
                                <input
                                    className='add-modal__field'
                                    type="text"
                                    name="code"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.eventInfo.code} />
                            </div>


                            <div className="add-modal__buttons">
                                <button type='submit' className='add-modal__button add-modal__submit'>Editează</button>
                                { this.props.stayOpenModal ? ('') : (
                                  <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
                                ) }
                            </div>
                        </form>
                    </div>
                </Modal>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default EditEventModal;
