import axios from 'axios';
import { loginService } from './LoginService';

export const supremeAdminService={
    createOrUpdateClient,
    getClients,
    deleteClient,
    getClient,
    addInvoice
}
function createOrUpdateClient(clientData, eventId){

    let url = `${process.env.REACT_APP_API_URL}/api/clients${clientData.id ? `/${clientData.id}` : ''}`;
    const requestOptionsPost = {
        method: clientData.id? 'PUT': 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data: {
          ...clientData,
          event_id:eventId
        }
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
}
function getClients( pageNumber){
    let url = `${process.env.REACT_APP_API_URL}/api/clients?page[number]=${pageNumber ? pageNumber : 1}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}

function deleteClient(clientId){
    const requestOptionsDelete = {
        headers: {
          Accept: 'application/json'
        }
      };

      if (loginService.loggedIn()) {
        requestOptionsDelete.headers['Authorization'] =loginService.getToken();
      }
      let url = `${process.env.REACT_APP_API_URL}/api/clients/${clientId}`;

      return axios.delete(url, requestOptionsDelete);
}
function getClient(id){
  let url = `${process.env.REACT_APP_API_URL}/api/clients/${id}`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }
  return axios(requestOptions);
}

function addInvoice(invoiceData){
  let url = `${process.env.REACT_APP_API_URL}/api/invoice`;
  const requestOptionsPost = {
      method: 'PUT',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: buildRequestData(invoiceData)
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function buildRequestData(invoiceData) {
  let formData = new FormData();

  const document = invoiceData.invoiceDocument;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`invoice[invoice_document]`, document.url);
    } else {
      formData.append(`invoice[invoice_document]`, document, document.name);
    }
  }

  return formData;
}
