import React from 'react';
import '../../resources/main.scss';
import { loginService } from '../../services/LoginService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { passwordService } from '../../services/ResetPasswordService';
import queryString from 'query-string';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userData: {
                password: "",
                confirmPassword: '',
                token: ''
            },
            showPassword: false

        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
       let token=queryString.parse(this.props.location.search).token;
       console.log(token)
        let userData = { ...this.state.userData }
        userData.token = token;
        this.setState({ userData })
    }
    handleChange = event => {
        const field = event.target.name;
        const userData = { ...this.state.userData };
        userData[field] = event.target.value;
        this.setState({ userData })
    }
    handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        passwordService
            .resetPassword(this.state.userData)
            .then(response => {
                notification.addNotification({
                    message: 'Parola ta a fost resetată cu succes.',
                    level: 'success',
                    position: 'tc'
                });

                    this.props.history.push('/app/login');
           

            })
            .catch(error => {
                if (this.state.userData.password !== this.state.userData.confirmPassword) {
                    notification.addNotification({
                        message: 'Parola introdusă nu coincide cu parola confirmată.',
                        level: 'error',
                        position: 'tc'
                    });

                    return;
                }
                if (error.response.data.error[0] === 'Link not valid or expired. Try generating a new link.') {
                    notification.addNotification({
                      message: 'Link-ul de resetare a parolei nu mai este valid. Te rugăm să introduci din nou adresa de email.',
                      level: 'error',
                      position: 'tc'
                    });
          
                    return;
                  }
                notification.addNotification({
                    message: 'Ne pare rau, a apărut o problema. Va rugăm sa dați un refresh sau să ne contactați daca problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    }
    togglePassword = () => {
        console.log('here')
        let showPassword = this.state.showPassword;
        showPassword = !showPassword;
        this.setState({ showPassword })
    }
    render() {
        return (
            <div className='standard login'>
                <Header />
                <div className='login__wrapper'>
                    <img src='/images/lateral.png' alt='background' className='login__lateral' />
                    <div className='login__container'>
                        <h3 className='login__title'>Introdu noua ta parolă.</h3>
                        <h4 className='login__subtitle'>Nu ai un cont? <Link className="standard__link" to="/app/creeaza-cont"><span> Înregistrează-te aici</span></Link></h4>
                        <form className='login__form' onSubmit={this.handleSubmit}>
                            <div className='account__password-box'>
                                <h4 className='login__label'>Parola*</h4>
                                <input
                                    className='login__input login__password-input'
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    name="password"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.userData.password} />
                                <img src='/images/eye.png' alt='see password' className='account__eye' onClick={() => this.togglePassword()} />
                            </div>
                            <div className='account__password-box'>
                                <h4 className='login__label'>Confirmă parola*</h4>
                                <input
                                    className='login__input login__password-input'
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.userData.confirmPassword} />
                                <img src='/images/eye.png' alt='see password' className='account__eye' onClick={() => this.togglePassword()} />
                            </div>
                            <div className='login__account-box'>
                                <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>Conectare</h5></button>

                            </div>

                        </form>

                    </div>
                </div>
                <Footer />
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default ResetPassword;
