import React from 'react';
import { VictoryPie, VictoryLabel, VictoryAnimation } from "victory";
import { invitationService } from '../../services/InvitationService';

class InvitationsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      percentages: {
        isInvitat: 0,
        isAcceptat: 0,
        isRefuzat: 0,
        dataInvitat: this.getData(0),
        dataRefuzat: this.getData(0),
        dataAcceptat: this.getData(0),
      },
      extraInformations: {
        total: 0,
        acceptat_count: 0,
        refuzat_count: 0,
        invitat_count: 0
      }
    }
  }

  componentDidMount() {
    invitationService.invitationsStatistics(this.props.currentEventId).then(response => {
      this.setData(response);
    })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/login");
        }
      })
  }

  getData(percent) {
    return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
  }

  setData(response) {
    this.setDataValues(response.data.invitat, 'isInvitat', 'dataInvitat');
    this.setDataValues(response.data.acceptat, 'isAcceptat', 'dataAcceptat');
    this.setDataValues(response.data.refuzat, 'isRefuzat', 'dataRefuzat');

    let extraInformations = { ...this.state.extraInformations };
    extraInformations = { ...response.data };

    this.setState({ extraInformations });
  }

  setDataValues(percent, key, dataKey) {
    if (!percent) {
      percent = 0
    }

    let percentages = { ...this.state.percentages };

    percentages[key] = percent;
    percentages[dataKey][0].y = percent;
    percentages[dataKey][1].y = 100 - percent;

    this.setState({ percentages });
  }

  render() {
    return (
      <div className="website-form">
        <h4 className='website-form__subtitle'>Număr invitații: {this.state.extraInformations.total}</h4>
        <h2 className='guests__title'></h2>
        <div className="dashboard__statistics-wrapper">
          <div className="dashboard__statistics-chart">
            <div>
              <svg viewBox="0 0 400 400" width="100%" height="100%">
                <VictoryPie
                  standalone={false}
                  animate={{ duration: 2000 }}
                  width={400} height={400}
                  data={this.state.percentages.dataAcceptat}
                  cornerRadius={25}
                  innerRadius={120}
                  labels={() => null}
                  style={{
                    data: {
                      fill: ({ datum }) => {
                        const color = datum.y <= 25 ? "red" : (datum.y <= 50 ? "orange" : (datum.y <= 75 ? "yellow" : datum.y === 100 ? "green" : "lightgreen"));
                        return datum.x === 1 ? color : (datum.y === 100 ? 'red' : 'lightgrey');
                      }
                    }
                  }}
                />
                <VictoryAnimation duration={2000} data={this.state}>
                  {(newProps) => {
                    return (
                      <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={200} y={200}
                        text={`${(newProps.extraInformations.acceptat_count).toFixed(0)} - ${(newProps.percentages.isAcceptat).toFixed(2)}%`}
                        style={{ fontSize: 45 }}
                      />
                    );
                  }}
                </VictoryAnimation>
              </svg>
            </div>
            <div className="dashboard__chart-title">
              <h2>Invitati care au spus DA</h2>
            </div>
          </div>
          <div className="dashboard__statistics-chart">
            <div>
              <svg viewBox="0 0 400 400" width="100%" height="100%">
                <VictoryPie
                  standalone={false}
                  animate={{ duration: 2000 }}
                  width={400} height={400}
                  data={this.state.percentages.dataRefuzat}
                  cornerRadius={25}
                  innerRadius={120}
                  labels={() => null}
                  style={{
                    data: {
                      fill: ({ datum }) => {
                        const color = datum.y <= 25 ? "lightgreen" : (datum.y <= 50 ? "yellow" : (datum.y <= 75 ? "orange" : "red"));
                        return datum.x === 1 ? color : (datum.y === 100 ? 'green' : 'lightgrey');
                      }
                    }
                  }}
                />
                <VictoryAnimation duration={2000} data={this.state}>
                  {(newProps) => {
                    return (
                      <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={200} y={200}
                        text={`${(newProps.extraInformations.refuzat_count).toFixed(0)} - ${(newProps.percentages.isRefuzat).toFixed(2)}%`}
                        style={{ fontSize: 45 }}
                      />
                    );
                  }}
                </VictoryAnimation>
              </svg>
            </div>
            <div className="dashboard__chart-title">
              <h2>Invitati care au spus NU</h2>
            </div>
          </div>
          <div className="dashboard__statistics-chart">
            <div>
              <svg viewBox="0 0 400 400" width="100%" height="100%">
                <VictoryPie
                  standalone={false}
                  animate={{ duration: 2000 }}
                  width={400} height={400}
                  data={this.state.percentages.dataInvitat}
                  cornerRadius={25}
                  innerRadius={120}
                  labels={() => null}
                  style={{
                    data: {
                      fill: ({ datum }) => {
                        const color = datum.y <= 25 ? "lightgreen" : (datum.y <= 50 ? "yellow" : (datum.y <= 75 ? "orange" : "red"));
                        return datum.x === 1 ? color : (datum.y === 100 ? 'green' : 'lightgrey');
                      }
                    }
                  }}
                />
                <VictoryAnimation duration={2000} data={this.state}>
                  {(newProps) => {
                    return (
                      <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={200} y={200}
                        text={`${(newProps.extraInformations.invitat_count).toFixed(0)} - ${(newProps.percentages.isInvitat).toFixed(2)}%`}
                        style={{ fontSize: 45 }}
                      />
                    );
                  }}
                </VictoryAnimation>

              </svg>
            </div>
            <div className="dashboard__chart-title">
              <h2>Invitati care nu au raspuns</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InvitationsDashboard;
