import React from 'react';
import './WebsiteBuilder.scss';
import '../Admin.scss';
import Menu from '../../../components/AdminMenu/AdminMenu';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { eventService } from '../../../services/EventService';
import WebsiteForm from '../../../components/WebsiteForm/WebsiteForm';
import InvitationForm from '../../../components/InvitationForm/InvitationForm';
import { Link } from 'react-router-dom';
import { websiteDetailsService } from '../../../services/WebsiteDetailsService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { clientsService } from '../../../services/ClientsService';
import moment from "moment";
import GenerateQR from "../../../components/GenerateQR/GenerateQR";
class WebsiteBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      currentEvent: {},
      tabValue: 0,
      liveWebsite: false,
      activeDemo: false,
      freeDays: 0,
      freeHours: 0,
      expiredAccount: false,
      idLocalStorage: '',
      isQRModalOpen: false,
      websiteLink: ''
    }
    this.notificationSystem = React.createRef();
  }

  componentDidMount() {
    const notification = this.notificationSystem.current;
    this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })
    clientsService.checkClient()
      .then(response => {
        if (response.data.active_demo === false) {
          this.setState({ expiredAccount: true });

          notification.addNotification({
            message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
            level: 'error',
            position: 'tc'
          });

          setTimeout(() => {
            this.props.history.push("/app/profil");
          }, 3000);

          return;
        }

        if (response.data.active_demo) {
          this.setState({ activeDemo: true });
          const freeDays = moment(response.data.free_until).diff(moment(), 'days');
          const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

          this.setState({ freeDays });

          if (freeHours > 0) {
            this.setState({ freeHours });
          }
        }

        eventService.getEvents().then(response => {
          let events = this.state.events;
          events = response.data.events;
          let currentEvent = { ...this.state.currentEvent };
          let specificEvent = response.data.events.find(event => event.id === this.state.idLocalStorage);

          currentEvent = specificEvent ? specificEvent : { ...events[0] };


          if (currentEvent && currentEvent.website_details.length) {
            this.setState({ liveWebsite: currentEvent.website_details[0].live })
          }

          this.setState({ events });
          this.setState({ currentEvent });
          let slug = this.state.currentEvent.slug;
          let websiteLink = 'https://www.facemnunta.ro/' + slug;
          this.setState({ websiteLink })
        })
          .catch(error => {
            if (error.response.status === 401) {
              this.props.history.push("/app/login");
            }
          })

      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/app/login");
        }
      })
  }

  updateTabValue = (event, value) => {
    this.setState({ tabValue: value });
  }
  openQRModal = () => {
    this.setState({ isQRModalOpen: true })


  }
  closeModal = () => {
    this.setState({ isQRModalOpen: false })
  }

  handleCheckboxChange = event => {
    const notification = this.notificationSystem.current;

    const field = event.target.name;
    const value = event.target.checked;

    this.setState({ liveWebsite: value }, () => {
      websiteDetailsService.liveWebsite(this.state.liveWebsite, this.state.currentEvent.id)
        .then(response => {
          notification.addNotification({
            message:
              `Statusul-ul website-ului a fost editat cu succes.`,
            level: 'success',
            position: 'tc'
          });
        })
        .catch(error => {
          notification.addNotification({
            message:
              'Ne pare rău, a intervenit o problemă. Dacă un refresh la pagină nu ajută, contactați-ne prin email sau telefon.',
            level: 'error',
            position: 'tc'
          });
        })
    });
  }
  updateEventId = event => {
    const eventId = event.target.value

    localStorage.setItem('idLocalStorage', eventId)
    let idLocalStorage = this.state.idLocalStorage;
    idLocalStorage = eventId;
    this.setState({ idLocalStorage }, () => window.location.reload())
  }

  render() {
    return (
      <div className={this.state.expiredAccount ? 'standard__no-interaction add-modal admin' : 'add-modal admin'}>
        <Menu menuItem="website-builder" />
        <AdminMobileHeader page="Creare website" />
        <div className='admin__container'>
          <div className='admin__top'>
            <div className='admin__select-box'>
              <select
                className="admin__select"
                title="tip"
                value={this.state.idLocalStorage}
                name="type"
                onChange={this.updateEventId}
                label='tip'
              >
                {this.state.events.map(event => (
                  <option key={event.id} value={event.id}>{event.name}</option>
                ))}
              </select>
            </div>
            <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
          </div>
          {this.state.activeDemo ? (
            <div className="admin__no-something">
              <img src="/images/info.png" alt="info" />
              <h5>Atenție! Sunteți în perioada de cont demo. Mai aveți {this.state.freeDays} zile și {this.state.freeHours} ore rămase. Pentru a activa contul vă rugăm să plătiți factura din secțiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
            </div>
          ) : null}
          <div className='admin__wrapper website-builder__wrapper'>
            <div className='guests__top-search'>
              <h2 className='admin__title'>Aici îți creezi website-ul de prezentare a nunții tale!</h2>

              <div className="website-builder__preview">
                {this.state.liveWebsite && <Link className='standard__link' to={`/${encodeURIComponent(this.state.currentEvent.slug)}`} target="_blank"><button className='admin__preview'>Live URL</button></Link>}
                <div className="add-modal__toggle-consent">
                  <h4 className='add-modal__label'>Website live</h4>
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="liveWebsite"
                      onChange={this.handleCheckboxChange}
                      checked={this.state.liveWebsite}
                      disabled={this.state.activeDemo}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <Link className='standard__link' to={`/app/preview/${encodeURIComponent(this.state.currentEvent.slug)}`} target="_blank"><button className='admin__preview'>Previzualizează website</button></Link>
                <button className='standard__qr' onClick={this.openQRModal}>Genereaza cod QR</button>
              </div>
            </div>
            <div className="admin__action-container">
              <Tabs
                value={this.state.tabValue}
                onChange={this.updateTabValue}
                variant="scrollable"
                scrollButtons="on"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#e66300",
                    width: '50px',
                    'border-top-left-radius': '12px',
                    'border-top-right-radius': '12px',
                    marginLeft: '4%',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    height: '8px'
                  }
                }}

              >
                <Tab
                  key={1}
                  label="Website"
                  id={`scrollable-force-tab-1`}
                  aria-controls={`scrollable-force-tabpanel-1`}
                  selected={true}

                />
                <Tab
                  key={2}
                  label="Formular Invitație"
                  id={`scrollable-force-tab-2`}
                  aria-controls={`scrollable-force-tabpanel-2`}
                  selected={true}
                />
              </Tabs>
              <div
                role="tabpanel"
                value={this.state.tabValue}
                hidden={this.state.tabValue !== 0}
                id={`scrollable-force-tabpanel-${0}`}
                aria-labelledby={`scrollable-force-tab-${0}`}
                name="statistics">
                {this.state.tabValue === 0 && (
                  this.state.currentEvent.id ? (
                    <WebsiteForm eventId={this.state.currentEvent.id}></WebsiteForm>
                  ) : ''
                )}
              </div>

              <div
                role="tabpanel"
                value={this.state.tabValue}
                hidden={this.state.tabValue !== 1}
                id={`scrollable-force-tabpanel-${1}`}
                aria-labelledby={`scrollable-force-tab-${1}`}
                className="test">
                {this.state.tabValue === 1 && (
                  <InvitationForm eventId={this.state.currentEvent.id}></InvitationForm>
                )}
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
        {this.state.isQRModalOpen && <GenerateQR
          modalIsOpen={this.state.isQRModalOpen}
          closeModal={this.closeModal}
          websiteLink={this.state.websiteLink}

        />}
      </div>
    )
  }
}

export default WebsiteBuilder;
