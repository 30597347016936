import axios from 'axios';
import { loginService } from './LoginService';

export const eventService={
    createOrUpdateEvent,
    getEvents,
    getEventById,
    checkEvent
}
function createOrUpdateEvent(eventData){
    let url = `${process.env.REACT_APP_API_URL}/api/events${eventData.id ? `/${eventData.id}` : ''}`;
    const requestOptionsPost = {
        method: eventData.id? 'PUT': 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data:{...eventData,
        event_type:eventData.type}
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
}
function getEvents(){
    let url = `${process.env.REACT_APP_API_URL}/api/events`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}
function getEventById(eventId){
    let url = `${process.env.REACT_APP_API_URL}/api/events/${eventId}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}

function checkEvent(eventId) {
  let url = `${process.env.REACT_APP_API_URL}/api/events/check_event/${eventId}`;

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
  }
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptions);
}
