import React from 'react';
import './AddInvitation.scss';
import { Link } from 'react-router-dom';
import Menu from '../../../components/AdminMenu/AdminMenu';
import { invitationService } from '../../../services/InvitationService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { eventService } from '../../../services/EventService';
import { clientsService } from '../../../services/ClientsService';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import AddGuestModal from "../../../components/AddGuest/AddGuest";
import ReactTooltip from 'react-tooltip';
import { Table, Space, Tooltip,Tag } from 'antd';

import moment from "moment";
import AddGuestCategory from "../../../components/AddGuestCategory/AddGuestCategory";

class AddInvitation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invitationInfo: {
                name: '',
                participant: false
            },
            pagination: {},
            guests: [],
            currentEvent: {},
            events: [],
            deleteGuest: {
                openedDialog: false,
                deleteId: 0
            },
            searchObject: {
              search_name: '',
              search_category: '',
              sent_invitation: '',
              status: ''
            },
            searchFieldStatus: false,
            isAddGuestModalOpen: false,
            isAddCategoryModalOpen:false,
            currentCategory:{},
            currentGuest: {},
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false,
            idLocalStorage:'',
            categories: []
        }
        this.notificationSystem = React.createRef();
    }

       columns = [
        {
            title: 'Categorie Invitat',
            dataIndex: 'category',
            key: 'category',
            render: (text, record) => (
              <Space size="middle">
                <h4>{record.category}</h4>
              </Space>
            )
          },
        {
            title: 'Nume invitat',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Space size="middle">
                    <div className="invitation__initials"  style={{ background: this.getRandomColor() }}>
                        <h4>{this.getInitial(record.name)}</h4>
                    </div>
                    <h4>{record.name}</h4>
                </Space>
            ),
        },
        {
            title: 'Cod',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Tag',
            dataIndex: 'participant',
            key: 'participant',
            render: (text, record) => (
                <Space size="middle">
                    {record.participant ? 'Persoană auxiliară' : 'Invitat'}
                </Space>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                const color = status === 'acceptat' ? 'green' : status === 'refuzat' ? 'yellow' : 'grey';
                return <Tag color={color}>{status}</Tag>;
            },
        },
        {
            title: 'Invitație trimisă',
            dataIndex: 'sent_invitation',
            key: 'sent_invitation',
            render: (text, record) => (
                <Space size="middle">

                    <img src={record.sent_invitation ? '/images/yes.png' : '/images/no.png'} alt="sent invitation" className="website-form__sent-invitation-icon" />
                </Space>
            )
        },
        {
            title: 'Acțiuni',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="Share Link">
                        <img
                            src="/images/share-icon.png"
                            alt="share"
                            className="invitation__tool-icon"
                            onClick={() => this.shareLink(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Editează">
                        <img
                            src="/images/edit-black.png"
                            alt="edit"
                            className="invitation__tool-icon"
                            onClick={() => this.openAddGuestModal(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Șterge">
                        <img
                            src="/images/delete-black.png"
                            alt="delete"
                            className="invitation__tool-icon"
                            onClick={() => this.openDeleteDialog(record.id)}
                        />
                    </Tooltip>
                    <Tooltip title="Resetează">
                        <img
                            src="/images/reset-black.png"
                            alt="reset"
                            className="invitation__tool-icon"
                            onClick={() => this.resetInvitation(record.id)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: 'Aprobă/Refuză',
            dataIndex: 'approve_reject',
            key: 'approve_reject',
            render: (text, record) => (
                <Space size="middle">
                    {record.status === 'invitat' ? (
                        <Link
                            to={`/${encodeURIComponent(this.state.currentEvent.slug)}/formular-invitatie?event_code=${this.state.currentEvent.code}&guest_code=${record.code}`}
                            className="standard__link invitation__link"
                        >
                            <h4>Aprobă</h4>
                        </Link>
                    ) : (
                        '-'
                    )}
                    {record.status === 'invitat' ? (
                        <Link
                            to={`/${encodeURIComponent(this.state.currentEvent.slug)}/formular-raspuns-negativ?event_code=${this.state.currentEvent.code}&guest_code=${record.code}`}
                            className="standard__link invitation__link"
                        >
                            <h4>Refuză</h4>
                        </Link>
                    ) : (
                        '-'
                    )}
                </Space>
            ),
        },
    ];
    expandedRowRender = (record) => {

        return (
          <p style={{ margin: 0 }}>{record.guestDetails}</p>
        );
      };
    getRandomColor=()=>{
        const colors = ['#e66300', '#ffb780', '#fff1e6', '#cc5800','#ffe2cc','#ffd4b3'];

        const randomColor =
            `linear-gradient(180deg, ${colors[Math.floor(Math.random() * colors.length)]}, 0%, ${colors[Math.floor(Math.random() * colors.length)]} 50%, #eee 95%)`;

        return randomColor;
    }
    componentDidMount() {
        this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })

      const notification = this.notificationSystem.current;

        clientsService.checkClient()
          .then(response => {
              if (response.data.active_demo === false) {
                this.setState({ expiredAccount: true });

                notification.addNotification({
                    message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                    level: 'error',
                    position: 'tc'
                });

                setTimeout(() => {
                  this.props.history.push("/app/profil");
                }, 3000);

                return;
              }

              if (response.data.active_demo) {
                this.setState({ activeDemo: true });
                const freeDays = moment(response.data.free_until).diff(moment(), 'days');
                const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

                this.setState({ freeDays });

                if (freeHours > 0) {
                  this.setState({ freeHours });
                }
              }

              eventService.getEvents().then(response => {
                  let events = this.state.events;
                  events = response.data.events;
                  let currentEvent = { ...this.state.currentEvent };
                  let specificEvent= response.data.events.find(event => event.id === this.state.idLocalStorage);

                  currentEvent = specificEvent ? specificEvent : { ...events[0] };
                  this.setState({ events });
                  this.setState({ currentEvent }, () => {
                      invitationService.getInvitations(this.state.currentEvent.id).then(response => {
                          let guests = this.state.guests;
                         guests = response.data.wedding_invitations.map(guest => ({
                            ...guest,
                            openedBox: false,
                          }));

                          let pagination = this.state.pagination;
                          pagination = response.data.pagination;
                          this.setState({ pagination })
                          this.setState({ guests })
                      })
                          .catch(error => {
                              if (error.response.status === 401) {
                                  this.props.history.push("/app/login");
                              }
                          })

                    invitationService.getCategories(this.state.currentEvent.id).then(response => {
                      let categories = [...this.state.categories];
                      categories = response.data.categories;
                      this.setState({ categories })
                    })
                      .catch(err => console.log(err))
                  })
              })
              .catch(error => {
                  if (error.response.status === 401) {
                      this.props.history.push("/app/login");
                  }
              });

          })
          .catch(error => {
              if (error.response.status === 401) {
                  this.props.history.push("/app/login");
              }
          })
    }
    handlePageClick = selectedPage => {
      invitationService.searchInvitation(this.state.currentEvent.id, this.state.searchObject, selectedPage.selected + 1).then(response => {
          this.setPaginationState(response);
      })
          .catch(err => console.log(err))

        // invitationService
        //     .getInvitations(this.state.currentEvent.id, selectedPage.selected + 1)
        //     .then(jsonResponse => {
        //         this.setPaginationState(jsonResponse);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let guests = this.state.guests;
        guests = response.data.wedding_invitations;

        this.setState({ guests });
    };
    updateField = event => {
        console.log('dfed')
        const field = event.target.name;
        const invitationInfo = { ...this.state.invitationInfo };
        invitationInfo[field] = event.target.value;
        this.setState({
            invitationInfo
        });
    };
    updateSearchField = event => {
      let searchObject = { ...this.state.searchObject }
      searchObject[event.target.name] = event.target.value

        this.setState({ searchObject });
    }
    handleAddInvitation = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        invitationService.createOrUpdateInvitation(this.state.invitationInfo, this.state.currentEvent.id).then(response => {
            notification.addNotification({
                message:
                    `Invitatul tău a fost ${this.state.invitationInfo.id ? 'editat' : 'creat'}  cu succes.`,
                level: 'success',
                position: 'tc'
            });
            if (this.state.invitationInfo.id) {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);

            } else {
                let guests = this.state.guests;
                guests.push(response.data);
                this.setState({ guests })
            }

        })


            .catch(err => {
                notification.addNotification({
                    message: 'Ne pare rau, dar a aparut o problema. Va rog sa dati refresh sau sa contactati administratorul site-ului.',
                    level: 'error',
                    position: 'tc'
                });
            })
    }
    updateEventId = event => {
        let currentEvent = { ...this.state.currentEvent };

        currentEvent = this.state.events.find(eventToFind => eventToFind.id === event.target.value);

        console.log(currentEvent)
        this.setState({ currentEvent }, () => {
            invitationService.getInvitations(this.state.currentEvent.id).then(response => {
                let guests = this.state.guests;
                guests = response.data.wedding_invitations;
                this.setState({ guests })
            })
                .catch(err => console.log(err))
        });
    }
    openDeleteDialog = id => {
        let deleteGuest = { ...this.state.deleteGuest };
        deleteGuest = {
            openedDialog: true,
            deleteId: id
        };

        this.setState({ deleteGuest });
    };

    closeDeleteDialog = () => {
        let deleteGuest = { ...this.state.deleteGuest };
        deleteGuest = {
            openedDialog: false,
            deleteId: 0
        };

        this.setState({ deleteGuest });
    };
    openSearchField = () => {
        let searchFieldStatus = this.state.searchFieldStatus;
        searchFieldStatus = true;
        this.setState({ searchFieldStatus })
        console.log('rfed')
    }
    closeSearchField = () => {
        let searchFieldStatus = this.state.searchFieldStatus;
        searchFieldStatus = false;
        let searchObject = { ...this.state.searchObject }
        searchObject.search_name = ''
        this.setState({ searchObject }, () => {
            invitationService.searchInvitation(this.state.currentEvent.id, this.state.searchObject).then(response => {
                this.setPaginationState(response);
            })
                .catch(err => console.log(err))
        })
        this.setState({ searchFieldStatus })
    }
    handleDelete = () => {
        const notification = this.notificationSystem.current;
        invitationService
            .deleteInvitation(this.state.deleteGuest.deleteId)
            .then(() => {
                let guests = this.state.guests.slice();
                guests = guests.filter(
                    guest => guest.id !== this.state.deleteGuest.deleteId
                );
                this.setState({ guests });

                this.closeDeleteDialog();

                notification.addNotification({
                    message: 'Invitatul a fost șters cu success.',
                    level: 'success',
                    position: 'tc'
                });
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    };
    setInvitationForEdit = guestInfo => {
        let invitationInfo = { ...this.state.invitationInfo };
        invitationInfo = guestInfo;
        this.setState({
            invitationInfo
        })
    }
    handleSearch = event => {
        event.preventDefault()
        invitationService.searchInvitation(this.state.currentEvent.id, this.state.searchObject).then(response => {
            this.setPaginationState(response);
        })
            .catch(err => console.log(err))
    }
    updateParticipant = () => {
        const invitationInfo = { ...this.state.invitationInfo };
        invitationInfo.participant = !invitationInfo.participant;
        this.setState({
            invitationInfo
        });
    };
    resetInvitation = invitationId => {
        const notification = this.notificationSystem.current;
        invitationService
            .resetInvitation(invitationId)
            .then(() => {

                notification.addNotification({
                    message: 'Invitatia a fost resetata cu success.',
                    level: 'success',
                    position: 'tc'
                });
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la resetare, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    }
    toggleGuest = currentGuest => {
        let guests = this.state.guests;
        const guestIndex = guests.findIndex(guest => guest.id === currentGuest.id);
        guests[guestIndex].openedBox = !guests[guestIndex].openedBox;
        this.setState({
            guests
        });
    }
    switchColors = status => {
        if (status === "acceptat") {
            return "green"
        }
        if (status === "refuzat") {
            return "red"
        }
        else {
            return "website-form__cell"
        }
    }
    openAddGuestModal = selectedGuest => {
        let currentGuest = { ...this.state.currentGuest };
        currentGuest = selectedGuest;
        this.setState({ currentGuest })
        this.setState({ isAddGuestModalOpen: true });
    }
    openAddCategoryModal = selectedCategory => {
        let currentCategory = { ...this.state.currentCategory };
        currentCategory = selectedCategory;
        this.setState({ currentCategory })
        this.setState({ isAddCategoryModalOpen: true });
    }

    closeModal = () => {
        this.setState({ isAddGuestModalOpen: false });
        this.setState({ isAddCategoryModalOpen: false });
    }


    updateGuestList = (newGuest) => {
        const notification = this.notificationSystem.current;
        let guests = this.state.guests.slice();

        const guestIndex = guests.findIndex(guest => guest.id === newGuest.id);

        if (guestIndex !== -1) {
            guests[guestIndex] = { ...newGuest };
        } else {
            guests.unshift(newGuest)
        }

        this.setState({ guests }, () => {
            this.closeModal();
            notification.addNotification({
                message:
                    `Invitatul a fost ${guestIndex !== -1 ? 'editat' : 'adaugat'} cu succes.`,
                level: 'success',
                position: 'tc'
            });
        });

    }
    getInitial = nameValue => {
        let name = nameValue.trim();
    let words = name.split(' ');
        let initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');

    return initials;
    }

    shareLink = guest => {
      navigator.clipboard.writeText(`${window.location.origin}/${this.state.currentEvent.slug}?event_code=${this.state.currentEvent.code}&guest_code=${guest.code}`);
      const notification = this.notificationSystem.current;
      notification.addNotification({
          message: `Link-ul a fost copiat.`,
          level: 'success',
          position: 'tc'
      });
    }

    render() {
        const colors = ['#e66300', ' #fff1e6', '#ffe2cc', '#ffd4b3','ffb780','#ffd4b3',' #ffb780','#ffa866',' #ff9a4d']
        let color1 = colors[Math.floor(Math.random() * colors.length)];
        let color2 = colors[Math.floor(Math.random() * colors.length)];

        return (
            <div className={ this.state.expiredAccount ? 'standard__no-interaction guests admin invitation' : 'guests admin invitation'}>
                <Menu menuItem="add-guest" />
                <AdminMobileHeader page="Adaugă invitat" />
                <div className='admin__container'>
                <div className='admin__top'>
                        <div className='admin__select-box'>

                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.idLocalStorage}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    <div className="admin__wrapper invitation__wrapper">
                        <div className=''>
                            {/* <div className='guests__add-part'>
                                <h3 className='guests__label'>Nume invitat:</h3>
                                <form className='guests__form' onSubmit={this.handleAddInvitation}>
                                    <input type='text'
                                        required
                                        name='name'
                                        className='guests__input'
                                        value={this.state.invitationInfo.name}
                                        onChange={this.updateField} />
                                    <label className='form__checkbox-container guests__checkbox-container'>Participant
                                        <input type="checkbox" checked={this.state.invitationInfo.participant} name="participant" onChange={() => this.updateParticipant()} />
                                        <span className="form__checkmark"></span>
                                    </label>
                                    <button type='submit'>{this.state.invitationInfo.id ? 'Editează' : 'Adaugă'}</button>


                                </form>
                            </div> */}
                            <button className="only-mobile invitation__add-guest" onClick={() => this.openAddCategoryModal()}>Adaugă categorie invitat</button>
                            <button className="only-mobile invitation__add-guest" onClick={() => this.openAddGuestModal()}>Adaugă invitat</button>
                            <div className="invitation__top-desktop only-desktop">
                                <h2 className="admin__title">Invitații voștri</h2>
                                <div className="invitation__top-right">
                                    {this.state.searchFieldStatus ? '' : <div className="invitation__search-button" onClick={() => this.openSearchField()}>
                                        <img src="/images/search-black.png" alt="search" className="invitation__search-img" />
                                    </div>}
                                    {this.state.searchFieldStatus ?
                                        <form className='admin__form invitation__search-form' onSubmit={this.handleSearch}>
                                            <input type='text'
                                                required
                                                name='search_name'
                                                placeholder="Cauta dupa nume..."
                                                className='invitation__search-field'
                                                value={this.state.searchObject.search_name}
                                                onChange={this.updateSearchField} />
                                            <img src='/images/search-black.png' alt='search' className="invitation__search-icon" />
                                            <img src="/images/close-black.png" onClick={() => this.closeSearchField()} alt="close" className="invitation__close-search invitation__special-close" />
                                        </form> : ''}
                                        <button className=" invitation__add-guest invitation__add-category" onClick={() => this.openAddCategoryModal()}>Adaugă categorie invitat</button>

                                    <button className=" invitation__add-guest" onClick={() => this.openAddGuestModal()}>Adaugă invitat</button>
                                </div>
                            </div>
                            <div className="only-mobile">
                                <form className='admin__form  invitation__search-form' onSubmit={this.handleSearch}>
                                    <input type='text'
                                        required
                                        name='search_name'
                                        placeholder="Cauta dupa nume..."
                                        className='invitation__search-field'
                                        value={this.state.searchObject.search_name}
                                        onChange={this.updateSearchField} />
                                    <img src='/images/search-black.png' alt='search' className="invitation__search-icon" />
                                </form>
                            </div>


                        </div>

                        <div className="admin__filter-form-wrapper">
                            <form className="admin__filter-form admin__filter-form-invite admin__filter-form--left-align" onSubmit={this.handleSearch}>
                                <div>
                                    <h4 className='add-modal__label'>Categorie Invitat</h4>
                                    <select
                                        className="add-modal__field"
                                        title="search_category"
                                        value={this.state.searchObject.search_category}
                                        name="search_category"
                                        onChange={this.updateSearchField}
                                    >
                                        <option value="">Oricare</option>
                                        { this.state.categories.map(category => (
                                          <option value={category.name}>{ category.name }</option>
                                        )) }
                                    </select>
                                </div>
                                <div>
                                    <h4 className='add-modal__label'>Status Invitatie</h4>
                                    <select
                                        className="add-modal__field"
                                        title="status"
                                        value={this.state.searchObject.status}
                                        name="status"
                                        onChange={this.updateSearchField}
                                    >
                                        <option value="">Oricare</option>
                                        <option value="acceptat">Acceptata</option>
                                        <option value="refuzat">Refuzata</option>
                                        <option value="invitat">Invitat</option>
                                    </select>
                                </div>
                                <div>
                                    <h4 className='add-modal__label'>Invitatie Trimisa</h4>
                                    <select
                                        className="add-modal__field"
                                        title="sent_invitation"
                                        value={this.state.searchObject.sent_invitation}
                                        name="sent_invitation"
                                        onChange={this.updateSearchField}
                                    >
                                        <option value="">Oricare</option>
                                        <option value="true">Da</option>
                                        <option value="false">Nu</option>
                                    </select>
                                </div>
                                <button type='submit' className="admin__add-budget">Filtreaza Invitatii</button>
                            </form>
                        </div>

                        <div className="guests__container">
                        <Table
                            columns={this.columns}
                            dataSource={this.state.guests}
                            pagination={false}
                            expandable={{
                                rowExpandable: (record) => record.category !== 'Categorie Invitat',
                              }}
                            rowKey="id"
                        />
                        {this.state.guests.length === 0 && <h4 className="standard__no-items">Încă nu ai invitați adăugați.</h4>}
                    </div>

                        {this.state.pagination && this.state.pagination.last_page > 1 && (
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pagination.last_page}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        )}
                    </div>
                    {this.state.isAddGuestModalOpen && <AddGuestModal
                        modalIsOpen={this.state.isAddGuestModalOpen}
                        closeModal={this.closeModal}
                        websiteDetailsId={this.state.currentEvent.id}
                        guest={this.state.currentGuest}
                        updateGuestList={this.updateGuestList}
                    />}
                       {this.state.isAddCategoryModalOpen && <AddGuestCategory
                        modalIsOpen={this.state.isAddCategoryModalOpen}
                        closeModal={this.closeModal}
                        eventId={this.state.currentEvent.id}
                        category={this.state.currentCategory}
                    />}
                </div>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />

                <DeletePUGCertificates
                    opened={this.state.deleteGuest.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur ca doriti sa ștergeți acesta invitatie?"
                    aditionalText="După ștergere, invitatia si invitatul nu vor mai putea fi recuperati."
                />

            </div>
        )
    }
}

export default AddInvitation;
