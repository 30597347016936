import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import { websiteContactsService } from '../../services/WebsiteContactsService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    borderRadius: "32px"
  }
};

class AddContactModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: {
        name: '',
        phone: '',
        consent: '',
        website_detail_id: this.props.websiteDetailsId
      }
    };

    this.notificationSystem = React.createRef();
  }

    componentDidMount() {
      if (this.props.contact && Object.keys(this.props.contact).length) {
        let contact = { ...this.state.contact };
        contact = {
          ...contact,
          ...this.props.contact,
          consent: this.props.contact.consent || undefined
        };

        this.setState({ contact });
      }
    }

    handleChange = event => {
      console.log(event.target.checked);
        const field = event.target.name;
        const contact = { ...this.state.contact };
        contact[field] = event.target.value;
        this.setState({ contact });
    }

  handleCheckboxChange = event => {
    const field = event.target.name;
    const contact = { ...this.state.contact };
    contact[field] = event.target.checked;
    this.setState({ contact });
  }

  handleAddContact = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    websiteContactsService.createOrUpdateContact(this.state.contact)
      .then(response => {
        console.log(response);

        this.props.updateContactList(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="modal Data"
          ariaHideApp={false}
        >
          <div className='standard add-modal'>
            <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
            <h2 className="add-modal__title">{this.state.contact.id ? 'Editează contactul' : 'Adaugă contact nou pentru ziua nuntii'} </h2>
            <form onSubmit={this.handleAddContact}>
              <div>
                <h4 className='add-modal__label'>Nume</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  required
                  value={this.state.contact.name} />
              </div>
              <div>
                <h4 className='add-modal__label'>Numar de telefon</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="phone"
                  onChange={this.handleChange}
                  required
                  value={this.state.contact.phone} />
              </div>
              <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Acord de postare a numelui si numarului de telefon pe site</h4>
              <label class="switch">
                <input type="checkbox"
                 name="consent"
                 onChange={this.handleCheckboxChange}
                 
                 checked={this.state.contact.consent} />
                <span class="slider round"></span>
              </label>
              </div>

              <div className="add-modal__buttons">
                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.contact.id ? 'Editează' : 'Adaugă'}</button>
                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
              </div>
            </form>
          </div>
        </Modal>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default AddContactModal;
