import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import './AttachementField.scss';

class AttachementField extends Component{
    render(){
        const dropzoneStyle = {
            width: '100%',
            height: '70px',
            border: 'dashed 0.7px #becad6',
            cursor: 'pointer'
          };

          const dropzoneDisabledStyle = {
            width: '100%',
            height: '100%',
            border: 'dashed 0.7px #becad6',
            borderRadius: 5,
            background: 'rgba(255, 255, 255, .5)',
            paddingLeft: '10px'
          };
        return(
            <div className="attachment-form__upload-content">
                <InputLabel>{this.props.attachmentLabel}</InputLabel>
                <Dropzone
                    accept={this.props.type}
                    multiple={false}
                    onDrop={(accepted, rejected) => this.props.onDrop(accepted, rejected, this.props.currentArticleSectionIndex)}
                    disabled={this.props.disabledDocuments}
                    style={dropzoneStyle}
                    disabledStyle={dropzoneDisabledStyle}
                >
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className={this.props.section? `attachment-form__upload-files ${this.props.section}`: "attachment-form__upload-files"}>
                       {this.props.document && this.props.document.url !== null ? '' :<div className="attachment-form__instruction">
                                <h3 className='attachment-form__upload'>{this.props.text}</h3>
                            <button className='attachment-form__upload-button' type='button'>
                                <img src="/images/add.png" alt="add" className="attachment-form__upload-icon"/>
                            </button>
                        </div>}

                        {this.props.document &&
                          Object.keys(this.props.document).length > 0 &&
                          (this.props.document.url || this.props.document.size) && (
                            <div className="attachment-form__existing-files">
                              <ul className="attachment-form__file-list">
                                <li
                                  key={
                                    this.props.document.name ||
                                    this.props.document.file_file_name
                                  }
                                >
                                  {this.props.document.name || this.props.documentFilename}
                                </li>
                              </ul>
                              <div className="attachment-form__remove-files">
                                <button
                                  type="button"
                                  className="attachment-form__delete-button"
                                  onClick={(event) =>  { event.stopPropagation(); this.props.removeDocument(this.props.currentArticleSectionIndex) }}
                                >
                                  <img src="/images/delete-orange.png"alt="delete" className='attachment-form__delete-icon'/>
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </section>
                )}
        </Dropzone>
      </div>
        )
    }
}

export default AttachementField;
