
import React from 'react';
import Menu from '../../../components/AdminMenu/AdminMenu';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import RegistryModal from "../../../components/RegistryModal/RegistryModal";
import { eventService } from '../../../services/EventService';
import ReactTooltip from 'react-tooltip';
import { registryService } from '../../../services/RegistryService'
import './Registry.scss';
import { Link } from 'react-router-dom';
import moment from "moment";
import { clientsService } from '../../../services/ClientsService';


class Registry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            registryList: [],
            isRegistryModalOpen: false,
            pagination: {},
            events: [],
            currentEvent: {},
            currentGift: {},
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false,
            deleteGift: {
                openedDialog: false,
                deleteId: 0
            },
            idLocalStorage:''
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })

        const notification = this.notificationSystem.current;
        clientsService.checkClient()
            .then(response => {
                if (response.data.active_demo === false) {
                    this.setState({ expiredAccount: true });

                    notification.addNotification({
                        message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                        level: 'error',
                        position: 'tc'
                    });

                    setTimeout(() => {
                        this.props.history.push("/app/profil");
                    }, 3000);

                    return;
                }

                if (response.data.active_demo) {
                    this.setState({ activeDemo: true });
                    const freeDays = moment(response.data.free_until).diff(moment(), 'days');
                    const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

                    this.setState({ freeDays });

                    if (freeHours > 0) {
                        this.setState({ freeHours });
                    }
                }

                eventService.getEvents().then(response => {
                    let events = this.state.events;
                    events = response.data.events;
                    let currentEvent = { ...this.state.currentEvent };
                   
                    let specificEvent= response.data.events.find(event => event.id === this.state.idLocalStorage);

                    currentEvent = specificEvent ? specificEvent : { ...events[0] };

                    this.setState({ events });
                    this.setState({ currentEvent }, () => {
                        registryService.getGifts(this.state.currentEvent.id).then(response => {
                            this.setPaginationState(response);
                        })
                            .catch(error => {
                                if (error.response.status === 401) {
                                    this.props.history.push("/app/login");
                                }
                            })
                    })
                })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.props.history.push("/app/login");
                        }
                    });

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }

    handlePageClick = selectedPage => {
        registryService
            .getGifts(this.state.currentEvent.id, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let registryList = this.state.registryList;
        registryList = response.data.presents;
        this.setState({ registryList });


    };
    updateField = event => {
        console.log('dfed')
        const field = event.target.name;
        const registryData = { ...this.state.registryData };
        registryData[field] = event.target.value;
        this.setState({
            registryData
        });
    };



    openDeleteDialog = id => {
        console.log('abd')
        let deleteGift = { ...this.state.deleteGift };
        deleteGift = {
            openedDialog: true,
            deleteId: id
        };

        this.setState({ deleteGift });
    };

    closeDeleteDialog = () => {
        let deleteGift = { ...this.state.deleteGift };
        deleteGift = {
            openedDialog: false,
            deleteId: 0
        };

        this.setState({ deleteGift });
    };


    openRegistryModal = selectedGift => {
        this.setState({ isRegistryModalOpen: true })
        let currentGift = { ...this.state.currentGift };
        currentGift = selectedGift;
        this.setState({ currentGift })
    }
    closeModal = () => {
        this.setState({ isRegistryModalOpen: false })
    }
    updateGiftList = newGift => {
        const notification = this.notificationSystem.current;
        let registryList = this.state.registryList.slice();

        const giftIndex = registryList.findIndex(gift => gift.id === newGift.id);

        if (giftIndex !== -1) {
            registryList[giftIndex] = { ...newGift };
        } else {
            registryList.unshift(newGift)
        }

        this.setState({ registryList }, () => {
            this.closeModal();
            notification.addNotification({
                message:
                    `Cadoul a fost ${giftIndex !== -1 ? 'editat' : 'adaugat'} cu succes.`,
                level: 'success',
                position: 'tc'
            });

        });

    }
    handleChange = event => {
        const field = event.target.name;
        const registryData = { ...this.state.registryData };
        registryData[field] = event.target.value;
        this.setState({ registryData });
    }

    handleDelete = () => {
        const notification = this.notificationSystem.current;
        registryService
            .deleteGift(this.state.deleteGift.deleteId)
            .then(() => {
                let registryList = this.state.registryList.slice();
                registryList = registryList.filter(
                    gift => gift.id !== this.state.deleteGift.deleteId
                );
                this.setState({ registryList });

                this.closeDeleteDialog();

                notification.addNotification({
                    message: 'Cadoul a fost șters cu success.',
                    level: 'success',
                    position: 'tc'
                });
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    };
    updateEventId=event=>{
        const eventId = event.target.value
        localStorage.setItem('idLocalStorage', eventId)
        let idLocalStorage=this.state.idLocalStorage;
        idLocalStorage=eventId;
        this.setState({idLocalStorage}, () => window.location.reload())
    }
    render() {

        return (
            <div className=' guests admin invitation registry'>
                <Menu menuItem="registru-cadouri" />
                <AdminMobileHeader page="Registru de cadouri" />
                <div className='admin__container registry__container'>
                    <div className='admin__top'>
                        <div className='admin__select-box'>
                            
                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.idLocalStorage}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    {this.state.activeDemo ? (
                        <div className="admin__no-something">
                            <img src="/images/info.png" alt="info" />
                            <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti {this.state.freeDays} zile si {this.state.freeHours} ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
                        </div>
                    ) : null}
                    <div className="admin__wrapper invitation__wrapper ">
                        <div className=''>
                            <button className="only-mobile invitation__add-guest" onClick={() => this.openRegistryModal()}>Adaugă cadou</button>
                            <div className="invitation__top-desktop only-desktop">
                                <h2 className="admin__title">Registru de cadouri</h2>

                                <div className="invitation__top-right">
                                    <button className=" invitation__add-guest" onClick={() => this.openRegistryModal()}>Adaugă cadou</button>
                                </div>
                            </div>



                        </div>


                        <div className="registry__table-container">
                            <div className='guests__wrapper registry__wrapper'>
                                <div className='website-form__contact-head'>
                                    <h4 className='guests__head-title'></h4>
                                    <h4 className='guests__head-title'>Nume</h4>
                                    <h4 className='guests__head-title'>Descriere</h4>
                                    <h4 className='guests__head-title'>Link</h4>
                                    <h4 className='guests__head-title'>Similar</h4>
                                    <h4 className='guests__head-title'>Rezervat</h4>
                                    <h4 className='guests__head-title'>Acțiuni</h4>
                                </div>
                                {this.state.registryList.length ? this.state.registryList.map(gift => (
                                    <div className='website-form__contact-row' key={gift.id}>
                                        <div className='website-form__cell'>
                                            {gift.image.url ? <img src={`${process.env.REACT_APP_API_URL}${gift.image.url}`} className="admin__logo-icon" alt="logo" /> :
                                                <img src="/images/gift-pic.jpg" className="website-form__cell admin__logo-icon" alt="gift" />}
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>{gift.name}</h4>
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>{gift.description}</h4>
                                        </div>
                                        <div className='website-form__cell registry__link'>
                                            <a rel="noopener noreferrer nofollow" className="standard__link" href={gift.url} target="_blank"> <h4>Link Cadou</h4></a>
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>  {gift.similar ? 'Da' : 'Nu'}</h4>
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>{gift.reserved ? `Rezervat de ${gift.wedding_invitation.name}` : 'Nerezervat'}</h4>
                                        </div>

                                        <div className="website-form__actions website-form__cell registry__actions">
                                            <div className="admin__tool-box">
                                                <img data-tip='Editează' data-event='mouseenter' src='/images/edit-black.png' alt='edit' onClick={() => this.openRegistryModal(gift)} />
                                                <div className='admin__additional-info'>
                                                    <ReactTooltip globalEventOff='click' />
                                                </div>
                                            </div>
                                            <div className="admin__tool-box">
                                                <img data-tip='Șterge' data-event='mouseenter' src='/images/delete-black.png' alt='delete' onClick={() => this.openDeleteDialog(gift.id)} />
                                                <div className='admin__additional-info'>
                                                    <ReactTooltip globalEventOff='click' />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )): (
                                  <div className="admin__no-something"><h5>Nu sunt cadouri adăugate momentan.</h5></div>
                                )}
                                {this.state.pagination && this.state.pagination.last_page > 1 && (
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.pagination.last_page}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                )}
                            </div>
                        </div>

                    </div>
                    {this.state.isRegistryModalOpen && <RegistryModal
                        modalIsOpen={this.state.isRegistryModalOpen}
                        closeModal={this.closeModal}
                        updateGiftList={this.updateGiftList}
                        eventId={this.state.currentEvent.id}
                        registryId={this.state.registryList.id}
                        gift={this.state.currentGift}
                    />}
                </div>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />

                <DeletePUGCertificates
                    opened={this.state.deleteGift.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur ca doriti sa ștergeți acest cadou?"
                    aditionalText="După ștergere, cadoul nu va mai putea fi recuperat."
                />

            </div>
        )
    }
}

export default Registry;
