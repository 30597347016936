import axios from 'axios';
import { loginService } from './LoginService';

export const todoService = {
  createOrUpdateTask,
  getTasks,
  deleteTask,
  updateTask,
  filterByStatus,
  searchTasks,
  editMyTask
}

function createOrUpdateTask(taskData) {

  let url = `${process.env.REACT_APP_API_URL}/api/tasks${taskData.id ? `/${taskData.id}` : ''}`;
  const requestOptionsPost = {
    method: taskData.id ? 'PUT' : 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: taskData
  };
  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}
function editMyTask(task){
  let url = `${process.env.REACT_APP_API_URL}/api/tasks/update_task_status${task.id}`;
  const requestOptionsPost = {
    method:'PUT',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: task
  };
  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function getTasks(eventId, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/tasks?page[number]=${pageNumber ? pageNumber : 1}&event_id=${eventId}`;

  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  }
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptions);
}



function deleteTask(taskId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/tasks/${taskId}`;

  return axios.delete(url, requestOptionsDelete);
}


function updateTask(budgetData) {
  let url = `${process.env.REACT_APP_API_URL}/api/budgets/${budgetData.id}`;
  const requestOptionsPost = {
    method: 'PUT',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: budgetData
  };
  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function filterByStatus(eventId, status, pageNumber, userId) {
  let url = `${process.env.REACT_APP_API_URL}/api/tasks/search?page[number]=${pageNumber ? pageNumber : 1
    }&status=${status}&event_id=${eventId}&user_id=${userId}`;

  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  return axios(requestOptions)
}
function searchTasks(eventId, status, importance, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/tasks/search?page[number]=${
    pageNumber ? pageNumber : 1
  }${status ? `&status=${status}` : '' }${importance ? `&importance=${importance}` : '' }&event_id=${eventId}`;

  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }
  return axios(requestOptions)
}