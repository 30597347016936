import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

import { compose, withProps } from 'recompose';

const ChurchMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAaamqPezKtwlEXQMrZPAPZeTshPushkZA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="google-location" />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: 47.189553845014146, lng: 27.560385813996998 }}
  >
    <Marker position={{ lat: 47.189553845014146, lng:27.560385813996998 }} />
  </GoogleMap>
));

export default ChurchMap;
