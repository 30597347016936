import axios from 'axios';
import { loginService } from './LoginService';

export const tablesService = {
  createOrUpdateTable,
  deleteTable,
  updateTablePosition,
}
function createOrUpdateTable(table, tableConfigurationId){
     let url = `${process.env.REACT_APP_API_URL}/api/tables/${table.id ? `${table.id}` : ''}`;
     const requestOptionsPost = {
         method: table.id? 'PUT': 'POST',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         },
         data:{
           table: {
             ...table,
             guest_number: table.guestNumber,
             table_configuration_id: tableConfigurationId,
             x_pos: table.x_pos || 0,
             y_pos: table.y_pos || 0,
           },
         }
       };
       if (loginService.loggedIn()) {
         requestOptionsPost.headers['Authorization'] = loginService.getToken();
       }

       return axios(requestOptionsPost);
 }

 function deleteTable(tableId){
     const requestOptionsDelete = {
         headers: {
           Accept: 'application/json'
         }
       };

       if (loginService.loggedIn()) {
         requestOptionsDelete.headers['Authorization'] =loginService.getToken();
       }
       let url = `${process.env.REACT_APP_API_URL}/api/tables/${tableId}`;

       return axios.delete(url, requestOptionsDelete);
 }

 function updateTablePosition(tableId, x_pos, y_pos){
      let url = `${process.env.REACT_APP_API_URL}/api/tables/update_table_position/${tableId}`;
      const requestOptionsPost = {
          method: 'PUT',
          url,
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
          },
          data:{
            table: {
              x_pos,
              y_pos
            },
          }
        };
        if (loginService.loggedIn()) {
          requestOptionsPost.headers['Authorization'] = loginService.getToken();
        }

        return axios(requestOptionsPost);
  }
