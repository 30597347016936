import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Homepage from './containers/Homepage/Homepage';
import OldHomepage from './containers/OldHomepage/OldHomepage';
import InvitationForm from './containers/InvitationForm/InvitationForm';
import NegativeForm from './containers/NegativeForm/NegativeForm';
import Gallery from './containers/Gallery/Gallery';
import Guests from './containers/Admin/Guests/Guests';
import Login from './containers/Login/Login';
import NonGuests from './containers/Admin/NonGuests/NonGuests';
import { loginService } from '../src/services/LoginService.jsx';
import UserProfile from '../src/containers/Admin/UserProfile/UserProfile';
import AddEvent from '../src/containers/AddEvent/AddEvent';
import Events from '../src/containers/Admin/Events/Events';
import AddInvitation from '../src/containers/Admin/AddInvitation/AddInvitation';
import Dashboard from '../src/containers/Admin/Dashboard/Dashboard';
import WebsiteBuilder from '../src/containers/Admin/WebsiteBuilder/WebsiteBuilder';
import LandingPage from '../src/containers/LandingPage/LandingPage';
import EditGuest from '../src/containers/Admin/EditGuest/EditGuest';
import CreateAccount from '../src/containers/CreateAccount/CreateAccount';
import PreorderPage from '../src/containers/PreorderPage/PreorderPage';
import PreviewWebsite from '../src/containers/Admin/PreviewWebsite/PreviewWebsite';
import PreviewInvitation from '../src/containers/Admin/PreviewInvitation/PreviewInvitation';
import PreviewNegativeInvitation from '../src/containers/Admin/PreviewInvitation/PreviewNegativeInvitation';
import Blog from '../src/containers/Blog/Blog';
import Article from '../src/containers/Article/Article';
import CivilCeremony from '../src/containers/CivilCeremony/CivilCeremony'
import GeneralConfiguration from "./containers/Admin/GeneralConfiguration/GeneralConfiguration";
import TablesConfigurator from "./containers/Admin/TablesConfigurator/TablesConfigurator";
import Budget from './containers/Admin/Budget/Budget'
import SupremeAdminPage from './containers/Supreme/SupremeAdminPage/SupremeAdminPage';
import Answers from './containers/Admin/Answers/Answers'
import NegativeAnswers from "./containers/Admin/NegativeAnswers/NegativeAnswers";
import GuestPage from './containers/Admin/GuestPage/GuestPage'
import couplesJson from './containers/Homepage/couples.json';
import {JssProvider} from 'react-jss';
import MetaTags from 'react-meta-tags';
import ClientPage from "./containers/Supreme/ClientPage/ClientPage";
import Providers from './containers/Supreme/Providers/Providers'
import PaymentRejected from './containers/PaymentRejected/PaymentRejected';
import PaymentSuccess from './containers/PaymentSuccess/PaymentSuccess';
import ProvidersPage from "./containers/ProvidersPage/ProvidersPage";
import SendEmail from "./containers/SendEmail/SendEmail"
import Registry from './containers/Admin/Registry/Registry'
import ResetPassword from './containers/ResetPassword/ResetPassword';
import './index.css';
import GiftRegistry from './containers/GiftRegistry/GiftRegistry'
import CharityPage from "./containers/CharityPage/CharityPage";
import TodoList from './containers/Admin/TodoList/TodoList.jsx'
import FrequentQuestions from "./containers/Admin/FrequentQuestions/FrequentQuestions.jsx";
import Notifications from "./containers/Admin/Notifications/Notifications.jsx";
class App extends React.Component {

  render(){
    return (
      <Suspense fallback="...is loading">
      <JssProvider classNamePrefix="facem-nunta-">
        <div className="container-wrap">
          <Router>
            <div>
              <DefaultRoute exact path="/:client_id" component={ (props) => {
              let parameters = props.location.pathname;
              const firstSegment = parameters.split("/")[1];

              const selectedCouple = couplesJson.find(couple => couple.slug === firstSegment);

              if(selectedCouple) {
                return <OldHomepage { ...props } />;
              } else {
                return <Homepage { ...props} />;
              }
            }
            } />
              <DefaultRoute exact path="/" component={LandingPage}/>

            <DefaultRoute exact path="/:client_id/formular-invitatie/:id?" component={InvitationForm}/>
            <DefaultRoute exact path="/:client_id/formular-raspuns-negativ/:id?" component={NegativeForm}/>
            <DefaultRoute exact path="/:client_id/galerie-foto" component={Gallery}/>
            <DefaultRoute exact path="/app/pre-inregistrare" component={PreorderPage}/>
            <DefaultRoute exact path="/app/blog" component={Blog}/>
            <DefaultRoute exact path="/app/article/:id" component={Article}/>
            <DefaultRoute exact path="/app/cununie-civila" component={CivilCeremony}/>
            <DefaultRoute exact path="/app/risipa-mancare" component={CharityPage}/>
            <DefaultRoute exact path="/app/plata-succes" component={PaymentSuccess}/>
            <DefaultRoute exact path="/app/plata-eroare" component={PaymentRejected}/>
            <DefaultRoute exact path="/app/parteneri" component={ProvidersPage}/>
            <DefaultRoute exact path="/app/trimite-email" component={SendEmail}/>
            <DefaultRoute exact path="/app/reseteaza-parola" component={ResetPassword}/>
            <DefaultRoute exact path="/:client_id/registru-de-cadouri" component={GiftRegistry}/>
            <PrivateRoute exact path="/app/invitati" component={Guests}/>
            <PrivateRoute exact path="/app/buget" component={Budget}/>
            <PrivateRoute exact path="/app/invitati-care-nu-vin" component={NonGuests}/>
            <PrivateRoute exact path="/app/profil" component={UserProfile}/>
            <PrivateRoute exact path="/app/evenimente" component={Events}/>
            <PrivateRoute exact path="/app/invitat/:id" component={GuestPage}/>
            <PrivateRoute exact path="/app/to-do-list" component={TodoList}/>
            <DefaultRoute exact path="/app/login" component={Login}/>
            <DefaultRoute exact path="/app/creeaza-cont" component={CreateAccount}/>
            <PrivateRoute exact path="/app/adauga-invitat" component={AddInvitation}/>
            <PrivateRoute exact path="/app/adauga-eveniment" component={AddEvent}/>
            <PrivateRoute exact path="/app/dashboard" component={Dashboard}/>
            <PrivateRoute exact path="/app/raspunsuri" component={Answers}/>

            <PrivateRoute exact path="/app/notificari" component={Notifications}/>
            <PrivateRoute exact path="/app/registru-cadouri" component={Registry}/>
            <PrivateRoute exact path="/app/raspunsuri-negative" component={NegativeAnswers}/>
            <PrivateRoute exact path="/app/editeaza-invitat/:id" component={EditGuest}/>
            <PrivateRoute exact path="/app/website-builder" component={WebsiteBuilder}/>
            <PrivateRoute exact path="/app/preview/:client_id" component={PreviewWebsite}/>
            <PrivateRoute exact path="/app/configurare-generala" component={GeneralConfiguration}/>
            <PrivateRoute exact path="/app/tables-configurator" component={TablesConfigurator} />
            <PrivateRoute exact path="/app/intrebari-frecvente" component={FrequentQuestions} />
            <PrivateRoute exact path="/app/preview/:client_id/formular-invitatie/:id?" component={PreviewInvitation}/>
            <PrivateRoute exact path="/app/preview/:client_id/formular-raspuns-negativ/:id?" component={PreviewNegativeInvitation}/>

            <AdminRoute exact path="/app/clienti" component={SupremeAdminPage}/>
            <AdminRoute exact path="/app/furnizori" component={Providers}/>
            <AdminRoute exact path="/app/client/:id" component={ClientPage}/>
            </div>
          </Router>
        </div>
        </JssProvider>
        </Suspense>
    );
  }
}

const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <div>
        <MetaTags>
          <meta property="og:url"
              content={`https://www.facemnunta.ro/${matchProps.location.pathname}`}
          />
          <link rel="canonical" href={`https://www.facemnunta.ro${matchProps.location.pathname}`} />
        </MetaTags>
        <Component {...matchProps} />
      </div>
    )}
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loginService.loggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/app/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loginService.loggedIn() ? (
        loginService.isAdmin() ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/app/evenimente',
              state: { from: props.location }
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/app/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default App;
