import React from 'react';
import './TablesConfigurator.scss';
import Menu from '../../../components/AdminMenu/AdminMenu';
import GuestTable from '../../../components/GuestTable/GuestTable';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import { eventService } from '../../../services/EventService';
import { invitationService } from "../../../services/InvitationService";

import Draggable from 'react-draggable';
import { MapInteractionCSS } from 'react-map-interaction';
import interact from 'interactjs';
import AddTable from "../../../components/AddTable/AddTable";
import { guestsService } from '../../../services/AdminService';
import { tablesConfiguratorService } from '../../../services/TablesConfiguratorService';
import { tablesService } from '../../../services/TablesService';
import { seatsService } from '../../../services/SeatsService';
import NotificationSystem from 'react-notification-system';
import ViewTableModal from "../../../components/ViewTableModal/ViewTableModal";
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TableConfiguratorDocument from '../../../components/TableConfiguratorPDFExport/TableConfiguratorPDFExport';
import generatePdfDocument from '../../../components/TableConfiguratorPDFExport/GeneratePDFDocument';
import domtoimage from 'dom-to-image';
import { clientsService } from '../../../services/ClientsService';
import moment from "moment";
import { Link } from 'react-router-dom';

class TablesConfigurator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      currentEvent: {},
      guests: [],
      categories: [],
      tables: [],
      isAddTableModalOpen: false,
      isEditTableMode: false,
      selectedTable: {},
      searchName: '',
      searchSelectedCategory: '',
      searchFieldStatus: false,
      viewGuests: false,
      tableConfiguration: {},
      isViewTableOpen:false,
      exportDisabled: true,
      exportStarted: false,
      activeDemo: false,
      freeDays: 0,
      freeHours: 0,
      expiredAccount: false,
      idLocalStorage:''
    }

    this.notificationSystem = React.createRef();
  }

  componentDidMount() {
    const notification = this.notificationSystem.current;
    this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })
    clientsService.checkClient()
      .then(response => {
          if (response.data.active_demo === false) {
            this.setState({ expiredAccount: true });

            notification.addNotification({
                message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                level: 'error',
                position: 'tc'
            });

            setTimeout(() => {
              this.props.history.push("/app/profil");
            }, 3000);

            return;
          }

          if (response.data.active_demo) {
            this.setState({ activeDemo: true });
            const freeDays = moment(response.data.free_until).diff(moment(), 'days');
            const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

            this.setState({ freeDays });

            if (freeHours > 0) {
              this.setState({ freeHours });
            }
          }

          eventService.getEvents()
            .then(response => {
              let events = this.state.events;
              events = response.data.events;
              let currentEvent = { ...this.state.currentEvent };
              let specificEvent= response.data.events.find(event => event.id === this.state.idLocalStorage);

              currentEvent = specificEvent ? specificEvent : { ...events[0] };


              this.setState({ events });
              this.setState({ currentEvent }, () => {
                guestsService.searchNoSeatInvites(this.state.currentEvent.id).then(response => {
                  this.setPaginationState(response);
                })
                  .catch(error => {
                    if (error.response.status === 401) {
                      this.props.history.push("/app/login");
                    }
                  })

              invitationService.getCategories(this.state.currentEvent.id).then(response => {
                let categories = [...this.state.categories];
                categories = response.data.categories;
                let guest = { ...this.state.guest }
                if (!guest.category) {
                  guest.category = categories[0].name;
                  this.setState({ guest })
                }

                this.setState({ categories })
              })
                .catch(err => console.log(err))
              })

              tablesConfiguratorService.getTablesConfigurator(this.state.currentEvent.id)
                .then(response => {
                  let tableConfiguration = { ...this.state.tableConfiguration };
                  let tables = this.state.tables.slice();

                  tableConfiguration = response.data.table_configuration;
                  tableConfiguration.tables.map(table => {
                    tables.push({
                      ...table,
                      geoType: table.geo_type,
                      guestNumber: table.guest_number
                    })
                  });

                  this.setState({ tableConfiguration });
                  this.setState({ tables }, () => {
                    tables.map(table => {
                      if (table.geoType === 'round') {
                        this.createRoundFields(table);
                      }

                      if (table.geoType === 'square') {
                        this.createSquareFields(table);
                      }
                    })

                    this.setState({ exportDisabled: false });
                  });
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                  }
                })
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.props.history.push("/app/login");
              }
            });

      })
      .catch(error => {
          if (error.response.status === 401) {
              this.props.history.push("/app/login");
          }
      })

    interact('.tables-configurator__table-wrapper')
      .draggable({
        inertia: true,
        //keep the element within the area of it's parent
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          }),
        ],

        listeners: {
          // call this function on every dragmove event
          move: this.dragMoveListener,

          // call this function on every dragend event
          end: (event) => {
            let targetId = event.target.id.split('-')[1];

            tablesService.updateTablePosition(targetId, event.target.dataset.x, event.target.dataset.y)
              .then(response => {
                this.setNewTablePosition(targetId, response);
              })
              .catch(error => { console.log(error) })
          }
        }
      });

    interact('.tables-configurator__chair').dropzone({
      accept: '#guest-details',
      ondropactivate: (event) => {
        let seat = event.target;
        let guest = event.relatedTarget;

        const freeSeats = this.getTableFreeSeats(seat.getAttribute('data-table-id'));
        const guestCompanyNumber = guest.getAttribute('data-company-number');

        if (freeSeats >= guestCompanyNumber && !seat.getAttribute('data-seat-id')) {
          event.target.classList.add('drop-active');
        } else {
          event.target.classList.add('drop-active--no-drop');
        }
      },
      ondragenter: (event) => {
        let seat = event.target;
        let guest = event.relatedTarget;

        const freeSeats = this.getTableFreeSeats(seat.getAttribute('data-table-id'));
        const guestCompanyNumber = guest.getAttribute('data-company-number');

        if (freeSeats >= guestCompanyNumber && !seat.getAttribute('data-seat-id')) {
          seat.classList.add('drop-target')
        }
      },
      ondragleave: (event) => {
        event.target.classList.remove('drop-target');
      },
      ondrop: (event) => {
        let seat = event.target;
        let guest = event.relatedTarget;
        let guestId = guest.getAttribute('data-id');
        let tableId = seat.getAttribute('data-table-id');
        const freeSeats = this.getTableFreeSeats(tableId);
        const guestCompanyNumber = guest.getAttribute('data-company-number');

        if (freeSeats < guestCompanyNumber || seat.getAttribute('data-seat-id')) {
          return;
        }

        seatsService.addGuestToSeat(seat.getAttribute('data-id'), guest.getAttribute('data-id'), tableId, guestCompanyNumber)
          .then(response => {
            response.data.seats.map(seat => {
              let chairWrap = document.getElementById(`chair-${seat.id}`);

              chairWrap.innerHTML = '';
              chairWrap.setAttribute('data-seat-id', seat.guest.id);

              let tables = this.state.tables.slice();
              let currentTableIndex = tables.findIndex(table => table.id === seat.table_id);

              tables[currentTableIndex].seats.map(tableSeat => {
                if (tableSeat.id === seat.id) {
                  tableSeat.guest = { ...seat.guest };
                  tableSeat.guest_id = seat.guest.id;
                }
              });

              this.setState({ tables });

              this.generateChairWrap(chairWrap, seat);

              guestsService.searchNoSeatInvites(this.state.currentEvent.id).then(response => {
                this.setPaginationState(response);
              })
                .catch(error => {
                  if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                  }
                })
            })
          })
          .catch(error => {
            console.log(error)
          })

      },
      ondropdeactivate: (event) => {
        event.target.classList.remove('drop-active')
        event.target.classList.remove('drop-target')
        event.target.classList.remove('drop-active--no-drop');
      }
    })
  }

  componentWillUnmount() {
    interact('.tables-configurator__table-wrapper').unset();
    interact('.tables-configurator__chair').unset();
    interact('.tables-configurator__guest').unset();
  }

  guestDragListener = () => {
    interact('.tables-configurator__guest').unset();
    interact('.tables-configurator__guest')
      .draggable({
        listeners: {
          // call this function on every dragmove event
          move: this.dragMoveListener,

          // call this function on every dragend event
          end(event) {
          }
        }
      });
  }

  dragMoveListener = (event) => {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
  }

  setNewTablePosition = (tableId, response) => {
    let tables = this.state.tables.slice();

    let currentTableIndex = tables.findIndex(table => table.id === tableId);

    tables[currentTableIndex] = {
      ...tables[currentTableIndex],
      x_pos: response.data.x_pos,
      y_pos: response.data.y_pos,
    };

    this.setState({ tables });
  }

  openAddTableModal = (editTable) => {
    this.setState({ isAddTableModalOpen: true });
    this.setState({ isEditTableMode: editTable });
  }

  closeModal = () => {
    this.setState({ isAddTableModalOpen: false });
    this.setState({ isViewTableOpen: false });
  }

  addTable = (tableDetails) => {
    tablesService.createOrUpdateTable(tableDetails, this.state.tableConfiguration.id)
      .then(response => {
        let tables = this.state.tables.slice();

        let currentTable = {};

        if (tableDetails.id) {
          let tableIndex = tables.findIndex(table => table.id === this.state.selectedTable.id);
          let selectedTable = { ...this.state.selectedTable };

          tables[tableIndex] = {
            ...response.data,
            geoType: response.data.geo_type,
            seats: response.data.seats,
            guestNumber: response.data.guest_number
          }

          selectedTable = tables[tableIndex];
          currentTable = tables[tableIndex];
          this.setState({ selectedTable });
        } else {
          currentTable = {
            ...response.data,
            geoType: response.data.geo_type,
            seats: response.data.seats,
            guestNumber: response.data.guest_number
          };

          tables.push(currentTable);
        }

        this.setState({ tables }, () => {
          if (!tableDetails.id) {
            let interactTable = document.getElementById(`table-${currentTable.id}`);

            let currentYPos = 0;
            const tablesCount = document.getElementsByClassName('tables-configurator__table-wrapper').length;

            if (this.state.tables.length) {
              currentYPos = (tablesCount - 1) * 80;
            }

            // translate the element
            interactTable.style.webkitTransform = interactTable.style.transform = 'translate(' + 0 + 'px, ' + -currentYPos + 'px)'

            // update the posiion attributes
            interactTable.setAttribute('data-x', 0);
            interactTable.setAttribute('data-y', -currentYPos);

            tablesService.updateTablePosition(currentTable.id, 0, -currentYPos)
              .then(response => {
                this.setNewTablePosition(currentTable.id, response);
              })
              .catch(error => { console.log(error) })
          }

          if (currentTable.geoType === 'round') {
            this.createRoundFields(currentTable);
          }

          if (currentTable.geoType === 'square') {
            this.createSquareFields(currentTable);
          }
        });
        this.closeModal();
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/app/login");
        }
      })
  }

  createRoundFields = (table) => {
    let guestNumber = parseInt(table.guestNumber);
    let element = document.getElementById(`table-details-${table.id}`);
    let radius = 60;
    let angle = 0;
    let step = (2 * Math.PI) / guestNumber;

    element.innerHTML = '';

    table.seats.map(seat => {
      let x = Math.round(80 / 2 + radius * Math.cos(angle) - 35 / 2);
      let y = Math.round(80 / 2 + radius * Math.sin(angle) - 25 / 2);

      let chairWrap = document.createElement("div");

      chairWrap.id = "chair-" + seat.id;
      chairWrap.className = "tables-configurator__chair";
      chairWrap.style.left = x + 'px';
      chairWrap.style.top = y + 'px';
      chairWrap.setAttribute('data-id', seat.id);
      chairWrap.setAttribute('data-table-id', table.id);
      if (seat.guest && seat.guest.id) {
        chairWrap.setAttribute('data-seat-id', seat.guest.id);
      }

      chairWrap = this.generateChairWrap(chairWrap, seat);

      element.append(chairWrap);
      angle += step;
    })
  }

  createSquareFields = (table) => {
    let guestNumber = parseInt(table.guestNumber);
    let element = document.getElementById(`table-details-${table.id}`);
    let tableSide = 100; // The side length of the square table
    let chairWidth = 32; // The width of the chair
    let chairHeight = 27; // The height of the chair
    let margin = 5; // Margin from the table to the chair

    element.innerHTML = '';

    let baseChairsPerSide = Math.floor(guestNumber / 4);
    let extraChairs = guestNumber % 4;

    for (let i = 0; i < guestNumber; i++) {
      // Calculate the side (0: top, 1: right, 2: bottom, 3: left)
      let side = Math.floor(i / (baseChairsPerSide + (extraChairs > 0 ? 1 : 0)));
      // Adjust for extra chairs once they have been placed
      if (i >= (baseChairsPerSide + 1) * extraChairs) {
        side = Math.floor((i - extraChairs) / baseChairsPerSide);
      }

      // Calculate position on side
      let position = i % (baseChairsPerSide + (side < extraChairs ? 1 : 0));
      let totalChairsCurrentSide = baseChairsPerSide + (side < extraChairs ? 1 : 0);

      let x, y;
      let offset = (tableSide - (chairWidth * totalChairsCurrentSide)) / (totalChairsCurrentSide + 1);
      let commonOffset = offset + chairWidth;

      switch(side) {
        case 0: // Top side
          x = commonOffset * position + offset;
          y = -chairHeight - margin;
          break;
        case 1: // Right side
          x = tableSide + margin;
          y = commonOffset * position + offset;
          break;
        case 2: // Bottom side
          x = tableSide - (commonOffset * (position + 1));
          y = tableSide + margin;
          break;
        case 3: // Left side
          x = -chairWidth - margin;
          y = tableSide - (commonOffset * (position + 1));
          break;
      }

      let chairWrap = document.createElement("div");
      chairWrap.id = "chair-" + table.seats[i].id;
      chairWrap.className = "tables-configurator__chair";
      chairWrap.style.left = x + 'px';
      chairWrap.style.top = y + 'px';
      chairWrap.setAttribute('data-id', table.seats[i].id);
      chairWrap.setAttribute('data-table-id', table.id);
      if (table.seats[i].guest && table.seats[i].guest.id) {
        chairWrap.setAttribute('data-seat-id', table.seats[i].guest.id);
      }

      chairWrap = this.generateChairWrap(chairWrap, table.seats[i]);

      element.append(chairWrap);
    }
  }


  generateChairWrap = (chairWrap, seat) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let image = document.createElement("img");
    let initialsEl = document.createElement('h5');
    let guestDetails = document.createElement("div");
    let guestDetailsDeleteImg = document.createElement('img');

    if (seat.guest && seat.guest.id) {
      let initials = [...seat.guest.name.matchAll(rgx)] || [];

      if (initials.length > 1) {
        initials = ((initials.shift()[1] || '') + (initials.pop()[1] || '')).toUpperCase();
      }

      if (initials.length === 1) {
        initials = seat.guest.name.substring(0, 2).toUpperCase();
      }

      image.src = "/images/chair-colored.png";
      initialsEl.className = "tables-configurator__chair-initials";
      initialsEl.textContent = initials;

      guestDetails.id = "guest-details";
      guestDetails.className = "tables-configurator__chair-guest-details hidden";
      guestDetails.setAttribute('data-id', seat.guest.id);

      let guestDetailsImg = document.createElement('img');
      guestDetailsImg.src = "/images/drag-indicator-black-24.png";
      guestDetails.append(guestDetailsImg);

      let guestDetailsName = document.createElement('h4');
      guestDetailsName.textContent = seat.guest.name + '( ' + (seat.guest.company_number || '-') + ')';
      guestDetails.append(guestDetailsName);

      guestDetailsDeleteImg.src = "/images/delete-black.png";
      guestDetailsDeleteImg.className = "tables-configurator__chair-guest-details--delete hidden";
      chairWrap.append(guestDetailsDeleteImg);

      guestDetailsDeleteImg.addEventListener("click", (event) => this.removeGuestFromChair(event, seat));

      chairWrap.addEventListener("click", (event) => this.selectChair(event, seat, chairWrap, guestDetails, guestDetailsDeleteImg));
    } else {
      image.src = "/images/chair.png";
    }

    chairWrap.append(image);

    if (seat.guest && seat.guest.id) {
      chairWrap.append(initialsEl);
      chairWrap.append(guestDetails);
    }

    return chairWrap;
  }

  getTableFreeSeats = tableId => {
    let freeSeats = 0;
    let tables = this.state.tables.slice();
    let tableIndex = tables.findIndex(table => table.id == tableId);

    tables[tableIndex].seats.map(seat => {
      if (seat.guest_id === null) {
        freeSeats = freeSeats + 1;
      }
    });

    return freeSeats;
  }

  updateGuests = (guestId) => {
    let guests = this.state.guests.slice();

    let guestIndex = guests.findIndex(guest => guest.id == guestId);

    if (guestIndex !== -1) {
      guests.splice(guestIndex, 1);
    }

    this.setState({ guests });
  }

  selectChair = (event, seat, elementWrap, guestElement, deleteIconEl) => {
    event.preventDefault();
    event.stopPropagation();

    let hiddenDetails = guestElement.classList.contains('hidden');

    if (hiddenDetails) {
      guestElement.classList.remove("hidden");
      deleteIconEl.classList.remove("hidden");
    } else {
      guestElement.classList.add("hidden");
      deleteIconEl.classList.add("hidden");
    }
  }

  removeGuestFromChair = (event, seat) => {
    event.preventDefault();
    event.stopPropagation();

    seatsService.removeGuestFromSeat(seat.id)
      .then(response => {
        response.data.seats.map(seat => {
          let chairWrap = document.getElementById(`chair-${seat.id}`);

          chairWrap.innerHTML = '';
          chairWrap.removeAttribute('data-seat-id');
          chairWrap.removeEventListener("click", chairWrap);

          let image = document.createElement("img");
          image.src = "/images/chair.png";
          chairWrap.append(image);
        });

        let tables = this.state.tables.slice();
        let currentTableIndex = tables.findIndex(table => table.id === seat.table_id);

        tables[currentTableIndex].seats.map(tableSeat => {
          if (tableSeat.guest_id === seat.guest_id) {
            tableSeat.guest_id = null;
          }
        })

        this.setState({ tables });

        guestsService.searchNoSeatInvites(this.state.currentEvent.id).then(response => {
          this.setPaginationState(response);
        })
          .catch(error => {
            if (error.response.status === 401) {
              this.props.history.push("/app/login");
            }
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  removeTable = () => {
    tablesService.deleteTable(this.state.selectedTable.id)
      .then(response => {
        let tables = this.state.tables.slice();
        let tableIndex = tables.findIndex(table => table.id === this.state.selectedTable.id);

        let tableWrap = document.getElementById(`table-${tables[tableIndex].id}`);

        interact(tableWrap).unset();
        tableWrap.remove();

        const tablesToUpdate = tables.slice(tableIndex + 1, tables.length);

        tablesToUpdate.map(table => {
          let interactTable = document.getElementById(`table-${table.id}`);

          if (interactTable) {
            const newYPosition = parseFloat(interactTable.getAttribute('data-y')) + 80;

            // translate the element
            interactTable.style.transform = 'translate(' + interactTable.getAttribute('data-x') + 'px, ' + newYPosition + 'px)'

            // update the posiion attributes
            interactTable.setAttribute('data-x', interactTable.getAttribute('data-x'));
            interactTable.setAttribute('data-y', newYPosition);

            tablesService.updateTablePosition(table.id, interactTable.getAttribute('data-x'), newYPosition)
              .then(response => {
                this.setNewTablePosition(table.id, response);
              })
              .catch(error => { console.log(error) })
          }
        })

        this.setState({ selectedTable: {} });

        guestsService.searchNoSeatInvites(this.state.currentEvent.id).then(response => {
          this.setPaginationState(response);
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.props.history.push("/app/login");
          }
        });
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/app/login");
        }
      })
  }

  setSelectedTable = (event, table) => {
    event.stopPropagation();

    let selectedTable = { ...this.state.selectedTable };

    if (selectedTable.id === table.id) {
      selectedTable = {};
    } else {
      selectedTable = { ...table };
    }

    this.setState({ selectedTable });
  }

  updateSearchField = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  openSearchField = () => {
    let searchFieldStatus = this.state.searchFieldStatus;
    searchFieldStatus = true;
    this.setState({ searchFieldStatus })
  }
  closeSearchField = () => {
    let searchFieldStatus = this.state.searchFieldStatus;
    searchFieldStatus = false;
    let searchName = this.state.searchName;
    searchName = "";
    this.setState({ searchName }, () => {
      guestsService.searchNoSeatInvites(this.state.currentEvent.id, this.state.searchName).then(response => {
        this.setPaginationState(response);
      })
        .catch(error => {
          if (error.response.status === 401) {
            this.props.history.push("/app/login");
          }
        })
    })
    this.setState({ searchFieldStatus })
  }

  handleSearch = event => {
    event.preventDefault();
    guestsService.searchNoSeatInvites(this.state.currentEvent.id, this.state.searchName, this.state.searchSelectedCategory).then(response => {
      this.setPaginationState(response);
    })
      .catch(err => console.log(err))
  }

  fetchNewInvites = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    guestsService.searchNoSeatInvites(this.state.currentEvent.id, '').then(response => {
      this.setPaginationState(response);
      if (response.data.length === 0) {
        notification.addNotification({
          message:
            `Toata lista de invitati a fost adaugata la masa cu succes`,
          level: 'success',
          position: 'tc'
        });
      }
    })
      .catch(err => console.log(err))
  }

  setPaginationState = response => {
    let guests = this.state.guests.slice();
    guests = response.data;

    this.setState({ guests }, () => {
      this.guestDragListener();
      this.setState({ viewGuests: !this.state.viewGuests }, () => {
        this.setState({ viewGuests: !this.state.viewGuests })
      })
    });
  };

  toggleViewGuests = () => {
    this.setState({ viewGuests: !this.state.viewGuests });
  }
  viewTable=()=>{
    this.setState({isViewTableOpen:true})
  }

  generatePDF = () => {
    this.setState({ exportStarted: true });
    const node = document.getElementById(`table-configurator-export-wrapper`);

    domtoimage
      .toPng(node)
      .then((dataUrl) => {
        let image = new Image();
        image.src = dataUrl;

        generatePdfDocument(image, this.state.currentEvent.name);
        this.setState({ exportStarted: false });
      })
      .catch(function (error) {
        console.error("oops, something wents wrong!", error);
      });
  }
  updateEventId=event=>{
    const eventId = event.target.value

    localStorage.setItem('idLocalStorage', eventId)
    let idLocalStorage=this.state.idLocalStorage;
    idLocalStorage=eventId;
    this.setState({idLocalStorage}, () => window.location.reload())
}
  render() {
    return (
      <div className={ this.state.expiredAccount ? 'standard__no-interaction admin website-form config' : 'admin website-form config'}>
        <Menu menuItem="tables-configurator" />
        <AdminMobileHeader page="Adăugare și aranjare mese" />
        <div className="standard__only-mobile admin__table-config-avertisment">
          <h3>Modulul de așezare la mese a invitaților este făcut pentru a fi folosit pe un ecran mare.</h3>
          <h3>Deci ia-ți partenerul și așezați-vă în fața laptop-ului pentru o experiență optimă.</h3>
        </div>
        <div className='admin__container standard__only-desktop'>
          <div className='admin__top'>
            <div className='admin__select-box'>
              <select
                className="admin__select"
                title="tip"
                value={this.state.idLocalStorage}
                name="type"
                onChange={this.updateEventId}
                label='tip'
              >
                {this.state.events.map(event => (
                  <option key={event.id} value={event.id}>{event.name}</option>
                ))}
              </select>
            </div>
            <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
          </div>
          { this.state.activeDemo ? (
            <div className="admin__no-something">
                <img src="/images/info.png" alt="info" />
                <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti { this.state.freeDays } zile si { this.state.freeHours } ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
            </div>
          ) : null }
          <div className='admin__wrapper'>
            <div className='guests__top-search'>
              <h2 className='admin__title'>Aranjare mese</h2>
            </div>
            <div className="config__top">
              <div className="config__stars">
                <img src="/images/stars.png" alt='magic' />
              </div>
              <div className="config__top-text">
                <h5>În acest panou poți adaugă mese, le poți aranja în sala sau elimina, poți să adaugi sau să ștergi invitații așezați la o masă. Joacă-te exact cum făceai când erai copil și fă din acest pas al evenimentului tău unul distractiv și fără nestresant.</h5>
                <h5>Hai să facem magie!</h5>
              </div>

            </div>
          </div>
          <div className="tables-configurator__config-section config__section">
            <div className="tables-configurator__top-buttons">
              <button className="website-form__add" onClick={() => this.openAddTableModal(false)}>Adaugă masă</button>
              {this.state.selectedTable.id ? (
                <div className="invitation__search-button tables-configurator__button" onClick={() => this.openAddTableModal(true)}>
                  <img src="/images/edit-black.png" alt="search" className="invitation__search-img" />
                </div>
              ) : (
                <div className="invitation__search-button tables-configurator__button tables-configurator__button--disabled">
                  <img src="/images/edit-disabled.png" alt="search" className="invitation__search-img" />
                </div>
              )}
              {this.state.selectedTable.id ? (
                <div className="invitation__search-button tables-configurator__button" onClick={() => this.removeTable()}>
                  <img src="/images/delete-black.png" alt="search" className="invitation__search-img" />
                </div>
              ) : (
                <div className="invitation__search-button tables-configurator__button tables-configurator__button--disabled">
                  <img src="/images/delete-disabled.png" alt="search" className="invitation__search-img" />
                </div>
              )}
              {this.state.selectedTable.id ? (
                <div className="invitation__search-button tables-configurator__button" onClick={() => this.viewTable()}>
                  <img src="/images/eye.png" alt="view" className="invitation__search-img" />
                </div>
              ) : (
                <div className="invitation__search-button tables-configurator__button tables-configurator__button--disabled">
                  <img src="/images/eye-disabled.png" alt="search" className="invitation__search-img" />
                </div>
              )}

            </div>
            <div className="tables-configurator__guests-list-wrapper">
              <h5>Lista invitați fără masă</h5>
              <div className="tables-configurator__guests-list-buttons">
                {this.state.viewGuests ? (
                  this.state.searchFieldStatus ? (
                    <form className='admin__form invitation__search-form tables-configurator__search-form' onSubmit={this.handleSearch}>
                      <div className="tables-configurator__search-input">
                        <input type='text'
                          name='searchName'
                          placeholder="Cauta dupa nume..."
                          className='invitation__search-field'
                          value={this.state.searchName}
                          onChange={this.updateSearchField} />
                      </div>
                      <div className="tables-configurator__search-select">
                        <select
                          className='add-modal__field'
                          name="searchSelectedCategory"
                          onChange={this.updateSearchField}
                          value={this.state.searchSelectedCategory}
                        >
                          <option value="">Categorie</option>
                          {this.state.categories.map(category => (
                            <option key={category.id} value={category.name}>{category.name}</option>
                          ))}
                        </select>
                      </div>
                      <img src='/images/search-black.png' onClick={(event) => this.handleSearch(event)} alt='search' className="tables-configurator__search-icon" />
                      <img src="/images/close-black.png" onClick={() => this.closeSearchField()} alt="close" className="invitation__close-search tables-configurator__close-search" />
                    </form>
                  ) : (<div className="invitation__search-button" onClick={() => this.openSearchField()}>
                    <img src="/images/search-black.png" alt="search" className="invitation__search-img" />
                  </div>)
                ) : (null)}
                <div className="invitation__search-button" onClick={() => this.toggleViewGuests()}>
                  <img src="/images/filter.png" alt="search" className="invitation__search-img" />
                </div>
              </div>
              {this.state.viewGuests ? (
                <div className="tables-configurator__guests-list">
                  {this.state.guests.length ?
                    this.state.guests.map(guest => (
                      <div id="guest-details" className="tables-configurator__guest" data-id={guest.id} data-company-number={guest.company_number || 0}>
                        <img src="/images/drag-indicator-black-24.png" />
                        <h4>{guest.name} ( {guest.company_number || '-'} )</h4>
                      </div>
                    )) : (
                      <div>
                        <button className="website-form__add" onClick={(event) => this.fetchNewInvites(event)}>Incarca lista invitati</button>
                      </div>
                    )}
                </div>
              ) : (
                null
              )}
            </div>
          </div>
          <div className="tables-configurator__wrapper">
            <div id='table-configurator-export-wrapper' className='tables-configurator__drag-wrapper'>
              {this.state.tables && this.state.tables.map(table => (
                <div
                  data-x={table.x_pos}
                  data-y={table.y_pos}
                  id={`table-${table.id}`}
                  className={table.id === this.state.selectedTable.id ? `tables-configurator__table-wrapper tables-configurator__table-wrapper-${table.geoType} tables-configurator__table-wrapper-${table.geoType}--selected` : `tables-configurator__table-wrapper tables-configurator__table-wrapper-${table.geoType}`}
                  onClick={(event) => this.setSelectedTable(event, table)}
                  style={{ transform: 'translate(' + table.x_pos + 'px, ' + table.y_pos + 'px)' }}
                >
                  <div className={`tables-configurator__handle tables-configurator__table-details`}>
                    <h4 className="tables-configurator__table-number" >{table.number}</h4>
                    <h6> {table.name} </h6>
                  </div>
                  <div id={`table-details-${table.id}`} className={`tables-configurator__table-${table.geoType}`}>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tables-configurator__export-wrapper">
            <button disabled={this.state.exportDisabled || this.state.exportStarted} className="website-form__add" onClick={this.generatePDF}>Genereaza PDF configuratie mese</button>
          </div>
        </div>
        {this.state.isAddTableModalOpen && <AddTable
          modalIsOpen={this.state.isAddTableModalOpen}
          closeModal={this.closeModal}
          addTable={this.addTable}
          isEditTableMode={this.state.isEditTableMode}
          table={this.state.selectedTable}
        />}
          {this.state.isViewTableOpen && <ViewTableModal
          modalIsOpen={this.state.isViewTableOpen}
          closeModal={this.closeModal}
          table={this.state.selectedTable}
        />}
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default TablesConfigurator;
