import React from 'react';
import '../../resources/main.scss';
import './AdminHamburgerMenu.scss';
import { loginService } from "../../services/LoginService";
import '../AdminMenu/AdminMenu.scss'
import { Link } from 'react-router-dom';

class AdminHamburgerMenu extends React.Component{
    constructor(props){
        super(props)
        this.state={
            activeMenu: false
        }
    }
    openMenu=() =>{
        this.setState({
            activeMenu:true       })
            document.body.classList.add('has-active-menu');
    };
    closeMenu=()=>{
        this.setState({
            activeMenu:false        })
            document.body.classList.remove('has-active-menu');
    }
    logout = () => {
        const notification = this.notificationSystem.current;

        loginService
            .logout()
            .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('userProfile');
                this.props.history.push('/app/login');
            })
            .catch(error => {
                notification.addNotification({
                    message: 'Delogare nereusita. Va rog sa ne contactati daca problema persista.',
                    level: 'error',
                    position: 'tc'
                });
            });
    }
    render(){
        return(
            <div className="standard hamburger ">
                <img
                onClick={this.openMenu}
                className="hamburger__icon"
                src='/images/menu.png'
                alt="hamburgerMenuLogo"
                />

          <ul
            className={
              'hamburger__links' + (this.state.activeMenu ? ' active' : '')
            }
          >
            <img src="/images/logo.png" alt="logo" className="hamburger__logo"/>
            <div className='hamburger__item-box'>
                        <img src='/images/menu/menu-1.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/dashboard'>
                            <li className='hamburger__item-name'>Dashboard</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/menu-2.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/evenimente'>
                            <li className='hamburger__item-name'>Evenimente</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/todo-list.svg' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/to-do-list'>
                            <li className='hamburger__item-name'>Todo list</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/menu-2.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/website-builder'>
                            <li className='hamburger__item-name'>Creare website</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/menu-4.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/adauga-invitat'>
                            <li className='hamburger__item-name'>Adaugă invitat</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/submenu-1.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/raspunsuri'>
                            <li className='hamburger__item-name'>Răspunsuri pozitive</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/submenu-2.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/raspunsuri-negative'>
                            <li className='hamburger__item-name'>Răspunsuri negative</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/menu-4.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/configurare-generala'>
                            <li className='hamburger__item-name'>Configurare generală mese</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/dot.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/tables-configurator'>
                            <li className='hamburger__item-name'>Aranjare mese</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/budget.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/buget'>
                            <li className='hamburger__item-name'>Buget</li>
                        </Link>
                    </div>
                    <div className='hamburger__item-box'>
                        <img src='/images/menu/present.png' className='hamburger__menu-icon' alt='menu' />
                        <Link className='standard__link' to='/app/registru-cadouri'>
                            <li className='hamburger__item-name'>Registru de cadouri</li>
                        </Link>
                    </div>
                      <div className='hamburger__item-box hamburger__logout' onClick={() => this.logout()}>
                            <img src='/images/menu/logout.png' className='hamburger__menu-icon' alt='menu' />
                            <Link className='standard__link' to='/app/website-builder'>
                                <li className='hamburger__item-name'>Delogare</li>
                            </Link>
                        </div>



          </ul>
            <img
              onClick={this.closeMenu}
              className={
                'hamburger__close-icon' + (this.state.activeMenu ? ' active' : '')
              }
              src="/images/close-black.png"
              alt="close Icon"
            />
        </div>
        )
    }
}

export default AdminHamburgerMenu;
