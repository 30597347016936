import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../../components/AdminMenu/AdminMenu';
import { invitationService } from '../../../services/InvitationService';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import { guestsService } from '../../../services/AdminService';
import { eventService } from '../../../services/EventService';
import ReactTooltip from 'react-tooltip';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';

class GuestPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            guest: {},
            currentEvent: {},
            events: [],
            deleteGuest: {
                openedDialog: false,
                deleteId: 0
            },
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        eventService.getEvents().then(response => {
            let events = this.state.events;
            events = response.data.events;
            let currentEvent = { ...this.state.currentEvent };
            currentEvent = { ...events[0] };

            this.setState({ events });
            this.setState({ currentEvent }, () => {
              if (this.props.match.params.id) {
                  guestsService.getGuest(this.props.match.params.id, this.state.currentEvent.id).then(response => {
                      let guest = { ...this.state.guest };
                      guest = response.data;
                      this.setState({ guest })
                  })
                  .catch(error => {
                    if (error.response.status === 401) {
                        this.props.history.push("/app/login");
                    }
                    if (error.response.status === 404) {
                        this.props.history.push("/app/evenimente");
                    }
                  })
              }
            })
        })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }

    switchCompany = companyName => {
        if (companyName === "single") {
            return "Singur(ă)"
        }
        if (companyName === "family") {
            return "Cu familia"
        }
        if (companyName === "couple") {
            return "Cuplu"
        }
    }

    openDeleteDialog = id => {
        let deleteGuest = { ...this.state.deleteGuest };
        deleteGuest = {
            openedDialog: true,
            deleteId: id
        };

        this.setState({ deleteGuest });
    };

    closeDeleteDialog = () => {
        let deleteGuest = { ...this.state.deleteGuest };
        deleteGuest = {
            openedDialog: false,
            deleteId: 0
        };

        this.setState({ deleteGuest });
    };

    handleDelete = () => {
        const notification = this.notificationSystem.current;
        guestsService
            .deleteGuest(this.state.deleteGuest.deleteId)
            .then(() => {
                this.closeDeleteDialog();

                notification.addNotification({
                    message: 'Invitatul a fost șters cu success.',
                    level: 'success',
                    position: 'tc'
                });

                setTimeout(() => {
                  this.props.history.push('/app/raspunsuri');
                }, 2000)
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    };

    render() {
        return (
            <div className=' guests admin invitation'>
                <Menu menuItem="" />
                <AdminMobileHeader page="Invitat" />
                <div className='admin__container'>
                    <div className='admin__top'>
                        <div className='admin__select-box'>
                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.eventId}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    <div className="admin__wrapper invitation__wrapper">
                        <div className=''>
                            <div className="admin__guest-top">
                                <Link to="/app/raspunsuri" className="standard__link"><img src='/images/go-back.png' alt='back' className="admin__back-icon" /></Link>
                                <h2 className="admin__title">Vizualizare invitat confirmat: {this.state.guest.name}</h2>
                            </div>
                            <div className="admin__guest-container">
                                <div className="admin__guest-icon-box">
                                    <img src="/images/guest.png" alt="guest" className="admin__guest-type-icon" />
                                    <h5>Prieten</h5>
                                </div>

                                <div className="admin__guest-info">
                                    <h3 className="admin__subtitle">Detalii personale</h3>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Codul de invitat:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.code}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Număr telefon:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.phone}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Adresă email:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.email}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Cu cine va veni:</h4>
                                        <h4 className="admin__info-specific">{this.switchCompany(this.state.guest.company)}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Numar total persoane:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.company_number}</h4>
                                    </div>
                                    <div className="admin__divider" />
                                    <h3 className="admin__subtitle">Detalii meniu</h3>
                                    <div className='admin__guest-info-box'>
                                        <h4 className='admin__info-generic'>Meniu:</h4>
                                        <h4 className='admin__info-specific'>{this.state.guest.menus && this.state.guest.menus.map(menu => (
                                            <div key={menu.id}>
                                                <h4>{menu.menu_type}</h4>
                                            </div>
                                        ))}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Alergii existente:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.allergy && this.state.guest.allergy !== 'no' ? 'da' : '-'}</h4>
                                    </div>
                                    {this.state.guest.allergy ? <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Alergii</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.allergy_mentions ? this.state.guest.allergy_mentions : '-'}</h4>
                                    </div> : ''}

                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Alte restricții:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.other_restrictions ? this.state.guest.other_restrictions : '-'}</h4>
                                    </div>
                                    <div className="admin__divider" />
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Cazare:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.housing ? 'Da' : 'Nu'}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Transport:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.transport ? 'Da' : 'Nu'}</h4>
                                    </div>
                                    <div className="admin__divider" />
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Participare Cununie:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.civil_marriage_attend ? 'Da' : 'Nu'}</h4>
                                    </div>
                                    <div className="admin__divider" />
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Alte mențiuni:</h4>
                                        <h4 className="admin__info-specific">{this.state.guest.other ? this.state.guest.other : '-'}</h4>
                                    </div>
                                    <div className="admin__guest-info-box">
                                        <h4 className="admin__info-generic">Actiuni:</h4>
                                        <div className="website-form__actions website-form__cell">
                                          <div className="admin__tool-box">
                                              <img data-tip='Șterge' data-event='mouseenter' src='/images/delete-black.png' alt='delete' onClick={() => this.openDeleteDialog(this.state.guest.id)} />
                                              <div className='admin__additional-info'>
                                                  <ReactTooltip globalEventOff='click' />
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="invitation__content-wrapper">


                            </div>

                        </div>
                    </div>
                </div>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />

                <DeletePUGCertificates
                    opened={this.state.deleteGuest.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur ca doriti sa ștergeți acest invitat?"
                    aditionalText="După ștergere, invitatul nu va mai putea fi recuperat si invitatia va fi resetata."
                />
            </div>
        )
    }
}

export default GuestPage;
