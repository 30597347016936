import React from 'react';
import { eventService } from '../../../services/EventService';
import '../Admin.scss';
import './Events.scss'
import Menu from '../../../components/AdminMenu/AdminMenu';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import EditEventModal from '../../../components/EditEventModal/EditEventModal'

class Events extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            isEditEventModalOpen:false,
            currentEvent:'',
            editMissingInformations: false
        }

    }
    componentDidMount() {
        eventService.getEvents().then(response => {
            let events = this.state.events;
            events = response.data.events;
            this.setState({ events });

            eventService.checkEvent(events[0].id)
              .then(response => {
              })
              .catch(error => {
                if (error.response.status === 409) {
                  if (error.response.data.missing_informations) {
                    this.setState({ editMissingInformations: true });
                    this.openEditEvent(events[0]);
                  }

                  return;
                }

                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
              })
        })
        .catch(error => {
            if (error.response.status === 401) {
                this.props.history.push("/app/login");
            }
        })
    }
    figureOutEvent = tag => {
        if (tag === "Cununie civila") {
            return "/images/step-1.png"
        }
        if (tag === "Cununie religioasa") {
            return "/images/step-2.png"
        }
        else if (tag === "Petrecere") {
            return "/images/step-3.png"
        }
    }
    openEditEvent=eventClicked=>{
        let currentEvent=this.state.currentEvent;
        currentEvent=eventClicked;
        this.setState({currentEvent});
        this.setState({isEditEventModalOpen:true});
    }
    closeModal=()=>{
        this.setState({isEditEventModalOpen:false})
    }
    render() {
        return (
            <div className='admin website-form events'>
                <Menu  menuItem="events" />
                <AdminMobileHeader page="Evenimente"/>
                <div className='admin__container' >

                    <div className='admin__wrapper'>
                        <h2 className='events__title'>Evenimente</h2>
                        <div className="events__events-container">
                            {this.state.events.map(event => (
                                <div className="events__event" key={event.id}>
                                    <div className="events__head">
                                        <h4 className="events__event-name">{event.name}</h4>
                                        <h4 className="events__event-type">{event.event_type}</h4>
                                    </div>
                                    <div className="events__info">
                                        <div className='events__info-box'>
                                            <h4 className="events__left">Slug</h4>
                                            <h4 className="events__right events__slug">{event.slug}</h4>
                                        </div>
                                        <div className='events__info-box'>
                                            <h4 className="events__left">Dată</h4>
                                            <h4 className="events__right">{event.date}</h4>
                                        </div>
                                        <div className='events__info-box'>
                                            <h4 className="events__left">Oras</h4>
                                            <h4 className="events__right">{event.city}</h4>
                                        </div>
                                        <div className='events__info-box'>
                                            <h4 className="events__left">Locație</h4>
                                            <h4 className="events__right">{event.location}</h4>
                                        </div>
                                        <div className='events__info-box'>
                                            <h4 className="events__left">Cod</h4>
                                            <h4 className="events__right">{event.code}</h4>
                                        </div>
                                        <div className='events__info-box'>
                                            <h4 className="events__left">Acțiuni</h4>
                                            <h4 onClick={() => this.openEditEvent(event)} className="events__right events__edit">Editează</h4>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <div className="standard__only-desktop website-form__wedding-contacts">
                                <div className="website-form__contact-head">
                                    <h4>Nume eveniment</h4>
                                    <h4 >Tip eveniment</h4>
                                    <h4>Slug</h4>
                                    <h4>Dată</h4>
                                    <h4>Oras</h4>
                                    <h4>Locație</h4>
                                    <h4>Cod</h4>
                                    <h4>Acțiuni</h4>
                                </div>
                                {this.state.events.map(event => (
                                    <div className="website-form__contact-row">
                                        <h4 className='website-form__cell'>{event.name}</h4>
                                        <h4 className='website-form__cell events__event-name'>{event.event_type}</h4>
                                        <h4 className='website-form__cell'>{event.slug}</h4>
                                        <h4 className='website-form__cell'>{event.date}</h4>
                                        <h4 className='website-form__cell'>{event.city}</h4>
                                        <h4 className='website-form__cell'>{event.location}</h4>
                                        <h4 className='website-form__cell'>{event.code}</h4>
                                        <div className="website-form__actions website-form__cell">
                                        <img src='/images/edit-black.png' alt='edit'onClick={() => this.openEditEvent(event)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>





                    </div>
                </div>
                {this.state.isEditEventModalOpen && <EditEventModal
                    modalIsOpen={this.state.isEditEventModalOpen}
                    closeModal={this.closeModal}
                    event={this.state.currentEvent}
                    stayOpenModal={this.state.editMissingInformations}
                />}
            </div>
        )
    }
}

export default Events;
