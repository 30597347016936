import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import {guestsService} from '../../services/AdminService';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    borderRadius: "32px"
  }
};

class EditCapacityHall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        tableConfiguration: {
        totalCapacity: '',
        maxTables: ''
    
      },
      eventId:this.props.eventId
    };

    this.notificationSystem = React.createRef();
  }

    componentDidMount() {
        console.log(this.props.tableConfiguration)
      if (this.props.tableConfiguration && Object.keys(this.props.tableConfiguration).length) {
        let tableConfiguration = { ...this.state.tableConfiguration };
        tableConfiguration.totalCapacity=this.props.tableConfiguration.total_capacity;
        tableConfiguration.maxTables=this.props.tableConfiguration.max_tables;
        tableConfiguration.id=this.props.tableConfiguration.id;

        this.setState({ tableConfiguration });
      }
    }

    handleChange = event => {
      console.log(event.target.checked);
        const field = event.target.name;
        const tableConfiguration = { ...this.state.tableConfiguration };
        tableConfiguration[field] = event.target.value;
        this.setState({ tableConfiguration });
    }



  handleEditCapacity = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    guestsService.editHallCapacity(this.state.eventId, this.state.tableConfiguration)
      .then(response => {
        console.log(response);
        notification.addNotification({
            message:
                `Capacitatea salii a fost editata  cu succes.`,
            level: 'success',
            position: 'tc'
        });
       setTimeout(() => {
        window.location.reload();
       }, 1000); 
      })
      .catch(error => {
        notification.addNotification({
            message: 'Ne pare rau, dar a aparut o problema. Va rog sa dati refresh sau sa contactati administratorul site-ului.',
            level: 'error',
            position: 'tc'
        });
      })
  }
 

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="modal Data"
          ariaHideApp={false}
        >
          <div className='standard add-modal'>
            <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
            <h2 className="add-modal__title">Editează capacitate sală</h2>
            <form onSubmit={this.handleEditCapacity}>
              <div>
                <h4 className='add-modal__label'>Capacitate totală restaurant:</h4>
                <input
                  className='add-modal__field'
                  type="number"
                  name="totalCapacity"
                  onChange={this.handleChange}
                  required
                  value={this.state.tableConfiguration.totalCapacity} />
              </div>
              <div>
                <h4 className='add-modal__label'>Nr. maxim de mese:</h4>
                <input
                  className='add-modal__field'
                  type="number"
                  name="maxTables"
                  onChange={this.handleChange}
                  required
                  value={this.state.tableConfiguration.maxTables} />
              </div>
              
             

              <div className="add-modal__buttons">
                <button type='submit' className='add-modal__button add-modal__submit'>Editează</button>
                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
              </div>
            </form>
          </div>
        </Modal>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default EditCapacityHall;
