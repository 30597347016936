import React from 'react';
import { eventService } from '../../../services/EventService';
import '../Admin.scss';
import './GeneralConfiguration.scss'
import Menu from '../../../components/AdminMenu/AdminMenu';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import { guestsService } from '../../../services/AdminService';
import { Arrow } from './Arrow.jsx';
import EditCapacityHall from "../../../components/EditCapacityHall/EditCapacityHall";
import { Link } from 'react-router-dom';
import { clientsService } from '../../../services/ClientsService';
import moment from "moment";

class GeneralConfiguration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            currentEvent: {},
            guests: [],
            isCapacityModalOpen: false,
            tableConfiguration: [],
            pagination: {},
            activeDemo: false,
            freeDays: 0,
            freeHours: 0,
            expiredAccount: false,
            idLocalStorage: ''
        }

        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        const notification = this.notificationSystem.current;
        this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })
        clientsService.checkClient()
            .then(response => {
                if (response.data.active_demo === false) {
                    this.setState({ expiredAccount: true });

                    notification.addNotification({
                        message: 'Contul demo a expirat, o sa fiti redirectionati catre profil pentru plata.',
                        level: 'error',
                        position: 'tc'
                    });

                    setTimeout(() => {
                        this.props.history.push("/app/profil");
                    }, 3000);

                    return;
                }

                if (response.data.active_demo) {
                    this.setState({ activeDemo: true });
                    const freeDays = moment(response.data.free_until).diff(moment(), 'days');
                    const freeHours = moment(response.data.free_until).diff(moment(), 'hours') - (parseInt(moment(response.data.free_until).diff(moment(), 'hours') / 24) * 24);

                    this.setState({ freeDays });

                    if (freeHours > 0) {
                        this.setState({ freeHours });
                    }
                }

                eventService.getEvents().then(response => {
                    let events = this.state.events;
                    events = response.data.events;
                    let currentEvent = { ...this.state.currentEvent };
                    let specificEvent = response.data.events.find(event => event.id === this.state.idLocalStorage);

                    currentEvent = specificEvent ? specificEvent : { ...events[0] };

                    this.setState({ events });
                    this.setState({ currentEvent }, () => {
                        guestsService.getGuests(this.state.currentEvent.id).then(response => {
                            let guests = this.state.guests.slice();
                            guests = response.data.guests;
                            this.setState({ guests })

                            let pagination = { ...this.state.pagination };
                            pagination = response.data.pagination;
                            this.setState({ pagination })
                        })
                            .catch(error => {
                                if (error.response.status === 401) {
                                    this.props.history.push("/app/login");
                                }
                            })

                        guestsService.getHallCapacity(this.state.currentEvent.id).then(response => {
                            let tableConfiguration = this.state.tableConfiguration;
                            tableConfiguration = response.data.table_configuration;
                            this.setState({ tableConfiguration })
                        })
                            .catch(error => {

                            })
                    })
                })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.props.history.push("/app/login");
                        }
                    })

            })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })
    }
    openCapacityHallModal = currentGuest => {
        this.setState({ isCapacityModalOpen: true })
    }
    closeModal = () => {
        this.setState({ isCapacityModalOpen: false });
    }
    updateEventId = event => {
        const eventId = event.target.value
        localStorage.setItem('idLocalStorage', eventId)
        let idLocalStorage = this.state.idLocalStorage;
        idLocalStorage = eventId;
        this.setState({ idLocalStorage }, () => window.location.reload())
    }

    render() {
        const settings = {
            infinite: true,
            speed: 200,
            swipe: true,
            swipeToSlide: true,
            adaptiveHeight: true,
            focusOnSelect: true,
            arrows: true,
            centeredMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: <Arrow type="next" />,
            prevArrow: <Arrow type="prev" />,
            dots: false,
            autoplay: false
        };
        return (
            <div className={this.state.expiredAccount ? 'standard__no-interaction admin website-form config' : 'admin website-form config'}>
                <Menu menuItem="configurare-generala" />
                <AdminMobileHeader page="Aranjare mese" />
                <div className='admin__container' >

                    <div className='admin__top'>
                        <div className='admin__select-box'>
                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.idLocalStorage}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    {this.state.activeDemo ? (
                        <div className="admin__no-something">
                            <img src="/images/info.png" alt="info" />
                            <h5>Atentie! Sunteti in perioada de cont demo. Mai aveti {this.state.freeDays} zile si {this.state.freeHours} ramase. Pentru a activa contul va rugam sa platiti factura din sectiunea <Link to="/app/profil" className="standard__link--profile">profil</Link>.</h5>
                        </div>
                    ) : null}
                    <div className='admin__wrapper'>
                        <div className='guests__top-search'>
                            <h2 className='admin__title'>Aranjare mese</h2>
                        </div>
                        <div className="config__top">
                            <div className="config__stars">
                                <img src="/images/stars.png" alt='magic' />
                            </div>
                            <div className="config__top-text">
                                <h5>Let’s do some magic here!</h5>
                                <h5>Poți adaugă câte mese dorești utilizând butonul “Adaugă masă”. Personalizeaz-o așa cum vrei: formă, numărul de persoane, invitați în ordinea perfectă, poți chiar să dai un nume fiecărei mese.</h5>
                                <h5>Hai să facem acest pas simplu și magic!</h5>
                            </div>

                        </div>
                    </div>

                    <div className="config__section">
                        <button className="website-form__add" onClick={() => this.openCapacityHallModal()}>Editează capacitate sală</button>
                        <div className="config__edit-capacity">
                            <div className="config__capacity-box">
                                <h4 className="config__capacity-name"> Capacitate totală restaurant: </h4>
                                <h4 className="config__capacity-info">{this.state.tableConfiguration.total_capacity} </h4>
                            </div>
                            <div className="config__capacity-box">
                                <h4 className="config__capacity-name"> Nr. maxim de mese: </h4>
                                <h4 className="config__capacity-info">{this.state.tableConfiguration.max_tables} </h4>
                            </div>

                            <div className="config__capacity-box">

                                <h4 className="config__capacity-name"> Nr. invitați confirmați:</h4>
                                <h4 className="config__capacity-info">{this.state.tableConfiguration.confirmed_guests} </h4>

                            </div>

                            <div className="config__capacity-box">
                                <h4 className="config__capacity-name"> Nr. invitați aranjați la mese:</h4>
                                <h4 className="config__capacity-info">{this.state.tableConfiguration.seated_guests}</h4>
                            </div>
                        </div>
                        {this.state.guests.length > this.state.tableConfiguration.total_capacity ? <div className="admin__no-something">
                            <img src="/images/info.png" alt="info" />
                            <h5>Atentie! Ai depasit capacitatea sălii.</h5>
                        </div> : ''}
                    </div>

                    <div className="config__section">
                        <h3 className="config__subtitle">Lista invitați</h3>
                        {this.state.guests.length ? '' : <div className="admin__no-something">
                            <img src="/images/info.png" alt="info" />
                            <h5>Nu ai niciun invitat adăugat în sistem. Dacă doreșți să faci asta, mergi la pagină Adaugă invitat și începe să-ti organizezi evenimentul mult visat.</h5>
                        </div>}
                        <div className="config__guests">
                            {this.state.guests.length && this.state.guests.map(guest => (
                                <div key={guest.id} className='config__guest'>
                                    <h3>{guest.name}</h3>
                                </div>
                            ))
                            }

                        </div>
                    </div>
                    <div className="config__section">
                        <Link to='/app/tables-configurator' className="standard__link"><button className="website-form__add">Mergi la aranjare mese</button></Link>
                    </div>
                </div>
                {this.state.isCapacityModalOpen && <EditCapacityHall
                    modalIsOpen={this.state.isCapacityModalOpen}
                    closeModal={this.closeModal}
                    tableConfiguration={this.state.tableConfiguration}
                    eventId={this.state.currentEvent.id}
                />}
            </div>
        )
    }
}

export default GeneralConfiguration;
