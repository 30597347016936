import axios from 'axios';
import { loginService } from './LoginService';

export const passwordService={
changePassword
}

function changePassword(userData){
    const requestOptionsPost = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    const data = buildRequestData(userData)

    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

    let url = `${process.env.REACT_APP_API_URL}/api/users/change_password`;

    return axios.post(url, data, requestOptionsPost);
  }

  function buildRequestData(passwordData) {
    let formData = new FormData();

    formData.append('user[current_password]', passwordData.currentPassword);

    formData.append('user[password]', passwordData.password);

    formData.append('user[password_confirmation]', passwordData.confirmPassword);



    return formData;
  }
