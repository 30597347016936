import React from 'react';
import './Article.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import { blogService } from '../../services/BlogService';
import { Link } from 'react-router-dom';
import {
    FacebookShareButton,
    WhatsappShareButton
} from "react-share";
import { withRouter } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import DOMPurify from 'dompurify';
import Moment from 'react-moment';
import MetaTags from 'react-meta-tags';

class Article extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            article: {},
            slug: '',
            articles: []
        }
        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            blogService.getArticleBySlug(this.props.match.params.id).then(response => {
                let article = { ...this.state.article };
                let slug = this.state.slug;
                slug = response.data.slug;
                article = response.data;
                this.setState({ article });
                this.setState({ slug })
            })
                .catch(err => console.log(err))
        } else {
            this.props.history.push('/blog');
        }
        blogService.getArticles().then(response => {
            let articles = this.state.articles;
            let newestArticle = { ...this.state.newestArticle }
            articles = response.data.articles;
            newestArticle = articles[articles.length - 1];

            this.setState({ articles });
            this.setState({ newestArticle });
        })
            .catch(err => console.log(err))

    }

    copyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: `Link-ul a fost copiat.`,
            level: 'success',
            position: 'tc'
        });
    }

    render() {

        return (
            <div className='standard article blog'>
                <MetaTags>
                  <title>{this.state.article.title}</title>
                  <meta property="og:title" content={this.state.article.title} />
                  <meta name="description" content={this.state.article.meta_description} />
                  <meta property="og:description" content={this.state.article.meta_description} />
                  <meta name="keywords" content={this.state.article.meta_tags} />
                  <meta property="og:url"
                      content={`https://www.facemnunta.ro/app/article/${this.state.article.slug}`}
                  />
                  <meta
                    property="og:image"
                    content={ this.state.article.main_image && this.state.article.main_image.url ? process.env.REACT_APP_BLOG_API_URL + this.state.article.main_image.url : '' }
                  />
                  <meta property="og:type" content="article" />
                </MetaTags>
                <Header />
                <div className='article__container'>
                    <div className='article__image-div'>
                        {this.state.article.main_image && this.state.article.main_image.url ? (
                            <img src={process.env.REACT_APP_BLOG_API_URL + this.state.article.main_image.url} alt={this.state.article.main_image_alt} className='article__main-img' />
                        ) : (
                            <img src="/images/wedding.png" className='article__main-img' alt='article main' />
                        )}
                        <div className='article__img-shadow' />
                    </div>
                    <div className='article__wrapper'>
                        <div className='blog__time-box article__time-box'>
                            <h4>Organizare</h4>
                            <div className='blog__circle' />
                            <h5 className='blog__article-time'>
                                <Moment format="DD/MM/YYYY">
                                    {this.state.article.updated_at}
                                </Moment>
                            </h5>
                        </div>
                        <div className='article__social'>
                            <FacebookShareButton url={`https://www.facemnunta.ro/app/article/${this.state.article.slug}`}>
                                <img src='/images/facebook-black.png' alt='facebook' />
                            </FacebookShareButton>
                            <WhatsappShareButton url={window.location.href}>
                                <img src='/images/whatsapp.png' alt='whatsapp' />
                            </WhatsappShareButton>
                            <img src='/images/link.png' alt='link' onClick={this.copyLink} />
                        </div>
                        <h1 className='article__title'>
                            {this.state.article.title}
                        </h1>
                        <div className='article__info-wrapper'>
                            <p className='article__paragraf article__first-paragraph' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.article.description) }}></p>

                            {this.state.article.article_sections && this.state.article.article_sections.map((article_section, index) => (
                                article_section.tip !== 'image' ? (
                                    article_section.tip === 'paragraf' ? (
                                        React.createElement(
                                            article_section.fe_component,
                                            { className: `article__${article_section.tip}`, key: article_section.id, alt: article_section.image_alt || `section_image-${article_section.id}`, dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(article_section.value) } }
                                        )
                                    ) : (
                                        React.createElement(
                                            article_section.fe_component,
                                            { className: `article__${article_section.tip}`, key: article_section.id },
                                            article_section.value
                                        )
                                    )
                                ) : (
                                    React.createElement(
                                        article_section.fe_component,
                                        {
                                            className: `article__${article_section.tip}`,
                                            key: article_section.id,
                                            src: article_section.image && article_section.image.url ? `${process.env.REACT_APP_BLOG_API_URL}${article_section.image.url}` : '/images/image-gym.jpg'
                                        },
                                        null
                                    )
                                )

                            ))}
                        </div>
                    </div>
                    <div className='article__social article__social-bottom'>
                        <FacebookShareButton url={`https://www.facemnunta.ro/app/article/${this.state.article.slug}`} quote="Test">
                            <img src='/images/facebook.png' alt='facebook' />
                        </FacebookShareButton>
                        <WhatsappShareButton url={window.location.href}>
                            <img src='/images/whatsapp.png' alt='whatsapp' />
                        </WhatsappShareButton>
                        <img src='/images/link.png' alt='link' onClick={this.copyLink} />
                    </div>

                    <div className='article__actions-box'>
                        {this.props.history.action !== 'POP' ? (
                            <div className='standard__link article__action' onClick={this.props.history.goBack}>
                                <img src='/images/back-orange.png' alt='back' />
                                <h3>Înapoi</h3>
                            </div>
                        ) : (
                            <Link className='standard__link article__action' to='/app/blog'>
                                <img src='/images/back-orange.png' alt='back' />
                                <h3>Înapoi</h3>
                            </Link>
                        )}

                        {this.state.article.next_slug ? (
                            <Link className='standard__link article__action' to={`/articol/${this.state.article.next_slug}`}>
                                <h3>Articolul următor</h3>
                                <img src='/images/right-orange.png' alt='next' />
                            </Link>
                        ) : ''}
                    </div>
                    <div className='blog__all-articles article__all-articles'>
                        <h3 className='blog__subtitle article__blog-subtitle'>Dacă ți-a plăcut ce ai citit, pariem că îți vor plăcea și următoarele articole:</h3>
                        <div className='blog__line' />
                        <div className='article__articles-wrapper'>
                            {this.state.articles.slice(0, 3).map(article =>
                                <div key={article.id} className='blog__article-container article__single-article' >
                                    <div className=''>
                                        <Link to={`${article.slug}`} className='standard__link blog__article-whole' >
                                            <div className='blog__image-div'>
                                                {article.main_image && article.main_image.url ? (
                                                    <img src={process.env.REACT_APP_BLOG_API_URL + article.main_image.url} className='blog__article-img article__blog-img' alt='wedding' />
                                                ) : (
                                                    <img src="/images/wedding.jpg" className='blog__article-img article__blog-img' alt='wedding' />
                                                )}
                                                <div className='blog__article-image-shadow' />
                                            </div>
                                            <div className='blog__article-only-info'>
                                                <div className='blog__time-box blog__article-time-box'>
                                                    <h4>Organizare</h4>
                                                    <div className='blog__circle' />
                                                    <h5 className='blog__article-time'>
                                                        <Moment format="DD/MM/YYYY">
                                                            {article.updated_at}
                                                        </Moment>
                                                    </h5>
                                                </div>
                                                <h3 className='blog__wrapper-article-title'>{article.title}</h3>
                                                <p className='blog__article-description' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.description) }}></p>

                                                <div className='blog__article-read-box article__article-read-box'>
                                                    <h4>Citeste articolul</h4>
                                                    <img src='/images/right-orange.png' alt='see article' />
                                                </div>

                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default withRouter(Article);
