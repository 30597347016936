import axios from 'axios';
import { loginService } from './LoginService';

export const websiteDetailsService={
    getDetails,
    updateWebsiteDetails,
    previewWebsite,
    liveWebsite,
    getWebsiteBySlug,
    getInvitationBySlug,
    previewInvitation,
    updateRegistryStatus
}
function getDetails(eventId){

     let url = `${process.env.REACT_APP_API_URL}/api/website_details/details?event_id=${eventId}`;
     const requestOptionsGet = {
         method: 'GET',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         }
       };

       if (loginService.loggedIn()) {
         requestOptionsGet.headers['Authorization'] = loginService.getToken();
       }

       return axios(requestOptionsGet);
 }

function updateWebsiteDetails(websiteDetailsData) {
  let url = `${process.env.REACT_APP_API_URL}/api/website_details/${websiteDetailsData.id}`;

  const requestOptionsPost = {
      method: 'PUT',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: buildRequestData(websiteDetailsData)
    };

    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}

function liveWebsite(liveWebsite, id) {
  let url = `${process.env.REACT_APP_API_URL}/api/website_details/live_website/`;

  const requestOptionsPost = {
      method: 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        website_details: {
          live: liveWebsite,
          event_id: id
        }
      }
    };

    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function updateRegistryStatus(registryStatus, id) {
  let url = `${process.env.REACT_APP_API_URL}/api/website_details/registry_list_activation_website/`;

  const requestOptionsPost = {
      method: 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        website_details: {
          registry_list_activation: registryStatus,
          event_id: id
        }
      }
    };

    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}

function buildRequestData(websiteDetailsData) {
  let formData = new FormData();

  formData.append('website_details[invitation_text]', websiteDetailsData.invitationText || '');
  formData.append('website_details[footer_text]', websiteDetailsData.footerText || '');
  formData.append('website_details[footer_disclaimer]', websiteDetailsData.footerDisclaimer || '');
  formData.append('website_details[video_invitation]', websiteDetailsData.videoInvitation);
  formData.append('website_details[active_website_video]', websiteDetailsData.activeWebsiteVideo);
  formData.append('website_details[long_invitation_text]', websiteDetailsData.longInvitationText);
  formData.append('website_details[simple_story]', websiteDetailsData.simpleStory);

  const document = websiteDetailsData.invitationImage;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`website_details[invitation_image]`, document.url);
    } else {
      formData.append(`website_details[invitation_image]`, document, document.name);
    }
  }

  const documentSimpleStory = websiteDetailsData.simpleStoryImage;

  if (documentSimpleStory && documentSimpleStory.url !== null) {
    if (documentSimpleStory.url) {
      formData.append(`website_details[simple_story_image]`, documentSimpleStory.url);
    } else {
      formData.append(`website_details[simple_story_image]`, documentSimpleStory, documentSimpleStory.name);
    }
  }

  const documentBackgroundImage = websiteDetailsData.backgroundImage;

  if (documentBackgroundImage && documentBackgroundImage.url !== null) {
    if (documentBackgroundImage.url) {
      formData.append(`website_details[background_image]`, documentBackgroundImage.url);
    } else {
      formData.append(`website_details[background_image]`, documentBackgroundImage, documentBackgroundImage.name);
    }
  }

  const video = websiteDetailsData.invitationVideo;

  if (video && video.url !== null) {
    if (video.url) {
      formData.append(`website_details[invitation_video]`, video.url);
    } else {
      formData.append(`website_details[invitation_video]`, video, video.name);
    }
  }

  const generalVideo = websiteDetailsData.generalWebsiteVideo;

  if (generalVideo && generalVideo.url !== null) {
    if (generalVideo.url) {
      formData.append(`website_details[general_website_video]`, generalVideo.url);
    } else {
      formData.append(`website_details[general_website_video]`, generalVideo, generalVideo.name);
    }
  }

  return formData;
}

function previewWebsite(slug) {
  let url = `${process.env.REACT_APP_API_URL}/api/events/preview_website?slug=${slug}`;

  const requestOptionsGet = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    if (loginService.loggedIn()) {
      requestOptionsGet.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsGet);

}

function getWebsiteBySlug(slug) {
  let url = `${process.env.REACT_APP_API_URL}/api/events/live_website?slug=${slug}`;

  const requestOptionsGet = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    return axios(requestOptionsGet);
}

function getInvitationBySlug(slug) {
  let url = `${process.env.REACT_APP_API_URL}/api/events/live_invitation?slug=${slug}`;

  const requestOptionsGet = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    return axios(requestOptionsGet);
}

function previewInvitation(slug) {
  let url = `${process.env.REACT_APP_API_URL}/api/events/preview_invitation?slug=${slug}`;

  const requestOptionsGet = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    if (loginService.loggedIn()) {
      requestOptionsGet.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsGet);
}
