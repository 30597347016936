import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import './Add.scss';
import { websiteEventPartsService } from '../../services/WebsiteEventPartsService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import AttachementField from '../AttachementField/AttachementField';
const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius:"32px",
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxHeight: '80%',
  }
};

class AddEventPartModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventPart: {
        tag: '',
        tagIcon: '',
        location: '',
        address: '',
        date: '',
        hour: '',
        image:"",
        website_detail_id: this.props.websiteDetailsId
      },
      disabledDocument: false
    };

    this.notificationSystem = React.createRef();
  }
  componentDidMount() {
    if (this.props.eventPart && Object.keys(this.props.eventPart).length) {
      let eventPart = { ...this.state.eventPart };
      eventPart = {
        ...eventPart,
        ...this.props.eventPart,
        active: this.props.eventPart.active || undefined,
        tagIcon: this.props.eventPart.tag_icon
      };

      if (this.props.eventPart.story_steps && this.props.eventPart.story_steps.length) {
        eventPart.storySteps = this.props.eventPart.story_steps.slice();
      }

      this.setState({ eventPart });
    }
  }

  handleChange = event => {
    const field = event.target.name;
    const eventPart = { ...this.state.eventPart };
    eventPart[field] = event.target.value;

    if (field === 'tag') {
      const options = {
        "Cununie civila": "cununie",
        "Cununie religioasa": "biserica",
        "Petrecere": "petrecere"
      }

      eventPart.tagIcon = options[event.target.value];
    }

    this.setState({ eventPart });
  }

  handleAddContact = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    websiteEventPartsService.createOrUpdatePart(this.state.eventPart)
      .then(response => {
        this.props.updateEventPartList(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  handleHourChange = event => {
    console.log(event);
  }
  onDropDocument = documents => {
    let eventPart = { ...this.state.eventPart };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
        eventPart.image = documents[0];

      self.setState({ eventPart });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let eventPart = { ...this.state.eventPart };
    eventPart.image = null;
    eventPart.image_filename = '';

    this.setState({ eventPart });
    this.setState({ disabledDocument: false });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="modal Data"
          ariaHideApp={false}
        >
          <div className='standard add-modal'>
            <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
            <h2 className="add-modal__title">{this.state.eventPart.id? "Editează":"Adaugă"} etapă</h2>
            <form onSubmit={this.handleAddContact}>
              <div>
                <h4 className='add-modal__label'>Tag (alege un nume pentru evenimentul tău: cununie civilă, cununie religioasă, petrecere, etc.) </h4>
                <select
                  className="add-modal__field"
                  title="tag"
                  value={this.state.eventPart.tag}
                  name="tag"
                  onChange={this.handleChange}
                >
                  <option value=''>Selectează Tag</option>
                  <option value="Cununie civila">Cununie civilă</option>
                  <option value="Cununie religioasa">Cununie religioasă</option>
                  <option value="Cununie umanista">Cununie umanistă</option>
                  <option value="Petrecere">Petrecere</option>

                </select>
              </div>
              <div>
                <h4 className='add-modal__label'>Denumire locație</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="location"
                  onChange={this.handleChange}
                  required
                  value={this.state.eventPart.location} />
              </div>
              <div>
                <h4 className='add-modal__label'>Adresă locație</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="address"
                  onChange={this.handleChange}
                  required
                  value={this.state.eventPart.address} />
              </div>
              <div>
                <h4 className='add-modal__label'>Imagine locatie</h4>
                <AttachementField
                              onDrop={this.onDropDocument}
                              removeDocument={this.removeDocument}
                              disabledDocuments={this.state.disabledDocument}
                              document={this.state.eventPart.image}
                              documentFilename={
                                  this.state.eventPart.image_filename
                              }
                              text="Adauga fotografie"
                              type="image/*"
                            />
              </div>
              <div>
                <h4 className='add-modal__label'>Data</h4>
                <input
                  className='add-modal__field'
                  type="date"
                  name="date"
                  onChange={this.handleChange}
                  required
                  value={this.state.eventPart.date} />
              </div>
              <div>
                <h4 className='add-modal__label'>Ora</h4>
                <input
                  className="add-modal__field"
                  type="time"
                  name="hour"
                  onChange={this.handleChange}
                  value={this.state.eventPart.hour}
                />
              </div>
              <div className="add-modal__buttons">
                <button type='submit' className='add-modal__button add-modal__submit'>{this.state.eventPart.id? "Editează":"Adaugă"}</button>
                <button type='submit' className='add-modal__button add-modal__cancel'onClick={this.props.closeModal}>Închide</button>
              </div>

            </form>
          </div>
        </Modal>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default AddEventPartModal;
