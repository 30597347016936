import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

import { compose, withProps } from 'recompose';

const GoogleMaps = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD3pQt__gn6xqyPX5nGJVYgnua1zuwxzuA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="google-location" />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: parseFloat(props.lat), lng: parseFloat(props.long) }}
  >
    <Marker position={{ lat: parseFloat(props.lat), lng: parseFloat(props.long) }} />
  </GoogleMap>
));

export default GoogleMaps;
