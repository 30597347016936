import React from 'react';
import './HamburgerMenu.scss';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {withRouter} from 'react-router-dom';

class HamburgerMenu extends React.Component{
    constructor(props){
        super(props)
        this.state={
            activeMenu: false
        }
    }
    openMenu=() =>{
        this.setState({
            activeMenu:true       })
            document.body.classList.add('has-active-menu');
    };
    closeMenu=()=>{
        this.setState({
            activeMenu:false        })
            document.body.classList.remove('has-active-menu');
    }

    scrollToBenefits = () => {
        const scroller = Scroll.scroller;

        scroller.scrollTo('benefits', {
          duration: 1000,
          smooth: true,
          spy: true
        })

        this.closeMenu();
      }

    render(){
        return(
            <div className="standard mobile-menu">
                <img
                onClick={this.openMenu}
                className="mobile-menu__icon"
                src='/images/mobile-menu.png'
                alt="mobile-menuMenuLogo"
                />
          <ul
            className={
              'mobile-menu__links' + (this.state.activeMenu ? ' active' : '')
            }
          >
            <h3 className='mobile-menu__title'>Meniu</h3>
            <Link to='/' className='standard__link' onClick={this.closeMenu}> <li className=" mobile-menu__list-item">Acasă</li></Link>

            {this.props.location.pathname === '/' ? (
              <Link to='/' className='standard__link' onClick={this.scrollToBenefits}> <li className=" mobile-menu__list-item">Beneficii</li></Link>
            ) : (
              <Link className='standard__link' to={`/?insidePageUrl=benefits`} onClick={this.closeMenu}><li className='mobile-menu__list-item'>Beneficii</li></Link>
            )}
            <Link to='/app/risipa-mancare' className='standard__link' onClick={this.closeMenu}> <li className=" mobile-menu__list-item">Evită risipa alimentară</li></Link>
            <Link to='/app/cununie-civila' className='standard__link' onClick={this.closeMenu}> <li className=" mobile-menu__list-item">Cununie civilă 101</li></Link>

            <Link to='/app/creeaza-cont' className='standard__link' onClick={this.closeMenu}> <li className=" mobile-menu__list-item">Înregistrare</li></Link>
            <Link to='/app/parteneri' className='standard__link' onClick={this.closeMenu}> <li className=" mobile-menu__list-item">Parteneri</li></Link>
            <Link to='/app/blog' className='standard__link' onClick={this.closeMenu}> <li className=" mobile-menu__list-item">Blog</li></Link>
            <Link to='/app/login' className='standard__link' onClick={this.closeMenu}> <li className=" mobile-menu__list-item">Contul tău</li></Link>
          </ul>
            <img
              onClick={this.closeMenu}
              className={
                'mobile-menu__close-icon' + (this.state.activeMenu ? ' active' : '')
              }
              src="/images/close.png"
              alt="close Icon"
            />
        </div>
        )
    }
}

export default withRouter(HamburgerMenu);
