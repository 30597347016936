import React from 'react';
import './ContactUs.scss';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        zIndex: 30,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        borderRadius: '32px'
    }
};

class ContactUs extends React.Component {

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}

                >
                    <div className='standard quote-modal qr contact-moda;'>
                        <img src='/images/close.png' className='quote-modal__close-icon contact-modal__close-icon' alt='close' onClick={this.props.closeModal} />
                        <h3 className='contact-modal__title'>Contactează-ne!</h3>
                        <h4 className='contact-modal__subtitle'>Scrie-ne pentru orice întrebare ai și îți vom răspunde rapid prin:</h4>
                        <div className=' contact-modal__container'>

                            <a href={`tel:+40747774796`} className='standard__link contact-modal__contact-box'>
                                <img src='/images/phone-icon.png' alt='suna' />
                                <h5>Telefon direct</h5>
                            </a>
                            <a
                                className='standard__link contact-modal__contact-box'
                                href={`https://api.whatsapp.com/send?phone=+40747774796&text=Buna, %0aCu ce te putem ajuta?`} target="_blank"
                                rel="noopener noreferrer">
                                <img src='/images/whatsapp-icon.png' alt='whatsapp' />
                                <h5>Whatsapp</h5>
                            </a>
                            <a
                                className='standard__link contact-modal__contact-box'
                                href={`https://www.facebook.com/facemnunta`} target="_blank"
                                rel="noopener noreferrer">
                                <img src='/images/messenger-icon.png' alt='messenger' />
                                <h5>Messenger</h5>
                            </a>
                        </div>

                    </div>

                </Modal>
            </div>
        )
    }
}

export default ContactUs;
