import React from 'react';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import AttachementField from '../AttachementField/AttachementField';
import AddMenuModal from '../AddMenuModal/AddMenuModal';
import DeletePUGCertificates from '../../components/DeleteDialog/DeleteDialog';
import { invitationFormService } from '../../services/InvitationFormService';
import { menuTypeConfigService } from '../../services/MenuTypeConfigService';
import { websiteDetailsService } from '../../services/WebsiteDetailsService';

class InvitationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invitationForm: {
        welcomeText: '',
        welcomeImage: '',
        refusedImage: '',
        refusedText: '',
        extraServices: '',
        accommodation: false,
        transport: false,
        message: '',
        messageRefused: '',
        civil_marriage_attend: false,
        civil_marriage_message: ''
      },
      menus: [],
      menuModalOpened: false,
      disabledWelcomeImage: false,
      disabledRefusedImage: false,
      deleteWebsiteMenu: {
        openedDialog: false,
        deleteId: 0
      },
      menuToEdit: {}
    };

    this.notificationSystem = React.createRef();
  }

  componentDidMount() {
    invitationFormService.getDetails(this.props.eventId)
      .then(response => {
        let invitationForm = { ...this.state.invitationForm };

        invitationForm = {
          ...response.data.invitation_form,
          welcomeText: response.data.invitation_form.welcome_text || '',
          refusedText: response.data.invitation_form.refused_text || '',
          welcomeImage: response.data.invitation_form.welcome_image,
          refusedImage: response.data.invitation_form.refused_image,
          extraServices: response.data.invitation_form.extra_services || '',
          messageRefused: response.data.invitation_form.message_refused || '',
        };

        let menus = this.state.menus.slice();
        menus = response.data.invitation_form.menu_type_configs.slice();

        this.setState({ invitationForm });
        this.setState({ menus });

      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push("/app/login");
        }
      })
  }

  handleChange = event => {
    const field = event.target.name;
    const invitationForm = { ...this.state.invitationForm };
    invitationForm[field] = event.target.value;
    this.setState({ invitationForm });
  }

  handleCheckboxChange = event => {
    const field = event.target.name;
    const invitationForm = { ...this.state.invitationForm };
    invitationForm[field] = event.target.checked;
    this.setState({ invitationForm });
  }

  openAddMenuModal = (menu) => {
    this.setState({ menuModalOpened: true });

    let menuToEdit = { ...this.state.menuToEdit };
    menuToEdit = menu;

    this.setState({ menuToEdit });
  }

  closeModal = () => {
    this.setState({ menuModalOpened: false });
  }

  onDropDocument = documents => {
    let invitationForm = { ...this.state.invitationForm };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      invitationForm.welcomeImage = documents[0];

      self.setState({ invitationForm });
      self.setState({ disabledWelcomeImage: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let invitationForm = { ...this.state.invitationForm };
    invitationForm.welcomeImage = null;
    invitationForm.welcome_image_filename = '';

    this.setState({ invitationForm });
    this.setState({ disabledWelcomeImage: false });
  }

  onDropRefusedDocument = documents => {
    let invitationForm = { ...this.state.invitationForm };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      invitationForm.refusedImage = documents[0];

      self.setState({ invitationForm });
      self.setState({ disabledRefusedImage: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeRefusedDocument = () => {
    let invitationForm = { ...this.state.invitationForm };
    invitationForm.refusedImage = null;
    invitationForm.refused_image_filename = '';

    this.setState({ invitationForm });
    this.setState({ disabledRefusedImage: false });
  }

  handleSubmit = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    invitationFormService.updateInvitationForm(this.state.invitationForm)
      .then(response => {
        notification.addNotification({
          message:
            `Builder-ul a fost editat cu succes.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        notification.addNotification({
          message:
            'Ne pare rău, a intervenit o problemă. Dacă un refresh la pagină nu ajută, contactați-ne prin email sau telefon.',
          level: 'error',
          position: 'tc'
        });
      })
  }

  openDeleteWebsiteMenuDialog = id => {
    let deleteWebsiteMenu = { ...this.state.deleteWebsiteMenu };
    deleteWebsiteMenu = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteWebsiteMenu });
  };

  closeDeleteWebsiteMenuDialog = () => {
    let deleteWebsiteMenu = { ...this.state.deleteWebsiteMenu };
    deleteWebsiteMenu = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteWebsiteMenu });
  };

  handleDeleteMenu = () => {
    const notification = this.notificationSystem.current;
    menuTypeConfigService
      .deleteMenu(this.state.deleteWebsiteMenu.deleteId)
      .then(() => {
        let menus = this.state.menus.slice();
        menus = menus.filter(
          menu => menu.id !== this.state.deleteWebsiteMenu.deleteId
        );
        this.setState({ menus });

        this.closeDeleteWebsiteMenuDialog();

        notification.addNotification({
          message: 'Meniul a fost șters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        notification.addNotification({
          message:
            'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  updateMenuList = (newMenu) => {
    let menus = this.state.menus.slice();

    const menuIndex = menus.findIndex(menu => menu.id === newMenu.id);

    if (menuIndex !== -1) {
      menus[menuIndex] = { ...newMenu };
    } else {
      menus.unshift(newMenu)
    }

    this.setState({ menus }, () => {
      this.closeModal();
    });
  }

  render() {
    return (
      <div className="website-form add-modal">
        <form onSubmit={this.handleSubmit}>
          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Detalii formular pentru invitații care acceptă:</h4>
            <div className="website-form__labels">
              <h4 className='website-form__label'>Imagine banner pentru pagina de formular (recomandat imagine wide):</h4>
              <h4 className='website-form__label'>Mesaj de întampinare</h4>
            </div>
            <div className="website-form__text-and-pic">
              <div className="website-form__attachement">

                <AttachementField
                  onDrop={this.onDropDocument}
                  removeDocument={this.removeDocument}
                  disabledDocuments={this.state.disabledWelcomeImage}
                  document={this.state.invitationForm.welcomeImage}
                  section="website-form-attachement"
                  documentFilename={
                    this.state.invitationForm.welcome_image_filename
                  }
                  type="image/*"
                />
              </div>
              <div className="website-form__attachement-text">
                <textarea
                  className='website-form__textarea website-form__attachement-textarea'
                  type="text"
                  name="welcomeText"
                  onChange={this.handleChange}
                  value={this.state.invitationForm.welcomeText} />
              </div>
            </div>
            <div className="website-form__attachement-text">
              <h4 className='website-form__label'>Mesaj general pentru invitati:</h4>
              <textarea
                className='website-form__textarea website-form__attachement-textarea'
                type="text"
                name="message"
                onChange={this.handleChange}
                value={this.state.invitationForm.message} />
            </div>
          </div>

          <div className='website-form__divider' />
          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Tipuri de meniuri</h4>
            <button type='button' className="website-form__add" onClick={() => this.openAddMenuModal({})}>Adauga Meniu</button>
            <div>
              <h4 className='website-form__label'>Meniuri Existente</h4>
              <h5 className='website-form__label'>Meniurile se discută cu restaurantul înainte de a fi adaugate în aplicație (vegetarian, vegan etc)</h5>
              <div className="website-form__wedding-contacts website-form__menus">
                <div className="website-form__contact-head">
                  <h4>Tip Meniu</h4>
                  <h4>Acțiuni</h4>
                </div>
                {this.state.menus.map(menu => (
                  <div className="website-form__contact-row" key={menu.id}>
                    <h4 className='website-form__cell'>{menu.menu_type}</h4>
                    <div className="website-form__actions website-form__cell">
                      <img className="action-icon" src="/images/edit-black.png" onClick={() => this.openAddMenuModal(menu)} />
                      <img className="action-icon" src="/images/delete-black.png" onClick={() => this.openDeleteWebsiteMenuDialog(menu.id)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='website-form__divider' />

          <div className="website-form__section">
            <h4 className='website-form__subtitle'>Servicii Extra oferite invitaților</h4>
            <div>
              <h4 className='website-form__label'>Mesaj general pentru serviciile extra: (Ex. Noi oferim cazare si transport din Suceava)</h4>
              <input
                className='website-form__textarea'
                type="text"
                name="extraServices"
                onChange={this.handleChange}
                value={this.state.invitationForm.extraServices} />
            </div>
            <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Oferire cazare</h4>
              <label class="switch">
                <input type="checkbox"
                  name="accommodation"
                  onChange={this.handleCheckboxChange}
                  checked={this.state.invitationForm.accommodation} />
                <span class="slider round"></span>
              </label>
            </div>


            <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Oferire Transport</h4>
              <label class="switch">
                <input type="checkbox"
                  name="transport"
                  onChange={this.handleCheckboxChange}
                  checked={this.state.invitationForm.transport} />
                <span class="slider round"></span>
              </label>
            </div>
            <div className='website-form__divider' />

            <div className="website-form__section">
              <h4 className='website-form__subtitle'>Vrei să îți întrebi invitații dacă vin și la cununia religioasă/civilă/umanistă?</h4>
              <div className="add-modal__toggle-consent">
              <h4 className='add-modal__label'>Selectează:</h4>
                <label class="switch">
                  <input type="checkbox"
                    name="civil_marriage_attend"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.invitationForm.civil_marriage_attend} />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <h4 className='website-form__label'>Formulează întrebarea: </h4>
                <input
                  className='website-form__textarea'
                  type="text"
                  name="civil_marriage_message"
                  onChange={this.handleChange}
                  value={this.state.invitationForm.civil_marriage_message} />
              </div>
            </div>
            <div className='website-form__divider' />


            <div className="website-form__section">
              <h4 className='website-form__subtitle'>Detalii generale pentru invitații care refuză</h4>
              <div className="website-form__labels">
                <h4 className='website-form__label'>Imagine banner formular negativ (recomandat imagine wide):</h4>
                <h4 className='website-form__label'>Mesaj de întâmpinare (refuz):</h4>
              </div>

              <div className="website-form__text-and-pic">
                <div className="website-form__attachement">
                  <AttachementField
                    onDrop={this.onDropRefusedDocument}
                    removeDocument={this.removeRefusedDocument}
                    disabledDocuments={this.state.disabledRefusedImage}
                    document={this.state.invitationForm.refusedImage}
                    section="website-form-attachement"
                    documentFilename={
                      this.state.invitationForm.refused_image_filename
                    }
                    type="image/*"
                  />
                </div>
                <div className="website-form__attachement-text">

                  <textarea
                    className='website-form__textarea website-form__attachement-textarea'
                    type="text"
                    name="refusedText"
                    onChange={this.handleChange}
                    value={this.state.invitationForm.refusedText} />
                </div>
              </div>
            </div>
            <div className='website-form__divider' />
            <div className="website-form__attachement-text">
              <h4 className='website-form__label'>Mesaj general (ex. ne pare rau ca nu puteti fi alaturi de noi, dar daca doriti ne puteti ajuta cu urmatoarele):</h4>
              <textarea
                className='website-form__textarea website-form__attachement-textarea'
                type="text"
                name="messageRefused"
                onChange={this.handleChange}
                value={this.state.invitationForm.messageRefused} />
            </div>
          </div>
          <div className='website-form__divider' />
          <div>
            <button type='submit' className='website-form__save' >Salvează</button>
          </div>
        </form>
        {this.state.menuModalOpened && <AddMenuModal
          modalIsOpen={this.state.menuModalOpened}
          closeModal={this.closeModal}
          invitationFormId={this.state.invitationForm.id}
          menu={this.state.menuToEdit}
          updateMenuList={this.updateMenuList}
        />}
        <DeletePUGCertificates
          opened={this.state.deleteWebsiteMenu.openedDialog}
          handleClose={this.closeDeleteWebsiteMenuDialog}
          handleDelete={this.handleDeleteMenu}
          headline="Sunteți sigur ca doriti sa ștergeți acest meniu?"
          aditionalText="După ștergere, meniul nu va mai putea fi recuperat."
        />
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default InvitationForm;
