import axios from 'axios';
import { loginService } from './LoginService';

export const formService={
    positiveForm,
    negativeForm,
    editForm,
    guestDetails
}
function positiveForm(userData){

     let url = `${process.env.REACT_APP_API_URL}/api/guests${userData.id ? `/${userData.id}` : ''}`;
     const requestOptionsPost = {
         method: userData.id ? 'PUT' : 'POST',
         url,
         headers: {
           Accept: 'application/json',
           'Content-type': 'application/json'
         },
         data:{
             guest:{
                id: userData.id,
                code:userData.code,
                name:userData.name,
                phone:userData.phone,
                email:userData.email,
                company:userData.company,
                company_number:userData.companyNumber,
                menus_attributes:userData.menus.map(menu => ({ menu_type: menu.menu_type })),
                allergy:userData.allergy,
                allergy_mentions:userData.allergyMentions,
                other_restrictions:userData.otherRestrictions,
                housing:userData.housing,
                transport:userData.transport,
                other:userData.other,
                event_code:userData.eventCode,
                event_slug: decodeURIComponent(userData.clientSlug),
                civil_marriage_attend:userData.civil_marriage_attend
             }
         }

       };

       return axios(requestOptionsPost);
 }

 function negativeForm(userData){

    let url = `${process.env.REACT_APP_API_URL}/api/refuzed_invitations`;
    const requestOptionsPost = {
        method: 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data:{
            refuzed_invitation:{
               code:userData.code,
               name:userData.name,
               reason:userData.reason,
               event_code:userData.eventCode,
               event_slug: decodeURIComponent(userData.clientSlug)
            }
        }
      };

      return axios(requestOptionsPost);
}
function editForm(userData){
    let url = `${process.env.REACT_APP_API_URL}/api/guests/${userData.id}`;
    const requestOptionsPost = {
        method: 'PUT',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },

        data:{
            guest:{
               code:userData.code,
               name:userData.name,
               phone:userData.phone,
               email:userData.email,
               company:userData.company,
               company_number:userData.companyNumber,
               menus_attributes:userData.menus.map(menu => ({ id: menu.id, menu_type: menu.menuType })),
               allergy:userData.allergy,
               allergy_mentions:userData.allergyMentions,
               other_restrictions:userData.otherRestrictions,
               housing:userData.housing,
               transport:userData.transport,
               other:userData.other,
               event_code:userData.eventCode,
               civil_marriage_attend:userData.civil_marriage_attend,
               event_slug: decodeURIComponent(userData.clientSlug)
            }
        }

      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
}

function guestDetails(eventCode, code) {
  let url = `${process.env.REACT_APP_API_URL}/api/guests/guest_details?event_code=${eventCode}&code=${code}`;
  const requestOptionsPost = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    return axios(requestOptionsPost);
}
