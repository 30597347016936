import axios from 'axios';
import { loginService } from './LoginService';

export const budgetService = {
  createOrUpdateExpense,
  getExpenses,
  deleteExpense,
  getBudget,
  updateBudget,
  getTotal
}

function createOrUpdateExpense(expenseData, budgetId) {

  let url = `${process.env.REACT_APP_API_URL}/api/expenses${expenseData.id ? `/${expenseData.id}` : ''}`;
  const requestOptionsPost = {
    method: expenseData.id ? 'PUT' : 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: createBodyRequest(expenseData)
  };
  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}
function createBodyRequest(expenseData) {
  let formData = new FormData();

  if (expenseData.id) {
    formData.append('expense[id]', expenseData.id);
  }

  formData.append('expense[name]', expenseData.name);
  formData.append('expense[total_price]', expenseData.total_price);
  formData.append('expense[advance_payment]', expenseData.advance_payment);
  formData.append('expense[cost_per_guest]', expenseData.cost_per_guest);
  formData.append('expense[paid]', expenseData.paid);
  formData.append('expense[comment]', expenseData.comment);
  formData.append('expense[budget_id]', expenseData.budgetId);
  formData.append('expense[currency]', expenseData.currency);

  const document = expenseData.document;

  if (document && document.url !== null) {
    if (document.url) {
      formData.append(`expense[document]`, document.url);
    } else {
      formData.append(`expense[document]`, document, document.name);
    }
  }



  return formData;
}
function getExpenses(eventId, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/expenses?page[number]=${pageNumber ? pageNumber : 1}&budget_id=${eventId}`;

  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  }
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptions);
}



function deleteExpense(expenseId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] = loginService.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/expenses/${expenseId}`;

  return axios.delete(url, requestOptionsDelete);
}
function getBudget(eventId, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/budgets/details?page[number]=${pageNumber ? pageNumber : 1}&event_id=${eventId}`;

  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  }
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptions);
}

function updateBudget(budgetData) {
  let url = `${process.env.REACT_APP_API_URL}/api/budgets/${budgetData.id}`;
  const requestOptionsPost = {
    method: 'PUT',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: budgetData
  };
  if (loginService.loggedIn()) {
    requestOptionsPost.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptionsPost);
}

function getTotal(budgetId) {
  let url = `${process.env.REACT_APP_API_URL}/api/budgets/calculate_total_row?budget_id=${budgetId}`;
  const requestOptions = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  }
  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptions);
}
