import axios from 'axios';
import { loginService } from './LoginService';

export const invitationService={
    createOrUpdateInvitation,
    createOrUpdateCategory,
    getCategories,
    getInvitations,
    deleteInvitation,
    invitationsStatistics,
    searchInvitation,
    resetInvitation,
    deleteCategory
}

function createOrUpdateInvitation(invitationData, eventId){

    let url = `${process.env.REACT_APP_API_URL}/api/wedding_invitations${invitationData.id ? `/${invitationData.id}` : ''}`;
    const requestOptionsPost = {
        method: invitationData.id? 'PUT': 'POST',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data: {
          ...invitationData,
          event_id:eventId
        }
      };
      if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptionsPost);
}
function createOrUpdateCategory(invitationData, eventId){

  let url = `${process.env.REACT_APP_API_URL}/api/categories${invitationData.id ? `/${invitationData.id}` : ''}`;
  const requestOptionsPost = {
      method: invitationData.id? 'PUT': 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        ...invitationData,
        event_id:eventId
      }
    };
    if (loginService.loggedIn()) {
      requestOptionsPost.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptionsPost);
}
function getCategories(eventId, pageNumber){
  let url = `${process.env.REACT_APP_API_URL}/api/categories?page[number]=${pageNumber ? pageNumber : 1}&event_id=${eventId}`;

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
  }
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] = loginService.getToken();
    }

    return axios(requestOptions);
}
function getInvitations(eventId, pageNumber){
    let url = `${process.env.REACT_APP_API_URL}/api/wedding_invitations?page[number]=${pageNumber ? pageNumber : 1}&event_id=${eventId}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}

function invitationsStatistics(eventId){
    let url = `${process.env.REACT_APP_API_URL}/api/wedding_invitations/invitations_statistics?event_id=${eventId}`;

    const requestOptions = {
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
    }
      if (loginService.loggedIn()) {
        requestOptions.headers['Authorization'] = loginService.getToken();
      }

      return axios(requestOptions);
}
function deleteCategory(categoryId){
  const requestOptionsDelete = {
      headers: {
        Accept: 'application/json'
      }
    };

    if (loginService.loggedIn()) {
      requestOptionsDelete.headers['Authorization'] =loginService.getToken();
    }
    let url = `${process.env.REACT_APP_API_URL}/api/categories/${categoryId}`;

    return axios.delete(url, requestOptionsDelete);
}
function deleteInvitation(invitationId){
    const requestOptionsDelete = {
        headers: {
          Accept: 'application/json'
        }
      };

      if (loginService.loggedIn()) {
        requestOptionsDelete.headers['Authorization'] =loginService.getToken();
      }
      let url = `${process.env.REACT_APP_API_URL}/api/wedding_invitations/${invitationId}`;

      return axios.delete(url, requestOptionsDelete);
}
function searchInvitation(eventId, searchObject, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/wedding_invitations/search?page[number]=${
    pageNumber ? pageNumber : 1
  }&event_id=${eventId}`;

  Object.keys(searchObject).map(searchKey => {
    if (searchObject[searchKey] !== '') {
      url += `&${searchKey}=${searchObject[searchKey]}`;
    }
  })

  const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] =loginService.getToken();
    }
    return axios(requestOptions)
}
function resetInvitation(invitationId){
  let url = `${process.env.REACT_APP_API_URL}/api/wedding_invitations/reset_invitation/${invitationId}`;


  const requestOptions = {
    url,
    method:'POST',
    headers: {
      Accept: 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] =loginService.getToken();
  }

  return axios( requestOptions);
}
