import React from 'react';
import './LandingPage.scss';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Slider from "react-slick";
import bannerGalleryJson from './bannerGallery.json';
import benefitsJson from './benefits.json';
import siteGalleryJson from './siteGallery.json';
import reviewsJson from './reviews.json';
import MetaTags from 'react-meta-tags';
import Footer from '../../components/Footer/Footer';
import Arrow from './Arrow';
import SecondArrow from './SecondArrow';
import * as Scroll from 'react-scroll';
import qs from 'qs';
import { googleVerificationService } from '../../services/GoogleVerificationService';

class LandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bannerGallery: bannerGalleryJson,
            benefits: benefitsJson,
            siteGallery: siteGalleryJson,
            reviews: reviewsJson
        }
    }
    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    componentDidMount() {
      this._notificationSystem = this.refs.notificationSystem;

      window.grecaptcha.ready(function() {
        window.grecaptcha
          .execute('6Lc4VHUeAAAAAHFrbwy7JveIQMokNSP419so-hQS', {
            action: 'ComunitateaTaHomepage'
          })
          .then(function(token) {
            googleVerificationService.verifyToken(token).then(response => {
              if (response.data.score < 0.2) {
                this._notificationSystem.addNotification({
                  message: 'Google Recaptcha a detectat o activitate suspecta, veti fi redirectionat de pe website.',
                  level: 'error',
                  position: 'tc'
                });

                setTimeout(() => {
                  window.location.assign('https://www.google.com');
                }, 4000);
              }
            });
          });
      });

      if (this.props.location.search) {
          const insideScrollTarget = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).insidePageUrl;

          let scroller = Scroll.scroller;

          scroller.scrollTo(insideScrollTarget, {
            duration: 1500,
            smooth: true,
            delay: 300
          })
      }
    }

    render() {
        const settings = {
            infinite: true,
            speed: 200,
            swipe: true,
            swipeToSlide: true,
            adaptiveHeight: true,
            focusOnSelect: true,
            arrows: true,
            centeredMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <Arrow type="next" />,
            prevArrow: <Arrow type="prev" />,
            dots: false,
            autoplay: false
        };
        const settingsWebsiteGallery = {
            infinite: true,
            speed: 200,
            swipe: true,
            swipeToSlide: true,
            adaptiveHeight: true,
            focusOnSelect: true,
            arrows: true,
            centeredMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SecondArrow type="next" />,
            prevArrow: <SecondArrow type="prev" />,
            dots: false,
            autoplay: false
        };

        return (
            <div className='standard landing'>
                <Header menuItem='homepage'/>
                <MetaTags>
                    <title>Facem Nunta</title>
                    <meta
                        name="description"
                        content="Facem Nunta este platforma online de organizare si planificare destinata mirilor si mireselor| Pentru o nunta asa cum iti doresti, fara stres sau compromisuri."
                    />
                    <meta
                        name="keywords"
                        content="facem nunta, nunta, organizare nunta, planificare nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta"
                    />
                    <meta
                        property="og:title"
                        content="Facem Nunta"
                    />
                    <meta
                        property="og:url"
                        content="https://www.facemnunta.ro"
                    />
                </MetaTags>
                <div className='landing__container'>
                    <div className='landing__first-part'>
                        <div className='landing__slider-box'>
                            <h1 className='landing__title standard__only-desktop'>Facem nuntă</h1>
                            <Slider {...settings} className='landing__banner-slider'>
                                {
                                    this.state.bannerGallery.map(banner => (
                                        <div key={banner.id} className='landing__banner-one-slider'>
                                            <img src={banner.imgUrl} alt="wedding" className='landing__banner-slider-img' alt="banner" />
                                        </div>
                                    ))
                                }

                            </Slider>
                        </div>
                        <div className='landing__top-section'>
                            <div className='landing__social-media'>
                                <a className='standard__link'
                                    href="https://www.facebook.com/facemnunta"
                                    target="_blank"
                                    rel="noopener noreferrer nofollow">   <img src='/images/facebook-icon.png' alt='facebook' /></a>
                                <a className='standard__link'
                                    href="https://www.instagram.com/facemnunta"
                                    target="_blank"
                                    rel="noopener noreferrer nofollow">  <img src='/images/instagram-icon.png' alt='instagram' /></a>
                                {/* <img src='/images/pinterest-icon.png' alt='pinterest' /> */}
                            </div>
                            <div className='landing__section landing__top-info'>
                                <h1 className='landing__subtitle landing__main-title'>Aplicația perfectă pentru o nuntă reușită</h1>
                                <div className='landing__line landing__main-line' />
                                <h4 className='landing__info landing__main-info'>Facem Nuntă este o aplicație “all-in-one” care să ajute pe viitorii miri în organizarea unei nunți de vis.</h4>
                                <h4 className='landing__info landing__main-info'>Vă invităm să descoperiți cât de ușor poate fi să organizezi nunta perfectă prin aplicația Facem Nuntă.</h4>
                                <Link to='/app/creeaza-cont' onClick={this.scrollToTop} className='standard__link'>   <button className='landing__action-button'>Începe să planifici</button></Link>
                            </div>
                        </div>
                    </div>

                    <div className='landing__section landing__colored-section landing__story-section'>
                        <h1 className='landing__subtitle'>Povestea noastră</h1>
                        <div className='landing__line' />
                        <h4 className='landing__info landing__italic-info'>	Noi suntem Cristina și Valentin, fondatorii Facem nuntă și primul experiment al aplicației. Ca și pentru voi, planificarea nunții a fost atât un prilej de bucurie, dar, să fim sinceri, și de anxietate. Cu ce ne-a ajutat website-ul nunții? </h4>
                        <h4 className='landing__info landing__italic-info'>Ei bine, după ce am publicat site-ul, am avut o referință pentru invitați pentru locațiile cununiei și a petrecerii. Am avut un countdown pe care îl verificam zilnic până la eveniment ”35 de zile, 10 ore și 50 de secunde până la nuntă!”). Invitații au putut răspunde invitației online, specificând restricții alimentare sau preferințe. Ne mândrim cu zero reacții alergice și zero vegetarieni flămânzi.</h4>
                        <h4 className='landing__info landing__italic-info'>Am publicat pe website cum ne-am cunoscut, lucru care pentru noi a luat forma a 5 pași simpli pentru a-ți cunoaște viitorul partener (iar pentru voi poate lua orice formă vi se potrivește, poate o formă mai puțin apropiată de un articol din Cosmopolitan; sau poate mai mult, noi nu judecăm). </h4>
                        <h4 className='landing__info landing__italic-info'>Am avut propria noastră bază de date cu invitați pe care am trimis-o foarte ușor restaurantului. Zecile de exceluri sau hârtiuțe cu tabele de care ne-au avertizat alte cupluri au devenit un mit.</h4>
                        <h4 className='landing__info landing__italic-info'>A, și încă avem o amprentă digitală a evenimentului pe care suntem siguri că părinții, nostalgici, mai intră din când în când.</h4>
                        <h4 className='landing__info landing__italic-info landing__bigger-font'><i>Pentru noi a  meritat din plin. </i></h4>
                    </div>

                    <div className='landing__section' name="benefits">
                        <h1 className='landing__subtitle'>Beneficii</h1>
                        <div className='landing__line' />
                        <div className='landing__benefits-container'>
                            {this.state.benefits.map(benefit => (
                                <div key={benefit.id} className='landing__benefit'>
                                    <img src={benefit.img} className='landing__benefit-img' alt='benefits' />
                                    <div className='landing__benefit-info-wrapper'>
                                        <h2 className='landing__benefit-title'>{benefit.title}</h2>
                                        <div className='landing__line landing__benefit-line' />
                                        <h4 className='landing__benefit-info'>{benefit.details}</h4>
                                    </div>
                                    <h3 className='landing__benefit-number'>{benefit.number}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='landing__third-section'>
                        <div className='landing__section landing__colored-section'>
                            <h1 className='landing__subtitle landing__left-title'>Să organizezi nunta visurilor tale a devenit foarte simplu</h1>
                            <div className='landing__line landing__main-line' />
                            <ul className='landing__list'>
                                <li>Povestea mirilor într-o grafică inedită</li>
                                <li>Countdown până la eveniment</li>
                                <li>Date, locația sau locațiile</li>
                                <li>Maps și imagini pentru cununia civilă, cea religioasă și  petrecere</li>
                                <li>Persoanele de contact în ziua nunții</li>
                              
                            </ul>
                        </div>

                        <Slider {...settingsWebsiteGallery} className='landing__banner-slider landing__site-slider'>
                            {
                                this.state.siteGallery.map(site => (
                                    <div key={site.id} className='landing__banner-one-slider landing__site-one-slider'>
                                        <img src={site.imgUrl} className='landing__banner-slider-img landing__site-slider-img' alt="landing banner" />
                                    </div>
                                ))
                            }

                        </Slider>
                    </div>

                    {/* <div className='landing__review-section landing__section landing__colored-section landing__review-desktop'>
                        <Slider {...settingsReviews} className='landing__review-slider'>
                            {
                                this.state.reviews.map(review => (
                                    <div key={review.id} className='landing__review-slider'>
                                        <div className='landing__review-top'>
                                            <h3>{review.number}</h3>
                                            <h4>{review.couple}</h4>
                                        </div>
                                        <div className='landing__review-box'>
                                            <img src={review.imgUrl} className='landing__review-slider-img' />
                                            <div className='landing__review-text'>
                                                <img src='/images/quotes-up.png' className='landing__quotes-up' alt='quotes' />
                                                <p>{review.description1}</p>
                                                <p>{review.description2}</p>
                                                <p>{review.description3}</p>
                                                <p>{review.description4}</p>
                                                <p>{review.description5}</p>
                                                <img src='/images/quotes-down.png' className='landing__quotes-down' alt='quotes' />
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }

                        </Slider>
                    </div> */}
                    <div className='landing__section  landing__story-section'>
                        <h1 className='landing__subtitle'> Oferta noastră</h1>
                        <div className='landing__line' />
                        <div className='landing__outline'>
                            <div className='landing__offer'>
                                <h3 className='landing__offer-title'>Pachet standard:</h3>
                                <h4 className='landing__price'> € 80 (TVA inclus)</h4>
                                <ul className='landing__offer-list'>
                                    <li>Website de prezentare a evenimentului</li>
                                    <li>Informații despre locațiile evenimentului (harta, persoane de contact)</li>
                                    <li>Formular pentru răspunsul invitaților</li>
                                    <li>Posibilitatea personalizării formularului (meniuri speciale, cazare, transport)</li>
                                    <li>Galerie Foto</li>
                                    <li>Vizualizarea în timp real a statusului invitaților</li>
                                    <li>Modul dinamic de așezare la mese a invitaților</li>
                                    <li>Management al bugetului nunții</li>
                                    <li>Registru de cadouri</li>
                                    <li>To-do list</li>
                                </ul>
                                <Link to='/app/creeaza-cont' onClick={this.scrollToTop} className='standard__link'> <button className='landing__final-button'>Începe acum</button></Link>
                            </div>
                        </div>

                    </div>
                    <div className='landing__section landing__final-section'>
                        <h1 className='landing__subtitle'> Facem nuntă - asistentul nunților reușite</h1>
                        <div className='landing__line' />
                        <h4 className='landing__final-info'>De la prezentarea evenimentului, invitații online și RSVP, gestiunea listei de invitați și acces la fotografiile de nuntă, totul va fi posibil în doar cateva click-uri. Aplicația de organizare și planificare a nunții a fost gândită să vă ajute să economisiți timp, telefoane și mesaje. </h4>
                        <h3 className='landing__final-subtitle'>Pentru o zi perfectă ai nevoie de planificare!</h3>
                        <Link to='/app/creeaza-cont' onClick={this.scrollToTop} className='standard__link'>   <button className='landing__final-button'>Începe acum</button></Link>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default LandingPage;
