import React from 'react';
import './Budget.scss';
import Menu from '../../../components/AdminMenu/AdminMenu';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import AdminMobileHeader from '../../../components/AdminMobileHeader/AdminMobileHeader';
import BudgetModal from "../../../components/BudgetModal/BudgetModal";
import { eventService } from '../../../services/EventService';
import { budgetService } from "../../../services/BudgetService";
import ReactTooltip from 'react-tooltip';
import currenciesJson from './currencies.json';

class Budget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            budgetData: {
                base_currency: '',
                expected_guests: '',
                id: ''
            },
            expenses: [],
            isBudgetModalOpen: false,
            pagination: {},
            events: [],
            currentEvent: {},
            pagination: {},
            currentExpense: {},
            totalCost: 0,
            amountLeft: 0,
            costPerGuest: 0,
            totalAdvance: 0,
            totalRowCurrency: 'RON',
            deleteExpense: {
                openedDialog: false,
                deleteId: 0
            },
            idLocalStorage: ''
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        this.setState({ idLocalStorage: parseInt(localStorage.getItem("idLocalStorage")) || '' })
        eventService.getEvents().then(response => {
            let events = this.state.events;
            events = response.data.events;
            let currentEvent = { ...this.state.currentEvent };
            let specificEvent = response.data.events.find(event => event.id === this.state.idLocalStorage);
            currentEvent = specificEvent ? specificEvent : { ...events[0] };

            this.setState({ events });
            console.log(currentEvent.id)
            this.setState({ currentEvent }, () => {
                budgetService.getBudget(this.state.currentEvent.id).then(response => {
                    let budgetData = { ...this.state.budgetData };
                    budgetData = response.data;

                    this.setState({ budgetData })

                    budgetService.getTotal(this.state.budgetData.id).then(response => {
                        console.log(response.data);
                        let totalCost = response.data.total_cost;
                        let amountLeft = response.data.left_to_pay;
                        let costPerGuest = response.data.cost_per_invitee;
                        let totalAdvance = response.data.total_advance;
                        let totalRowCurrency = response.data.currency;

                        this.setState({ totalCost });
                        this.setState({ amountLeft });
                        this.setState({ costPerGuest });
                        this.setState({ totalAdvance });
                        this.setState({ totalRowCurrency });
                    })
                        .catch(error => {
                            console.log(error)
                        })

                    budgetService.getExpenses(this.state.budgetData.id).then(response => {
                        this.setPaginationState(response);
                    })
                        .catch(error => {
                            console.log(error)
                        })

                })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.props.history.push("/app/login");
                        }
                    })
            })
        })
            .catch(error => {
                if (error.response.status === 401) {
                    this.props.history.push("/app/login");
                }
            })




        console.log(this.state.budgetData)

    }
    handlePageClick = selectedPage => {
        budgetService.getExpenses(this.state.budgetData.id, selectedPage.selected + 1)
            .then(jsonResponse => {
                this.setPaginationState(jsonResponse);
            })
            .catch(error => {
                console.log(error);
            });
    };
    setPaginationState = response => {
        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
            pagination
        });
        let expenses = this.state.expenses;
        expenses = response.data.expenses;

        this.setState({ expenses });
    };
    updateField = event => {
        const field = event.target.name;
        const budgetData = { ...this.state.budgetData };
        budgetData[field] = event.target.value;
        this.setState({
            budgetData
        });
    };

    calculateTotalRow = () => {
        budgetService.getTotal(this.state.budgetData.id).then(response => {
            let totalCost = response.data.total_cost;
            let amountLeft = response.data.left_to_pay;
            let costPerGuest = response.data.cost_per_invitee;
            let totalAdvance = response.data.total_advance;
            let totalRowCurrency = response.data.currency;

            this.setState({ totalCost });
            this.setState({ amountLeft });
            this.setState({ costPerGuest });
            this.setState({ totalAdvance });
            this.setState({ totalRowCurrency });
        })
            .catch(error => {
                console.log(error)
            })
    }



    openDeleteDialog = id => {
        let deleteExpense = { ...this.state.deleteExpense };
        deleteExpense = {
            openedDialog: true,
            deleteId: id
        };

        this.setState({ deleteExpense });
    };

    closeDeleteDialog = () => {
        let deleteExpense = { ...this.state.deleteExpense };
        deleteExpense = {
            openedDialog: false,
            deleteId: 0
        };

        this.setState({ deleteExpense });
    };


    openBudgetModal = selectedExpense => {
        this.setState({ isBudgetModalOpen: true })
        let currentExpense = { ...this.state.currentExpense };
        currentExpense = selectedExpense;
        this.setState({ currentExpense })
    }
    closeModal = () => {
        this.setState({ isBudgetModalOpen: false })
    }
    updateExpenseList = newExpense => {
        const notification = this.notificationSystem.current;
        let expenses = this.state.expenses.slice();

        const expenseIndex = expenses.findIndex(expense => expense.id === newExpense.id);

        if (expenseIndex !== -1) {
            expenses[expenseIndex] = { ...newExpense };
        } else {
            expenses.unshift(newExpense)
        }

        this.setState({ expenses }, () => {
            this.closeModal();
            notification.addNotification({
                message:
                    `Cheltuiala a fost ${expenseIndex !== -1 ? 'editată' : 'adaugată'} cu succes.`,
                level: 'success',
                position: 'tc'
            });
            this.calculateTotalRow();
        });

    }
    handleChange = event => {
        const field = event.target.name;
        const budgetData = { ...this.state.budgetData };
        budgetData[field] = event.target.value;
        this.setState({ budgetData });
    }
    editBudget = event => {
        const notification = this.notificationSystem.current;
        event.preventDefault();

        budgetService.updateBudget(this.state.budgetData)
            .then(response => {
                notification.addNotification({
                    message:
                        `Bugetul a fost editat cu succes.`,
                    level: 'success',
                    position: 'tc'
                });
                this.calculateTotalRow();
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        `A aparut o problemă la editare, faceți refresh la pagină sau contactați administratorul dacă problema persistă.`,
                    level: 'error',
                    position: 'tc'
                });
            })
    }
    calculateRestMoney = (totalPrice, advancePayment, costPerGuest) => {
        if (costPerGuest) {
            return (totalPrice * this.state.budgetData.expected_guests) - advancePayment;
        }
        else {
            return totalPrice - advancePayment;
        }

    }

    handleDelete = () => {
        const notification = this.notificationSystem.current;
        budgetService
            .deleteExpense(this.state.deleteExpense.deleteId)
            .then(() => {
                let expenses = this.state.expenses.slice();
                expenses = expenses.filter(
                    expense => expense.id !== this.state.deleteExpense.deleteId
                );
                this.setState({ expenses });

                this.closeDeleteDialog();

                notification.addNotification({
                    message: 'Cheltuiala a fost ștearsă cu success.',
                    level: 'success',
                    position: 'tc'
                });
                this.calculateTotalRow();
            })
            .catch(error => {
                notification.addNotification({
                    message:
                        'A aparut o problemă la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
                    level: 'error',
                    position: 'tc'
                });
            });
    };
    updateEventId = event => {
        const eventId = event.target.value

        localStorage.setItem('idLocalStorage', eventId)
        let idLocalStorage = this.state.idLocalStorage;
        idLocalStorage = eventId;
        this.setState({ idLocalStorage }, () => window.location.reload())
    }

    render() {

        return (
            <div className=' guests admin invitation budget'>
                <Menu menuItem="buget" />
                <AdminMobileHeader page="Adaugă buget" />
                <div className='admin__container budget__container'>
                    <div className='admin__top'>
                        <div className='admin__select-box'>

                            <select
                                className="admin__select"
                                title="tip"
                                value={this.state.idLocalStorage}
                                name="type"
                                onChange={this.updateEventId}
                                label='tip'
                            >
                                {this.state.events.map(event => (
                                    <option key={event.id} value={event.id}>{event.name}</option>
                                ))}
                            </select>
                        </div>
                        <img src='/images/couple.jpg' alt='couple' className='admin__couple-icon' />
                    </div>
                    <div className="admin__wrapper invitation__wrapper">
                        <div className=''>


                            <div className="admin__budget-form-wrapper">
                                <form className="admin__budget-form" onSubmit={this.editBudget}>
                                    <div>
                                        <h4 className='add-modal__label'>Moneda de bază</h4>
                                        <select
                                            className="add-modal__field"
                                            title="base_currency"
                                            value={this.state.budgetData.base_currency}
                                            name="base_currency"
                                            onChange={this.handleChange}
                                        >
                                            <option value="RON">Lei</option>
                                            <option value="EUR">Euro</option>
                                            <option value="USD">Dolari</option>
                                            <option value="GBP">Lire</option>

                                        </select>
                                    </div>
                                    <div>
                                        <h4 className='add-modal__label'>Nr. de invitați aproximat</h4>
                                        <input
                                            className='add-modal__field'
                                            type="text"
                                            name="expected_guests"
                                            onChange={this.handleChange}
                                            value={this.state.budgetData.expected_guests} />
                                    </div>
                                    <button type='submit' className="admin__add-budget">Editează buget</button>
                                </form>
                            </div>
                            <button className="only-mobile invitation__add-guest" onClick={() => this.openBudgetModal()}>Adaugă cheltuiala</button>
                            <div className="invitation__top-desktop only-desktop">
                                <h2 className="admin__title">Buget</h2>

                                <div className="invitation__top-right">


                                    <button className=" invitation__add-guest" onClick={() => this.openBudgetModal()}>Adaugă cheltuiala</button>
                                </div>
                            </div>



                        </div>


                        <div className="budget__table-container website-form__wedding-contacts">
                            <div className='guests__wrapper budget__wrapper'>
                                <div className="website-form__contact-head">
                                    <h4 >Tipul cheltuielii</h4>
                                    <h4 >Preț</h4>
                                    <h4>Cost per invitat</h4>
                                    <h4>Avans</h4>
                                    <h4 >Rămas de plata</h4>
                                    <h4 >Comentariu</h4>
                                    <h4 >Acțiuni</h4>
                                </div>
                                {this.state.expenses.length ? this.state.expenses.map(expense => (
                                    <div className='website-form__contact-row' key={expense.id}>
                                        <div className='website-form__cell'>
                                            <h4>{expense.name}</h4>
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>{expense.total_price} {currenciesJson[expense.currency]}</h4>
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>{expense.cost_per_guest ? 'Da' : 'Nu'}</h4>
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>{expense.advance_payment ? `${expense.advance_payment} ${currenciesJson[expense.currency]}` : '-'}</h4>
                                        </div>
                                        <div className='website-form__cell'>
                                            {expense.paid ? <div className="budget__paid-cell">
                                                <h4>Platit</h4>
                                                <img src='/images/yes.png' alt='paid' />
                                            </div> : <h4>{this.calculateRestMoney(expense.total_price, expense.advance_payment, expense.cost_per_guest)} {currenciesJson[expense.currency]}</h4>}
                                        </div>
                                        <div className='website-form__cell'>
                                            <h4>{expense.comment ? expense.comment : '-'}</h4>
                                        </div>
                                        <div className="website-form__actions website-form__cell budget__actions">
                                            {expense.document && expense.document.url ? (
                                                <div className="admin__tool-box">
                                                    <img data-tip='Contract furnizor' data-event='mouseenter' src="/images/file.png" alt="file" onClick={() =>
                                                        window.open(
                                                            `${process.env.REACT_APP_API_URL}${expense.document.url
                                                            }`
                                                        )
                                                    } />
                                                    <ReactTooltip globalEventOff='click' />
                                                </div>
                                            ) : (null)}

                                            <div className="admin__tool-box">
                                                <img data-tip='Editează' data-event='mouseenter' src='/images/edit-black.png' alt='edit' onClick={() => this.openBudgetModal(expense)} />
                                                <div className='admin__additional-info'>
                                                    <ReactTooltip globalEventOff='click' />
                                                </div>
                                            </div>
                                            <div className="admin__tool-box">
                                                <img data-tip='Șterge' data-event='mouseenter' src='/images/delete-black.png' alt='delete' onClick={() => this.openDeleteDialog(expense.id)} />
                                                <div className='admin__additional-info'>
                                                    <ReactTooltip globalEventOff='click' />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )) : <h4 className='standard__no-items'>Încă nu ai cheltuieli adăugate.</h4>}
                            </div>
                        </div>
                        {this.state.pagination && this.state.pagination.last_page > 1 && (
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pagination.last_page}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        )}
                        <div className="budget__orange-line" />

                        <div className="budget__total">
                            <div className="budget__box">
                                <h4 className="budget__general-info">
                                    Nunta va costa în total:
                                </h4>
                                <h4 className="budget__specific-info">
                                    {this.state.totalCost} {currenciesJson[this.state.totalRowCurrency]}
                                </h4>
                            </div>
                            <div className="budget__box">
                                <h4 className="budget__general-info">
                                    Avans total plătit:
                                </h4>
                                <h4 className="budget__specific-info">
                                    {this.state.totalAdvance} {currenciesJson[this.state.totalRowCurrency]}
                                </h4>
                            </div>
                            <div className="budget__box">
                                <h4 className="budget__general-info">
                                    Mai aveți de plătit:
                                </h4>
                                <h4 className="budget__specific-info">
                                    {this.state.amountLeft} {currenciesJson[this.state.totalRowCurrency]}
                                </h4>
                            </div>
                            <div className="budget__box">
                                <h4 className="budget__general-info">
                                    Costul unui invitat este de:
                                </h4>
                                <h4 className="budget__specific-info">
                                    {this.state.costPerGuest} {currenciesJson[this.state.totalRowCurrency]}
                                </h4>
                            </div>
                        </div>
                    </div>
                    {this.state.isBudgetModalOpen && <BudgetModal
                        modalIsOpen={this.state.isBudgetModalOpen}
                        closeModal={this.closeModal}
                        updateExpenseList={this.updateExpenseList}
                        eventId={this.state.currentEvent.id}
                        budgetId={this.state.budgetData.id}
                        expense={this.state.currentExpense}
                    />}
                </div>
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />

                <DeletePUGCertificates
                    opened={this.state.deleteExpense.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur ca doriti sa ștergeți acesta cheltuială?"
                    aditionalText="După ștergere, cheltuiala nu va mai putea fi recuperată."
                />

            </div>
        )
    }
}

export default Budget;
