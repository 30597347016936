import axios from 'axios';
import { loginService } from './LoginService';

export const passwordService={
sendEmail,
resetPassword
}
function sendEmail(userEmail){
    const requestOptionsPost = {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        data:userEmail
      };
  
      const data = userEmail;
  
      if (loginService.loggedIn()) {
          requestOptionsPost.headers['Authorization'] = loginService.getToken();
        }
  
      let url = `${process.env.REACT_APP_API_URL}/api/password/forgot`;
  
      return axios.post(url, data, requestOptionsPost);
}
function resetPassword(userData){
    const requestOptionsPost = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    const data = buildRequestData(userData)

    if (loginService.loggedIn()) {
        requestOptionsPost.headers['Authorization'] = loginService.getToken();
      }

    let url = `${process.env.REACT_APP_API_URL}/api/password/reset`;

    return axios.post(url, data, requestOptionsPost);
  }

  function buildRequestData(passwordData) {
    let formData = new FormData();

    formData.append('password', passwordData.password);
    formData.append('password_confirmation', passwordData.confirmPassword);
    formData.append('token', passwordData.token);



    return formData;
  }
