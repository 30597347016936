import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './AdminMenu.scss';
import '../../resources/main.scss';
import { loginService } from "../../services/LoginService";
import AddGuestModal from '../AddGuest/AddGuest';
import ContactUs from "../ContactUs/ContactUs"
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import { Badge } from 'antd'
import { notificationsService } from "../../services/NotificationsService";
import { eventService } from "../../services/EventService";

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAddGuestModalOpen: false,
            isContactModalOpen: false,
            answersSubmenu: false,
            configuratorSubmenu: false,
            events: [],
            currentEvent: {},
            notificationsCount: 0,
            idLocalStorage: ''
        }
        this.notificationSystem = React.createRef();
    }
    openAddGuestModal = () => {
        this.setState({ isAddGuestModalOpen: true });
    }
    openContactUsModal = () => {
        this.setState({ isContactModalOpen: true });
    }
    componentDidMount() {
        const storedId = parseInt(localStorage.getItem("idLocalStorage"));
        this.setState({ idLocalStorage: storedId }, () => {

            if (this.props.menuItem && this.props.menuItem.length && this.props.menuItem.includes("answers")) {
                this.setState({ answersSubmenu: true });
            }
            if (this.props.menuItem && this.props.menuItem.length && this.props.menuItem.includes("configur")) {
                this.setState({ configuratorSubmenu: true });
            }
            eventService.getEvents()
                .then(response => {
                    let events = [...this.state.events];
                    events = response.data.events;
                    this.setState({ events });
                    let selectedEvent = response.data.events.find(event => event.id == this.state.idLocalStorage);
                    let currentEvent = { ...this.state.currentEvent };
                    currentEvent = selectedEvent ? selectedEvent : response.data.events[0];
                    this.setState({ currentEvent }, () => {
                        notificationsService.getUnreadNotificationsCount(this.state.currentEvent.id)
                            .then(response => {
                                this.setState({ notificationsCount: response.data.notifications_count });
                            })
                            .catch(error => {
                                console.log(error);
                            });
                        ;


                    });

                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.props.history.push("/login");
                    }
                });
        });
    }

  
    closeModal = () => {
        this.setState({ isAddGuestModalOpen: false });
        this.setState({ isContactModalOpen: false });

    }
    toggleAnswersSubmenu = () => {
        let answersSubmenu = this.state.answersSubmenu;
        answersSubmenu = !answersSubmenu;
        this.setState({ answersSubmenu })
    }
    toggleConfiguratorSubmenu = () => {
        let configuratorSubmenu = this.state.configuratorSubmenu;
        configuratorSubmenu = !configuratorSubmenu;
        this.setState({ configuratorSubmenu })
    }
    logout = () => {
        const notification = this.notificationSystem.current;

        loginService
            .logout()
            .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('userProfile');
                this.props.history.push('/app/login');
            })
            .catch(error => {
                notification.addNotification({
                    message: 'Delogare nereusita. Va rog sa ne contactati daca problema persista.',
                    level: 'error',
                    position: 'tc'
                });
            });
    }
    render() {
        return (
            <div className='standard menu'>
                <Link to='/' className='standard__link'><img src='/images/logo.png' alt='logo' className='menu__logo' /></Link>
                <img src='/images/menu-gradient.png' alt='gradient' className='menu__gradient' />
                <ul className='menu__list'>
                    <Link className='standard__link' to='/app/dashboard'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-1.png' className='menu__img' alt='menu' />

                            <li className={this.props.menuItem === "dashboard" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Dashboard</li>

                        </div>
                    </Link>
                    <Link className='standard__link' to='/app/evenimente'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-2.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "events" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Evenimente</li>

                        </div>
                    </Link>
                    <Link className='standard__link' to='/app/to-do-list'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/todo-list.svg' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "to-do-list" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Lista to-do</li>

                        </div>
                    </Link>
                    <Link className='standard__link' to='/app/website-builder'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-3.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "website-builder" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Creare website</li>
                        </div>
                    </Link>
                    <Link className='standard__link' to='/app/adauga-invitat'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-4.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "add-guest" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Adauga invitat</li>
                        </div>
                    </Link>

                    <div className=' menu__item-with-submenu' onClick={() => this.toggleAnswersSubmenu()}>
                        <div className="menu__item-box"> <img src='/images/menu/menu-5.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "answers" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Răspunsuri</li></div>
                        <img src={this.state.answersSubmenu ? '/images/up.png' : '/images/down.png'} alt='down' className="menu__down" />


                    </div>

                    {this.state.answersSubmenu ? <div className="menu__submenu">
                        <Link className='standard__link' to='/app/raspunsuri' >
                            <div className='menu__item-box'>
                                <img src='/images/menu/submenu-1.png' className='menu__img submenu-img' alt='menu' />
                                <li className={this.props.menuItem === "answers" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Răspunsuri pozitive</li>
                            </div>
                        </Link>
                        <Link className='standard__link' to='/app/raspunsuri-negative' >
                            <div className='menu__item-box'>
                                <img src='/images/menu/submenu-2.png' className='menu__img submenu-img' alt='menu' />
                                <li className={this.props.menuItem === "answers-no" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Răspunsuri negative</li>
                            </div>
                        </Link>
                    </div> : ''}



                    <div className=' menu__item-with-submenu' onClick={() => this.toggleConfiguratorSubmenu()}>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-6.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "configurare-generala" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Mese și săli</li>
                        </div>
                        <img src={this.state.configuratorSubmenu ? '/images/up.png' : '/images/down.png'} alt='down' className="menu__down" />

                    </div>

                    {this.state.configuratorSubmenu ? <div className="menu__submenu">
                        <Link className='standard__link' to='/app/configurare-generala' >
                            <div className='menu__item-box'>
                                <img src='/images/menu/setting.png' className='menu__img submenu-img' alt='menu' />
                                <li className={this.props.menuItem === "configurare-generala" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Configurare generală</li>
                            </div>
                        </Link>
                        <Link className='standard__link' to='/app/tables-configurator' >
                            <div className='menu__item-box'>
                                <img src='/images/menu/dot.png' className='menu__img submenu-img' alt='menu' />
                                <li className={this.props.menuItem === "tables-configurator" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Aranjare mese</li>
                            </div>
                        </Link>
                    </div> : ''}
                    {/* <Link className='standard__link' to='/app/tables-configurator'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/menu-6.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "tables-configurator" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Mese si sali</li>
                        </div>
                    </Link> */}
                    <Link className='standard__link' to='/app/buget'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/budget.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "buget" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Buget</li>
                        </div>
                    </Link>
                    <Link className='standard__link' to='/app/registru-cadouri'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/present.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "registru-cadouri" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Registru de cadouri</li>
                        </div>
                    </Link>
                    <Link className='standard__link' to='/app/notificari'>
                        <div className='menu__item-box'>
                            <img src='/images/menu/bell.png' className='menu__img' alt='menu' />
                            <li className={this.props.menuItem === "Notificari" ? 'menu__orange menu__item-name' : 'menu__item-name'}>Notificări</li>
                            <Badge count={this.state.notificationsCount ? this.state.notificationsCount : '0'} className='menu__notifications'>

                            </Badge>
                        </div>
                    </Link>

                    <div className='menu__lower-container'>

                        <div className='menu__item-box' onClick={() => this.openContactUsModal()}>
                            <img src='/images/menu/contact.png' className='menu__img' alt='menu' />
                            <li className='menu__item-name'>Contactează-ne</li>
                        </div>
                        <div className='menu__item-box' onClick={() => this.logout()}>
                            <img src='/images/menu/logout.png' className='menu__img' alt='menu' />
                            <li className='menu__item-name' >Delogare</li>

                        </div>
                        <Link className='standard__link' to='/app/profil' >
                            <div className='menu__item-box'>
                                <img src='/images/password.png' className='menu__img' alt='menu' />
                                <li className='menu__item-name' >Profil</li>
                            </div>
                        </Link>
                    </div>


                </ul >
                <div className='menu__upgrade-container'>
                    <div className='menu__upgrade-box'>
                        {/* <h4 className='menu__upgrade-text'>Fă upgrade și bucura-te de tot
                            ce îți poate oferi aplicația noastră!</h4>
                        <Link to='' className='menu__upgrade-link'>
                            <h4 className='menu__upgrade-text'>Fă upgrade acum</h4>
                            <img src='/images/menu/arrow-right.png' />
                        </Link> */}
                        <img src='/images/menu/couple.png' alt='couple' className='menu__couple-img' />
                    </div>
                </div>
                {
                    this.state.isAddGuestModalOpen && <AddGuestModal
                        modalIsOpen={this.state.isAddGuestModalOpen}
                        closeModal={this.closeModal}
                    />
                }
                {
                    this.state.isContactModalOpen && <ContactUs
                        modalIsOpen={this.state.isContactModalOpen}
                        closeModal={this.closeModal}
                    />
                }
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div >
        )
    }
}

export default withRouter(Menu);
