import React from 'react';
import '../Homepage/Homepage.scss';
import { Link } from 'react-router-dom';
import '../../resources/main.scss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Countdown from 'react-countdown';
import SwipeableViews from 'react-swipeable-views';
import LazyLoad from 'react-lazy-load';
import couplesJson from '../Homepage/couples.json';
import MetaTags from 'react-meta-tags';

import OptimizedImg from '../../components/OptimizedImg/OptimizedImg';
import ChurchMap from '../../components/LocationsMap/ChurchMap';
import RestaurantMap from '../../components/LocationsMap/RestaurantMap';
import DaianChurchMap from '../../components/LocationsMap/DaianChurchMap';
import DaianRestaurantMap from '../../components/LocationsMap/DaianRestaurantMap';

class OldHomepage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
            couples: couplesJson,
            answer: {
                answerGiven: '',
                rotate: false,
                toggle: false,
                showWriting: true
            },
            selectedCouple: {},
            currentPathName: ''
        }
        this.rotatingDone = this.rotatingDone.bind(this);
    }
    handleChange = (event, tabValue) => {
        this.setState({
            tabValue
        });
    };
    handleChangeIndex = index => {
        this.setState({
            tabValue: index
        });
    };
    updateAnswer = answerValue => {
        const answer = { ...this.state.answer };
        answer.answerGiven = answerValue;
        this.setState({
            answer
        });
    }
    rotatingDone = () => {
        let rotate = this.state.rotate;
        rotate = false;
        let toggle = this.state.toggle;
        toggle = !toggle;
        let showWriting = this.state.showWriting;
        showWriting = false;
        this.setState({ rotate })
        this.setState({ toggle })
        this.setState({ showWriting })
    }
    componentDidMount() {
      console.log('here');
        const elm = this.image;
        elm.addEventListener("animationend", this.rotatingDone);

        let parameters = this.props.location.pathname;

        let selectedCouple = { ...this.state.selectedCouple };

        const firstSegment = parameters.split("/")[1];

        selectedCouple = this.state.couples.find(couple => couple.slug === firstSegment);
        this.setState({ selectedCouple });
    }
    componentWillUnmount() {
        // const elm = this.image;
        // elm.removeEventListener("animationend", this.rotatingDone);
    }
    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <p>Success</p>;
        } else {
            return <span style={{ display: 'flex' }}>{days} <span style={{ fontSize: '14px', paddingRight: '10px', color: '#1992D4' }}>D</span>:{hours}<span style={{ color: '#1992D4', fontSize: '14px', paddingRight: '10px' }}>H</span>:{minutes}<span style={{ color: '#1992D4', fontSize: '14px', paddingRight: '10px' }}>M</span>:{seconds}<span style={{ fontSize: '14px', paddingRight: '10px', color: '#1992D4' }}>S</span></span>;
        }
    }

    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    render() {
        return (
            <div className='standard homepage'>
                {this.state.selectedCouple.meta ? (
                    <MetaTags>
                        <title>{this.state.selectedCouple.meta.title}</title>
                        <meta name="description" content={this.state.selectedCouple.meta.description} />
                        <meta property="og:title" content={this.state.selectedCouple.meta.title} />
                        <meta property="og:description" content={this.state.selectedCouple.meta.description} />
                    </MetaTags>
                ) : ''}
                <div className='homepage__container'>
                    <div className='homepage__header'>
                        <div>
                            <h1 className='homepage__names'>{this.state.selectedCouple.couplesName}</h1>
                            {/* <h3 className='homepage__date standard__only-desktop'>Abia așteptăm să petrecem această zi specială alături de voi!</h3>
                            <h3 className='homepage__date standard__only-mobile'>25 septembrie 2021</h3> */}
                        </div>
                        <div className='homepage__header-info'>
                            <h3 className='homepage__date homepage__date-first standard__only-desktop'>{this.state.selectedCouple.eventDate}</h3>


                        </div>
                    </div>
                    <div className='homepage__story-title-box'>
                        <h2> {this.state.selectedCouple.storyTitle}</h2>
                        <h3>{this.state.selectedCouple.storySubtitle}</h3>
                    </div>
                    <div className='homepage__first-section'>
                        {this.state.selectedCouple.story ? (
                            <div className='homepage__story'>
                                <div className='homepage__story-1'>
                                    <div className='homepage__story-photos'>
                                        <div className='homepage__story-box'>
                                            <LazyLoad
                                                debounce={false}
                                                offsetVertical={500}
                                            >
                                                <img src={this.state.selectedCouple.story[0].img} className='homepage__story-img' alt='story' />
                                            </LazyLoad>
                                            <h4 className='homepage__writing'>{this.state.selectedCouple.story[0].storyText}</h4>
                                        </div>
                                        <div className='homepage__story-box'>
                                            <LazyLoad
                                                debounce={false}
                                                offsetVertical={500}
                                            >
                                                <img src={this.state.selectedCouple.story[1].img} className='homepage__story-img' alt='story' />
                                            </LazyLoad>
                                            <h4 className='homepage__writing'>{this.state.selectedCouple.story[1].storyText}</h4>
                                        </div>

                                    </div>
                                   {encodeURIComponent(this.state.selectedCouple.slug) ==='laura-cata'? <div className='homepage__daian-steps'>
                                        <div className='homepage__story-box-arrow'>
                                            <div className='homepage__story-box'>
                                                <img src='/images/arrow-7.svg' alt='arrow' className='homepage__daian-arrow' />
                                                <h4 className='homepage__writing'>{this.state.selectedCouple.catalinLine}</h4>
                                            </div>
                                        </div>
                                        <div className='homepage__story-box-arrow'>
                                            <div className='homepage__story-box'>
                                                <img src='/images/arrow-7.svg' alt='arrow' className='homepage__daian-arrow' />
                                                <h4 className='homepage__writing'>{this.state.selectedCouple.lauraLine}</h4>
                                            </div>
                                        </div>
                                    </div> : ''}
                                    <div className='homepage__story-arrows'>
                                        <img src='/images/sageata-1.svg' style={{ fill: 'red', stroke: 'red' }} alt='arrow' className='homepage__arrow' />
                                        <img src='/images/sageata-2.svg' alt='arrow' className='homepage__arrow' />
                                    </div>
                                </div>
                                <div className='homepage__story-2 homepage__daian-story-2'>
                                    <h4 className='homepage__writing homepage__daian-writing'>{this.state.selectedCouple.additionalLine}</h4>
                                    <div className='homepage__story-box'>
                                        <LazyLoad
                                            debounce={false}
                                            offsetVertical={500}
                                        >
                                            <img src={this.state.selectedCouple.story[2].img} className='homepage__story-img' alt='story' />
                                        </LazyLoad>
                                        <h4>{this.state.selectedCouple.story[2].storyText}</h4>
                                    </div>
                                </div>
                                <img src='/images/sageata-3.svg' alt='arrow' className='homepage__arrow homepage__arrow-3' />
                                <div className='homepage__story-3'>
                                    <div className='homepage__story-box homepage__story-box-4'>
                                        <LazyLoad
                                            debounce={false}
                                            offsetVertical={500}
                                        >
                                            <img src={this.state.selectedCouple.story[3].img} className='homepage__story-img' alt='story' />
                                        </LazyLoad>
                                        <h4>{this.state.selectedCouple.story[3].storyText}</h4>
                                    </div>
                                    <img src='/images/sageata-4.svg' alt='arrow' className='homepage__arrow homepage__arrow-4' />
                                    <div className='homepage__story-box  homepage__story-box-5'>
                                        <LazyLoad
                                            debounce={false}
                                            offsetVertical={500}
                                        >
                                            <img src={this.state.selectedCouple.story[4].img} className='homepage__story-img' alt='story' />
                                        </LazyLoad>
                                        <h4>{this.state.selectedCouple.story[4].storyText}</h4>
                                    </div>
                                    <img src='/images/sageata-4.svg' alt='arrow' className='homepage__arrow homepage__arrow-4 standard__only-desktop' />
                                    <div className='homepage__story-box standard__only-desktop'>
                                        <LazyLoad
                                            debounce={false}
                                            offsetVertical={500}
                                        >
                                            <img src={this.state.selectedCouple.story[5].img} className='homepage__story-img' alt='story' />
                                        </LazyLoad>
                                        <h4>{this.state.selectedCouple.story[5].storyText}</h4>
                                    </div>
                                </div>
                                < div className='homepage__story-box homepage__spot-story standard__only-mobile'>
                                    <LazyLoad
                                        debounce={false}
                                        offsetVertical={500}
                                    >
                                        <img src={this.state.selectedCouple.story[5].img} className='homepage__story-img' alt='story' />
                                    </LazyLoad>
                                    <h4>{this.state.selectedCouple.story[5].storyText}</h4>

                                </div>
                                <img src='/images/sageata-2.svg' alt='arrow' className='homepage__arrow homepage__arrow-6' />
                               {encodeURIComponent(this.state.selectedCouple.slug)==='laura-cata'?<h4 className='homepage__daian-writing homepage__daian-writing-2'>{this.state.selectedCouple.story[6].storyText}</h4>:'' }
                            </div>

                        ) : <div></div>

                        }

                        <img src='/images/arrow-7.svg' alt='arrow' className='homepage__last-arrow homepage__arrow-7' />

                        <div className='standard__only-desktop homepage__time-details'>
                            <h4 className='homepage__time-info'>{this.state.selectedCouple.officialDate} </h4>
                            <div className="homepage__countdown-box">
                                <Countdown date={new Date(this.state.selectedCouple.countDownNumber)} renderer={this.renderCountdown}></Countdown>
                            </div>
                            <div className='homepage__ask standard__only-desktop'>
                                <div className='homepage__title-box'>
                                    <h2 className='homepage__title'>Vii la nunta noastră?</h2>
                                    <div className='homepage__smudge' />
                                    <div className='standard__only-desktop' >
                                        <div className='homepage__answer'>
                                            <Link to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie'} className='standard__link' onClick={() => this.scrollToTop()}><h3>DA</h3></Link>
                                            <Link to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ'} className='standard__link' onClick={() => this.scrollToTop()}> <h3>NU</h3></Link>
                                        </div>
                                    </div>
                                </div>
                                <LazyLoad
                                    debounce={false}
                                    offsetVertical={500}
                                >
                                    <OptimizedImg src='/images/flower-3.png' imageUrlWebp='/images/flower-3.webp' className='standard__only-desktop homepage__flower' alt='flower' />
                                </LazyLoad>
                            </div>
                        </div>
                    </div>
                    {this.state.selectedCouple.slug === 'laura-cata' ?
                        <img src={this.state.selectedCouple.invitationPhoto} className='homepage__daian-invitation' alt="invitation" />
                        : <div className='homepage__invitation' onClick={() => this.setState({ rotate: true })}>
                            {this.state.selectedCouple.story ? (
                                <h4 className='homepage__invitation-title'>{this.state.selectedCouple.story[6].storyText}</h4>
                            ) : ''}
                            {/* <img src='/images/arrow-7.svg' alt='arrow' className='homepage__last-arrow'/> */}
                            <span>  <img
                                src={this.state.toggle ? this.state.selectedCouple.invitationPhoto : '/images/invitation.jpg'}
                                alt='invitation'
                                className={this.state.rotate ? ' homepage__invitation-img rotate' : 'homepage__invitation-img'}
                                ref={elm => { this.image = elm; }}
                                onClick={() => this.setState({ rotate: true })} /></span>
                            {this.state.toggle ? <div>
                                <h4>TE INVITĂM</h4>
                                <h4>LA NUNTĂ</h4></div> :
                                <div>
                                    <h4>DESCHIDE</h4>
                                    <h4>INVITAȚIA</h4>
                                </div>}
                        </div>}

                    <div className='homepage__ask standard__only-mobile'>
                        <div className='homepage__title-box'>
                            <h4 className='homepage__small-detail'>Deci...</h4>
                            <h2 className='homepage__title'>Vii la nunta noastră?</h2>
                            <div className='homepage__smudge' />
                            <div className='homepage__answer'>
                                <Link to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie'} className='standard__link' onClick={() => this.scrollToTop()}>  <h3>DA</h3></Link>
                                <Link to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ'} className='standard__link' onClick={() => this.scrollToTop()}><h3>NU</h3></Link>
                            </div>
                        </div>
                    </div>
                    {this.state.selectedCouple.locationInfo ? (
                        <div className='homepage__location-info'>
                            <Tabs
                                value={this.state.tabValue}
                                onChange={this.handleChange}
                                name="tabValue"
                                indicatorColor="none"
                                centered
                            >

                                {this.state.selectedCouple.slug === 'laura-cata' ? '' : <Tab label="Cununia civila" icon={<img src='/images/civilCeremony.png' alt='wedding' className='homepage__tab-icon' />}></Tab>}
                                <Tab label="Cununia religioasa" icon={<img src='/images/church.png' alt='wedding' className='homepage__tab-icon' />} />
                                <Tab label="Petrecerea" icon={<img src='/images/reception.png' alt='wedding' className='homepage__tab-icon' />} />
                            </Tabs>
                            <SwipeableViews
                                axis="x"
                                index={this.state.tabValue}
                                onChangeIndex={this.handleChangeIndex}

                            >
                                <div className='homepage__location-wrapper'>
                                    <div className='standard__only-desktop homepage__location-info-desktop'>
                                        <h4 className='homepage__location-title'>{this.state.selectedCouple.locationInfo[0].locationName}</h4>
                                        <div className='homepage__location-smaller--wrapper'>
                                            <div className='homepage__place'>
                                                <div className='homepage__location-box'>
                                                    <img src='/images/location.png' alt='location' />
                                                    <h5>{this.state.selectedCouple.locationInfo[0].locationAddress}</h5>
                                                </div>
                                                <div className='homepage__location-box'>
                                                    <img src='/images/date.png' alt='date' />
                                                    <h5>{this.state.selectedCouple.locationInfo[0].eventDate}</h5>
                                                </div>
                                                <div className='homepage__location-box'>
                                                    <img src='/images/clock.png' alt='clock' />
                                                    <h5>{this.state.selectedCouple.locationInfo[0].eventHour} </h5>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='homepage__location standard__only-mobile'>
                                        <h4 className='homepage__location-title'>{this.state.selectedCouple.locationInfo[0].locationName}</h4>
                                        <div className='homepage__location-wrapper'>
                                            <div>
                                                <div className='homepage__location-box'>
                                                    <img src='/images/location.png' alt='location' />
                                                    <h5>{this.state.selectedCouple.locationInfo[0].locationAddress}</h5>
                                                </div>
                                                <div className='homepage__location-box'>
                                                    <img src='/images/date.png' alt='date' />
                                                    <h5>{this.state.selectedCouple.locationInfo[0].eventDate}</h5>
                                                </div>
                                                <div className='homepage__location-box'>
                                                    <img src='/images/clock.png' alt='clock' />
                                                    <h5>{this.state.selectedCouple.locationInfo[0].eventHour} </h5>
                                                </div>
                                            </div>

                                            <div className='standard__only-mobile homepage__google-mobile-location'>
                                               {this.state.selectedCouple.slug==='laura-cata'? <DaianChurchMap/>:<RestaurantMap /> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='standard__only-desktop'>
                                        <LazyLoad
                                            debounce={false}
                                            offsetVertical={500}
                                        >
                                            <img src={this.state.selectedCouple.locationInfo[0].locationPhoto} alt='location' className='homepage__location-img' />
                                        </LazyLoad>
                                    </div>
                                    <div className='standard__only-desktop homepage__google-location'>
                                    {this.state.selectedCouple.slug==='laura-cata'? <DaianChurchMap/>:<RestaurantMap /> }
                                    </div>
                                </div>

                                <div>
                                    {this.state.selectedCouple.locationInfo[1] ? (
                                        <div className='homepage__location-wrapper'>
                                            <div className='standard__only-desktop homepage__location-info-desktop'>
                                                <h4 className='homepage__location-title homepage__church-title'>{this.state.selectedCouple.locationInfo[1].locationName}</h4>
                                                <div className='location-smaller-wrapper  homepage__church-wrapper'>
                                                    <div className='homepage__place'>
                                                        <div className='homepage__location-box'>
                                                            <img src='/images/location.png' alt='location' />
                                                            <h5>{this.state.selectedCouple.locationInfo[1].locationAddress}</h5>
                                                        </div>
                                                        <div className='homepage__location-box'>
                                                            <img src='/images/date.png' alt='date' />
                                                            <h5>{this.state.selectedCouple.locationInfo[1].eventDate}</h5>
                                                        </div>
                                                        <div className='homepage__location-box'>
                                                            <img src='/images/clock.png' alt='clock' />
                                                            <h5>{this.state.selectedCouple.locationInfo[1].eventHour} </h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='homepage__location standard__only-mobile'>
                                                <h4 className='homepage__location-title'>{this.state.selectedCouple.locationInfo[1].locationName}</h4>
                                                <div className='homepage__location-wrapper '>
                                                    <div>
                                                        <div className='homepage__location-box'>
                                                            <img src='/images/location.png' alt='location' />
                                                            <h5>{this.state.selectedCouple.locationInfo[1].locationAddress} </h5>
                                                        </div>
                                                        <div className='homepage__location-box'>
                                                            <img src='/images/date.png' alt='date' />
                                                            <h5>{this.state.selectedCouple.locationInfo[1].eventDate}</h5>
                                                        </div>
                                                        <div className='homepage__location-box'>
                                                            <img src='/images/clock.png' alt='clock' />
                                                            <h5>{this.state.selectedCouple.locationInfo[1].eventHour} </h5>
                                                        </div>
                                                    </div>

                                                    <div className='standard__only-mobile homepage__google-mobile-location'>
                                                       {this.state.selectedCouple.slug==='laura-cata'? <DaianRestaurantMap/>:<ChurchMap />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='standard__only-desktop'>
                                                <img src={this.state.selectedCouple.locationInfo[1].locationPhoto} alt='location' className='homepage__location-img' />
                                            </div>
                                            <div className='standard__only-desktop homepage__google-location'>
                                            {this.state.selectedCouple.slug==='laura-cata'? <DaianRestaurantMap/>:<ChurchMap />}
                                            </div>
                                        </div>
                                    ) : (
                                            <div>
                                                <div><div className='homepage__location standard__only-mobile'>
                                                    <h4 className='homepage__not-yet'>{this.state.selectedCouple.churchDetails}</h4>
                                                    <img src='/images/question.jpg' alt='question' className='homepage__question-img' />
                                                </div>
                                                    <div className='standard__only-desktop homepage__unknown-location'>
                                                        <h4 className='homepage__not-yet'>{this.state.selectedCouple.churchDetails}</h4>
                                                        <img src='/images/question.jpg' alt='question' className='homepage__question-img' />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                </div>

                                <div>
                                    {this.state.selectedCouple.locationInfo[2] ? <div className='homepage__location-wrapper'>
                                        <div className='standard__only-desktop homepage__location-info-desktop'>
                                            <h4 className='homepage__location-title homepage__church-title'>{this.state.selectedCouple.locationInfo[2].locationName}</h4>
                                            <div className='homepage__location-smaller-wrapper homepage__church-wrapper'>
                                                <div className='homepage__place'>
                                                    <div className='homepage__location-box'>
                                                        <img src='/images/location.png' alt='location' />
                                                        <h5>{this.state.selectedCouple.locationInfo[2].locationAddress} </h5>
                                                    </div>
                                                    <div className='homepage__location-box'>
                                                        <img src='/images/date.png' alt='date' />
                                                        <h5>{this.state.selectedCouple.locationInfo[2].eventDate}</h5>
                                                    </div>
                                                    <div className='homepage__location-box'>
                                                        <img src='/images/clock.png' alt='clock' />
                                                        <h5>{this.state.selectedCouple.locationInfo[2].eventHour}</h5>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='homepage__location standard__only-mobile'>
                                            <h4 className='homepage__location-title'>{this.state.selectedCouple.locationInfo[2].locationName}</h4>
                                            <div className='homepage__location-wrapper'>
                                                <div>
                                                    <div className='homepage__location-box'>
                                                        <img src='/images/location.png' alt='location' />
                                                        <h5>{this.state.selectedCouple.locationInfo[2].locationAddress}</h5>
                                                    </div>
                                                    <div className='homepage__location-box'>
                                                        <img src='/images/date.png' alt='date' />
                                                        <h5>{this.state.selectedCouple.locationInfo[2].eventDate}</h5>
                                                    </div>
                                                    <div className='homepage__location-box'>
                                                        <img src='/images/clock.png' alt='clock' />
                                                        <h5>{this.state.selectedCouple.locationInfo[2].eventHour} </h5>
                                                    </div>
                                                </div>

                                                <div className='standard__only-mobile homepage__google-mobile-location'>
                                                    <RestaurantMap />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='standard__only-desktop'>
                                            <LazyLoad
                                                debounce={false}
                                                offsetVertical={500}
                                            >
                                                <img src={this.state.selectedCouple.locationInfo[0].locationPhoto} alt='location' className='homepage__location-img' />
                                            </LazyLoad>
                                        </div>
                                        <div className='standard__only-desktop homepage__google-location'>
                                            <RestaurantMap />
                                        </div>
                                    </div> : <div><div className='homepage__location standard__only-mobile'>
                                        <h4 className='homepage__not-yet'>{this.state.selectedCouple.partyDetails}</h4>
                                        <img src='/images/question.jpg' alt='question' className='homepage__question-img' />
                                    </div>
                                            <div className='standard__only-desktop homepage__unknown-location'>
                                                <h4 className='homepage__not-yet'>{this.state.selectedCouple.partyDetails}</h4>
                                                <img src='/images/question.jpg' alt='question' className='homepage__question-img' />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </SwipeableViews>
                        </div>
                    ) : ''}
                    <div className="homepage__countdown-box standard__only-mobile">
                        <Countdown date={new Date(this.state.selectedCouple.countDownNumber)} renderer={this.renderCountdown}></Countdown>

                    </div>
                    <div className='homepage__contact'>
                        <div className='homepage__title-box'>
                            <h2 className='homepage__title'>În ziua nunții</h2>
                            <div className='homepage__smudge homepage__smudge-2' />
                        </div>
                        <h4 className='homepage__info'>Pentru orice întrebare vei avea, îi poți contacta pe:</h4>
                        {this.state.selectedCouple.contactPersons ?
                            <div className='homepage__contact-wrapper'>
                                <div className='homepage__contact-box'>
                                    <div>
                                        <img src='/images/person.png' alt='person' />
                                        <h4>{this.state.selectedCouple.contactPersons[0].name}</h4>
                                    </div>
                                    <div>
                                        <img src='/images/phone.png' alt='phone' />
                                        <a href={`tel:${this.state.selectedCouple.contactPersons[0].phone}`} className='standard__link'> <h4>{this.state.selectedCouple.contactPersons[0].phone}</h4></a>
                                    </div>
                                </div>
                                <div className='homepage__contact-box'>
                                    <div>
                                        <img src='/images/person.png' alt='person' />
                                        <h4>{this.state.selectedCouple.contactPersons[1].name}</h4>
                                    </div>
                                    <div>
                                        <img src='/images/phone.png' alt='phone' />
                                        <a href={`tel:${this.state.selectedCouple.contactPersons[1].phone}`} className='standard__link'> <h4>{this.state.selectedCouple.contactPersons[1].phone}</h4></a>
                                    </div>
                                </div>
                            </div> : ''}

                    </div>
                </div>
                <div className='standard__footer'>
                    <Link onClick={this.scrollToTop} to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-invitatie'} className='standard__link'><h4 className='standard__menu-item'>Formular pentru raspuns pozitiv</h4></Link>
                    <Link onClick={this.scrollToTop} to={'/' + encodeURIComponent(this.state.selectedCouple.slug) + '/formular-raspuns-negativ'} className='standard__link'><h4 className='standard__menu-item'>Formular pentru raspuns negativ</h4></Link>
                    <div className='standard__disclaimer'><h5>{this.state.selectedCouple.footerText1}</h5>
                        <h5> {this.state.selectedCouple.footerText2} </h5>
                    </div>
                    <div className='standard__footer-box'>
                        <a className='standard__link'
                            href="https://prometeus-tech.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <h5 className="footer__copyright">© Prometeus Tech 2023</h5></a>
                        <a className='standard__link'
                            href="https://www.facebook.com/CosminaHatmanu"
                            target="_blank"
                            rel="noopener noreferrer">
                            <h5 className="footer__copyright">Designed by Cosmina Hatmanu</h5></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default OldHomepage;
