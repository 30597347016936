import React from 'react';
import '../../resources/main.scss';
import './GuestModal.scss';
import Modal from 'react-modal';


const customStyles = {
    overlay: {
      zIndex: 12,
      backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      height: '85%',
    }
  };

class GuestModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            guestInfo:this.props.guest
        }
    }

    render(){
        return(
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="modal Data"
                    ariaHideApp={false}
                >
            <div className='standard guest-modal'>
                <img src='/images/close.png' className='guest-modal__close-icon' alt='close' onClick={this.props.closeModal}/>
                <h4 className='guest-modal__title'>{this.state.guestInfo.name}</h4>
                <div className='guest-modal__info-container'>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Telefon:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.phone}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Email:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.email}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Companei invitat:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.company}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Numar persoane:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.company_number}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Meniu:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.menus.map(menu => (
                                                <div key={menu.id}>
                                                    <h4>{menu.menu_type}</h4>
                                                </div>
                                            ))}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Alergii:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.allergy?this.state.guestInfo.allergy:'-' }</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Ce alergii:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.allergy_mentions?this.state.guestInfo.allergy_mentions: '-' }</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Alte restrictii:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.other_restrictions? this.state.guestInfo.other_restrictions:'-'}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Cazare:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.housing === '1' ? 'Da' : 'Nu'}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Transport:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.transport==='1' ?'Da':'Nu'}</h4>
                    </div>
                    <div className='guest-modal__info-box'>
                        <h4 className='guest-modal__generic-info'>Alte mentiuni:</h4>
                        <h4 className='guest-modal__specific-info'>{this.state.guestInfo.other ? this.state.guestInfo.other : '-'}</h4>
                    </div>
                </div>
            </div>
      </Modal>
            </div>
        )
    }
}

export default GuestModal;
