import React from 'react';
import './Header.scss';
import {Link} from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'
import * as Scroll from 'react-scroll';
import {withRouter} from 'react-router-dom';

class Header extends React.Component{
    scrollToBenefits = () => {
        const scroller = Scroll.scroller;

        scroller.scrollTo('benefits', {
          duration: 1000,
          smooth: true,
          spy: true
        })
      }
    render(){
        return(
            <div className='header'>
                <div className='header__container'>
                <Link className='standard__link' to='/'> <img src='/images/logo.png' alt='logo' className='header__logo'/></Link>
                <HamburgerMenu/>
                <div className='standard__only-desktop header__menu-items'>
                <ul className='header__menu'>
                    <Link className='standard__link' to='/'><li className={this.props.menuItem==='homepage'? 'header__item header__hightlight' :'header__item'}>Acasă</li></Link>
                    {this.props.location.pathname === '/' ? (
                      <Link className='standard__link' to='/'> <li className='header__item' onClick={() => this.scrollToBenefits()} >Beneficii</li></Link>
                    ) : (
                      <Link className='standard__link' to={`/?insidePageUrl=benefits`}><li className='header__item'>Beneficii</li></Link>
                    )}
                    <Link className='standard__link' to='/app/cununie-civila'><li className={this.props.menuItem==='cununie-civila'? 'header__item header__hightlight' :'header__item'}>Cununie civilă 101</li></Link>
                    <Link className='standard__link' to='/app/risipa-mancare'><li className={this.props.menuItem==='risipa-mancare'? 'header__item header__hightlight' :'header__item'}>Evită risipa alimentară</li></Link>

                    <Link className='standard__link' to='/app/creeaza-cont'><li className={this.props.menuItem==='creaza-cont'? 'header__item header__hightlight' :'header__item'}>Înregistrare</li></Link>
                    <Link className='standard__link' to='/app/login'><li className={this.props.menuItem==='creaza-cont'? 'header__item header__hightlight' :'header__item'}>Contul tău</li></Link>
                    <Link className='standard__link' to='/app/parteneri'><li className={this.props.menuItem==='parteneri'? 'header__item header__hightlight' :'header__item'}>Parteneri</li></Link>
                    <Link className='standard__link' to='/app/blog'><li className={this.props.menuItem==='blog'? 'header__item header__hightlight' :'header__item'}>Blog</li></Link>
                </ul>

                </div>
                <img src='/images/blog-banner.png' alt='shadow' className='standard__only-desktop header__gradient'/>
                </div>
            </div>
        )
    }
}
export default withRouter(Header);
